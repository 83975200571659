import { AnyAction } from 'redux';
//import { REMOVE_ASSET } from '../types/asset';
import {
  ADD_TICKET,
  UPDATE_TICKET,
  REMOVE_TICKET,
  SET_TICKETS,
  SET_SEARCH_OPTIONS,
  RESET_SEARCH_OPTIONS,
  SET_SEARCH_OPTIONS_TYPE,
  TicketSearchParameters,
  TicketDetails,
} from '../types/tickets';

export const assignedToMeAndOpen: TicketSearchParameters = {
  assignedTo: 'me',
  createdBy: '',
  category: '',
  subcategory: '',
  status: 'open',
  createdAt: [null, null],
  updatedAt: [null, null],
  closedAt: [null, null],
  orderBy: 'updatedAt',
  orderDirection: 'desc',
  limit: 10,
  offset: 0,
};

export const createdbyMe: TicketSearchParameters = {
  ...assignedToMeAndOpen,
  status: '',
  assignedTo: null,
  createdBy: 'me',
};

export const all: TicketSearchParameters = {
  ...assignedToMeAndOpen,
  assignedTo: null,
  status: '',
  orderBy: 'updatedAt',
};

export const unallocatedOpen: TicketSearchParameters = {
  ...assignedToMeAndOpen,
  assignedTo: 'none',
  status: 'open',
};

export const allocatedOpen: TicketSearchParameters = {
  ...unallocatedOpen,
  assignedTo: 'not-none',
  orderBy: 'assignedAt',
};

export const recentlyClosed: TicketSearchParameters = {
  status: 'close',
  orderBy: 'closedAt',
};

export const recentlyCreated: TicketSearchParameters = {
  ...all,
  orderBy: 'createdAt',
};

export interface TicketState {
  tickets: Array<TicketDetails>;
  searchOptions: TicketSearchParameters;
  searchOptionType: string;
}

export const searchOptionsType: Record<string, TicketSearchParameters> = {
  assignedToMeAndOpen: assignedToMeAndOpen,
  createdByMe: createdbyMe,
  all: all,
  unallocatedOpen: unallocatedOpen,
  allocatedOpen: allocatedOpen,
  recentlyClosed: recentlyClosed,
  recentlyCreated: recentlyCreated,
};

const initialState: TicketState = {
  tickets: [],
  searchOptions: createdbyMe,
  searchOptionType: 'createdByMe',
};

export default function ticketsReducer(state = initialState, action: AnyAction): TicketState {
  switch (action.type) {
    case ADD_TICKET:
      return {
        ...state,
        tickets: [action.ticket, ...state.tickets],
      };
    case REMOVE_TICKET:
      return {
        ...state,
        tickets: state.tickets.filter((ticket: TicketDetails) => ticket.id !== action.ticketId),
      };
    case UPDATE_TICKET:
      return {
        ...state,
        tickets: state.tickets.map((ticket: TicketDetails) =>
          ticket.id === action.ticket.id ? { ...ticket, ...action.ticket } : ticket,
        ),
      };
    case SET_TICKETS:
      return {
        ...state,
        tickets: action.tickets,
      };
    case SET_SEARCH_OPTIONS:
      return {
        ...state,
        searchOptions: { ...action.searchOptions },
        searchOptionType: 'custom',
      };

    case RESET_SEARCH_OPTIONS:
      return {
        ...state,
        searchOptions: assignedToMeAndOpen,
        searchOptionType: 'assignedToMeAndOpen',
      };
    case SET_SEARCH_OPTIONS_TYPE:
      return {
        ...state,
        searchOptionType: action.searchOptionType,
        searchOptions: {
          ...(searchOptionsType[action.searchOptionType] || assignedToMeAndOpen),
        },
      };
  }
  return state;
}
