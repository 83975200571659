import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux-store/reducers';
import ReuseableLayoutHeader from '../../common/layouts';
import FilteredAutocompleteMultiple from '../../forms/Autocomplete/filtered-autocomplete-multiple';
import { getAllResourceCosts } from '../actions';
import {
  billableHours,
  costPerHour,
  CtcDataValues,
  Designations,
  LIST_RESOURCE_COSTS_SEARCH_OPTIONS,
  RESET_RESOURCE_COSTS_SEARCH_OPTIONS,
} from '../constants';
import { ResourceCostState } from '../store/ResourceCostReducer';
import { EmployeeCosts } from '../types';

export default function Cost_Billing_Filters(): JSX.Element {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { searchOptions } = useSelector<RootState, ResourceCostState>(
    (state) => state.delivery.resourceCosts,
  );
  const [resourceCostList, setResourceCostList] = useState<EmployeeCosts[]>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedEmployees, setSelectedEmployees] = useState<Array<any>>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedDesignation, setSelectedDesignation] = useState<Array<any>>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedCtc, setSelectedCtc] = useState<Array<any>>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedBillableHours, setSelectedBillableHours] = useState<Array<any>>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedPerHourRate, setSelectedPerHourRate] = useState<Array<any>>([]);

  function onDesignationValuesChanged(values: Array<string>) {
    setSelectedDesignation(values);
  }
  function onCtcValuesChanged(values: Array<string>) {
    setSelectedCtc(values);
  }
  function onEmployeesValuesChanged(values: Array<string>) {
    setSelectedEmployees(values);
  }
  function onBillableHoursValuesChanged(values: Array<string>) {
    setSelectedBillableHours(values);
  }
  function onPerHourRateValuesChanged(values: Array<string>) {
    setSelectedPerHourRate(values);
  }
  useEffect(() => {
    async function fetchUsers() {
      try {
        const resourceCostList_ = await dispatch(getAllResourceCosts());
        setResourceCostList(resourceCostList_ as unknown as EmployeeCosts[]);
      } catch (error) {
        enqueueSnackbar((error as Error).message, { variant: 'error' });
      }
    }
    fetchUsers();
  }, []);

  useEffect(() => {
    if (selectedEmployees.length > 0) {
      dispatch({
        type: LIST_RESOURCE_COSTS_SEARCH_OPTIONS,
        searchOptions: {
          ...searchOptions,
          employee: selectedEmployees.map((employee) => employee.id).join(','),
        },
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { employee, ...rest } = searchOptions;
      dispatch({
        type: LIST_RESOURCE_COSTS_SEARCH_OPTIONS,
        searchOptions: {
          ...rest,
        },
      });
    }
  }, [selectedEmployees]);

  useEffect(() => {
    if (selectedDesignation.length > 0) {
      dispatch({
        type: LIST_RESOURCE_COSTS_SEARCH_OPTIONS,
        searchOptions: {
          ...searchOptions,
          designation: selectedDesignation.map((designation) => designation.id).join(','),
        },
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { designation, ...rest } = searchOptions;
      dispatch({
        type: LIST_RESOURCE_COSTS_SEARCH_OPTIONS,
        searchOptions: {
          ...rest,
        },
      });
    }
  }, [selectedDesignation]);

  useEffect(() => {
    if (selectedCtc.length > 0) {
      dispatch({
        type: LIST_RESOURCE_COSTS_SEARCH_OPTIONS,
        searchOptions: {
          ...searchOptions,
          CTC: selectedCtc.map((ctc) => ctc.id).join(','),
        },
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { CTC, ...rest } = searchOptions;
      dispatch({
        type: LIST_RESOURCE_COSTS_SEARCH_OPTIONS,
        ...rest,
      });
    }
  }, [selectedCtc]);

  useEffect(() => {
    if (selectedBillableHours.length > 0) {
      dispatch({
        type: LIST_RESOURCE_COSTS_SEARCH_OPTIONS,
        searchOptions: {
          ...searchOptions,
          billableHours: selectedBillableHours.map((billableHours) => billableHours.id).join(','),
        },
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { billableHours, ...rest } = searchOptions;
      dispatch({
        type: LIST_RESOURCE_COSTS_SEARCH_OPTIONS,
        ...rest,
      });
    }
  }, [selectedBillableHours]);

  useEffect(() => {
    if (selectedPerHourRate.length > 0) {
      dispatch({
        type: LIST_RESOURCE_COSTS_SEARCH_OPTIONS,
        searchOptions: {
          ...searchOptions,
          costPerHour: selectedPerHourRate.map((costPerHr) => costPerHr.id).join(','),
        },
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { costPerHour, ...rest } = searchOptions;
      dispatch({
        type: LIST_RESOURCE_COSTS_SEARCH_OPTIONS,
        ...rest,
      });
    }
  }, [selectedPerHourRate]);

  const designations = Designations.map((role) => {
    return {
      name: role.label,
      id: role.value,
    };
  });

  const employees = resourceCostList?.map((employee) => {
    return {
      name: employee.employee.name,
      id: employee.employee.id,
    };
  });

  const showClearButton =
    selectedEmployees.length > 0 ||
    selectedDesignation.length > 0 ||
    selectedCtc.length > 0 ||
    selectedBillableHours.length > 0 ||
    selectedPerHourRate.length > 0;

  const handleClear = () => {
    setSelectedEmployees([]),
      setSelectedDesignation([]),
      setSelectedCtc([]),
      setSelectedBillableHours([]),
      setSelectedPerHourRate([]),
      dispatch({
        type: RESET_RESOURCE_COSTS_SEARCH_OPTIONS,
      });
  };

  return (
    <>
      <ReuseableLayoutHeader>
        <FilteredAutocompleteMultiple
          label="Names"
          searchPlaceholder="Search Employees"
          options={employees as Array<{ name: string; id: string }>}
          value={selectedEmployees}
          filterId="name-id"
          onChange={onEmployeesValuesChanged}
          sx={{ minWidth: 240 }}
        />
        <FilteredAutocompleteMultiple
          label="Designation"
          searchPlaceholder="Search Designation"
          options={designations}
          value={selectedDesignation}
          filterId="designation-id"
          onChange={onDesignationValuesChanged}
          sx={{ minWidth: 225 }}
        />
        <FilteredAutocompleteMultiple
          label="Billable Hours"
          options={billableHours}
          value={selectedBillableHours}
          filterId="billing-id"
          onChange={onBillableHoursValuesChanged}
          disableSearch={true}
        />
        <FilteredAutocompleteMultiple
          label="CTC"
          searchPlaceholder="Search CTC"
          options={CtcDataValues}
          value={selectedCtc}
          filterId="ctc-id"
          onChange={onCtcValuesChanged}
        />

        <FilteredAutocompleteMultiple
          label="Cost Per Hour"
          searchPlaceholder="Search Week"
          options={costPerHour}
          filterId="costPerHour-id"
          value={selectedPerHourRate}
          onChange={onPerHourRateValuesChanged}
          disableSearch={true}
        />
      </ReuseableLayoutHeader>
      {showClearButton && (
        <Button variant="text" sx={{ ml: 1 }} onClick={handleClear}>
          Clear
        </Button>
      )}
    </>
  );
}
