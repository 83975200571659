import * as Yup from 'yup';

import { Form, Formik } from 'formik';
import { Box } from '@mui/material';

import FormDatePicker from '../../forms/FormDatePicker';
import FormTextInput from '../../forms/FormTextInput';
import { SubmitButton } from '../../forms/Button';
import FormSelect from '../../forms/FormSelect';

import { CampaignFormBody } from '../types';

const Schema = Yup.object().shape({
  name: Yup.string().required('Required Name!'),
  position: Yup.string().required('Required Position!'),
  description: Yup.string().required('Required Description!'),
  status: Yup.string().required('Required Status!'),
  startDate: Yup.date().required('Required startDate!'),
  endDate: Yup.date().required('Required endDate!'),
});
export type CampaignFormProps = {
  onSubmit: (values: CampaignFormBody) => void;
  initialValues: CampaignFormBody;
};
export default function CampaignForm({ onSubmit, initialValues }: CampaignFormProps): JSX.Element {
  return (
    <Formik
      initialValues={initialValues}
      validateOnMount={false}
      validationSchema={Schema}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <FormTextInput
            label="Name"
            name="name"
            type="text"
            placeholder="Name"
            disabled={isSubmitting}
          />
          <FormTextInput
            label="Position"
            name="position"
            type="text"
            placeholder="Position"
            disabled={isSubmitting}
          />
          <FormTextInput
            label="Description"
            name="description"
            type="text"
            placeholder="Description"
            disabled={isSubmitting}
          />
          <FormSelect
            options={['active', 'inactive'].map((status) => ({
              value: status,
              label: status,
            }))}
            name="status"
            label="Status"
            disabled={isSubmitting}
          />
          <FormDatePicker label="StartDate" disabled={isSubmitting} name="startDate" />
          <FormDatePicker label="EndDate" disabled={isSubmitting} name="endDate" />
          <Box sx={{ m: '10px', float: 'right' }}>
            <SubmitButton label="Submit" />
          </Box>
        </Form>
      )}
    </Formik>
  );
}
