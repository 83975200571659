import {
  Box,
  Button,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  SelectProps,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { RootStateType } from '../../../redux-store/reducers';
import { formatShortDate } from '../../../utils/date';
import { ButtonPrimary } from '../../forms/Button';
import { NotesState } from '../store/notes-reducer';
import { SET_SEARCH_OPTIONS_TYPE } from '../types';
import { onGetNotes } from './actions';
import NotesSearchOptions from './searchOptions';

export default function NotesList(): JSX.Element {
  const { notes, searchOptions, searchOptionType } = useSelector<RootStateType, NotesState>(
    (state) => state.ats.notes,
  );
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [showProgress, setShowProgress] = useState(false);
  const [openOptions, setOpenOptionsType] = useState(false);
  useEffect(() => {
    async function getNotes() {
      try {
        setShowProgress(true);
        await dispatch(onGetNotes(searchOptions));
      } catch (error) {
        enqueueSnackbar((error as Error).message, { variant: 'error' });
      } finally {
        setShowProgress(false);
      }
    }
    getNotes();
  }, [searchOptions]);

  const handleOptionsTypeChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    if (value === 'custom') {
      setOpenOptionsType(true);
    } else {
      dispatch({ type: SET_SEARCH_OPTIONS_TYPE, searchOptionType: value });
    }
  };
  return (
    <>
      <Box>
        <NotesSearchOptions
          open={openOptions}
          onClose={() => {
            setOpenOptionsType(false);
          }}
        />
        <Typography variant="h4" sx={{ mb: 2 }}>
          Notes
        </Typography>
        <Stack spacing={2} direction="row" justifyContent="space-between" sx={{ pb: 2 }}>
          <Stack spacing={2} direction="row">
            <SearchOptionsSelect
              defaultValue={searchOptionType}
              onChange={handleOptionsTypeChange}
              autoWidth
            />
            <ButtonPrimary
              variant="outlined"
              onClick={() => {
                setOpenOptionsType(true);
              }}
            >
              Filters
            </ButtonPrimary>
          </Stack>
          <Button
            component={Link}
            to={{ pathname: `${url}/add`, state: { background: location } }}
            variant="outlined"
            fullWidth={false}
          >
            Create Note
          </Button>
        </Stack>
        {showProgress && <LinearProgress />}
        <TableContainer component={Paper}>
          <Table aria-label="Notes list">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Candidate</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Note</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Entered At</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Entered By</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notes?.map((note) => (
                <TableRow key={note.id}>
                  <TableCell>
                    <Button
                      to={{
                        pathname: `/admin/ats/candidates/${note.candidate.id}`,
                        state: { background: { pathname: `/admin/ats/candidates` } },
                      }}
                      component={RouterLink}
                      sx={{ textTransform: 'none' }}
                    >
                      {note.candidate.name}
                    </Button>
                  </TableCell>
                  <TableCell>
                    {note.note.length < 140 ? note.note : `${note.note.substring(0, 140)}...`}
                  </TableCell>
                  <TableCell>{formatShortDate(note.createdAt)}</TableCell>
                  <TableCell>{note.enteredBy.name}</TableCell>
                  <TableCell>
                    <Button
                      to={{
                        pathname: `${url}/${note.id}`,
                        state: { background: location },
                      }}
                      component={Link}
                      sx={{ textTransform: 'none' }}
                    >
                      View
                    </Button>
                    <Button
                      to={{
                        pathname: `${url}/update/${note.id}`,
                        state: {
                          background: location,
                          candidateId: note.candidate.id,
                          id: note.id,
                        },
                      }}
                      component={Link}
                      sx={{ textTransform: 'none' }}
                    >
                      Update
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
function SearchOptionsSelect(props: SelectProps<string>) {
  return (
    <Select label="Quick Filters" variant="standard" {...props}>
      <MenuItem value="allNotes">All Notes</MenuItem>
      <MenuItem value="custom">Custom</MenuItem>
    </Select>
  );
}
