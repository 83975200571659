import { PermissionsAction, PermissionsList, SET_PERMISSIONS } from '../types';

const initialState: PermissionsList = null;
export default function reducer(
  state: PermissionsList = initialState,
  action: PermissionsAction,
): PermissionsList {
  switch (action.type) {
    case SET_PERMISSIONS:
      return action.payload;
    case 'LOGOUT_SUCCESS':
      return initialState;
    default:
      return state;
  }
}
