import { Box, Drawer } from '@mui/material';
import { Location } from 'history';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router';
import GroupDetail from './detail';
import GroupsList from './list';
import CreateGroup from './create';
import UpdateGroup from './update';
import AddMember from './add-member';
import AddSubGroup from './add-subgroup';
import GroupPermissions from './permissions';

export default function GroupsRouter(): JSX.Element {
  const { path } = useRouteMatch();
  const history = useHistory();
  const location = useLocation<{ background: Location }>();
  const background = location.state && location.state.background;
  return (
    <>
      <Switch location={background || location}>
        <Route path={`${path}/add`} component={CreateGroup} />
        <Route path={`${path}/update/:id`} component={UpdateGroup} />
        <Route path={`${path}/:id/add-member`} component={AddMember} />
        <Route path={`${path}/:id/add-subgroup`} component={AddSubGroup} />
        <Route path={`${path}/:id/permissions`} component={GroupPermissions} />
        <Route path={`${path}/:id`} component={GroupDetail} />
        <Route exact path={path} component={GroupsList} />
      </Switch>
      <Drawer
        open={!!background}
        anchor="right"
        onClose={() => {
          history.goBack();
        }}
      >
        <Box sx={{ width: 420 }} role="presentation">
          <Switch>
            <Route exact path={`${path}/add`} component={CreateGroup} />
            <Route path={`${path}/update/:id`} component={UpdateGroup} />
            <Route path={`${path}/:id/add-member`} component={AddMember} />
            <Route path={`${path}/:id/add-subgroup`} component={AddSubGroup} />
            <Route path={`${path}/:id/permissions`} component={GroupPermissions} />
            <Route exact path={`${path}/:id`} component={GroupDetail} />
          </Switch>
        </Box>
      </Drawer>
    </>
  );
}
