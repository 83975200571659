import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Box, Grid, Typography } from '@mui/material';
import FormTextInput from '../../forms/FormTextInput';
import { SubmitButton } from '../../forms/Button';
import { UpdateBody } from '../types';
import FormCheckbox from '../../forms/FormCheckbox';
import FormUsersPicker from '../../forms/FormUsersPicker';
import FormSelect from '../../forms/FormSelect';
import { Designations } from '../constants';

export type FormProps = {
  onSubmit: (values: UpdateBody) => void;
  initialValues: UpdateBody;
};

const validationSchema = Yup.object().shape({
  capacity: Yup.number().required('Billing hours are required'),
  ctc: Yup.number().required('Cost Per Hour is required'),
  expectedBillingPerHour: Yup.number().required('Billing Per Hour is required'),
  trackTime: Yup.boolean().required('Track Time is required'),
});

const designations = Designations.map((designation) => ({
  value: designation.value,
  label: designation.label,
}));

export default function UpdateForm({ initialValues, onSubmit }: FormProps): JSX.Element {
  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {() => (
          <Form>
            <Box sx={{ px: 8, '& .MuiGrid-container': { alignItems: 'center', mb: 2 } }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  <Typography variant="h5">Employee Name</Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <FormUsersPicker name="name" />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  <Typography variant="h5">Employee Role</Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <FormSelect name="role" options={designations} />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  <Typography variant="h5">Billing Hours</Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <FormTextInput name="capacity" type="number" />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  <Typography variant="h5">CTC (per year)</Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <FormTextInput name="ctc" type="number" />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  <Typography variant="h5">Billing Per Hour</Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <FormTextInput name="expectedBillingPerHour" type="number" />
                </Grid>
              </Grid>
              <FormCheckbox name="trackTime" label="Track Time" />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}></Grid>
                <Grid item xs={12} sm={7}>
                  <SubmitButton label="Update" sx={{ minWidth: 165 }} />
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
}
