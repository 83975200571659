/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
//import { useDispatch } from 'react-redux';
import { CALL_API } from '../middleware';

import {
  ADD_TICKET,
  AssignedObject,
  SET_TICKETS,
  TicketDetails,
  TicketPayload,
  TicketSearchParameters,
  UPDATE_TICKET,
} from '../types/tickets';

//const dispatch = useDispatch();

export const defaultSearchOptions: TicketSearchParameters = {
  assignedTo: '',
  createdBy: '',
  category: '',
  subcategory: '',
  status: '',
  createdAt: [null, null],
  updatedAt: [null, null],
  closedAt: [null, null],
  orderBy: '',
  orderDirection: 'asc',
  limit: 10,
  offset: 0,
};

export const onGetTickets =
  (searchOptions = defaultSearchOptions) =>
  async (dispatch: any) => {
    try {
      const searchParams = new URLSearchParams();

      if (searchOptions.assignedTo) {
        searchParams.append('assignedTo', searchOptions.assignedTo);
      }
      if (searchOptions.createdBy) {
        searchParams.append('createdBy', searchOptions.createdBy);
      }
      if (searchOptions.category) {
        searchParams.append('category', searchOptions.category);
      }
      if (searchOptions.subcategory) {
        searchParams.append('subcategory', searchOptions.subcategory);
      }
      if (searchOptions.status) {
        searchParams.append('status', searchOptions.status);
      }
      if (searchOptions.createdAt) {
        const [createdAfter, createdBefore] = searchOptions.createdAt;
        if (createdAfter) {
          searchParams.append('createdAfter', createdAfter.toISOString());
        }
        if (createdBefore) {
          searchParams.append('createdBefore', createdBefore.toISOString());
        }
      }
      if (searchOptions.updatedAt) {
        const [updatedAfter, updatedBefore] = searchOptions.updatedAt;
        if (updatedAfter) {
          searchParams.append('updatedAfter', updatedAfter.toISOString());
        }
        if (updatedBefore) {
          searchParams.append('updatedBefore', updatedBefore.toISOString());
        }
      }
      if (searchOptions.closedAt) {
        const [closedAfter, closedBefore] = searchOptions.closedAt;
        if (closedAfter) {
          searchParams.append('closedAfter', closedAfter.toISOString());
        }
        if (closedBefore) {
          searchParams.append('closedBefore', closedBefore.toISOString());
        }
      }

      if (searchOptions.orderBy) {
        searchParams.append('orderBy', searchOptions.orderBy);
      }
      if (searchOptions.orderDirection) {
        searchParams.append('orderDirection', searchOptions.orderDirection);
      }
      if (searchOptions.limit) {
        searchParams.append('limit', searchOptions.limit.toString());
      }
      if (searchOptions.offset) {
        searchParams.append('offset', searchOptions.offset.toString());
      }

      const tickets = await dispatch({
        [CALL_API]: {
          url: `/tickets/tickets?${searchParams.toString()}`,
          method: 'GET',
        },
      });
      dispatch({
        type: SET_TICKETS,
        tickets,
      });
      return tickets as TicketDetails[];
    } catch (error) {
      alert(`${error}`);
      return [];
    }
  };

export const getTicketsbyId = (ticketsId: string) => async (dispatch: any) => {
  try {
    const ticketsById = await dispatch({
      [CALL_API]: {
        url: `/tickets/tickets/${ticketsId}`,
        method: 'GET',
      },
    });
    return ticketsById as TicketDetails;
  } catch (error) {
    alert(error);
  }
};

export const updateTicket =
  (id?: string, title?: string, status?: string, assignedTo?: AssignedObject) =>
  async (dispatch: any) => {
    const a = { id: assignedTo?.id };
    try {
      const updatedTicket = await dispatch({
        [CALL_API]: {
          url: `/tickets/tickets/${id}`,
          method: 'PUT',
          body: { title, status, assignedTo: a },
        },
      });
      dispatch({
        type: UPDATE_TICKET,
        ticket: updatedTicket,
      });
      return updatedTicket;
    } catch (error) {
      throw new Error('Error updating ticket. ' + error);
    }
  };

export const onCreateTickets = (ticketformbody: TicketPayload) => async (dispatch: any) => {
  try {
    const ticket = await dispatch({
      [CALL_API]: {
        url: `/tickets/tickets`,
        method: 'POST',
        types: [],
        body: ticketformbody as TicketPayload,
      },
    });
    dispatch({
      type: ADD_TICKET,
      ticket,
    });
    return ticket;
    return ticket as TicketPayload;
  } catch (error) {
    alert(`${error}`);
  }
};

export const getCategories = () => async (dispatch: any) => {
  try {
    const categories = await dispatch({
      [CALL_API]: {
        url: `/tickets/tickets/categories`,
        method: 'GET',
        types: [],
      },
    });
    return categories;
  } catch (error) {
    console.error(error);
  }
};

export const getSubCategories = (category: string) => async (dispatch: any) => {
  try {
    const subCategories = await dispatch({
      [CALL_API]: {
        url: `/tickets/tickets/categories/${category}`,
        method: 'GET',
      },
    });
    return subCategories;
  } catch (error) {
    console.error(error);
  }
};

export const updateComment = (id: string, title: string) => async (dispatch: any) => {
  try {
    const updateComment = await dispatch({
      [CALL_API]: {
        url: `/tickets/tickets/${id}`,
        method: 'PUT',
        body: { title },
      },
    });
    dispatch({
      type: UPDATE_TICKET,
      ticket: updateComment,
    });
    return updateComment;
  } catch (error) {
    alert(error);
  }
};
