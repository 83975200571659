import {
  DialogProps,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  Typography,
  Divider,
} from '@mui/material';
import * as yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import AutocompleteField from '../../forms/Autocomplete/field';
import { SOWType, Task, TaskWithSOW } from '../types';
import { FormEvent, MouseEvent, useRef } from 'react';
import { removeDuplicates } from '../constants';
import CloseIcon from '@mui/icons-material/Close';

export type TaskSelectionProps = {
  task: TaskWithSOW;
  sow: SOWType;
};

const initialValues: TaskSelectionProps = {
  task: {
    id: '',
    name: '',
    sow: {
      id: '',
      name: '',
      project: {
        id: '',
        name: '',
      },
    },
  },
  sow: {
    id: '',
    name: '',
    project: {
      id: '',
      name: '',
    },
  },
};

const ValidationSchema = yup.object().shape({
  sow: yup
    .object()
    .shape({
      id: yup.string().required('SOW is required'),
    })
    .required('SOW is required'),
  task: yup
    .object()
    .shape({
      id: yup.string().required('Task is required'),
    })
    .required('Task is required'),
});

type TaskPickerDialogProps = Omit<DialogProps, 'onSubmit'> & {
  sows: Array<SOWType>;
  onSubmit: (selection: TaskSelectionProps) => void;
};

export default function TaskPickerDialog({
  sows,
  onClose,
  onSubmit,
  ...props
}: TaskPickerDialogProps): JSX.Element {
  const sortedSOWs = removeDuplicates(
    sows
      .sort((a, b) => a.project.name.localeCompare(b.project.name))
      .map(({ tasks = [], ...rest }) => ({
        ...rest,
        tasks: tasks.sort(
          (t1, t2) =>
            (t2.billable ? 1 : 0) - (t1.billable ? 1 : 0) || t1.name.localeCompare(t2.name),
        ),
      })),
  );
  const formikRef = useRef<FormikProps<TaskSelectionProps>>(null);
  function handleSave(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    if (formikRef.current) {
      formikRef.current.handleSubmit(event as unknown as FormEvent<HTMLFormElement>);
    } else {
      onClose && onClose(event, 'backdropClick');
    }
  }
  function handleClose(event: MouseEvent<HTMLButtonElement>) {
    onClose && onClose(event, 'backdropClick');
  }
  return (
    <Dialog
      scroll="paper"
      {...props}
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          m: [0, 4],
          maxHeight: ['unset', 'calc(100% - 64px)'],
          height: ['100vh', 'auto'],
        },
      }}
    >
      <DialogTitle sx={{ position: 'relative' }}>
        <Stack justifyContent={'space-between'} direction="row" alignItems="center">
          <Typography variant="h3" fontSize="20px" color="secondary">
            New Task
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <Button
              onClick={handleClose}
              color="secondary"
              sx={{
                padding: 0,
                minWidth: '34px',
                marginLeft: '20px !important',
                mb: [1, 0],
              }}
            >
              <CloseIcon />
            </Button>
          </Stack>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ pb: 0 }}>
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema}
          innerRef={formikRef}
          enableReinitialize
          onSubmit={(values) => {
            try {
              onSubmit(values);
            } catch (error) {
              //Must have already been handled at the top level
              console.log(error);
            }
          }}
        >
          {({ values }) => (
            <Form>
              <AutocompleteField<SOWType>
                name="sow"
                label="Project / SOW"
                placeholder="Select Project / SOW"
                options={sortedSOWs}
                toggleButtonProps={{
                  sx: { justifyContent: 'space-between', textTransform: 'none' },
                }}
                autoCompleteProps={{
                  groupBy: (option) => option.project.name,
                }}
              />
              <AutocompleteField<Task>
                name="task"
                label="Task"
                placeholder="Select a task"
                options={values.sow?.tasks || []}
                hint="Select a SOW first"
                toggleButtonProps={{
                  sx: { justifyContent: 'space-between', textTransform: 'none' },
                }}
                autoCompleteProps={{
                  groupBy: (option) => (option.billable ? 'Billed' : 'Not Billed'),
                }}
              />
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          sx={{
            width: ['100%', '145px'],
            height: '44px',
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
