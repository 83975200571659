import { combineReducers } from 'redux';
import AssessmentReducer, { AssessmentState } from './assessment-reducer';
import CampaignsReducer, { CampaignsState } from './campaigns-reducer';
import CandidatesReducer, { CandidatesState } from './candidates-reducer';
import NotesReducer, { NotesState } from './notes-reducer';

export type ATSState = {
  campaigns: CampaignsState;
  candidates: CandidatesState;
  notes: NotesState;
  assessment: AssessmentState;
};
export default combineReducers({
  campaigns: CampaignsReducer,
  candidates: CandidatesReducer,
  notes: NotesReducer,
  assessment: AssessmentReducer,
});
