import { Button, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onGetUsers } from '../../../redux-store/actions/users';
import { RootState, RootStateType } from '../../../redux-store/reducers';
import { UsersState } from '../../../redux-store/reducers/users';
import { UserSearchParameters } from '../../../redux-store/types/users';
import theme from '../../common/default';
import ReuseableLayoutHeader from '../../common/layouts';
import FilteredAutocompleteMultiple from '../../forms/Autocomplete/filtered-autocomplete-multiple';
import { BasicDatePicker } from '../../time-tracking/common';
import { useExtractParams } from '../../time-tracking/constants';
import { getPhases } from '../actions';
import {
  RESET_RESOURCE_SEARCH_OPTIONS,
  ResourceRoles,
  RESOURCE_SEARCH_OPTIONS,
} from '../constants';
import { ResourceState } from '../store/resourceReducer';
import { RelatedPhase } from '../types';

export default function AllocationsFilters(): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedEmployees, setSelectedEmployees] = useState<Array<any>>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedRoles, setSelectedRoles] = useState<Array<any>>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedClients, setSelectedClients] = useState<Array<any>>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedProjects, setSelectedProjects] = useState<Array<any>>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedBillingRate, setSelectedBillingRate] = useState<Array<any>>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedWeek, setSelectedWeek] = useState<Array<any>>([]);
  const [sows, setSOWs] = useState<RelatedPhase[]>([]);
  const dispatch = useDispatch();
  // const { users } = useSelector<RootStateType, UsersState>((state) => state.users);
  const { enqueueSnackbar } = useSnackbar();
  const { searchOptions } = useSelector<RootState, ResourceState>(
    (state) => state.delivery.resources,
  );

  const { users } = useSelector<RootStateType, UsersState>((state) => state.users);
  const userSearchOptions = useSelector<RootStateType, UserSearchParameters>(
    (state) => state.users.searchOptions,
  );

  const { date } = useExtractParams();
  const [startDateChanged, setStartDateChanged] = useState(false);
  const [endDateChanged, setEndDateChanged] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [startDate, setStartDate] = useState(date);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [endDate, setEndDate] = useState(date);
  useEffect(() => {
    async function fetchUsers() {
      try {
        await dispatch(onGetUsers(userSearchOptions));
      } catch (error) {
        enqueueSnackbar((error as Error).message, { variant: 'error' });
      }
    }
    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchSOWs = async function () {
      const _phases = await dispatch(getPhases());
      setSOWs(_phases as unknown as Array<RelatedPhase>);
    };
    fetchSOWs();
  }, []);

  function onProjectValuesChanged(values: Array<string>) {
    setSelectedProjects(values);
  }
  function onEmployeesValuesChanged(values: Array<string>) {
    setSelectedEmployees(values);
  }
  function onRoleValuesChanged(values: Array<string>) {
    setSelectedRoles(values);
  }
  function onBillingRateValuesChanged(values: Array<string>) {
    setSelectedBillingRate(values);
  }

  useEffect(() => {
    if (selectedEmployees.length > 0) {
      dispatch({
        type: RESOURCE_SEARCH_OPTIONS,
        searchOptions: {
          ...searchOptions,
          employee: {
            id: selectedEmployees.map((employee) => employee.id),
          },
        },
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { employee, ...rest } = searchOptions;
      dispatch({
        type: RESOURCE_SEARCH_OPTIONS,
        searchOptions: {
          ...rest,
        },
      });
    }
  }, [selectedEmployees]);

  useEffect(() => {
    if (selectedRoles.length > 0) {
      dispatch({
        type: RESOURCE_SEARCH_OPTIONS,
        searchOptions: {
          ...searchOptions,
          role: selectedRoles.map((role) => role.id),
        },
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { role, ...rest } = searchOptions;
      dispatch({
        type: RESOURCE_SEARCH_OPTIONS,
        searchOptions: {
          ...rest,
        },
      });
    }
  }, [selectedRoles]);

  useEffect(() => {
    if (selectedProjects.length > 0) {
      dispatch({
        type: RESOURCE_SEARCH_OPTIONS,
        searchOptions: {
          ...searchOptions,
          assignedTo: selectedProjects.map((project) => project.id).join(','),
        },
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { assignedTo, ...rest } = searchOptions;
      dispatch({
        type: RESOURCE_SEARCH_OPTIONS,
        searchOptions: {
          ...rest,
        },
      });
    }
  }, [selectedProjects]);

  useEffect(() => {
    if (selectedBillingRate.length > 0) {
      dispatch({
        type: RESOURCE_SEARCH_OPTIONS,
        searchOptions: {
          ...searchOptions,
          billingRate: selectedBillingRate.map((billingRate) => billingRate.id).join(','),
        },
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { billingRate, ...rest } = searchOptions;
      dispatch({
        type: RESOURCE_SEARCH_OPTIONS,
        searchOptions: {
          ...rest,
        },
      });
    }
  }, [selectedBillingRate]);

  async function handleDatePickerStartDateChange(d: Date | null) {
    if (d) {
      const date = await new Date(d);
      setStartDateChanged(true);
      await setStartDate(date);
      await dispatch({
        type: RESOURCE_SEARCH_OPTIONS,
        searchOptions: {
          ...searchOptions,
          startDate: date,
        },
      });
    }
  }

  async function handleDatePickerEndDateChange(d: Date | null) {
    if (d) {
      const date = await new Date(d);
      setEndDateChanged(true);
      await setEndDate(date);
      await dispatch({
        type: RESOURCE_SEARCH_OPTIONS,
        searchOptions: {
          ...searchOptions,
          endDate: date,
        },
      });
    }
  }

  const roles = ResourceRoles.map((role) => {
    return {
      name: role.label,
      id: role.value,
    };
  });

  const formattedSows = sows.map((phase) => ({
    name: `${phase.name} (${phase.project.name})`,
    id: phase.id,
  }));

  const employees = users.map((employee) => ({
    name: employee.name,
    id: employee.id,
  }));

  const showClearButton =
    selectedEmployees.length > 0 ||
    selectedRoles.length > 0 ||
    selectedClients.length > 0 ||
    selectedProjects.length > 0 ||
    selectedWeek.length > 0 ||
    selectedBillingRate.length > 0 ||
    startDateChanged === true ||
    endDateChanged === true;

  const handleClear = () => {
    setSelectedEmployees([]);
    setSelectedRoles([]);
    setSelectedProjects([]);
    setSelectedClients([]);
    setSelectedBillingRate([]);
    setSelectedWeek([]);
    setStartDateChanged(false);
    setEndDateChanged(false);
    dispatch({
      type: RESET_RESOURCE_SEARCH_OPTIONS,
    });
  };

  return (
    <>
      <ReuseableLayoutHeader>
        <FilteredAutocompleteMultiple
          label="Employees"
          searchPlaceholder="Search Employees"
          options={employees}
          value={selectedEmployees}
          filterId="employee-id"
          onChange={onEmployeesValuesChanged}
        />
        <FilteredAutocompleteMultiple
          label="Role"
          searchPlaceholder="Search Roles"
          options={roles}
          value={selectedRoles}
          filterId="role-id"
          onChange={onRoleValuesChanged}
        />
        <FilteredAutocompleteMultiple
          label="Project"
          searchPlaceholder="Search Project"
          options={formattedSows}
          value={selectedProjects}
          filterId="project-id"
          onChange={onProjectValuesChanged}
        />

        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRight: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
            fontWeight: 600,
            display: 'flex',
            minWidth: 150,
            px: 2,
            py: 1.5,
          }}
        >
          <Typography variant="h5" sx={{ color: 'secondary.main' }}>
            Start Date
          </Typography>
          <BasicDatePicker value={date} onChange={handleDatePickerStartDateChange} />
        </Stack>
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRight: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
            fontWeight: 600,
            display: 'flex',
            minWidth: 150,
            px: 2,
            py: 1.5,
          }}
        >
          <Typography variant="h5" sx={{ color: 'secondary.main' }}>
            End Date{' '}
          </Typography>
          <BasicDatePicker value={date} onChange={handleDatePickerEndDateChange} />
        </Stack>
        <FilteredAutocompleteMultiple
          label="Billing Rate"
          options={[
            {
              name: 'All',
              id: '',
            },
            {
              name: '0-500',
              id: '0-500',
            },
            {
              name: '500-1000',
              id: '500-1000',
            },
            {
              name: '1000-1500',
              id: '1000-1500',
            },
            {
              name: '1500-2000',
              id: '1500-2000',
            },
          ]}
          value={selectedBillingRate}
          filterId="billing-id"
          onChange={onBillingRateValuesChanged}
          disableSearch={true}
        />
      </ReuseableLayoutHeader>

      {showClearButton && (
        <Button variant="text" sx={{ ml: 1 }} onClick={handleClear}>
          Clear
        </Button>
      )}
    </>
  );
}
