import { Box, LinearProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { TitleBar } from '../../assets/components';
import { NotesFormBody } from '../types';
import { createNote } from './actions';
import NoteForm from './form';

export default function CreateNote(): JSX.Element {
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const initialValues = {
    candidate: undefined,
    note: '',
    date: new Date(),
  };
  return (
    <>
      <TitleBar title="New Note" />
      {showProgress && <LinearProgress />}
      <Box sx={{ p: 2 }}>
        <NoteForm
          initialValues={initialValues}
          onSubmit={async (values: NotesFormBody): Promise<void> => {
            try {
              setShowProgress(true);
              await dispatch(createNote(values));
              enqueueSnackbar('Note added successfully', { variant: 'success' });
              setTimeout(history.push.bind(null, '/admin/ats/notes'), 1);
            } catch (error) {
              enqueueSnackbar((error as Error).message, { variant: 'error' });
            } finally {
              setShowProgress(false);
            }
          }}
        />
      </Box>
    </>
  );
}
