import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Box } from '@mui/material';
import FormDatePicker from '../../forms/FormDatePicker';
import FormTextInput from '../../forms/FormTextInput';
import { SubmitButton } from '../../forms/Button';
import FormSelect from '../../forms/FormSelect';
import FormUsersPicker from '../../forms/FormUsersPicker';
import { AssessmentFormBody } from '../types';

const validationSchema = Yup.object().shape({
  date: Yup.string(),
  score: Yup.string().required('Score is required'),
  comment: Yup.string(),
  interviewer: Yup.object()
    .nullable()
    .shape({
      id: Yup.string(),
      name: Yup.string().required('Name is required'),
      email: Yup.string().required('Email is required'),
    }),
  status: Yup.string().required('Status is required'),
  type: Yup.string().required('Type is required'),
});

export type AssessmentFormProps = {
  onSubmit: (values: AssessmentFormBody) => void;
  initialValues: AssessmentFormBody;
};

export default function AssessmentForm({
  onSubmit,
  initialValues,
}: AssessmentFormProps): JSX.Element {
  return (
    <>
      <Box sx={{ ml: '10px' }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {() => (
            <Form>
              <FormDatePicker label="Date" name="date" />
              <FormTextInput label="Score" name="score" type="text" />
              <FormTextInput
                label="Comment"
                name="comment"
                type="text"
                placeholder="Write Comment"
                multiline
                rows={3}
              />
              <FormUsersPicker name="interviewer" label="Interviewer" />
              <FormSelect
                name="status"
                label="Status"
                options={[
                  { label: 'Recommended', value: 'recommended' },
                  { label: 'Hold', value: 'hold' },
                  { label: 'Rejected', value: 'rejected' },
                  { label: 'In Progress', value: 'in progress' },
                ]}
              />
              <FormSelect
                name="type"
                label="Type"
                options={[
                  { label: 'Preliminary Coding Round', value: 'preliminary-coding-round' },
                  { label: 'Coding Round', value: 'coding-round' },
                  { label: 'Internal', value: 'internal' },
                  { label: 'HR Round', value: 'hr-round' },
                ]}
              />

              <Box sx={{ m: '10px', float: 'right' }}>
                <SubmitButton label="Submit" />
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
}
