/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from 'react';
import { ButtonPrimary } from '../forms/Button';
import { Formik, withFormik, Form, FastField, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import FormTextInput from '../forms/FormTextInput';
import FormDatePicker from '../forms/FormDatePicker';
import { Box } from '@mui/system';
import { Stack } from '@mui/material';
const Schema = Yup.object().shape({
  details: Yup.string().min(2, 'Too Short!').required('Required Details!'),
  startDate: Yup.date()
    .max(Yup.ref('endDate'), `Start date can't be after end date`)
    .required('Required Start Date'),
  endDate: Yup.date().required('Required End Date'),
});

function UpdateLeaveForm(props: any) {
  return (
    <>
      <Box>
        <h3>Update Leave</h3>
        <Formik
          initialValues={{
            id: props.data._id,
            details: props.data.details,
            startDate: props.data.startDate,
            endDate: props.data.endDate,
            status: props.data.status,
          }}
          validateOnMount={false}
          validationSchema={Schema}
          onSubmit={async (values, { setSubmitting }) => {
            await props.onUpdateLeave(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form>
              <Stack sx={{ m: '10px' }}>
                <FormTextInput
                  label="Details"
                  name="details"
                  type="text"
                  placeholder="Details"
                  disabled={isSubmitting}
                />
              </Stack>
              <Stack sx={{ m: '10px' }}>
                <FormDatePicker label="Start Date" name="startDate" disabled={isSubmitting} />
              </Stack>
              <Stack sx={{ m: '10px' }}>
                <FormDatePicker label="End Date" name="endDate" disabled={isSubmitting} />
              </Stack>
              <Box sx={{ float: 'right', mt: '10px' }}>
                <ButtonPrimary fullWidth={false} variant="outlined" disabled={isSubmitting}>
                  Submit
                </ButtonPrimary>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
}
export default UpdateLeaveForm;
