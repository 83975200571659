import { Box, Drawer } from '@mui/material';
import { Location } from 'history';
import { useEffect } from 'react';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router';
import CampaignDetail from './detail';
import CampaignsList from './list';
import CreateCampaign from './create';
import UpdateCampaign from './update';
import { SET_CAMPAIGNS } from './constants';
import { useDispatch } from 'react-redux';

export default function CampaignsRouter(): JSX.Element {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const history = useHistory();
  const location = useLocation<{ background: Location }>();
  const background = location.state && location.state.background;
  useEffect(() => {
    return () => {
      dispatch({ type: SET_CAMPAIGNS, campaigns: [] });
    };
  }, []);
  return (
    <>
      <Switch location={background || location}>
        <Route path={`${path}/add`} component={CreateCampaign} />
        <Route path={`${path}/update/:id`} component={UpdateCampaign} />
        <Route path={`${path}/:id`} component={CampaignDetail} />
        <Route exact path={path} component={CampaignsList} />
      </Switch>
      <Drawer
        open={!!background}
        anchor="right"
        onClose={() => {
          history.goBack();
        }}
      >
        <Box sx={{ width: 420 }} role="presentation">
          <Switch>
            <Route exact path={`${path}/add`} component={CreateCampaign} />
            <Route path={`${path}/update/:id`} component={UpdateCampaign} />
            <Route exact path={`${path}/:id`} component={CampaignDetail} />
          </Switch>
        </Box>
      </Drawer>
    </>
  );
}
