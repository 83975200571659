import { AnyAction } from 'redux';
import {
  CREATE_PHASE,
  PHASE_SEARCH_OPTIONS,
  PHASE_SEARCH_OPTION_TYPE,
  RESET_PHASE_SEARCH_OPTIONS,
  SET_PHASES,
  UPDATE_PHASE,
} from '../constants';
import { Phase, PhasesSearchParameters } from '../types';

export const allPhases: PhasesSearchParameters = {
  name: '',
  projectId: '',
  phaseStart: [null, null],
  phaseEnd: [null, null],
  orderBy: '',
  orderDirection: 'desc',
  limit: 25,
  offset: '',
};
export interface PhasesState {
  phases: Array<Phase>;
  searchOptions: PhasesSearchParameters;
  searchOptionType: string;
}
export const searchOptionsList: Record<string, PhasesSearchParameters> = {
  allPhases: allPhases,
};

const initialState: PhasesState = {
  phases: [],
  searchOptions: allPhases,
  searchOptionType: 'allPhases',
};
export default function phaseReducer(state = initialState, action: AnyAction): PhasesState {
  switch (action.type) {
    case SET_PHASES: {
      return {
        ...state,
        phases: action.phases,
      };
    }
    case CREATE_PHASE: {
      return {
        ...state,
        phases: [action.phases, ...state.phases],
      };
    }
    case UPDATE_PHASE: {
      return {
        ...state,
        phases: state.phases.map((phase: Phase) =>
          phase.id === action.phases.id ? { ...phase, ...action.phases } : phase,
        ),
      };
    }
    case RESET_PHASE_SEARCH_OPTIONS: {
      return {
        ...state,
        searchOptions: allPhases,
      };
    }
    case PHASE_SEARCH_OPTIONS: {
      return {
        ...state,
        searchOptions: { ...action.searchOptions },
      };
    }
    case PHASE_SEARCH_OPTION_TYPE: {
      return {
        ...state,
        searchOptionType: action.searchOptionType,
        searchOptions: {
          ...(searchOptionsList[action.searchOptionType] || allPhases),
        },
      };
    }
    default:
      return state;
  }
}
