import { useState, useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { LinearProgress } from '@mui/material';
import { formatLongDate, getDayOfWeek } from '../../utils/date';
import { onGetHolidays } from '../../redux-store/actions/holidays';

import { HolidayPayload } from '../../redux-store/types/holidays';
import { HolidaysState } from '../../redux-store/reducers/holidays';
import { RootStateType } from '../../redux-store/reducers';
import { OnlyIfPermission } from '../common';

const currentYear = new Date().getFullYear().toString();
const years = [currentYear, '' + (+currentYear + 1)];

function HolidaysList(): JSX.Element {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [showProgress, setShowProgress] = useState<boolean>(false);
  const location = useLocation<{ background: Location }>();

  const { holidays } = useSelector<RootStateType, HolidaysState>((state) => state.holidays);

  const [holidayYear, setHolidayYear] = useState<string>(new Date().getFullYear().toString());

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleYearChange = (event: any) => {
    const yearToBeSet = event.target.value;
    setHolidayYear(yearToBeSet);
  };

  const holidaysToDisplay = holidays
    .filter((holiday) => holiday.year === holidayYear)
    .map((holiday) => {
      return {
        _id: holiday._id,
        name: holiday.name,
        date: holiday.date,
        year: holiday.year,
        dayOfWeek: getDayOfWeek(holiday.date),
      };
    })
    .sort(function (dateA, dateB) {
      const c = new Date(dateA.date);
      const d = new Date(dateB.date);
      return Number(c) - Number(d);
    });

  useEffect(() => {
    async function fetchHolidays(): Promise<void> {
      try {
        setShowProgress(true);
        await dispatch(onGetHolidays());
      } catch (error) {
        enqueueSnackbar((error as Error).message, { variant: 'error' });
      } finally {
        setShowProgress(false);
      }
    }
    fetchHolidays();
  }, []);

  return (
    <>
      <Typography variant="h4">Holidays</Typography>
      <Stack justifyContent="space-between" direction="row" alignItems="center">
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-standard-label">Year</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={holidayYear}
            onChange={handleYearChange}
            label="Year"
          >
            {years.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <OnlyIfPermission name="leaves:holidays:create">
          <Button
            component={Link}
            to={{ pathname: `${url}/add`, state: { background: location } }}
            variant="outlined"
            fullWidth={false}
          >
            Create Holiday
          </Button>
        </OnlyIfPermission>
      </Stack>
      {showProgress && <LinearProgress />}
      {holidaysToDisplay && <HolidaysTable data={holidaysToDisplay} />}
    </>
  );
}

function HolidaysTable({ data }: { data: Array<HolidayPayload> }): JSX.Element {
  const location = useLocation<{ background: Location }>();
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Date</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Day</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
            <OnlyIfPermission name="leaves:holidays:update">
              <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
            </OnlyIfPermission>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row: HolidayPayload, index) => (
            <TableRow key={index}>
              <TableCell>{formatLongDate(row.date)}</TableCell>
              <TableCell>{row.dayOfWeek}</TableCell>
              <TableCell>{row.name}</TableCell>
              <OnlyIfPermission name="leaves:holidays:update">
                <TableCell>
                  <Button
                    to={{
                      pathname: `/holidays/update/${row._id}`,
                      state: { background: location },
                    }}
                    component={Link}
                  >
                    Update
                  </Button>
                </TableCell>
              </OnlyIfPermission>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default HolidaysList;
