import { AnyAction } from 'redux';
import { ADD_CAMPAIGN, SET_CAMPAIGNS, UPDATE_CAMPAIGN } from '../campaigns/constants';
import { Campaign } from '../types';

export type CampaignsState = Array<Campaign>;

export default function CampaignsReducer(
  state: CampaignsState = [],
  action: AnyAction,
): CampaignsState {
  switch (action.type) {
    case SET_CAMPAIGNS:
      return action.campaigns;
    case ADD_CAMPAIGN:
      return [...state, action.campaign];
    case UPDATE_CAMPAIGN:
      return state.map((campaign: Campaign) => {
        if (campaign.id === action.campaign.id) {
          return action.campaign;
        }
        return campaign;
      });
    default:
      return state;
  }
}
