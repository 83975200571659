import { Grid, LinearProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import { formatShortDate } from '../../../utils/date';
import { getCampaignDetail } from './actions';
import { TitleBar } from '../../assets/components';
import { Campaign } from '../types';
import { DataValues } from '../components';

export default function CampaignDetail(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const [campaign, setCampaign] = useState<Campaign>();
  const [showProgress, setShowProgress] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    async function getCampaignsIDdata() {
      try {
        setShowProgress(true);
        const details = await dispatch(getCampaignDetail(id));
        setCampaign(details as unknown as Campaign);
      } catch (error) {
        enqueueSnackbar((error as Error).message, {
          variant: 'error',
        });
      } finally {
        setShowProgress(false);
      }
    }
    getCampaignsIDdata();
  }, [id]);
  return (
    <>
      <TitleBar title="Campaign Details" />
      {showProgress && <LinearProgress />}

      {campaign && (
        <Grid container sx={{ px: 2 }}>
          <DataValues label="Name" value={campaign.name} half />
          <DataValues label="Status" value={campaign.status} half />
          <DataValues label="Position" value={campaign.position} />
          <DataValues label="Description" value={campaign.description} />
          <DataValues label="Start Date" value={formatShortDate(campaign.endDate)} half />
          <DataValues label="End Date" value={formatShortDate(campaign.endDate)} half />
        </Grid>
      )}
    </>
  );
}
