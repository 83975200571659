import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { SubmitButton } from '../../forms/Button';
import FormTextInput from '../../forms/FormTextInput';
import { FormUpload } from '../../forms/FormUpload';
import { ProjectBody } from '../types';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Project name is required'),
  clientName: Yup.string().required('Client name is required'),
  description: Yup.string(),
  websiteUrl: Yup.string(),
  email: Yup.string(),
  phoneNumber: Yup.string(),
  address: Yup.string(),
});

export type FormProps = {
  onSubmit: (values: ProjectBody) => void;
  initialValues: ProjectBody;
};
export default function ProjectForm({ initialValues, onSubmit }: FormProps): JSX.Element {
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {() => (
        <Form>
          <Box sx={{ px: 8, '& .MuiGrid-container': { alignItems: 'center', mb: 2 } }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <Typography variant="h5">Client Name</Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                <FormTextInput name="clientName" type="text" />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <Typography variant="h5">Project Name</Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                <FormTextInput name="name" type="text" />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <Typography variant="h5">Logo</Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                <FormUpload name="logo" text="Upload Logo: " />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <Typography variant="h5">About</Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                <FormTextInput name="description" type="text" multiline rows={3} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <Typography variant="h5">Website URL</Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                <FormTextInput name="websiteUrl" type="text" />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <Typography variant="h5">Email</Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                <FormTextInput name="email" type="text" />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <Typography variant="h5">Mobile</Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                <FormTextInput name="phoneNumber" type="text" />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <Typography variant="h5">Address</Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                <FormTextInput name="address" type="text" multiline rows={4} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}></Grid>
              <Grid item xs={12} sm={7}>
                <SubmitButton label="Submit" sx={{ minWidth: 165 }} />
              </Grid>
            </Grid>
            <Box sx={{ m: 1, float: 'right' }}></Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
}
