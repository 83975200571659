import { Box } from '@mui/system';
import { Formik, Form, FormikState } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { SubmitButton } from '../../forms/Button';
import FormDatePicker from '../../forms/FormDatePicker';
import FormSelect from '../../forms/FormSelect';
import FormTextInput from '../../forms/FormTextInput';
import { FormattedEngagementTypes } from '../constants';
import { getProjects } from '../actions';
import { PhaseBody, RelatedProject } from '../types';
import { Grid, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { RootStateType } from '../../../redux-store/reducers';
import { onGetUsers } from '../../../redux-store/actions/users';
import { UsersState } from '../../../redux-store/reducers/users';

const validationSchema = Yup.object().shape({
  project: Yup.object()
    .shape({
      id: Yup.string().required('Client is required'),
    })
    .required('Project is required'),
  budget: Yup.number(),
  name: Yup.string().required('Required Name'),
  notes: Yup.string(),
  type: Yup.string().required('Required Phase Type'),
  startDate: Yup.date().nullable(),
  endDate: Yup.date().nullable(),
});

export type FormProps = {
  onSubmit: (values: PhaseBody) => void;
  initialValues: PhaseBody;
};
export default function PhaseForm({ initialValues, onSubmit }: FormProps): JSX.Element {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { searchOptions } = useSelector<RootStateType, UsersState>((state) => state.users);
  const [projects, setProjects] = useState<Array<RelatedProject>>([]);
  useEffect(() => {
    const fetchProjects = async function () {
      const _projects = await dispatch(getProjects());
      setProjects(_projects as unknown as Array<RelatedProject>);
    };
    fetchProjects();
  }, []);

  useEffect(() => {
    async function fetchUsers() {
      try {
        await dispatch(onGetUsers(searchOptions));
      } catch (error) {
        enqueueSnackbar((error as Error).message, { variant: 'error' });
      }
    }
    fetchUsers();
  }, []);
  const formattedProjects = projects.map((project) => ({
    value: project.id,
    label: project.name || project.id,
  }));

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        await onSubmit(values);
        resetForm(values as unknown as Partial<FormikState<PhaseBody>>);
        setSubmitting(false);
      }}
    >
      {() => (
        <Form>
          <Box sx={{ px: 8, '& .MuiGrid-container': { alignItems: 'center', mb: 2 } }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <Typography variant="h5">SOW Name</Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                <FormTextInput name="name" type="text" />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <Typography variant="h5">Type</Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                <FormSelect name="type" options={FormattedEngagementTypes} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <Typography variant="h5">Project</Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                <FormSelect name="project.id" options={formattedProjects} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <Typography variant="h5">Start Date</Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                <FormDatePicker name="startDate" />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <Typography variant="h5">End Date</Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                <FormDatePicker name="endDate" />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <Typography variant="h5">Budget</Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                <FormTextInput name="budget" type="text" />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <Typography variant="h5">Notes</Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                <FormTextInput name="notes" type="text" multiline rows={4} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}></Grid>
              <Grid item xs={12} sm={7}>
                <SubmitButton label="Submit" sx={{ minWidth: 165 }} />
              </Grid>
            </Grid>
            <Box sx={{ m: 1, float: 'right' }}></Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
}
