import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Box, LinearProgress } from '@mui/material';
import { Phase, PhaseBody } from '../types';
import { getPhaseById, updatePhase } from '../actions';
import PhaseForm from './Form';
import { TitleBar } from '../../assets/components';

export default function UpdatePhase(): JSX.Element {
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [phase, setPhase] = useState<PhaseBody>();
  useEffect(() => {
    async function fetchPhase() {
      setShowProgress(true);
      try {
        const _phase = await dispatch(getPhaseById(id));
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { id: _id, createdAt, updatedAt, ...rest } = _phase as unknown as Phase;
        setPhase(rest as PhaseBody);
      } catch (error) {
        enqueueSnackbar((error as Error).message, { variant: 'error' });
      } finally {
        setShowProgress(false);
      }
    }
    fetchPhase();
  }, []);
  return (
    <>
      <TitleBar title="Update SOW" />
      {showProgress && <LinearProgress />}
      {phase && (
        <Box sx={{ p: 2 }}>
          <PhaseForm
            initialValues={phase}
            onSubmit={async (values: PhaseBody): Promise<void> => {
              try {
                setShowProgress(true);
                await dispatch(updatePhase(values, id));
                enqueueSnackbar('SOW updated successfully', { variant: 'success' });
                setTimeout(history.goBack, 1);
              } catch (error) {
                enqueueSnackbar((error as Error).message, { variant: 'error' });
              } finally {
                setShowProgress(false);
              }
            }}
          />
        </Box>
      )}
    </>
  );
}
