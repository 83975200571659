import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import GroupsRouter from './groups/router';

export default function Router(): JSX.Element {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/groups`} component={GroupsRouter} />
      <Route path={`${path}/permissions`} component={GroupsRouter} />
      <Route exact path={path} render={() => <Redirect to={`${path}/groups`} />} />
    </Switch>
  );
}
