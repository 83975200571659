import { AuthTypes } from '../../redux-store/types/login';
import { UserTypes } from '../../redux-store/types/users';

export const SHOW_GLOBAL_ERROR = 'SHOW_GLOBAL_ERROR';
export const CLEAR_GLOBAL_ERROR = 'CLEAR_GLOBAL_ERROR';

export const SHOW_TOAST = 'SHOW_TOAST';
export const STOP_TOAST = 'STOP_TOAST';

export type AppActions = AuthTypes | UserTypes;
