/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CALL_API } from '../middleware';

import { GET_HOLIDAYS, CREATE_HOLIDAY, UPDATE_HOLIDAY, HolidayPayload } from '../types/holidays';

export const onGetHolidays = () => async (dispatch: any) => {
  try {
    const holidays = await dispatch({
      [CALL_API]: {
        url: `/leaves/holidays`,
        method: 'GET',
      },
    });
    dispatch({
      type: GET_HOLIDAYS,
      holidays,
    });
  } catch (error) {
    alert(`${error}`);
  }
};
export const onCreateHolidays = (body: HolidayPayload) => async (dispatch: any) => {
  try {
    const createdHoliday = await dispatch({
      [CALL_API]: {
        url: `/leaves/holidays`,
        method: 'POST',
        body,
      },
    });
    dispatch({
      type: CREATE_HOLIDAY,
      holiday: createdHoliday,
    });
  } catch (error) {
    alert(`${error}`);
  }
};

export const updateHoliday = (holidayValues: HolidayPayload) => async (dispatch: any) => {
  const { _id, name, date } = holidayValues;
  try {
    const updatedHoliday = await dispatch({
      [CALL_API]: {
        url: `/leaves/holidays/${_id}/update`,
        method: 'POST',
        body: { name, date },
      },
    });
    dispatch({
      type: UPDATE_HOLIDAY,
      holiday: updatedHoliday,
    });
    return updatedHoliday as HolidayPayload;
  } catch (error) {
    throw new Error('Unable to fetch holiday. ' + error);
  }
};
