import { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Box, LinearProgress } from '@mui/material';
import { PhaseBody } from '../types';
import { createPhase } from '../actions';
import PhaseForm from './Form';
import { TitleBar } from '../../assets/components';

const initialValues: PhaseBody = {
  name: '',
  type: 'EP',
  project: { id: '' },
  lead: '',
  team: '',
  startDate: new Date(),
  endDate: new Date(),
  budget: '',
  notes: '',
};

export default function CreatePhase(): JSX.Element {
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return (
    <>
      <TitleBar title="Add SOW" />
      {showProgress && <LinearProgress />}
      <Box sx={{ py: 2 }}>
        <PhaseForm
          initialValues={initialValues}
          onSubmit={async (values: PhaseBody): Promise<void> => {
            const { name, type, project, startDate, endDate, budget, notes } = values;
            const reqBody = {
              name: name,
              type: type,
              project: project,
              startDate: startDate,
              endDate: endDate,
              budget: budget,
              notes: notes,
            };
            try {
              setShowProgress(true);
              await dispatch(createPhase(reqBody));
              enqueueSnackbar('SOW created successfully', { variant: 'success' });
              setTimeout(history.goBack, 1);
            } catch (error) {
              enqueueSnackbar((error as Error).message, { variant: 'error' });
            } finally {
              setShowProgress(false);
            }
          }}
        />
      </Box>
    </>
  );
}
