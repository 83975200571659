import { Box, Button, LinearProgress, Toolbar, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { onDeactivateUsers, onGetUsersbyId, onUpdateUsers } from '../../redux-store/actions/users';
import { UserPayload } from '../../redux-store/types/users';
import UserForm from './UserForm';

export default function UpdateUser(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const [showProgress, setShowProgress] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [userUpdateValue, setUsersValue] = useState<UserPayload>();
  const history = useHistory();

  const handleDeActivate = async () => {
    if (confirm('Are you sure you want to permanently deactivate this user?')) {
      await dispatch(onDeactivateUsers(id));
      history.push('/');
    }
  };

  useEffect(() => {
    async function getDetailsByid() {
      try {
        setShowProgress(true);
        const userdetails = await dispatch(onGetUsersbyId(id));
        const {
          name,
          email,
          designation,
          joining_date,
          department,
          subDepartment,
          additionalDetails,
        } = userdetails as unknown as UserPayload;

        setUsersValue({
          name,
          email,
          designation,
          joining_date,
          department,
          subDepartment,
          additionalDetails,
        });
      } catch (error) {
        enqueueSnackbar((error as Error).message, {
          variant: 'error',
        });
      } finally {
        setShowProgress(false);
      }
    }
    getDetailsByid();
  }, [id]);
  return (
    <>
      <Toolbar
        sx={{
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          flexGrow: 0,
          flexShrink: 0,
          flexBasis: 'auto',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h6">Update User</Typography>
        <Button onClick={handleDeActivate}>Deactivate</Button>
      </Toolbar>
      {showProgress && <LinearProgress />}
      {userUpdateValue && (
        <Box sx={{ p: 2 }}>
          <UserForm
            initialValues={userUpdateValue}
            onSubmit={async (values) => {
              try {
                const {
                  name,
                  email,
                  designation,
                  joining_date,
                  department,
                  subDepartment,
                  additionalDetails,
                } = values;
                setShowProgress(true);
                const additionalData = {
                  address: additionalDetails?.address,
                  bloodGroup: additionalDetails?.bloodGroup,
                  empId: additionalDetails?.empId,
                  pan: additionalDetails?.pan,
                  phoneNumber: additionalDetails?.phoneNumber,
                  type: additionalDetails?.type,
                  dob: additionalDetails?.dob,
                  personalEmail: additionalDetails?.personalEmail || '',
                  fatherName: additionalDetails?.fatherName || '',
                  spouseName: additionalDetails?.spouseName || '',
                  emergencyContact: additionalDetails?.emergencyContact || '',
                };
                await dispatch(
                  onUpdateUsers({
                    id,
                    name,
                    email,
                    designation,
                    joining_date,
                    department,
                    subDepartment,
                    additionalDetails: additionalData,
                  }),
                );
                enqueueSnackbar('User Updated successfully', { variant: 'success' });
                setShowProgress(false);
                setTimeout(history.goBack.bind(null), 1);
              } catch (error) {
                enqueueSnackbar((error as Error).message, { variant: 'error' });
              } finally {
                setShowProgress(false);
              }
            }}
          />
        </Box>
      )}
    </>
  );
}
