export const SET_PHASES = 'SET_PHASES';
export const CREATE_PHASE = 'CREATE_PHASE';
export const UPDATE_PHASE = 'UPDATE_PHASE';
export const RESET_PHASE_SEARCH_OPTIONS = 'RESET_PHASE_SEARCH_OPTIONS';
export const PHASE_SEARCH_OPTIONS = 'PHASE_SEARCH_OPTIONS';
export const PHASE_SEARCH_OPTION_TYPE = 'PHASE_SEARCH_OPTION_TYPE';
export const SET_RESOURCES = 'SET_RESOURCES';
export const SET_ROLES = 'SET_ROLES';
export const CREATE_RESOURCE = 'CREATE_RESOURCE';
export const DELETE_RESOURCE = 'DELETE_RESOURCE';
export const UPDATE_RESOURCE = 'UPDATE_RESOURCE';
export const RESET_RESOURCE_SEARCH_OPTIONS = 'RESET_RESOURCE_SEARCH_OPTIONS';
export const RESOURCE_SEARCH_OPTIONS = 'RESOURCE_SEARCH_OPTIONS';
export const RESOURCE_SEARCH_OPTION_TYPE = 'RESOURCE_SEARCH_OPTION_TYPE';
export const LIST_RESOURCE_COSTS = 'LIST_RESOURCE_COSTS';
export const LIST_RESOURCE_COSTS_SEARCH_OPTIONS = 'LIST_RESOURCE_COSTS_SEARCH_OPTIONS';
export const RESET_RESOURCE_COSTS_SEARCH_OPTIONS = 'RESET_RESOURCE_COSTS_SEARCH_OPTIONS';
export const UPDATE_RESOURCE_COST = 'EXPORT_RESOURCE_COST';

export const ENGAGEMENT_TYPES = {
  EP: 'Engineering Program',
  GAP: 'Growth Acceleration Program',
  RA: 'Resource Augumentation',
  'S&M': 'Support & Maintenance',
  Staffing: 'Staffing',
};

export const FormattedEngagementTypes = Object.entries(ENGAGEMENT_TYPES).map(([key, value]) => ({
  value: key,
  label: value,
}));

export const SearchProjectTypes = Object.entries(ENGAGEMENT_TYPES).map(([key, value]) => ({
  name: key,
  id: value,
}));

export const ResourceRoles = [
  {
    label: 'Project Manager',
    value: 'PM',
  },
  {
    label: 'Front End Developer',
    value: 'FrontEnd',
  },
  {
    label: 'HTML',
    value: 'HTML',
  },
  {
    label: 'Backend Developer',
    value: 'Node',
  },
  {
    label: 'QA',
    value: 'QA',
  },
  {
    label: 'Business Analyst',
    value: 'BA',
  },
  {
    label: 'Design',
    value: 'Design',
  },
  {
    label: 'Technical Architect',
    value: 'TechnicalArchitect',
  },
  {
    label: 'Core Team',
    value: 'CoreTeam',
  },
];

export const Designations = [
  {
    label: 'Program Manager',
    value: 'Program Manager',
  },
  {
    label: 'QA',
    value: 'QA',
  },
  {
    label: 'Business Analyst',
    value: 'Business Analyst',
  },
  {
    label: 'Technical Architect',
    value: 'Technical Architect',
  },
  {
    label: 'Chief Architect',
    value: 'Chief Architect',
  },
  {
    label: 'Associate Software Engineer',
    value: 'Associate Software Engineer',
  },
  {
    label: 'Software Engineer',
    value: 'Software Engineer',
  },
  {
    label: 'Senior Software Engineer',
    value: 'Senior Software Engineer',
  },
  {
    label: 'Technical Lead',
    value: 'Technical Lead',
  },
  {
    label: 'Trainee Designer',
    value: 'Trainee Designer',
  },
  {
    label: 'Trainee Software Engineer',
    value: 'Trainee Software Engineer',
  },
  {
    label: 'UI/UX Designer',
    value: 'UI/UX Designer',
  },
  {
    label: 'UI/UX Lead',
    value: 'UI/UX Lead',
  },
  {
    label: 'UI/UX Architect',
    value: 'UI/UX Architect',
  },
  {
    label: 'COO',
    value: 'COO',
  },
  {
    label: 'CTO',
    value: 'CTO',
  },
  {
    label: 'HR Manager',
    value: 'HR Manager',
  },
  {
    label: 'Finance',
    value: 'Finance',
  },
  {
    label: 'Senior QA',
    value: 'Senior QA',
  },
];

export const CtcDataValues = [
  {
    name: 'All',
    id: '',
  },
  {
    name: '0 - 1LPA',
    id: '0-100000',
  },
  {
    name: '1 - 2LPA',
    id: '100000-200000',
  },
  {
    name: '2 - 3LPA',
    id: '200000-300000',
  },
  {
    name: '3 - 4LPA',
    id: '300000-400000',
  },
  {
    name: '4 - 5LPA',
    id: '400001-500000',
  },
];

export const costPerHour = [
  {
    name: 'All',
    id: '',
  },
  {
    name: '0 - 200',
    id: '0-200',
  },
  {
    name: '200 - 400',
    id: '200-400',
  },
  {
    name: '400 - 600',
    id: '400-600',
  },
  {
    name: '600 - 800',
    id: '600-800',
  },
];

export const billableHours = [
  {
    name: 'All',
    id: '',
  },
  {
    name: '0 - 10/Week',
    id: '0-10',
  },
  {
    name: '10 - 20/Week',
    id: '10-20',
  },
  {
    name: '20 - 30/Week',
    id: '20-30',
  },
  {
    name: '30 - 40/Week',
    id: '30-40',
  },
];
