/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CALL_API } from '../middleware';
import {
  UserPayload,
  GET_USERS_SUCCESS,
  CREATE_USERS_SUCCESS,
  UPDATE_USERS_SUCCESS,
  UserAdditionalDetails,
  UserDetails,
  UserSearchParameters,
  AssignManager,
  UPDATE_MANAGER,
  SET_UNASSIGN_MANAGER,
} from '../types/users';

export const defaultSearchOptions: UserSearchParameters = {
  name: '',
  email: '',
  designation: '',
  joiningDateBefore: '',
  joiningDateAfter: '',
  leavingDateBefore: '',
  leavingDateAfter: '',
  employmentType: '',
  department: '',
  subDepartment: '',
  manager: null,
  pan: '',
  isActive: false,
  sortBy: '_id',
  sortDirection: 'desc',
  limit: 10,
  page: 1,
};

export const onGetUsers =
  (searchOptions = defaultSearchOptions) =>
  async (dispatch: any) => {
    try {
      const searchParams = new URLSearchParams();
      if (searchOptions.name) {
        searchParams.append('name', searchOptions.name);
      }
      if (searchOptions.email) {
        searchParams.append('email', searchOptions.email);
      }
      if (searchOptions.designation) {
        searchParams.append('designation', searchOptions.designation);
      }
      if (searchOptions.manager && searchOptions.manager.id) {
        searchParams.append('manager', searchOptions.manager.id);
      }
      if (searchOptions.joiningDateBefore) {
        searchParams.append('joiningDateBefore', searchOptions.joiningDateBefore.toString());
      }
      if (searchOptions.joiningDateAfter) {
        searchParams.append('joiningDateAfter', searchOptions.joiningDateAfter.toString());
      }
      if (searchOptions.leavingDateBefore) {
        searchParams.append('leavingDateBefore', searchOptions.leavingDateBefore.toString());
      }
      if (searchOptions.leavingDateAfter) {
        searchParams.append('leavingDateAfter', searchOptions.leavingDateAfter.toString());
      }
      if (searchOptions.employmentType) {
        searchParams.append('employmentType', searchOptions.employmentType);
      }
      if (searchOptions.department) {
        searchParams.append('department', searchOptions.department);
      }
      if (searchOptions.subDepartment) {
        searchParams.append('subDepartment', searchOptions.subDepartment);
      }
      if (searchOptions.pan) {
        searchParams.append('pan', searchOptions.pan);
      }
      if (searchOptions.isActive) {
        searchParams.append('isActive', searchOptions.isActive.toString());
      }
      if (searchOptions.sortBy) {
        searchParams.append('sortBy', searchOptions.sortBy);
      }
      if (searchOptions.sortDirection) {
        searchParams.append('sortDirection', searchOptions.sortDirection);
      }
      if (searchOptions.limit) {
        searchParams.append('limit', searchOptions.limit.toString());
      }
      if (searchOptions.page) {
        searchParams.append('page', searchOptions.page.toString());
      }
      const users = await dispatch({
        [CALL_API]: {
          url: `/users/admin/users?${searchParams.toString()}`,
          method: 'GET',
        },
      });

      dispatch({
        type: GET_USERS_SUCCESS,
        users,
      });
      return users;
    } catch (error) {
      alert(`${error}`);
    }
  };

export const onGetUsersbyId = (users_id: string) => async (dispatch: any) => {
  try {
    const getUsersbyId = await dispatch({
      [CALL_API]: {
        url: `/users/admin/users/${users_id}`,
        method: 'GET',
      },
    });
    return getUsersbyId as UserDetails;
  } catch (error) {
    alert(error);
  }
};

export const createUser = (body: UserPayload) => async (dispatch: any) => {
  try {
    const { designation, joining_date, name, email, additionalDetails } = body;

    const additionalDetailsBody: Partial<UserAdditionalDetails> = {};
    if (additionalDetails) {
      Object.keys(additionalDetails).forEach((key: string) => {
        if (additionalDetails[key] !== '') {
          additionalDetailsBody[key] = additionalDetails[key];
        }
      });
    }
    const createdUser = await dispatch({
      [CALL_API]: {
        url: `/users/admin/users/create`,
        method: 'POST',
        body: {
          name,
          email,
          designation,
          joining_date,
          additionalDetails: additionalDetailsBody,
        },
      },
    });
    dispatch({
      type: CREATE_USERS_SUCCESS,
      body: createdUser,
    });
    return createdUser;
  } catch (error) {
    alert(`${error}`);
    throw error;
  }
};

export const onUpdateUsers =
  ({
    id,
    name,
    email,
    designation,
    department,
    subDepartment,
    joining_date,
    additionalDetails,
  }: {
    id: string;
    name?: string;
    email?: string;
    designation?: string;
    department?: string;
    subDepartment?: string;
    joining_date?: string | Date;
    additionalDetails?: Record<string, any>;
  }) =>
  async (dispatch: any) => {
    try {
      const updateUser = await dispatch({
        [CALL_API]: {
          url: `/users/admin/users/${id}/update`,
          method: 'POST',
          body: {
            name,
            email,
            designation,
            joining_date,
            department,
            subDepartment,
            additionalDetails,
          },
        },
      });
      dispatch({
        type: UPDATE_USERS_SUCCESS,
        user: updateUser,
      });

      return updateUser;
    } catch (error) {
      throw new Error('Error updating users. ' + error);
    }
  };

export const onActivateUsers = (id: string) => async (dispatch: any) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/users/admin/users/${id}/activate`,
        method: 'GET',
      },
    });
  } catch (error) {
    alert(`${error}`);
  }
};
export const onDeactivateUsers = (id: string) => async (dispatch: any) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/users/admin/users/${id}/deactivate`,
        method: 'GET',
      },
    });
  } catch (error) {
    alert(`${error}`);
  }
};
export const onUpdateManagerUsers =
  (id: string, manager?: AssignManager) => async (dispatch: any) => {
    const md = { manager: manager?.id };
    const md_name = manager?.name;
    const md_id = manager?.id;
    try {
      const assignManager = await dispatch({
        [CALL_API]: {
          url: `/users/admin/users/${id}/assign_manager`,
          method: 'POST',
          body: md,
        },
      });
      dispatch({
        type: UPDATE_MANAGER,
        user: { id },
        body: { manager: { id: md_id, name: md_name } },
      });
      return assignManager;
    } catch (error) {
      throw new Error('Manager Not Assigned. ' + error);
    }
  };

export const unassignManager = (id: string) => async (dispatch: any) => {
  try {
    const unassign_manager = await dispatch({
      [CALL_API]: {
        url: `/users/admin/users/${id}/unassign_manager`,
        method: 'POST',
      },
    });
    dispatch({
      type: SET_UNASSIGN_MANAGER,
      user: { id },
      body: { manager: null },
    });
    return unassign_manager;
  } catch (error) {
    throw new Error('Cannot unassign Manager' + error);
  }
};

export const onSearchUsers = (term: string) => async (dispatch: any) => {
  try {
    const users = await dispatch({
      [CALL_API]: {
        url: `/users/users/search?term=${term}`,
        method: 'GET',
      },
    });
    return users;
  } catch (error) {
    alert(`${error}`);
  }
};
