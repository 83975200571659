/* eslint-disable @typescript-eslint/no-explicit-any */
import { Middleware } from 'redux';
import { LOGOUT_SUCCESS } from './types/login';
import en from '../lang/en-us';
import { RootStateType } from './reducers';
import { SHOW_GLOBAL_ERROR } from './types/global';

const BASE_URL = process.env.REACT_APP_API_BASE_URL || '/api';
export const CALL_API = 'CALL_API';

const DEFAULT_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Expires: 0,
};

class AuthError extends Error {}

class APIError extends Error {}

class SilentError extends Error {}
interface CustomError {
  error: boolean;
  message: string;
  code: number;
}

export interface ResponseType {
  data: any;
  error: null | CustomError;
}

export interface ActionReqType {
  types: string[];
  body?: any;
  json?: string;
  url: string;
  method: 'POST' | 'GET' | 'PUT' | 'DELETE';
  contentType?: any;
  silent?: boolean;
}

async function invokeAPI({
  endpoint,
  config,
  headerContent,
  contentType,
}: {
  endpoint: string;
  config: any;
  headerContent?: any;
  json?: string;
  contentType?: any;
}): Promise<ResponseType> {
  const headers = { ...DEFAULT_HEADERS };
  const updatedConfig = {
    ...config,
    headers: { ...headers, ...headerContent },
  };
  const url = `${BASE_URL}${endpoint}`;
  const response = await fetch(url, updatedConfig);

  if (response.status === 401) {
    throw new AuthError(en.networkText.timeOut);
  }

  let body: any;
  if (typeof contentType === 'undefined') {
    body = await response.json();
  } else if (contentType === 'application/pdf') {
    body = await response.blob();
  }

  if (response.status === 404) {
    const { message = 'Something went wrong' } = body || {};
    throw new APIError(message);
  }

  if (response.status >= 400) {
    const { error, message } = body || {};
    throw new APIError(message || error || en.networkText.unableToProcess);
  }

  return { data: body, error: null };
}

export const middleWareDispatch: Middleware = (store) => (next) => async (action) => {
  if (typeof action[CALL_API] === 'undefined') {
    return next(action);
  }
  const { silent = false } = action[CALL_API];
  try {
    const { url, body, method = 'POST', json, contentType }: ActionReqType = action[CALL_API];
    const {
      authReducer: { token },
    }: RootStateType = store.getState();

    const headerContent = {
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
      ...DEFAULT_HEADERS,
    };
    const payload = {
      endpoint: url,
      headerContent,
      config: {
        method,
        body: JSON.stringify(body),
      },
      json,
      contentType,
    };
    const { data, error }: ResponseType = await invokeAPI(payload);
    if (error) {
      if (silent) {
        throw new SilentError(error.message);
      } else {
        throw error;
      }
    }
    return data;
  } catch (error) {
    if (error instanceof AuthError) {
      next({ type: LOGOUT_SUCCESS });
    } else if (silent || error instanceof SilentError) {
      throw error;
    } else if (error instanceof APIError) {
      next({
        type: SHOW_GLOBAL_ERROR,
        errorType: 'ALERT',
        message: (error as Error).message,
      });
      throw error;
    } else {
      next({
        type: SHOW_GLOBAL_ERROR,
        errorType: 'ALERT',
        message: en.networkText.networkError,
      });
      throw error;
    }
  }
};
