import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell as MUITableCell,
  TableBody,
  Button,
  TableCellProps,
} from '@mui/material';
import { Location } from 'history';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams, useRouteMatch, useLocation } from 'react-router-dom';
import { formatShortDate } from '../../../utils/date';
import { getAssessments } from '../assessment/actions';
import { AssessmentDataType } from '../types';

const assessmentHeaders = ['Date', 'Score', 'Comment', 'Type', 'Status'];

function TableHeadCell({ sx, ...rest }: TableCellProps): JSX.Element {
  return <MUITableCell {...rest} sx={{ fontWeight: 'bold', p: 1, ...sx }} />;
}

function TableCell({ sx, ...rest }: TableCellProps): JSX.Element {
  return <MUITableCell {...rest} sx={{ px: 1, ...sx }} />;
}

export default function CandidateAssessment(): JSX.Element {
  const [assessment, setAssessment] = useState<Array<AssessmentDataType>>([]);
  const { id: candidateId } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const containerRef = useRef();
  const { url } = useRouteMatch();
  const location = useLocation<{ background: Location }>();

  useEffect(() => {
    async function getAssessements() {
      try {
        const result = await dispatch(getAssessments({ candidateId }));
        setAssessment(result as unknown as Array<AssessmentDataType>);
      } catch (error) {
        alert(error);
      }
    }
    getAssessements();
  }, []);
  return (
    <Box ref={containerRef}>
      <Button
        variant="outlined"
        to={{
          pathname: `${url}/../add-assessment`,
          state: { background: location.state.background || '../' },
        }}
        component={Link}
        sx={{ textTransform: 'none', float: 'right', m: 3 }}
      >
        Add Assessment
      </Button>
      <TableContainer>
        <Table aria-label="Assessments list" size="small">
          <TableHead>
            <TableRow>
              {assessmentHeaders.map((head) => (
                <TableHeadCell key={head}>{head}</TableHeadCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {assessment.map((assess) => (
              <TableRow key={assess.id}>
                <TableCell>
                  {formatShortDate(assess.date)} by {assess.interviewer.name}
                </TableCell>
                <TableCell>{assess.score}</TableCell>
                <TableCell>{assess.comment}</TableCell>
                <TableCell>{assess.type}</TableCell>
                <TableCell>{assess.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
