/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CALL_API } from '../../../redux-store/middleware';
import { ADD_CAMPAIGN, SET_CAMPAIGNS, UPDATE_CAMPAIGN } from './constants';
import { Campaign, CampaignFormBody } from '../types';

const BASE_URL = '/ats/campaigns';

export const getCampaigns = () => async (dispatch: any) => {
  try {
    const campaigns = await dispatch({
      [CALL_API]: {
        url: BASE_URL,
        method: 'GET',
      },
    });
    dispatch({
      type: SET_CAMPAIGNS,
      campaigns,
    });
    return campaigns as Campaign[];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const createNewCampaign = (campaignFormBody: CampaignFormBody) => async (dispatch: any) => {
  const { ...campaignBody } = campaignFormBody;
  try {
    const campaign = await dispatch({
      [CALL_API]: {
        url: BASE_URL,
        method: 'POST',
        body: campaignBody,
      },
    });
    dispatch({
      type: ADD_CAMPAIGN,
      campaign,
    });
    return campaign;
  } catch (error) {
    throw new Error('Error creating new campaign. ' + error);
  }
};

export const updateCampaign =
  (id: string, campaignFormBody: CampaignFormBody) => async (dispatch: any) => {
    try {
      const campaign = await dispatch({
        [CALL_API]: {
          url: `${BASE_URL}/${id}`,
          method: 'POST',
          body: campaignFormBody,
        },
      });
      dispatch({
        type: UPDATE_CAMPAIGN,
        campaign,
      });
      return campaign;
    } catch (error) {
      throw new Error('Error updating campaign. ' + error);
    }
  };

export const getCampaignDetail = (campaignId: string) => async (dispatch: any) => {
  try {
    const campaign = await dispatch({
      [CALL_API]: {
        url: `${BASE_URL}/${campaignId}`,
        method: 'GET',
      },
    });
    return campaign as Campaign;
  } catch (error) {
    throw new Error('Unable to fetch campaign. ' + error);
  }
};
