import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  LinearProgress,
  ModalProps,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { formatShortDate } from '../../../utils/date';
import { createNote, onGetNotes, updateNote } from '../notes/actions';
import { Note } from '../types';
import EditIcon from '@mui/icons-material/Edit';

export default function NotesList(): JSX.Element {
  const [notes, setNotes] = useState<Array<Note>>([]);
  const [showAddNoteModal, setShowAddNoteModal] = useState(false);
  const [showUpdateNoteModal, setShowUpdateNoteModal] = useState(false);
  const { id: candidateId } = useParams<{ id: string }>();
  const [noteUnderEdit, setNoteUnderEdit] = useState<Note>();
  const dispatch = useDispatch();
  const containerRef = useRef();
  const [showProgress, setShowProgress] = useState(false);

  useEffect(() => {
    async function getNotes() {
      try {
        setShowProgress(true);
        const notes = await dispatch(onGetNotes({ candidateId }));
        setNotes(notes as unknown as Array<Note>);
      } catch (error) {
        console.log(error);
      } finally {
        setShowProgress(false);
      }
    }
    getNotes();
  }, []);

  return (
    <Box ref={containerRef}>
      {showProgress && <LinearProgress />}
      {showAddNoteModal && (
        <AddNoteModal
          open={showAddNoteModal}
          container={containerRef.current}
          onClose={setShowAddNoteModal.bind(null, false)}
          candidateId={candidateId}
          sx={{ position: 'absolute' }}
          onNoteAdded={(note) => {
            setNotes([note, ...notes]);
            setShowAddNoteModal(false);
          }}
        />
      )}
      {showUpdateNoteModal && (
        <UpdateNoteModal
          open={showUpdateNoteModal}
          container={containerRef.current}
          onClose={setShowUpdateNoteModal.bind(null, false)}
          candidateId={candidateId}
          sx={{ position: 'absolute' }}
          note={noteUnderEdit}
          onNoteUpdated={(updatedNote) => {
            setNotes(notes.map((note) => (note.id === updatedNote.id ? updatedNote : note)));
            setShowUpdateNoteModal(false);
          }}
        />
      )}
      <Stack direction="row" justifyContent="flex-end" sx={{ pt: 2 }}>
        <Button variant="outlined" onClick={setShowAddNoteModal.bind(null, true)} sx={{ mr: 2 }}>
          Add a note
        </Button>
      </Stack>
      <TableContainer>
        <Table aria-label="Notes list" size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Date</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Note</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notes.map((note) => (
              <TableRow key={note.id}>
                <TableCell>
                  {formatShortDate(note.createdAt)} by {note.enteredBy.name}
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => {
                      setNoteUnderEdit(note);
                      setShowUpdateNoteModal(true);
                    }}
                    sx={{ minWidth: 'unset' }}
                  >
                    <EditIcon />
                  </Button>
                  {note.note}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function UpdateNoteModal({
  onClose,
  candidateId,
  note,
  onNoteUpdated,
  ...props
}: DialogProps & {
  onClose: ModalProps['onClose'];
  candidateId: string;
  note?: Note;
  onNoteUpdated: (note: Note) => void;
}): JSX.Element {
  const [content, setContent] = useState(note?.note || '');
  const dispatch = useDispatch();
  async function handleSubmit() {
    try {
      if (!note) return;
      const reqBody = { note: content, date: new Date(), candidate: { id: candidateId } };
      const updatedNote = await dispatch(updateNote(note.id, reqBody));
      onNoteUpdated(updatedNote as unknown as Note);
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <Dialog {...props} PaperProps={{ sx: { width: '100%' } }}>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          rows={2}
          fullWidth
          multiline
          value={content}
          onChange={(event) => setContent(event.target.value || '')}
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose?.({}, 'backdropClick')}>Cancel</Button>
        <Button disabled={content.trim().length == 0} variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function AddNoteModal({
  onClose,
  candidateId,
  onNoteAdded,
  ...props
}: DialogProps & {
  onClose: ModalProps['onClose'];
  candidateId: string;
  onNoteAdded: (note: Note) => void;
}): JSX.Element {
  const [content, setContent] = useState('');
  const dispatch = useDispatch();
  async function handleSubmit() {
    try {
      const note = await dispatch(
        createNote({ note: content, date: new Date(), candidate: { id: candidateId } }),
      );
      onNoteAdded(note as unknown as Note);
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <Dialog {...props} PaperProps={{ sx: { width: '100%' } }}>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          rows={2}
          fullWidth
          multiline
          value={content}
          onChange={(event) => setContent(event.target.value || '')}
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose?.({}, 'backdropClick')}>Cancel</Button>
        <Button disabled={content.trim().length == 0} variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
