import { AnyAction } from 'redux';
import { SET_CANDIDATES, ADD_CANDIDATE } from '../candidates/constants';
import {
  Candidate,
  CandidatesSearchParameters,
  CANDIDATES_SEARCH_OPTIONS,
  SET_SEARCH_OPTIONS_TYPE,
  RESET_CANDIDATES_SEARCH_OPTIONS,
} from '../types';

export const allCandidates: Partial<CandidatesSearchParameters> = {
  status: 'any',
  campaignId: 'any',
  name: '',
  phone: '',
  email: '',
  orderBy: 'createdAt',
  orderDirection: 'desc',
  limit: 10,
};

export interface CandidatesState {
  candidates: Array<Candidate>;
  searchOptions: Partial<CandidatesSearchParameters>;
  searchOptionType: string;
}

const initialState: CandidatesState = {
  candidates: [],
  searchOptions: allCandidates,
  searchOptionType: 'allCandidates',
};

export const searchOptionsType: Record<string, Partial<CandidatesSearchParameters>> = {
  allCandidates: allCandidates,
};

export default function CandidatesReducer(
  state = initialState,
  action: AnyAction,
): CandidatesState {
  switch (action.type) {
    case SET_CANDIDATES:
      return {
        ...state,
        candidates: action.candidates,
      };
    case SET_SEARCH_OPTIONS_TYPE:
      return {
        ...state,
        searchOptionType: action.searchOptionType,
        searchOptions: {
          ...(searchOptionsType[action.searchOptionType] || allCandidates),
        },
      };
    case CANDIDATES_SEARCH_OPTIONS: {
      return {
        ...state,
        searchOptions: { ...action.searchOptions },
        searchOptionType: 'custom',
      };
    }
    case RESET_CANDIDATES_SEARCH_OPTIONS: {
      return {
        ...state,
        searchOptions: { ...allCandidates },
      };
    }
    case ADD_CANDIDATE: {
      return {
        ...state,
        candidates: [action.candidate, ...state.candidates],
        searchOptions: allCandidates,
      };
    }
    // case ADD_CANDIDATE:
    //   return [...state, action.candidate];
    // case UPDATE_CANDIDATE:
    //   return state.map((candidate: Candidate) => {
    //     if (candidate.id === action.candidate.id) {
    //       return action.candidate;
    //     }
    //     return candidate;
    //   });
    default:
      return state;
  }
}
