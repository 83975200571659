import {
  Button,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  SelectProps,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import ReplayIcon from '@mui/icons-material/Replay';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { getAssets } from '../../redux-store/actions/assets';
import { SET_SEARCH_OPTIONS, SET_SEARCH_OPTIONS_TYPE } from '../../redux-store/types/asset';
import { formatShortDate } from '../../utils/date';
import { ButtonPrimary } from '../forms/Button';
import { RootStateType } from '../../redux-store/reducers';
import SearchOptions from './SearchOptions';
import { useSnackbar } from 'notistack';
import { AssetsState } from '../../redux-store/reducers/assets';

const headers = [
  'Serial No',
  'Type',
  'Description',
  '(De)Allocated Since',
  'Allocated To',
  'Actions',
];

/**
 *
 * "Type", "description", "serial no", allocated to, allocated since.
 */
export default function AssetsList(): JSX.Element {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [showProgress, setShowProgress] = useState(false);
  const [openOptionsModal, setOpenOptionsModal] = useState(false);
  const { assets, searchOptions, searchOptionType } = useSelector<RootStateType, AssetsState>(
    (state) => state.assets,
  );
  useEffect(() => {
    async function fetchAssets() {
      try {
        setShowProgress(true);
        await dispatch(getAssets(searchOptions));
      } catch (error) {
        enqueueSnackbar((error as Error).message, { variant: 'error' });
      } finally {
        setShowProgress(false);
      }
    }
    fetchAssets();
  }, [searchOptions]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOptionsTypeChange = (event: any) => {
    const value = event.target.value;
    if (value === 'custom') {
      setOpenOptionsModal(true);
    } else {
      dispatch({ type: SET_SEARCH_OPTIONS_TYPE, searchOptionType: value });
    }
  };
  return (
    <>
      <SearchOptions
        open={openOptionsModal}
        onClose={() => {
          setOpenOptionsModal(false);
        }}
      ></SearchOptions>
      <Grid pb={2} spacing={2} container justifyContent="space-between">
        <Grid item>
          <Stack spacing={2} direction="row">
            <SearchOptionsSelect
              value={searchOptionType}
              onChange={handleOptionsTypeChange}
              autoWidth
            />
            <ButtonPrimary
              variant="outlined"
              endIcon={<FilterListIcon />}
              onClick={() => {
                setOpenOptionsModal(true);
              }}
            >
              More
            </ButtonPrimary>
            <ButtonPrimary
              variant="outlined"
              startIcon={<ReplayIcon />}
              onClick={() => {
                dispatch({ type: SET_SEARCH_OPTIONS, searchOptions: { ...searchOptions } });
              }}
            >
              Reload
            </ButtonPrimary>
          </Stack>
        </Grid>
        <Grid item>
          <Button
            component={RouterLink}
            variant="outlined"
            to={{ pathname: `${url}/add`, state: { background: location } }}
          >
            Create Asset
          </Button>
        </Grid>
      </Grid>
      {showProgress && <LinearProgress />}
      <TableContainer component={Paper}>
        <Table aria-label="assets table">
          <TableHead>
            <TableRow>
              {headers.map((head) => (
                <TableCell sx={{ fontWeight: 'bold' }} key={head}>
                  {head}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {assets.map((asset, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Button
                    to={{ pathname: `/admin/assets/${asset.id}`, state: { background: location } }}
                    component={RouterLink}
                  >
                    {asset.serialNumber}
                  </Button>
                </TableCell>
                <TableCell>{asset.type}</TableCell>
                <TableCell>{asset.description}</TableCell>
                <TableCell>{formatShortDate(asset.currentAllocation?.allocatedSince)}</TableCell>
                <TableCell>
                  {!(asset.currentAllocation && asset.currentAllocation.allocatedTo) && (
                    <>
                      <Typography component="span" sx={{ color: 'text.secondary' }}>
                        Allocate Now
                      </Typography>
                      <IconButton
                        size="small"
                        to={{
                          pathname: `/admin/assets/update/${asset.id}`,
                          state: { background: location },
                        }}
                        component={RouterLink}
                      >
                        <PersonAddAlt1OutlinedIcon />
                      </IconButton>
                    </>
                  )}
                  {asset.currentAllocation && asset.currentAllocation.allocatedTo && (
                    <>
                      <Typography component="span" sx={{ color: 'primary.main' }}>
                        {asset.currentAllocation?.allocatedTo?.name}
                      </Typography>
                      <IconButton
                        size="small"
                        to={{
                          pathname: `/admin/assets/update/${asset.id}`,
                          state: { background: location },
                        }}
                        component={RouterLink}
                      >
                        <PersonRemoveIcon />
                      </IconButton>
                    </>
                  )}
                </TableCell>
                <TableCell>
                  <Button
                    to={{ pathname: `/admin/assets/${asset.id}`, state: { background: location } }}
                    component={RouterLink}
                  >
                    View
                  </Button>
                  <Button
                    to={{
                      pathname: `/admin/assets/update/${asset.id}`,
                      state: { background: location },
                    }}
                    component={RouterLink}
                  >
                    Update
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function SearchOptionsSelect(props: SelectProps) {
  return (
    <Select label="Quick Filters" variant="standard" {...props}>
      <MenuItem value="unallocated">All Unallocated Assets</MenuItem>
      <MenuItem value="recentlyUpdated">Recently Updated</MenuItem>
      <MenuItem value="recentlyAllocated">Recently Allocated</MenuItem>
      <MenuItem value="inactive">Inactive Assets</MenuItem>
      <MenuItem value="custom">Custom Filters</MenuItem>
    </Select>
  );
}
