import { FormControl, FormHelperText, Typography } from '@mui/material';
import { useField } from 'formik';
import FilteredAutocomplete, {
  FilteredAutoCompleteProps,
  LabelType,
} from './filtered-autocomplete';

export type AutocompleteFieldProps<T> = FilteredAutoCompleteProps<T> & {
  name: string;
  placeholder?: string;
  label?: string;
  fullWidth?: boolean;
  margin?: 'none' | 'dense' | 'normal';
};

export default function AutocompleteField<T extends LabelType>({
  name,
  label,
  placeholder,
  fullWidth = true,
  margin = 'dense',
  options = [],
  ...props
}: AutocompleteFieldProps<T>): JSX.Element {
  const [field, meta, helper] = useField(name);
  const errorText =
    meta.error && meta.touched
      ? typeof meta.error === 'string'
        ? meta.error
        : (Object.values(meta.error).pop() as string)
      : '';
  const hasError = !!errorText;
  function onChange(event: React.SyntheticEvent, value: T) {
    helper.setValue(value);
  }
  return (
    <FormControl fullWidth={fullWidth} margin={margin} error={hasError} sx={{ mb: 3 }}>
      <Typography variant="h5" sx={{ mb: 2, opacity: '0.8', color: 'primary.darkBlue' }}>
        {label}
      </Typography>
      <FilteredAutocomplete<T>
        {...props}
        options={options}
        onChange={onChange}
        value={field.value}
        label={field.value?.name || placeholder || `Choose a ${label}`}
      />
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
}
