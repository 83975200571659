import { Box, LinearProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { createUser } from '../../redux-store/actions/users';
import { UserPayload } from '../../redux-store/types/users';
import { TitleBar } from '../assets/components';
import UserForm from './UserForm';

const date20YearsAgo = new Date();
date20YearsAgo.setFullYear(date20YearsAgo.getFullYear() - 20);
const initialValues: UserPayload = {
  name: '',
  email: '',
  designation: '',
  department: '',
  subDepartment: '',
  joining_date: new Date(),
  additionalDetails: {
    type: 'FullTime',
    empId: '',
    phoneNumber: '',
    pan: '',
    bloodGroup: '',
    address: '',
    fatherName: '',
    spouseName: '',
    dob: date20YearsAgo,
    aadhar: '',
    emergencyContact: '',
    personalEmail: '',
  },
};

export default function CreateUser(): JSX.Element {
  const [showProgress, setShowProgress] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <TitleBar title="Create New Employee" />
      {showProgress && <LinearProgress />}
      <Box
        sx={{
          px: 2,
          flexGrow: 1,
          flexShrink: 1,
          flexBasis: 'auto',
          overflowY: 'auto',
        }}
      >
        <UserForm
          initialValues={initialValues}
          onSubmit={async (values) => {
            try {
              setShowProgress(true);
              await dispatch(createUser(values));
              enqueueSnackbar('Employee created successfully', { variant: 'success' });
              setTimeout(history.goBack.bind(null), 1);
            } catch (error) {
              enqueueSnackbar((error as Error).message, { variant: 'error' });
            } finally {
              setShowProgress(false);
            }
          }}
        />
      </Box>
    </>
  );
}
