import { Grid, Typography } from '@mui/material';
import { useField } from 'formik';
import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import { useEffect, useMemo, useState } from 'react';
import { getCandidates } from './candidates/actions';

export interface IDataValues {
  label: string;
  value?: string | number;
  half?: boolean;
}

const isUrl = (value: string) =>
  !!value &&
  !/\S+@\S+\.\S+/.test(value) &&
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g.test(
    value,
  );
const formatAsLink = (value: string) => {
  return value.startsWith('http') ? value : `https://${value}`;
};
export function DataValues({ label, value, half = false }: IDataValues): JSX.Element {
  return (
    <Grid item xs={half ? 6 : 12} sx={{ mt: 0.5 }}>
      <Typography variant="body1" sx={{ color: 'text.secondary', mb: 0.5 }}>
        {label}
      </Typography>
      {typeof value === 'string' && isUrl(value) ? (
        <Typography>
          <a href={formatAsLink(value)} target="_blank" rel="noopener noreferrer">
            {value}
          </a>
        </Typography>
      ) : (
        <Typography variant="h4" fontWeight={400}>
          {value ? value : '---'}
        </Typography>
      )}
    </Grid>
  );
}

export type CandidateShort = {
  id: string;
  name: string;
};

export type FormCandidatesPickerProps<T> = Omit<
  AutocompleteProps<T, boolean, boolean, boolean>,
  'renderInput' | 'options'
> & {
  name: string;
  disabled?: boolean;
  margin?: 'dense' | 'none';
  fullWidth?: boolean;
  label?: string;
};

export function FormCandidatesPicker({
  name,
  fullWidth = true,
  disabled = false,
  margin = 'dense',
  label,
  ...rest
}: FormCandidatesPickerProps<CandidateShort>): JSX.Element {
  const [field, meta, { setValue }] = useField(name);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onChange, ...restField } = field;
  const dispatch = useDispatch();
  const errorText =
    meta.error && meta.touched
      ? typeof meta.error === 'string'
        ? meta.error
        : (Object.values(meta.error).pop() as string)
      : '';
  const hasError = !!errorText;
  const [inputValue, setInputValue] = useState('');
  const [users, setUsers] = useState<CandidateShort[]>([]);

  const handleInputChange = (event: React.SyntheticEvent, value: string) => {
    setInputValue(value);
  };

  //https://dmitripavlutin.com/react-throttle-debounce/
  const debouncedChangeHandler = useMemo(() => debounce(handleInputChange, 400), []);
  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, []);

  //Fetch results from the server for search term.
  useEffect(() => {
    let active = true;
    if (field.value) {
      setUsers([field.value]);
    }
    async function fetchUsersByText(text: string) {
      const users = await dispatch(
        getCandidates({
          name: text,
        }),
      );
      if (!active) return;
      setUsers(users as unknown as Array<CandidateShort>);
    }
    inputValue.length > 2 ? fetchUsersByText(inputValue) : setUsers([]);
    return () => {
      active = false;
    };
  }, [field.value, inputValue]);
  return (
    <Autocomplete
      options={users}
      clearOnBlur
      onInputChange={debouncedChangeHandler}
      onChange={(event, value) => {
        setValue(value);
      }}
      noOptionsText="Start typing or try different term"
      disablePortal
      filterOptions={(x) => x}
      {...restField}
      {...rest}
      getOptionLabel={(option) => `${option.name}`}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth={fullWidth}
          margin={margin}
          label={label}
          disabled={disabled}
          error={hasError}
          helperText={errorText}
        />
      )}
    />
  );
}
