import { Box, Drawer } from '@mui/material';
import { Location } from 'history';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router';
import CandidatesList from './list';
import CreateCandidate from './create';
import updateCandidate from './update';
import CreateAssessment from '../assessment/create';
import DetailRouter from './detail-router';

export default function CandidatesRouter(): JSX.Element {
  const { path } = useRouteMatch();
  const history = useHistory();
  const location = useLocation<{ background: Location }>();
  const background = location.state && location.state.background;
  return (
    <>
      <Switch location={background || location}>
        <Route exact path={path} component={CandidatesList} />
        <Route path={`${path}/:id/add-assessment`} component={CreateAssessment} />
        <Route path={`${path}/:id`} component={DetailRouter} />
        <Route path={`${path}/update/:id`} component={updateCandidate} />
        <Route exact path={`${path}/add`} component={CreateCandidate} />
      </Switch>
      <Drawer
        open={!!background}
        anchor="right"
        onClose={() => {
          history.go(-1);
        }}
      >
        <Box sx={{ width: 420 }} role="presentation">
          <Switch>
            <Route exact path={`${path}/add`} component={CreateCandidate} />
            <Route path={`${path}/:id/add-assessment`} component={CreateAssessment} />
            <Route path={`${path}/update/:id`} component={updateCandidate} />
            <Route path={`${path}/:id`} component={DetailRouter} />
          </Switch>
        </Box>
      </Drawer>
    </>
  );
}
