import { Box, LinearProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { getTicketsbyId, updateTicket } from '../../redux-store/actions/tickets';
import { UpdateTicketFormBody } from '../../redux-store/types/tickets';
import { TitleBar } from '../assets/components';
import UpdateForm from './UpdateForm';

export default function UpdateTicket(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const [showProgress, setShowProgress] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [updateValues, setUpdateValues] = useState<UpdateTicketFormBody>();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    async function getTicketDetails() {
      try {
        setShowProgress(true);
        const ticketdetails = await dispatch(getTicketsbyId(id));
        const { title, status, assignedTo } = ticketdetails as unknown as UpdateTicketFormBody;

        setUpdateValues({
          title,
          status,
          assignedTo,
        });
      } catch (error) {
        enqueueSnackbar((error as Error).message, {
          variant: 'error',
        });
      } finally {
        setShowProgress(false);
      }
    }
    getTicketDetails();
  }, [id]);

  return (
    <>
      <TitleBar title="Update Ticket" />
      {showProgress && <LinearProgress />}
      {updateValues && (
        <Box sx={{ p: 2 }}>
          <UpdateForm
            initialValues={updateValues}
            onSubmit={async (values) => {
              try {
                const { title, status, assignedTo } = values;
                setShowProgress(true);
                await dispatch(updateTicket(id, title, status, assignedTo));
                enqueueSnackbar('Ticket Updated successfully', { variant: 'success' });
                setShowProgress(false);
                setTimeout(history.push.bind(null, '/admin/tickets'), 1);
              } catch (error) {
                enqueueSnackbar((error as Error).message, { variant: 'error' });
              } finally {
                setShowProgress(false);
              }
            }}
          />
        </Box>
      )}
    </>
  );
}
