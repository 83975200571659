import * as React from 'react';
import { useTheme, styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Autocomplete, {
  AutocompleteCloseReason,
  autocompleteClasses,
} from '@mui/material/Autocomplete';
import ButtonBase from '@mui/material/ButtonBase';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Stack, Typography } from '@mui/material';

interface PopperComponentProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      borderBottom: `1px solid  ${theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'}`,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.action.hover,
        },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

function PopperComponent(props: PopperComponentProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  boxShadow: `0 8px 24px ${
    theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
  }`,
  borderRadius: 6,
  width: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 10,
  width: '100%',
  borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
  '& input': {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
    padding: 8,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    border: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
    fontSize: 14,
    '&:focus': {
      boxShadow: 'none',
      borderColor: theme.palette.mode === 'light' ? '#0366d6' : '#388bfd',
    },
  },
}));

const Button = styled(ButtonBase)(({ theme }) => ({
  fontSize: 13,
  width: '100%',
  textAlign: 'left',
  paddingBottom: 8,
  color: theme.palette.mode === 'light' ? '#586069' : '#8b949e',
  fontWeight: 600,
  '&:hover,&:focus': {
    color: theme.palette.mode === 'light' ? '#0366d6' : '#58a6ff',
  },
  '& span': {
    width: '100%',
  },
  '& svg': {
    width: 16,
    height: 16,
  },
}));

export type FilteredAutocompleteMultipleProps<T> = {
  label: string;
  searchPlaceholder?: string;
  options: T[];
  value?: T[];
  sx?: SxProps;
  extraPopperStyles?: SxProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (value: Array<string> | any) => void;
  disableSearch?: boolean;
  filterId: string;
};

export default function FilteredAutocompleteMultiple<T>({
  label,
  searchPlaceholder,
  options,
  value,
  onChange,
  disableSearch = false,
  filterId,
  sx,
}: FilteredAutocompleteMultipleProps<T>): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? filterId : undefined;
  return (
    <React.Fragment>
      <Box
        sx={{
          borderRight: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
          fontWeight: 600,
          display: 'flex',
          minWidth: 150,
          px: 1,
          py: 1.5,
          ...sx,
        }}
      >
        <Button
          sx={{
            justifyContent: 'space-between',
            '&:hover': {
              bgcolor: 'primary.white',
            },
            textTransform: 'none',
            width: '100%',
            padding: '0 10px',
            py: 0.6,
          }}
          disableRipple
          aria-describedby={id}
          onClick={handleClick}
          color="secondary"
        >
          <Typography variant="h5" sx={{ color: 'secondary.main', mr: 1 }}>
            {label}
          </Typography>
          {value && value.length > 0 && (
            <Typography
              component="span"
              sx={{
                mr: 1,
                ml: ['auto', 2],
                color: 'primary.main',
                fontSize: 12,
                fontWeight: 700,
                backgroundColor: 'primary.skyBlueBg',
                width: '20px !important',
                height: 20,
                borderRadius: '50%',
                textAlign: 'center',
              }}
            >
              {value.length}
            </Typography>
          )}
          <ExpandMoreIcon sx={{ '&.MuiSvgIcon-root': { width: 24, height: 24 } }} />
        </Button>
      </Box>
      <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Autocomplete
              open
              multiple
              // eslint-disable-next-line @typescript-eslint/ban-types
              onClose={(event: React.ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
                if (reason === 'escape') {
                  handleClose();
                }
              }}
              value={value}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(event, newValue: any, reason: any) => {
                if (
                  event.type === 'keydown' &&
                  (event as React.KeyboardEvent).key === 'Backspace' &&
                  reason === 'removeOption'
                ) {
                  return;
                }
                onChange && onChange(newValue);
              }}
              disableCloseOnSelect
              PopperComponent={PopperComponent}
              renderTags={() => null}
              noOptionsText={<Typography>No results to show</Typography>}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Stack flexDirection="row" alignItems="center">
                    <Box
                      component={selected ? CheckBoxIcon : CheckBoxOutlineBlankIcon}
                      sx={{
                        width: 17,
                        height: 17,
                        mr: 2,
                        ml: '-2px',
                        color: selected ? 'primary.main' : 'secondary.main',
                      }}
                    />
                    <Box
                      sx={{
                        flexGrow: 1,
                        '& span': {
                          color: theme.palette.mode === 'light' ? '#586069' : '#8b949e',
                        },
                      }}
                    >
                      <Typography>{option.name}</Typography>
                    </Box>
                  </Stack>
                </li>
              )}
              options={options}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <StyledInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder={searchPlaceholder}
                  sx={{
                    padding: !disableSearch ? '10px' : 0,
                    position: !disableSearch ? 'relative' : 'absolute',
                    '& input': {
                      display: !disableSearch ? 'block' : 'none',
                    },
                  }}
                />
              )}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </React.Fragment>
  );
}
