import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux-store/reducers';
import ReuseableLayoutHeader from '../../common/layouts';
import FilteredAutocompleteMultiple from '../../forms/Autocomplete/filtered-autocomplete-multiple';
import { getProjects } from '../actions';
import { ProjectState } from '../store/projectReducer';
import { Project } from '../types';
import { PROJECT_SEARCH_OPTIONS, RESET_PROJECT_SEARCH_OPTIONS } from './constants';

export default function ProjectFilters(): JSX.Element {
  const dispatch = useDispatch();
  const [projects, setProjects] = useState<Array<Project>>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedProjects, setSelectedProjects] = useState<Array<any>>([]);
  const { searchOptions } = useSelector<RootState, ProjectState>(
    (state) => state.delivery.projects,
  );

  function onProjectValuesChanged(values: Array<string>) {
    setSelectedProjects(values);
  }

  useEffect(() => {
    async function projectList() {
      try {
        const projects_ = await dispatch(getProjects());
        setProjects(projects_ as unknown as Array<Project>);
      } catch (error) {
        console.log(error);
      }
    }
    projectList();
  }, []);

  useEffect(() => {
    if (selectedProjects.length > 0) {
      dispatch({
        type: PROJECT_SEARCH_OPTIONS,
        searchOptions: {
          ...searchOptions,
          name: selectedProjects.map((project) => project.id).join(','),
        },
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { name, ...rest } = searchOptions;
      dispatch({
        type: PROJECT_SEARCH_OPTIONS,
        searchOptions: {
          ...rest,
        },
      });
    }
  }, [selectedProjects]);

  const project_ = projects?.map((project) => {
    return {
      name: project.name,
      id: project.name,
    };
  });
  const showClearButton = selectedProjects.length > 0;

  const handleClear = () => {
    setSelectedProjects([]);
    dispatch({
      type: RESET_PROJECT_SEARCH_OPTIONS,
    });
  };

  return (
    <>
      <ReuseableLayoutHeader>
        <FilteredAutocompleteMultiple
          label="Projects"
          searchPlaceholder="Search Client"
          options={project_ as Array<{ name: string; id: string }>}
          filterId="project-id"
          value={selectedProjects}
          onChange={onProjectValuesChanged}
          sx={{ minWidth: 245 }}
        />
      </ReuseableLayoutHeader>
      {showClearButton && (
        <Button variant="text" sx={{ ml: 1 }} onClick={handleClear}>
          Clear
        </Button>
      )}
    </>
  );
}
