import { AnyAction } from 'redux';
import { EmployeeCosts } from '../types';
import {
  UPDATE_RESOURCE_COST,
  LIST_RESOURCE_COSTS,
  LIST_RESOURCE_COSTS_SEARCH_OPTIONS,
  RESET_RESOURCE_COSTS_SEARCH_OPTIONS,
} from '../constants';

export interface ResourceCostSearchOptions {
  employee?: string;
  designation?: string;
  CTC?: string;
  costPerHour?: string;
  billableHours?: string;
}

export type ResourceCostState = {
  resourceCosts: EmployeeCosts[];
  searchOptions: ResourceCostSearchOptions;
};

const initialState = {
  resourceCosts: [],
  searchOptions: {},
};

export default function ResourceCostReducer(
  state = initialState,
  action: AnyAction,
): ResourceCostState {
  switch (action.type) {
    case LIST_RESOURCE_COSTS: {
      return {
        ...state,
        resourceCosts: action.payload,
      };
    }
    case UPDATE_RESOURCE_COST: {
      return {
        ...state,
        resourceCosts: state.resourceCosts.map((resourceCost: EmployeeCosts) =>
          resourceCost.employee.id === action.payload.employee.id ? action.payload : resourceCost,
        ),
      };
    }

    case LIST_RESOURCE_COSTS_SEARCH_OPTIONS: {
      return {
        ...state,
        searchOptions: { ...action.searchOptions },
      };
    }
    case RESET_RESOURCE_COSTS_SEARCH_OPTIONS: {
      return {
        ...state,
        searchOptions: {},
      };
    }
    default:
      return state;
  }
}
