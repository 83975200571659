import * as React from 'react';
import { useTheme, styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import DoneIcon from '@mui/icons-material/Done';
import Autocomplete, {
  AutocompleteCloseReason,
  AutocompleteProps,
  autocompleteClasses,
} from '@mui/material/Autocomplete';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import { ToggleButton, ToggleButtonProps, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

interface PopperComponentProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  width: 'unset !important',
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      borderBottom: `1px solid  ${theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'}`,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"], &[data-focus="true"][aria-selected="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
  [`& .${autocompleteClasses.groupLabel}`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#f0f0f0' : '#1c2128',
    lineHeight: '30px',
  },
}));

function PopperComponent(props: PopperComponentProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  boxShadow: `0 8px 24px ${
    theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
  }`,
  borderRadius: 6,
  width: '100%',
  maxWidth: '500px',
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  width: '100%',
  border: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
  borderRadius: 4,
  //   '& input:focus': {
  //     boxShadow: `0px 0px 0px 3px ${
  //       theme.palette.mode === 'light' ? 'rgba(3, 102, 214, 0.3)' : 'rgb(12, 45, 107)'
  //     }`,
  //     borderColor: theme.palette.mode === 'light' ? '#0366d6' : '#388bfd',
  //   },
  '& input': {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
    borderRadius: 4,
    padding: 8,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontSize: 14,
  },
}));

export type FilteredAutoCompleteProps<T> = {
  label: string;
  options: T[];
  value?: T;
  onChange?: (event: React.SyntheticEvent, value: T) => void;
  toggleButtonProps?: Omit<ToggleButtonProps, 'value'>;
  autoCompleteProps?: Omit<AutocompleteProps<T, false, false, false>, 'options' | 'renderInput'>;
  hint?: string;
  sx?: SxProps;
  extraPopperStyles?: SxProps;
};
export default function FilteredAutocomplete<T extends LabelType>({
  label,
  options,
  value,
  onChange,
  toggleButtonProps,
  autoCompleteProps,
  hint,
  sx,
  extraPopperStyles,
}: FilteredAutoCompleteProps<T>): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'github-label' : undefined;

  return (
    <React.Fragment>
      <ToggleButton
        aria-describedby={id}
        onClick={handleClick}
        value="check"
        selected={open}
        {...toggleButtonProps}
        sx={{
          background: 'rgba(217, 217, 217, 0.22)',
          border: '1px solid rgba(196, 196, 196, 0.4)',
          borderRadius: '2px',
          padding: '12px 18px',
          justifyContent: 'space-between',
          color: 'primary.darkBlue',
          fontWeight: 400,
          '&:hover': {
            backgroundColor: 'rgba(217, 217, 217, 0.4)',
          },
          '&.Mui-selected': {
            backgroundColor: 'rgba(217, 217, 217, 0.22)',
            color: 'primary.darkBlue',
            fontWeight: 400,
            borderRadius: '2px !important',
            '&:hover': {
              backgroundColor: 'rgba(217, 217, 217, 0.4)',
            },
          },
          ...sx,
        }}
      >
        {label}
        <ArrowDropDownIcon sx={{ color: 'primary.lightGrey' }} />
      </ToggleButton>
      <StyledPopper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        sx={{ ...extraPopperStyles }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            {hint && (
              <Box
                sx={{
                  borderBottom: `1px solid ${
                    theme.palette.mode === 'light' ? '#eaecef' : '#30363d'
                  }`,
                  padding: '8px 10px',
                  fontWeight: 600,
                }}
              >
                <Typography variant="h5">{hint}</Typography>
              </Box>
            )}
            <Autocomplete
              open
              fullWidth
              onClose={(event: React.SyntheticEvent, reason: AutocompleteCloseReason) => {
                if (reason === 'escape') {
                  handleClose();
                }
              }}
              onChange={(event, newValue, reason) => {
                if (
                  event.type === 'keydown' &&
                  (event as React.KeyboardEvent).key === 'Backspace' &&
                  reason === 'removeOption'
                ) {
                  return;
                }
                if (reason === 'clear' && event.type === 'change') {
                  return;
                }
                handleClose();
                if (!newValue) {
                  return;
                }
                onChange && onChange(event, newValue);
              }}
              PopperComponent={PopperComponent}
              renderTags={() => null}
              noOptionsText={<Typography>No results to show</Typography>}
              renderOption={(props, option) => (
                <li {...props}>
                  <Box
                    component={DoneIcon}
                    sx={{ width: 17, height: 17, mr: '5px', ml: '-2px' }}
                    style={{
                      visibility: option === value ? 'visible' : 'hidden',
                    }}
                  />
                  <Box
                    sx={{
                      flexGrow: 1,
                    }}
                  >
                    <Typography>{option.name}</Typography>
                  </Box>
                </li>
              )}
              options={options}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <Box
                  sx={{
                    p: 1,
                    borderBottom: (theme) =>
                      `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
                  }}
                >
                  <StyledInput
                    ref={params.InputProps.ref}
                    inputProps={params.inputProps}
                    startAdornment={<SearchIcon />}
                    autoFocus
                    placeholder="Search..."
                  />
                </Box>
              )}
              {...autoCompleteProps}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </React.Fragment>
  );
}

export interface LabelType {
  name: string;
}
