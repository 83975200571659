export type RelatedType = {
  id: string;
  name?: string;
};

export type Employee = RelatedType;

export type GroupShort = RelatedType;

export type GroupFormBody = {
  name: string;
};

export type Group = GroupFormBody &
  RelatedType & {
    updatedAt?: Date;
    createdAt?: Date;
    createdBy?: Employee;
    membersCount: number;
    subgroupsCount: number;
    members: GroupMember[];
    subGroups: Group[];
    subgroupSince: Date;
    addedBy: Employee;
  };
export type GroupMember = {
  id: string;
  employee: Employee;
  addedBy: Employee;
  memberSince: Date;
};

export type Permission = {
  name: string;
  description: string;
  includes?: Array<string>;
};

export type Grant = {
  allowed: boolean;
  permission: Permission;
  group?: GroupShort;
  grantedBy?: Employee;
  createdAt?: Date;
  permissionOwner?: 'self' | 'group';
};

import { Action } from 'redux';
export type PermissionsList = Array<string> | null;
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export type PermissionsAction = Action<typeof SET_PERMISSIONS | 'LOGOUT_SUCCESS'> & {
  payload: PermissionsList;
};
