// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Box, Drawer, Stack, Typography, useTheme } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { ReuseableStack } from '../time-tracking/approve/CustomLayouts';
import { useHistory } from 'react-router';

export default function ReuseableLayoutHeader({
  children,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sx = {},
}: {
  children?: React.ReactNode;
  sx?: React.CSSProperties;
}): JSX.Element {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ...(theme.mixins as any).standardBorder,
          my: 2,
          width: '100%',
          borderRadius: '2px',
          ...sx,
        }}
      >
        <Stack direction="row">
          <ReuseableStack>
            <FilterListIcon />
            {/* <Typography variant="h5" sx={{ color: 'secondary.main' }}>
              Filters
            </Typography> */}
          </ReuseableStack>
          {children}
        </Stack>
      </Box>
    </>
  );
}

export function DrawerRouterLayout({
  open,
  anchor,
  children,
}: {
  open: boolean;
  anchor: 'left' | 'right';
  children?: React.ReactNode;
}): JSX.Element {
  const history = useHistory();
  return (
    <Box sx={{ position: 'relative' }}>
      <Drawer
        open={open}
        anchor={anchor}
        onClose={() => {
          history.goBack();
        }}
        PaperProps={{
          style: {
            width: '45%',
            paddingBottom: '50px',
          },
        }}
      >
        {children}
      </Drawer>
    </Box>
  );
}
