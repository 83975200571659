/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from 'react';
import { ButtonPrimary } from '../forms/Button';
import { Formik, withFormik, Form, FastField, ErrorMessage } from 'formik';
import FormTextInput from '../forms/FormTextInput';
import FormDatePicker from '../forms/FormDatePicker';
import FormSelect from '../forms/FormSelect';
import * as Yup from 'yup';
import { Box } from '@mui/system';
import { Stack } from '@mui/material';

const LeaveTypes = ['sick', 'personal', 'comp-off', 'special'];

const Schema = Yup.object().shape({
  details: Yup.string().min(2, 'Too Short!').required('Required Details!'),
  startDate: Yup.date()
    .max(Yup.ref('endDate'), `Start date can't be after end date`)
    .required('Required Start Date'),
  endDate: Yup.date().required('Required End Date'),
  leaveType: Yup.string().required('Required Leave Type!'),
});

function CreateLeave(props: any) {
  return (
    <>
      <Box>
        <h3>Create Leave</h3>
        <Formik
          initialValues={{
            details: '',
            startDate: new Date(),
            endDate: new Date(),
            leaveType: '',
            forEmployee: '',
          }}
          validateOnMount={false}
          validationSchema={Schema}
          onSubmit={async (values, { setSubmitting }) => {
            await props.onSubmit(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form>
              <Stack sx={{ m: '10px' }}>
                <FormSelect label="Applying For" name="forEmployee" disabled={isSubmitting}>
                  <option value="">Self</option>
                  {/* suborniates list should be added here */}
                </FormSelect>
              </Stack>
              <Stack sx={{ m: '10px' }}>
                <FormTextInput
                  label="Details"
                  name="details"
                  type="text"
                  placeholder="Details"
                  disabled={isSubmitting}
                />
              </Stack>
              <Stack sx={{ m: '10px' }}>
                <FormDatePicker label="Start Date" name="startDate" disabled={isSubmitting} />
              </Stack>
              <Stack sx={{ m: '10px' }}>
                <FormDatePicker label="End Date" name="endDate" disabled={isSubmitting} />
              </Stack>
              <Stack sx={{ m: '10px' }}>
                <FormSelect label="Leave Type" name="leaveType" disabled={isSubmitting}>
                  <option value="">Select Leave Type</option>
                  {LeaveTypes.length > 0 &&
                    LeaveTypes.map((LeaveType) => {
                      return (
                        <option key={LeaveType} value={LeaveType}>
                          {LeaveType}
                        </option>
                      );
                    })}
                </FormSelect>
              </Stack>
              <Box sx={{ float: 'right', mt: '10px' }}>
                <ButtonPrimary fullWidth={false} variant="outlined" disabled={isSubmitting}>
                  Submit
                </ButtonPrimary>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
}
export default CreateLeave;
