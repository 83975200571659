import { combineReducers } from 'redux';
import authReducer from './login';
import usersReducer, { UsersState } from './users';
import leavesReducer from '../../components/leaves/store/reducer';
import errorReducer, { NetworkError } from './error';
import { AuthState } from '../types/login';
import assetsReducer, { AssetsState } from './assets';
import holidayReducer, { HolidaysState } from './holidays';
import ticketsReducer, { TicketState } from './tickets';
import atsReducer, { ATSState } from '../../components/ats/store/reducer';
import permissionsReducer, { PermissionsState } from '../../components/permissions/store/reducer';
import deliveryReducer, { DeliveryState } from '../../components/delivery/store/reducer';

export interface RootStateType {
  authReducer: AuthState;
  users: UsersState;
  error: NetworkError;
  assets: AssetsState;
  holidays: HolidaysState;
  tickets: TicketState;
  ats: ATSState;
  permissions: PermissionsState;
  delivery: DeliveryState;
}

export const rootReducer = combineReducers({
  authReducer,
  users: usersReducer,
  leavesReducer,
  error: errorReducer,
  assets: assetsReducer,
  holidays: holidayReducer,
  tickets: ticketsReducer,
  ats: atsReducer,
  permissions: permissionsReducer,
  delivery: deliveryReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
