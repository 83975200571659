import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import React from 'react';
import { TableProps } from './type';
import { ButtonPrimary } from '../../forms/Button';
import { Box } from '@mui/system';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    height: ' 100%',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.5,
    borderSpacing: 10,
  },
});

function PendingLeavesTable(props: TableProps): JSX.Element {
  const classes = useStyles();
  const { headers = [], data = [] } = props;
  return (
    <Box>
      <h1>Pending Leaves</h1>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow
              sx={{
                bgcolor: 'rgb(197, 206, 203)',
                '& .MuiTableCell-head': {
                  fontWeight: 'bold',
                },
              }}
            >
              {headers.map((head) => (
                <TableCell key={head.label} align="center">
                  {head.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                {headers.map((head) =>
                  head.label === 'Action' ? (
                    <TableCell
                      key={head.stateKey}
                      // component="th"
                      scope="row"
                      align="center"
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          '& .MuiButton-root': {
                            m: '5px',
                          },
                        }}
                      >
                        <ButtonPrimary
                          onClick={() => props.onAssignTo(row)}
                          fullWidth={false}
                          variant="contained"
                          color="secondary"
                        >
                          Assign To
                        </ButtonPrimary>
                        <ButtonPrimary
                          onClick={() => props.onApprove(row)}
                          fullWidth={false}
                          variant="contained"
                          color="primary"
                        >
                          Approve
                        </ButtonPrimary>
                        <ButtonPrimary
                          onClick={() => props.onReject(row)}
                          fullWidth={false}
                          variant="contained"
                          color="secondary"
                        >
                          Reject
                        </ButtonPrimary>
                      </Box>
                    </TableCell>
                  ) : (
                    <TableCell
                      key={head.stateKey}
                      // component="th"
                      scope="row"
                      align="center"
                    >
                      {row[head.stateKey]}
                    </TableCell>
                  ),
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default PendingLeavesTable;
