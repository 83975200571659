import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Box, LinearProgress } from '@mui/material';
import { CampaignFormBody } from '../types';
import CampaignForm from './forms';
import { updateCampaign, getCampaignDetail } from './actions';
import { TitleBar } from '../../assets/components';
export default function UpdateCampaign(): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<{ id: string }>();

  const [campaign, setCampaign] = useState<CampaignFormBody>();
  const [showProgress, setShowProgress] = useState<boolean>(true);

  useEffect(() => {
    async function fetchCampaign() {
      try {
        setShowProgress(true);
        const _campaign = await dispatch(getCampaignDetail(id));
        setCampaign(_campaign as unknown as CampaignFormBody);
      } catch (error) {
        enqueueSnackbar((error as Error).message, {
          variant: 'error',
        });
      } finally {
        setShowProgress(false);
      }
    }
    fetchCampaign();
  }, [id]);
  return (
    <>
      <TitleBar title="Update Campaign" />
      {showProgress && <LinearProgress />}
      {campaign && (
        <Box sx={{ p: 2 }}>
          <CampaignForm
            initialValues={campaign}
            onSubmit={async (values: CampaignFormBody): Promise<void> => {
              try {
                setShowProgress(true);
                await dispatch(updateCampaign(id, values));
                setTimeout(history.push.bind(null, '../../campaigns'), 1);
                enqueueSnackbar('Campaign updated successfully', { variant: 'success' });
              } catch (error) {
                enqueueSnackbar((error as Error).message, { variant: 'error' });
              } finally {
                setShowProgress(false);
              }
            }}
          />
        </Box>
      )}
    </>
  );
}
