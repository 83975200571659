import { LoadingButton } from '@mui/lab';
import { LinearProgress, Typography, Box, Toolbar, Button, Stack, Divider } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { formatDateRange } from '../../../utils/date';
import {
  approveTimeSheetByEmployeeId,
  getTimeLogs,
  getTimesheetById,
  unlockTimeSheetByEmployeeId,
} from '../actions';
import { TimesheetStatusChip } from '../common';
import { useTimeStore } from '../store/context-store';
import TimeLogsConsolidated from './TimeLogsConsolidated';
import TimeLogsDetailed from './TimeLogsDetail';
import { Timelog, Timesheet } from '../types';
import { LockOpen } from '@mui/icons-material';
import { UPDATE_TIMESHEET } from '../store/timesheet-list.reducer';
import CloseIcon from '@mui/icons-material/Close';

export default function TimesheetDetail(): JSX.Element {
  const { timeSheetId } = useParams<{ timeSheetId: string }>();
  const [rootState, dispatchToTimeStore] = useTimeStore();
  const members = rootState.team.users;
  const [showProgress, setShowProgress] = useState(false);
  const [showTimesheetDetails, setShowTimesheetDetails] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [timesheet, setTimesheet] = useState<Timesheet>();
  const [timelogs, setTimelogs] = useState<Array<Timelog>>();
  const canApprove =
    timesheet && members.find((member) => member.id === timesheet.employee.id) !== undefined;
  const history = useHistory();
  function goBack() {
    history.goBack();
  }
  async function approveTimesheet() {
    try {
      if (!timesheet) {
        return;
      }
      setShowProgress(true);
      const response = await dispatch(
        approveTimeSheetByEmployeeId({
          employeeId: timesheet.employee.id,
          startDate: timesheet.startDate,
          endDate: timesheet.endDate,
        }),
      );
      enqueueSnackbar('Timesheet approved successfully', { variant: 'success' });
      dispatchToTimeStore({
        type: 'UPDATE_TIMESHEET',
        timesheet: response as unknown as Timesheet,
      });
      goBack();
    } catch (error) {
      enqueueSnackbar((error as Error).message, { variant: 'error' });
    } finally {
      setShowProgress(false);
    }
  }
  async function unlockTimesheet() {
    try {
      if (!timesheet) {
        return;
      }
      setShowProgress(true);
      const response = await dispatch(
        unlockTimeSheetByEmployeeId({
          employeeId: timesheet.employee.id,
          startDate: timesheet.startDate,
          endDate: timesheet.endDate,
        }),
      );
      enqueueSnackbar('Timesheet unlocked successfully', { variant: 'success' });
      dispatchToTimeStore({
        type: UPDATE_TIMESHEET,
        timesheet: response as unknown as Timesheet,
      });
      goBack();
    } catch (error) {
      enqueueSnackbar((error as Error).message, { variant: 'error' });
    } finally {
      setShowProgress(false);
    }
  }
  const date = new Date(timesheet?.startDate || new Date());
  date.setUTCHours(12, 0, 0, 0);
  const trackUrl = `../track/week/${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}/${
    timesheet?.employee.id
  }`;

  useEffect(() => {
    async function fetchTimesheetDetails() {
      try {
        setShowProgress(true);
        const _t = (await dispatch(getTimesheetById(timeSheetId))) as unknown as Timesheet;
        setTimesheet({ ..._t });
      } catch (error) {
        enqueueSnackbar(
          'Could not fetch timesheet. Please try again. Error: ' + (error as Error).message,
          { variant: 'error' },
        );
      } finally {
        setShowProgress(false);
      }
    }
    fetchTimesheetDetails();
  }, [timeSheetId]);
  useEffect(() => {
    async function fetchTimelogs() {
      if (!timesheet) {
        return;
      }
      try {
        setShowProgress(true);
        const _logs = await dispatch(getTimeLogs(timesheet.employee.id, timesheet.startDate));
        setTimelogs(_logs as unknown as Array<Timelog>);
      } catch (error) {
        enqueueSnackbar(
          'Could not fetch timesheet entries. Please try again. Error: ' + (error as Error).message,
          { variant: 'error' },
        );
      } finally {
        setShowProgress(false);
      }
    }
    fetchTimelogs();
  }, [timesheet]);

  return (
    <>
      <Toolbar
        sx={{
          flexGrow: 0,
          flexShrink: 0,
          flexBasis: 'auto',
          p: 3,
          position: 'relative',
        }}
      >
        <Typography
          variant="h2"
          sx={{
            flexGrow: 1,
            fontSize: [16, 22],
            pr: [3, 0],
          }}
        >
          {timesheet && (
            <>
              {timesheet.employee.name}
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 400,
                  px: 1,
                  fontSize: [14, 22],
                  display: ['inline-block', 'inline'],
                  mb: [1, 0],
                }}
                component="span"
              >
                {`(${formatDateRange(timesheet.startDate, timesheet.endDate)})`}
              </Typography>
              {timesheet && <TimesheetStatusChip status={timesheet.status} />}
            </>
          )}
          {!timesheet && 'Loading Timesheet'}
        </Typography>
        <Button
          onClick={() => goBack()}
          sx={{
            position: ['absolute', 'relative'],
            right: [20, 0],
            top: [20, 0],
            p: [0, '7px 16px'],
            minWidth: ['unset', '64px'],
            '&:hover': {
              background: 'transparent',
            },
          }}
        >
          <CloseIcon sx={{ color: 'black' }} />
        </Button>
      </Toolbar>
      <Divider />

      {showProgress && <LinearProgress />}
      {timesheet && (
        <Box sx={{ px: 3, pt: 3, overflowY: 'auto' }}>
          <Typography variant="h3" sx={{ pb: 1, mb: 2 }}>
            Consolidated Hours
          </Typography>
          {timelogs && <TimeLogsConsolidated timelogs={timelogs} startDate={timesheet.startDate} />}
          {timelogs && showTimesheetDetails && (
            <Stack sx={{ mb: 3 }}>
              <Typography variant="h3" sx={{ pb: 1, mb: 2, pt: 3 }}>
                Time Log Entries
              </Typography>
              <TimeLogsDetailed timelogs={timelogs} startDate={timesheet.startDate} />
            </Stack>
          )}
        </Box>
      )}
      <Toolbar
        sx={{
          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
          backgroundColor: 'rgba(72, 120, 245, 0.05)',
          py: 1,
        }}
      >
        <Stack spacing={2} direction="row" sx={{ flexGrow: 1 }}>
          {timelogs && (
            <Button
              sx={{ px: [1, 2] }}
              variant="contained"
              color="primary"
              onClick={setShowTimesheetDetails.bind(null, !showTimesheetDetails)}
            >
              {!showTimesheetDetails ? 'Show Log Entries' : 'Hide Log Entries'}
            </Button>
          )}
          <Typography
            variant="h6"
            component="span"
            sx={{ flexGrow: 1, ml: ['0 !important', 2] }}
          ></Typography>
          {timesheet && timesheet.status === 'CREATED' && (
            <Button variant="outlined" color="primary" component={RouterLink} to={trackUrl}>
              Modify
            </Button>
          )}
          {canApprove &&
            timesheet &&
            (timesheet.status === 'SUBMITTED' || timesheet.status === 'CREATED') && (
              <LoadingButton
                disabled={showProgress}
                loading={showProgress}
                variant="contained"
                color="primary"
                onClick={() => approveTimesheet()}
              >
                Approve
              </LoadingButton>
            )}
          {canApprove && timesheet && timesheet.status === 'APPROVED' && (
            <LoadingButton
              disabled={showProgress}
              loading={showProgress}
              startIcon={<LockOpen />}
              variant="contained"
              color="primary"
              onClick={() => unlockTimesheet()}
            >
              Unlock
            </LoadingButton>
          )}
        </Stack>
      </Toolbar>
    </>
  );
}
