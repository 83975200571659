import {
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { CSVLink } from 'react-csv';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { TableHeadCell } from '../common';
import { EmployeeWithHours } from '../types';
import { useReport } from './constants';
import TeamReportsHeader from './sow-period-filter-header';
import { getTaskReportForSOW } from '../actions';

function formatHours(hours: number) {
  return hours.toFixed(1);
}

type EmployeeWithTask = EmployeeWithHours & {
  costPerHour: number;
  date: Date;
  sow: string;
  task: string;
};

export default function TaskReports(): JSX.Element {
  const { showLoading, results } = useReport<EmployeeWithTask>(getTaskReportForSOW);
  return (
    <>
      <Stack direction="row" spacing={2} alignItems="center">
        <TeamReportsHeader />
        <CSVLink data={results || []} filename="tasks.csv" style={{ marginLeft: 0 }}>
          <CloudDownloadIcon color="secondary" />
        </CSVLink>
      </Stack>
      {showLoading && <LinearProgress />}
      {results && results.length > 0 && <MUITable results={results} />}
    </>
  );
}

function MUITable({ results }: { results: Array<EmployeeWithTask> }): JSX.Element {
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableHeadCell>#</TableHeadCell>
            <TableHeadCell>SOW</TableHeadCell>
            <TableHeadCell>Name</TableHeadCell>
            <TableHeadCell>Date</TableHeadCell>
            <TableHeadCell sx={{ textAlign: 'right' }}>Hours</TableHeadCell>
            <TableHeadCell>Task</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableHeadCell></TableHeadCell>
            <TableHeadCell>Total</TableHeadCell>
            <TableHeadCell></TableHeadCell>
            <TableHeadCell></TableHeadCell>
            <TableHeadCell sx={{ textAlign: 'right' }}>
              {formatHours(results.map(({ noOfHours }) => noOfHours).reduce((a, b) => a + b, 0))}
            </TableHeadCell>
            <TableHeadCell></TableHeadCell>
          </TableRow>
          {results.map((employee, i) => (
            <TableRow key={employee.id}>
              <TableCell>{i + 1}</TableCell>
              <TableCell>{employee.sow}</TableCell>
              <TableCell>{employee.name}</TableCell>
              <TableCell>{employee.date}</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>{formatHours(employee.noOfHours)}</TableCell>
              <TableCell>{employee.task}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
