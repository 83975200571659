/* eslint-disable @typescript-eslint/no-unused-vars */
import { Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Link as RouterLink,
  useRouteMatch,
  generatePath,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { periods, useExtractParams } from './constants';
import { LocationDescriptor } from 'history';
import { getPhases } from '../delivery/actions';
import { SOWType } from '../time-tracking/types';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
// import { BasicDatePicker } from '../time-tracking/common';

function useTabUrls() {
  const { search, pathname } = useLocation();
  const pathComponents = pathname.split('/').slice(0, -1);
  return {
    teamReportsUrl: { pathname: pathComponents.concat('team').join('/'), search },
    projectSummaryReportUrl: { pathname: pathComponents.concat('sow').join('/'), search },
    taskReportsUrl: { pathname: pathComponents.concat('tasks').join('/'), search },
  };
}

export default function TeamReportsHeader(): JSX.Element {
  const { dateLabel, periodLabel, sow, period, prevUrl, nextUrl, startDateString } =
    useExtractParams();

  const { path } = useRouteMatch();
  const history = useHistory();

  function handleChange(newSOW: string | undefined, newPeriod: string) {
    if (newSOW == sow && newPeriod == period) {
      return;
    }
    const searchParams = new URLSearchParams({
      period: newPeriod,
      startDate: startDateString,
    });
    if (newSOW) {
      searchParams.set('sow', newSOW);
    }

    //navigate to new url
    const location: LocationDescriptor<unknown> = {
      pathname: generatePath(path),
      search: searchParams.toString(),
    };
    history.replace(location);
  }

  const dispatch = useDispatch();
  const [sows, setSows] = useState<Array<SOWType>>([]);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    async function getAllPhases() {
      try {
        const _sows = await dispatch(getPhases());
        setSows(_sows as unknown as Array<SOWType>);
      } catch (error) {
        enqueueSnackbar(`Could not fetch SOWS: ${(error as Error).message}`, { variant: 'error' });
      }
    }
    getAllPhases();
  }, []);
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
      flexGrow={1}
    >
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
        }}
      >
        {period !== 'all' && (
          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              width: ['100%', 'auto'],
              '.date-toggle-arrows': {
                '&.MuiToggleButtonGroup-root': {
                  borderRadius: '2px 0 0 2px',
                  borderRight: 0,
                  '& .MuiToggleButton-root': {
                    minWidth: 30,
                    borderRight: '1px solid #BEBFC6',
                    padding: '10px 5px',
                    borderRadius: 0,
                    '& svg': {
                      height: '14px',
                    },
                    '&:last-child': {
                      borderRight: 0,
                    },
                  },
                },
              },
            }}
          >
            <ToggleButtonGroup color="primary" className="date-toggle-arrows">
              <ToggleButton
                sx={{ py: '6px', px: 1.5, borderRadius: 20 }}
                value="prev"
                component={RouterLink}
                to={prevUrl}
                size="small"
              >
                <ArrowBackIosNewIcon fontSize="small" />
              </ToggleButton>
              <ToggleButton
                sx={{ py: '6px', px: 1.5, borderRadius: 20 }}
                value="next"
                component={RouterLink}
                to={nextUrl}
                size="small"
              >
                <ArrowForwardIosIcon fontSize="small" />
              </ToggleButton>
            </ToggleButtonGroup>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: 'center',
                justifyContent: 'space-between',
                border: '1px solid #BEBFC6',
                borderRadius: '0 2px 2px 0',
                height: 34,
                padding: '7px 20px',
                width: ['100%', 'auto'],
              }}
            >
              <Typography variant="h4">{periodLabel}</Typography>
              <Typography variant="h4">{dateLabel}</Typography>
              {/* {!isCurrentPeriod && (
                    <RouterLink to={currentPeriodUrl}>{returnToCurrentPeriodLabel}</RouterLink>
                  )} */}
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

{
  /* <Tabs value={pathComponent}>
        <Tab
          value={'sow'}
          component={RouterLink}
          label="Project Summary"
          to={projectSummaryReportUrl}
        />
        <Tab value={'team'} label="Team" component={RouterLink} to={teamReportsUrl} />
        <Tab value={'tasks'} label="Tasks" component={RouterLink} to={taskReportsUrl} />
      </Tabs>
      <Formik
        initialValues={{ sow, period }}
        onSubmit={() => {
          console.log('Form Submitted');
        }}
      >
        {() => (
          <Stack direction="row">
            <FormSelect
              label="SOW"
              value={sow}
              options={sowOptions}
              name="sow"
              sx={{ width: '150px', mr: 4 }}
              onChange={async (event) => {
                handleSOWChange(event.target.value as string);
              }}
            />
            <FormSelect
              label="Period"
              value={period}
              options={periodValues}
              name="period"
              sx={{ width: '150px' }}
              onChange={async (event) => {
                handlePeriodChange(event.target.value as string);
              }}
            />
          </Stack>
        )}
      </Formik> */
}
