import { AnyAction } from 'redux';
import {
  CREATE_LEAVE_SUCCESS,
  GET_LEAVES_BY_USER_SUCCESS,
  UPDATE_LEAVE_SUCCESS,
  UPDATE_LEAVE_STATUS_SUCCESS,
} from '../types';

export type LeavesState = {
  leaves: unknown[];
};

const intiState: LeavesState = {
  leaves: [],
};
function leavesReducer(state = intiState, action: AnyAction): LeavesState {
  switch (action.type) {
    case GET_LEAVES_BY_USER_SUCCESS: {
      const { body } = action;
      return {
        ...state,
        ...body,
      };
    }
    case CREATE_LEAVE_SUCCESS: {
      const { body } = action;
      return {
        ...state,
        ...body,
      };
    }
    case UPDATE_LEAVE_SUCCESS: {
      const { body } = action;
      return {
        ...state,
        ...body,
      };
    }
    case UPDATE_LEAVE_STATUS_SUCCESS: {
      const { body } = action;
      return {
        ...state,
        ...body,
      };
    }
    default:
      return state;
  }
}

export default leavesReducer;
