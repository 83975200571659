import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Box, LinearProgress } from '@mui/material';

import { updateHoliday } from '../../redux-store/actions/holidays';

// import { onCreateHolidays } from '../../redux-store/actions/holidays';
import { HolidayPayload } from '../../redux-store/types/holidays';
import { HolidaysState } from '../../redux-store/reducers/holidays';
import { RootStateType } from '../../redux-store/reducers';

import HolidayForm from './Forms';
import { TitleBar } from '../assets/components';

function UpdateHoliday(): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { id: _id } = useParams<{ id: string }>();

  const [holiday, setHoliday] = useState<HolidayPayload>();
  const [showProgress, setShowProgress] = useState<boolean>(true);

  const { holidays: allHolidays } = useSelector<RootStateType, HolidaysState>((state) => {
    return state.holidays;
  });
  useEffect(() => {
    async function fetchHoliday() {
      try {
        setShowProgress(true);
        const currentHoliday = allHolidays.filter((obj: HolidayPayload) => {
          return obj._id === _id;
        });
        const { name, date } = currentHoliday[0];
        setHoliday({ name, date });
      } catch (error) {
        enqueueSnackbar((error as Error).message, {
          variant: 'error',
        });
      } finally {
        setShowProgress(false);
      }
    }
    fetchHoliday();
  }, [_id, allHolidays]);

  return (
    <>
      <TitleBar title="Update Holiday" />
      {showProgress && <LinearProgress />}
      {holiday && (
        <Box>
          <HolidayForm
            initialValues={holiday}
            onSubmit={async (values: HolidayPayload): Promise<void> => {
              try {
                const d = new Date(values.date);
                // Year to update year in store
                const year = String(d.getFullYear());
                const newDate = d.toISOString().split('T')[0];

                values['date'] = newDate + 'T00:00:00.000Z';

                setShowProgress(true);
                await dispatch(updateHoliday({ _id, ...values, year }));
                setTimeout(history.push.bind(null, '/holidays'), 1);
                enqueueSnackbar('Holiday updated successfully', { variant: 'success' });
              } catch (error) {
                enqueueSnackbar((error as Error).message, { variant: 'error' });
              } finally {
                setShowProgress(false);
              }
            }}
          />
        </Box>
      )}
    </>
  );
}
export default UpdateHoliday;
