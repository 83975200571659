import {
  TableHead,
  TableRow,
  Table,
  TableContainer,
  Typography,
  TableBody,
  Box,
  useTheme,
} from '@mui/material';
import { DateTitle, formatAsTime, TableBodyCell, TableHeadCell } from '../common';
import { useConsolidatedLogs } from '../constants';
import { SOWType, Timelog } from '../types';

export default function TimeLogsConsolidated({
  timelogs,
  startDate,
}: {
  timelogs: Array<Timelog>;
  startDate: Date;
}): JSX.Element {
  const { uniqueTaskEntries, days, timelogsCompressed } = useConsolidatedLogs(timelogs, startDate);

  const rows = uniqueTaskEntries.map((entry) => {
    const task = entry;
    const sow = entry.sow;
    const hours = days.map((day) => {
      const log = timelogsCompressed.find(
        (l) => l.sow.id === sow.id && l.task.id === task.id && l.date.getTime() === day.getTime(),
      );
      return log ? log.noOfHours : 0;
    });
    return [sow, task.name, ...hours, hours.reduce((a, b) => a + b, 0)];
  });

  const totals = rows.length
    ? (rows[0].slice(2) as Array<number>).map((_, i) =>
        (rows as unknown as Array<Array<number>>).reduce(
          (prev, row) => (row[i + 2] as number) + prev,
          0,
        ),
      )
    : // eslint-disable-next-line @typescript-eslint/no-unused-vars
      days.map((_) => 0);
  rows.push(['', '', ...totals]);
  const theme = useTheme();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const themeMixins = theme.mixins as any;
  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow sx={{ '& .MuiTableCell-root': { borderRight: 0 } }}>
              <TableHeadCell
                sx={{
                  minWidth: [220, 'unset'],
                }}
              >
                <Typography variant="h3">Project/SOW</Typography>
              </TableHeadCell>
              {days?.map((day) => (
                <TableHeadCell
                  key={day.toISOString()}
                  sx={{
                    width: 68,
                    textAlign: 'center',
                    ...themeMixins.standardBorderRight,
                    ...themeMixins.standardBorderLeft,
                    p: 1,
                  }}
                >
                  <DateTitle date={day}></DateTitle>
                </TableHeadCell>
              ))}
              <TableHeadCell sx={{ width: 120 }}></TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              '& .MuiTableRow-root:last-child': {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ...(theme.mixins as any).standardBorderBottom,
                '& .MuiTableCell-root': {
                  py: 4,
                },
              },
            }}
          >
            {rows.map((row) => {
              const sow = row[0] as SOWType;
              const task = row[1] as string;
              return (
                <TableRow key={sow?.id}>
                  <TableBodyCell sx={{ borderLeft: 'none', borderBottom: 'none' }}>
                    {sow && (
                      <>
                        <Box component="div" sx={{ mb: 1 }}>
                          <Typography variant="h4">
                            {sow.name} {'   '}
                            <Typography variant="body1" component="span">
                              ({sow.project.name})
                            </Typography>
                          </Typography>
                        </Box>
                        <Box component="div">
                          <Typography
                            variant="body1"
                            sx={{
                              padding: '4px 10px',
                              borderRadius: '40px',
                              bgcolor: 'primary.veryLightGrey',
                              display: 'inline-flex',
                            }}
                          >
                            {task}
                          </Typography>
                        </Box>
                      </>
                    )}
                  </TableBodyCell>
                  {row.slice(2).map((hours, i) => (
                    <TableBodyCell
                      key={i}
                      sx={{
                        // px: 3,
                        width: 68,
                        textAlign: 'right',
                        borderRight: 'none',
                        borderBottom: 'none',
                        '&:last-child': {
                          '& .MuiTypography-root': {
                            fontWeight: 'bold',
                            fontSize: 16,
                            textAlign: 'right',
                          },
                        },
                      }}
                    >
                      <Typography
                        variant="h4"
                        sx={{
                          color: hours == 0 ? 'primary.lightGrey' : 'primary.darkBlue',
                        }}
                      >
                        {hours ? formatAsTime(hours as number) : '0:00'}
                      </Typography>
                    </TableBodyCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
