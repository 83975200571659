import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import * as yup from 'yup';
import { FormEvent, MouseEvent, useRef, useState } from 'react';
import { SOWType, Task, TimelogBody } from '../types';
import { Form, Formik, FormikProps } from 'formik';
import FormTextInput from '../../forms/FormTextInput';
import AutocompleteField from '../../forms/Autocomplete/field';
import { LoadingButton } from '@mui/lab';
import { removeDuplicates } from '../constants';
import CloseIcon from '@mui/icons-material/Close';
const ValidationSchema = yup.object().shape({
  sow: yup
    .object()
    .shape({
      id: yup.string().required('SOW is required'),
    })
    .required('SOW is required'),
  task: yup
    .object()
    .shape({
      id: yup.string().required('Task is required'),
    })
    .required('Task is required'),
  notes: yup.string().optional(),
  noOfHours: yup.number().required('No of hours is required').min(0).max(24),
});

type TimeDialogFormProps = Omit<DialogProps, 'onSubmit'> & {
  date: Date;
  allowDelete?: boolean;
  title: string;
  sows?: Array<SOWType>;
  initialValues: AddTimeDialogFormType;
  onSubmit: (values: AddTimeDialogFormType) => void;
  onDelete?: () => void;
};

export type AddTimeDialogFormType = Omit<TimelogBody, 'date' | 'employee'>;

export function TimeDialogForm({
  allowDelete = false,
  title,
  onClose,
  onSubmit,
  sows,
  initialValues,
  onDelete,
  date,
  ...props
}: TimeDialogFormProps): JSX.Element {
  const [showLoading, setShowLoading] = useState(false);
  const formikRef = useRef<FormikProps<AddTimeDialogFormType>>(null);
  function handleSave(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    if (formikRef.current) {
      formikRef.current.handleSubmit(event as unknown as FormEvent<HTMLFormElement>);
    } else {
      onClose && onClose(event, 'backdropClick');
    }
  }
  function handleClose(event: MouseEvent<HTMLButtonElement>) {
    onClose && onClose(event, 'backdropClick');
  }
  async function handleDeleteClick(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    try {
      const answer = confirm('Are you sure you want to permanently delete this time entry?');
      if (!answer) {
        return;
      }
      setShowLoading(true);
      onDelete && (await onDelete());
    } catch (error) {
      console.log('This will be handled by the parent');
    } finally {
      setShowLoading(false);
    }
  }

  const sortedSows = removeDuplicates(sows || [])
    .sort((a, b) => a.project.name.localeCompare(b.project.name))
    .map(({ tasks, ...rest }) => ({
      ...rest,
      tasks: (tasks || []).sort(
        (t1, t2) => (t2.billable ? 1 : 0) - (t1.billable ? 1 : 0) || t1.name.localeCompare(t2.name),
      ),
    }));
  const theme = useTheme();
  return (
    <Dialog
      scroll="paper"
      {...props}
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          m: [0, 4],
          maxHeight: ['unset', 'calc(100% - 64px)'],
          height: ['100vh', 'auto'],
        },
      }}
    >
      <DialogTitle sx={{ position: 'relative' }}>
        <Stack
          justifyContent={'space-between'}
          direction="row"
          alignItems="center"
          sx={{ display: ['block', 'flex'] }}
        >
          <Typography variant="h3" fontSize="20px" color="secondary" sx={{ mb: [1, 0] }}>
            {title}
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography
              variant="h3"
              fontWeight={400}
              sx={{
                color: 'primary.grey',
                fontSize: [14, 18],
              }}
            >
              {date.toLocaleDateString('en-GB', {
                weekday: 'long',
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })}
            </Typography>
            {/* <Chip
              label={
                isToday(date)
                  ? 'Today'
                  : formatDistanceToNow(date, { addSuffix: true, includeSeconds: false })
              }
            ></Chip> */}
            <Button
              onClick={handleClose}
              color="secondary"
              sx={{
                padding: 0,
                minWidth: '34px',
                marginLeft: '20px !important',
                position: ['absolute', 'relative'],
                right: [20, 0],
                top: [20, 0],
              }}
            >
              <CloseIcon />
            </Button>
          </Stack>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema}
          innerRef={formikRef}
          enableReinitialize
          onSubmit={async (values) => {
            try {
              setShowLoading(true);
              await onSubmit(values);
            } catch (error) {
              //Must have already been handled at the top level
              console.log(error);
            } finally {
              setShowLoading(false);
            }
          }}
        >
          {({ values }) => (
            <Form>
              <AutocompleteField<SOWType>
                name="sow"
                label="Project / SOW"
                placeholder="Select Project / SOW"
                options={sortedSows}
                toggleButtonProps={{
                  sx: { justifyContent: 'space-between', textTransform: 'none' },
                }}
                autoCompleteProps={{
                  groupBy: (option) => option.project.name,
                }}
              />
              <AutocompleteField<Task>
                name="task"
                label="Task"
                placeholder="Select a task"
                options={values.sow?.tasks || []}
                hint={values.sow.id ? 'Select a task' : 'Select a SOW first'}
                toggleButtonProps={{
                  sx: { justifyContent: 'space-between', textTransform: 'none' },
                }}
                autoCompleteProps={{
                  groupBy: (option) => (option.billable ? 'Billed' : 'Not Billed'),
                }}
              />
              <Grid container spacing={3}>
                <Grid item xs={12} sm={8}>
                  <Typography
                    variant="h5"
                    sx={{ mb: 1, opacity: '0.8', color: 'primary.darkBlue' }}
                  >
                    Notes (Optional)
                  </Typography>
                  <FormTextInput name="notes" label="Enter" rows={2} multiline />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="h5"
                    sx={{ mb: 1, opacity: '0.8', color: 'primary.darkBlue' }}
                  >
                    No. of Hours
                  </Typography>
                  <FormTextInput
                    name="noOfHours"
                    type="number"
                    sx={{ '& input': { height: '46px', fontSize: '24px' } }}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        {allowDelete && (
          <LoadingButton
            loading={showLoading}
            onClick={handleDeleteClick}
            color="warning"
            sx={{
              width: ['100%', 'unset'],
              height: ['44px', 'auto'],
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ...(theme.mixins as any).standardBorder,
            }}
          >
            Delete
          </LoadingButton>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <LoadingButton
          loading={showLoading}
          onClick={handleSave}
          variant="contained"
          color="primary"
          sx={{
            width: ['100%', '145px'],
            height: '44px',
          }}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
