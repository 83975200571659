import { AnyAction } from 'redux';
import { ADD_NOTE, SET_NOTES, SET_SEARCH_OPTIONS, UPDATE_NOTE } from '../notes/constants';
import {
  Note,
  NotesSearchParameters,
  RESET_SEARCH_OPTIONS,
  SET_SEARCH_OPTIONS_TYPE,
} from '../types';

export const allNotes: Partial<NotesSearchParameters> = {
  candidateId: '',
};

export interface NotesState {
  notes: Array<Note>;
  searchOptions: Partial<NotesSearchParameters>;
  searchOptionType: string;
}

const initialState: NotesState = {
  notes: [],
  searchOptions: allNotes,
  searchOptionType: 'allNotes',
};

export const searchOptionsType: Record<string, Partial<NotesSearchParameters>> = {
  allNotes: allNotes,
};

export default function NotesReducer(state = initialState, action: AnyAction): NotesState {
  switch (action.type) {
    case SET_NOTES: {
      return {
        ...state,
        notes: action.notes,
      };
    }
    case ADD_NOTE:
      return {
        ...state,
        notes: [action.notes, ...state.notes],
      };
    case UPDATE_NOTE: {
      return {
        ...state,
        notes: state.notes.map((note) =>
          note.id === action.note.id ? { ...note, ...action.note } : note,
        ),
      };
    }
    case SET_SEARCH_OPTIONS_TYPE:
      return {
        ...state,
        searchOptionType: action.searchOptionType,
        searchOptions: {
          ...(searchOptionsType[action.searchOptionType] || allNotes),
        },
      };
    case SET_SEARCH_OPTIONS: {
      return {
        ...state,
        searchOptions: { ...action.searchOptions },
        searchOptionType: 'custom',
      };
    }
    case RESET_SEARCH_OPTIONS: {
      return {
        ...state,
        searchOptions: allNotes,
      };
    }
    default:
      return state;
  }
}
