import { Box, LinearProgress } from '@mui/material';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { TitleBar } from '../../assets/components';
import { SubmitButton } from '../../forms/Button';
import FormDatePicker from '../../forms/FormDatePicker';
import { AssessmentFormBody } from '../types';
import FormSelect from '../../forms/FormSelect';
import { addAssessment } from './actions';
import FormUsersPicker from '../../forms/FormUsersPicker';
import { useHistory, useParams } from 'react-router';
import { useState } from 'react';
import { useSnackbar } from 'notistack';

const validationSchema = Yup.object().shape({
  date: Yup.string(),
  interviewer: Yup.object()
    .nullable()
    .shape({
      id: Yup.string(),
      name: Yup.string().required('Name is required'),
      email: Yup.string().required('Email is required'),
    }),
  type: Yup.string().required('Type is required'),
});

export default function CreateAssessment(): JSX.Element {
  const dispatch = useDispatch();
  const [showProgress, setShowProgress] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { id: candidateId } = useParams<{ id: string }>();
  const initialValues = {
    date: new Date(),
    interviewer: undefined,
    type: '',
  };

  const onSubmit = async (values: Partial<AssessmentFormBody>) => {
    const { date, interviewer, type } = values;

    const reqBody = {
      candidate: { id: `${candidateId}` },
      date: `${date}`,
      interviewer: { id: `${interviewer?.id}` },
      type: `${type}`,
    };
    try {
      setShowProgress(true);
      await dispatch(addAssessment(reqBody));
      enqueueSnackbar('Assessment created successfully', { variant: 'success' });
      setTimeout(history.push.bind(null, '/admin/ats/candidates'), 1);
    } catch (error) {
      enqueueSnackbar((error as Error).message, { variant: 'error' });
    } finally {
      setShowProgress(false);
    }
  };
  return (
    <>
      <TitleBar title="Assessment" />
      {showProgress && <LinearProgress />}
      <Box sx={{ ml: '10px' }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {() => (
            <Form>
              <FormSelect
                name="type"
                label="Type"
                options={[
                  { label: 'Preliminary Coding Round', value: 'preliminary-coding-round' },
                  { label: 'Coding Round', value: 'coding-round' },
                  { label: 'Internal', value: 'internal' },
                  { label: 'HR Round', value: 'hr-round' },
                ]}
              />
              <FormDatePicker label="Date" name="date" />
              <FormUsersPicker name="interviewer" label="Interviewer" />
              <Box sx={{ m: 1, float: 'right' }}>
                <SubmitButton label="Submit" />
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
}
