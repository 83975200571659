export const SET_SEARCH_OPTIONS_TYPE = 'SET_SEARCH_OPTIONS_TYPE';
export const RESET_SEARCH_OPTIONS = 'RESET_SEARCH_OPTIONS';
export const CANDIDATES_SEARCH_OPTIONS = 'CANDIDATES_SEARCH_OPTIONS';
export const RESET_CANDIDATES_SEARCH_OPTIONS = 'RESET_CANDIDATES_SEARCH_OPTIONS';
export type RelatedType = {
  id: string;
  name?: string;
  email?: string;
};
export type CampaignFormBody = {
  name: string;
  position: string;
  description: string;
  status: 'active' | 'inactive';
  startDate: Date;
  endDate: Date;
};

export type Campaign = CampaignFormBody &
  RelatedType & {
    updatedAt?: Date;
  };

export type CandidateFormBody = {
  status: string;
  name: string;
  resume: string;
  email: string;
  phone: string;
  linkedin: string;
  currentSalary: string;
  expectedSalary: string;
  totalExperience: number;
  relevantExperience: number;
  campaign: RelatedType | null;
  noticePeriod: string;
  noticePeriodNegotiable: string;
  holdingAnyOffer: string;
  lastWorkingDay: string;
};

export type Candidate = CandidateFormBody &
  RelatedType & {
    createdAt?: Date;
    updatedAt?: Date;
  };

export type Note = {
  id: string;
  note: string;
  createdAt: string;
  candidate: RelatedType;
  enteredBy: RelatedType;
};

export interface NoteSearchParameters {
  candidateId: string;
  createdBefore: Date | string;
  createdAfter: Date | string;
  limit: number;
}

export interface CandidatesSearchParameters {
  status: string;
  campaignId: string;
  name: string;
  phone: string;
  email: string;
  limit: number;
  createdBefore: Date;
  createdAfter: Date;
  updatedBefore: Date;
  updatedAfter: Date;
  orderBy: string;
  orderDirection: string;
}

export interface NotesFormBody {
  note?: string;
  date?: Date | string;
  candidate?: {
    id?: string;
  };
}

export interface NotesSearchParameters {
  candidateId: string;
  createdBefore: Date | string;
  createdAfter: Date | string;
  limit: number;
}
export interface AssessmentSearchParameters {
  status: string;
  type: string;
  candidateId: string;
  interviewer: RelatedType;
  limit: number;
  createdBefore: Date | string;
  createdAfter: Date | string;
  updatedBefore: Date | string;
  updatedAfter: Date | string;
  orderBy: string;
  orderDirection: string;
}
export interface AssessmentDataType {
  date: Date | string;
  score: string;
  comment: string;
  status: string;
  type: string;
  candidate: RelatedType;
  createdAt: Date | string;
  id: string;
  interviewer: RelatedType;
  createdBy: RelatedType;
}
export interface AssessmentFormBody {
  candidate: RelatedType;
  date: Date | string;
  score?: number | string | null;
  comment?: string;
  interviewer?: RelatedType;
  status?: string;
  type: string;
}
