import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReuseableLayoutHeader from '../../common/layouts';
import FilteredAutocompleteMultiple from '../../forms/Autocomplete/filtered-autocomplete-multiple';
import FormSelect from '../../forms/FormSelect';
import { useRouteMatch, generatePath, useHistory } from 'react-router-dom';
import { periods, useExtractParams } from '../constants';
import { LocationDescriptor } from 'history';
import { getPhases } from '../../delivery/actions';
import { SOWType } from '../../time-tracking/types';
import { Formik } from 'formik';
import { Button, Stack, useTheme } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function ReportFilters({ datavalues }: { datavalues: Array<any> }): JSX.Element {
  const { sow, period, startDateString, billableHours } = useExtractParams();

  const { path } = useRouteMatch();
  const history = useHistory();
  const theme = useTheme();

  function handleChange(
    newSOW: string | undefined,
    newBillableHours: string | undefined,
    newPeriod: string,
  ) {
    if (newSOW === sow && newBillableHours === billableHours && newPeriod === period) {
      return;
    }
    const searchParams = new URLSearchParams({
      period: newPeriod,
      startDate: startDateString,
    });
    if (newSOW) {
      searchParams.set('sow', newSOW);
    }
    if (newBillableHours) {
      searchParams.set('billableHours', newBillableHours);
    }
    const location: LocationDescriptor<unknown> = {
      pathname: generatePath(path),
      search: searchParams.toString(),
    };
    history.replace(location);
  }

  function handlePeriodChange(newPeriod: string) {
    handleChange(sow, billableHours, newPeriod);
  }

  const periodValues: Array<{ label: string; value: string }> = [...periods] as Array<{
    label: string;
    value: string;
  }>;
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sows, setSows] = useState<Array<SOWType>>([]);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    async function getAllPhases() {
      try {
        const _sows = await dispatch(getPhases());
        setSows(_sows as unknown as Array<SOWType>);
      } catch (error) {
        enqueueSnackbar(`Could not fetch SOWS: ${(error as Error).message}`, { variant: 'error' });
      }
    }
    getAllPhases();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedProjects, setSelectedProjects] = useState<Array<{ name: string; id: string }>>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [billableHours_, setBillableHours] = useState<Array<any>>([]);

  function onProjectValuesChanged(values: Array<{ name: string; id: string }>) {
    const newSow = values.map((value) => value.id);
    handleChange(newSow.join(','), billableHours, period);
    setSelectedProjects(values);
  }

  function onBillableHoursChanged(values: Array<{ name: string; id: string }>) {
    const newBillableHours = values.map((value) => value.id);
    handleChange(sow, newBillableHours.join(','), period);
    setBillableHours(values);
  }
  const projects = datavalues.map((project) => {
    return {
      name: project.name,
      id: project.id,
    };
  });
  const billableHoursOptions = [
    {
      name: 'All',
      id: '0-100',
    },
    {
      name: '> 80%',
      id: '80-100',
    },
    {
      name: '> 60%',
      id: '60-80',
    },
    {
      name: '< 60%',
      id: '40-60',
    },
    {
      name: '< 40%',
      id: '20-40',
    },
    {
      name: '< 20%',
      id: '0-20',
    },
  ];

  const handleClear = () => {
    handleChange(undefined, undefined, period);
    setSelectedProjects([]);
    setBillableHours([]);
  };
  const showButton = selectedProjects.length > 0 || billableHours_?.length > 0;
  return (
    <>
      <ReuseableLayoutHeader>
        <FilteredAutocompleteMultiple
          label="Projects"
          searchPlaceholder="Search Projects"
          options={projects}
          filterId="project-id"
          value={selectedProjects}
          onChange={onProjectValuesChanged}
        />
        <FilteredAutocompleteMultiple
          label="Billable Hours"
          options={billableHoursOptions}
          value={billableHours_}
          filterId="billable-hours"
          onChange={onBillableHoursChanged}
          disableSearch
        />
        <Formik
          initialValues={{ sow, period }}
          onSubmit={() => {
            console.log('Form Submitted');
          }}
        >
          {() => (
            <Stack
              direction="row"
              sx={{
                px: 2,
                borderRight: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
              }}
            >
              <FormSelect
                fullWidth={false}
                value={period}
                options={periodValues}
                name="period"
                sx={{ width: '150px', '& fieldset': { display: 'none' } }}
                onChange={async (event) => {
                  handlePeriodChange(event.target.value as string);
                }}
              />
            </Stack>
          )}
        </Formik>
        {showButton && (
          <Button variant="text" sx={{ ml: 1 }} onClick={handleClear}>
            Clear
          </Button>
        )}
      </ReuseableLayoutHeader>
    </>
  );
}
