import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Stack,
  Typography,
  TableBody,
  Button,
  Link as MUILink,
} from '@mui/material';
import { MouseEvent, MouseEventHandler, useEffect, useState } from 'react';
import { DateTitle, formatAsTime } from '../common';
import LockClockIcon from '@mui/icons-material/LockClockOutlined';
import AddIcon from '@mui/icons-material/Add';
import { Link as RouterLink } from 'react-router-dom';
import { generatePath } from 'react-router';
import { useConsolidatedLogs, useExtractParams } from '../constants';
import { TrackEntryProps, Timelog, TaskWithSOW } from '../types';
import TaskPickerDialog, { TaskSelectionProps } from './TaskPickerDialog';
import EditableTimeEntry, { TimeLogEntry } from './EditableTimeEntry';
import { addTimelog, deleteTimelog, updateTimelog } from '../actions';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

const timeOnlyFormat = new Intl.DateTimeFormat(undefined, {
  timeStyle: 'short',
});

export default function TrackWeek({
  timesheet,
  timelogs = [],
  setTimelogs,
  sows,
  submitTimesheet,
}: TrackEntryProps): JSX.Element {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { weekStart, path, employeeId } = useExtractParams();
  const [showTaskPickerDialog, setShowTaskPickerDialog] = useState(false);
  const { timelogsCompressed, days, uniqueTaskEntries } = useConsolidatedLogs(timelogs, weekStart);
  const [showLoading, setShowLoading] = useState(false);
  const [timerId, setTimerId] = useState<number>();
  const [pendingLogEntries, setPendingLogEntries] = useState<
    Array<{ logEntry: TimeLogEntry; newHours: number | undefined }>
  >([]);
  const [lastSavedAt, setLastSavedAt] = useState<Date>();
  const [tasks, setTasks] = useState(uniqueTaskEntries);
  useEffect(() => {
    setTasks(uniqueTaskEntries);
  }, [timelogs]);

  useEffect(() => {
    clearTimeout(timerId);
    if (pendingLogEntries.length == 0) {
      return;
    }
    const timeoutId = setTimeout(() => {
      processPendingLogEntries();
    }, 5000);
    setTimerId(timeoutId as unknown as number);
    return () => {
      clearTimeout(timerId);
    };
  }, [pendingLogEntries]);

  const [dayUnderFocus, setDayUnderFocus] = useState<Date>();

  async function deleteTaskEntry(task: TaskWithSOW) {
    try {
      const result = confirm(
        `Are you sure you want to permanently delete all entries for \n "${task.sow.name} - ${task.name}"?`,
      );
      if (!result) {
        return;
      }
      setShowLoading(true);
      const logsToDelete = timelogs.filter(
        (log) => log.task.id === task.id && log.sow.id === task.sow.id,
      );
      for (const log of logsToDelete) {
        await dispatch(deleteTimelog(log.id));
      }
      setTimelogs(timelogs.filter((log) => !logsToDelete.includes(log)));
      setPendingLogEntries([]);
      setLastSavedAt(new Date());
    } catch (error) {
      enqueueSnackbar('Could not delete entries. Please reload the page', { variant: 'error' });
    } finally {
      setShowLoading(false);
    }
  }

  const modifiedSOWs = (sows || []).map((sow) => ({
    ...sow,
    tasks: (sow.tasks || []).filter((task) =>
      tasks.every((t) => !(t.id === task.id && t.sow.id === sow.id)),
    ),
  }));
  const handleAddEntryButtonClick: MouseEventHandler = (event: MouseEvent) => {
    event.preventDefault();
    setShowTaskPickerDialog(true);
  };
  const isApproved = timesheet && timesheet.status === 'APPROVED';

  const queueTimelogsForLogEntry = (logEntry: TimeLogEntry, newHours: number | undefined) => {
    const entries = [...pendingLogEntries, { logEntry, newHours }];
    setPendingLogEntries(entries);
  };

  async function processPendingLogEntries() {
    if (pendingLogEntries.length === 0) {
      return;
    }
    setShowLoading(true);
    try {
      let processedTimelogs = timelogs;
      for (const pendingLogEntry of pendingLogEntries) {
        const { logEntry, newHours } = pendingLogEntry;
        processedTimelogs = await updateTimelogsForLogEntry(logEntry, newHours, [
          ...processedTimelogs,
        ]);
      }
      setTimelogs(processedTimelogs);
      setLastSavedAt(new Date());
      setPendingLogEntries([]);
    } catch (error) {
      console.error(error);
    } finally {
      setShowLoading(false);
    }
  }
  async function updateTimelogsForLogEntry(
    logEntry: TimeLogEntry,
    newHours: number | undefined,
    logsToUpdate: Array<Timelog>,
  ): Promise<Array<Timelog>> {
    //TODO: Find if there are logs for the day for that sow and task
    const newTimelogs = timelogs.filter(
      (log) =>
        log.sow.id === logEntry.task.sow.id &&
        log.task.id === logEntry.task.id &&
        log.date.getTime() === logEntry.date.getTime(),
    );

    //If none, create logs.
    if (newTimelogs.length === 0) {
      if (newHours == 0 || newHours === undefined) {
        return logsToUpdate;
      }
      const timelog = await dispatch(
        addTimelog({
          notes: '',
          noOfHours: newHours,
          date: logEntry.date,
          employee: { id: employeeId, name: 'Employee' },
          sow: logEntry.task.sow,
          task: { id: logEntry.task.id, name: logEntry.task.name },
        }),
      );
      return [...logsToUpdate, timelog];
    }
    //If there is only one, update it.
    else if (newTimelogs.length == 1) {
      if (newHours == 0 || newHours === undefined) {
        await dispatch(deleteTimelog(newTimelogs[0].id));
        return logsToUpdate.filter((log) => log.id !== newTimelogs[0].id);
      } else {
        const firstLog = newTimelogs[0];
        await dispatch(
          updateTimelog(firstLog.id, {
            ...firstLog,
            noOfHours: newHours,
          }),
        );
        return logsToUpdate.map((log) =>
          log.id === firstLog.id ? { ...log, noOfHours: newHours } : log,
        );
      }
    }
    //If more than one, add a new timelog with positive or negative hours.
    const totalHours = newTimelogs.reduce((acc, log) => acc + log.noOfHours, 0);
    const negativeHours =
      newHours == 0 || newHours === undefined ? -totalHours : newHours - totalHours;
    const createdTimelog = await dispatch(
      addTimelog({
        notes: '',
        noOfHours: negativeHours,
        date: logEntry.date,
        employee: { id: employeeId, name: 'Employee' },
        sow: logEntry.task.sow,
        task: { id: logEntry.task.id, name: logEntry.task.name },
      }),
    );
    return [...logsToUpdate, createdTimelog];
  }

  return (
    <>
      {timesheet && sows && (
        <TaskPickerDialog
          onClose={() => setShowTaskPickerDialog(false)}
          sows={modifiedSOWs}
          open={showTaskPickerDialog}
          onSubmit={function ({ task, sow }: TaskSelectionProps) {
            console.log('Task Selected', task);
            setTasks([...tasks, { ...task, sow }]);
            setShowTaskPickerDialog(false);
          }}
        />
      )}
      {timesheet && timesheet.status !== 'APPROVED' && (
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          sx={{ display: ['block', 'flex'] }}
        >
          {!isApproved && (
            <Stack
              direction={'row'}
              spacing={2}
              sx={{
                my: [1, 3],
                alignItems: 'center',
                mb: [2, 1],
              }}
            >
              <Button
                variant="outlined"
                onClick={handleAddEntryButtonClick}
                startIcon={<AddIcon />}
              >
                Add Row
              </Button>
              <LoadingButton
                variant="contained"
                loading={showLoading}
                disabled={pendingLogEntries.length === 0}
                onClick={() => processPendingLogEntries()}
              >
                Save
              </LoadingButton>
              {showLoading && <Typography>Saving...</Typography>}
              {!showLoading && lastSavedAt && (
                <Typography>Last saved at {timeOnlyFormat.format(lastSavedAt)}</Typography>
              )}
            </Stack>
          )}
          <Button
            disabled={showLoading}
            variant="contained"
            color="primary"
            onClick={submitTimesheet}
            sx={{ m: '0 !important', width: ['100%', 'auto'] }}
          >
            {timesheet.status === 'SUBMITTED'
              ? 'Resubmit Timesheet for Week'
              : `Submit Timesheet for Week`}
          </Button>
        </Stack>
      )}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h3">Project/SOW</Typography>
              </TableCell>
              {days?.map((day) => {
                const isDayUnderFocus = dayUnderFocus?.getTime() === day.getTime();
                const dayURL = generatePath(path, {
                  mode: 'day',
                  year: day.getFullYear().toString(),
                  month: (day.getMonth() + 1).toString(),
                  day: day.getDate().toString(),
                  employeeId,
                });
                return (
                  <TableCell
                    key={day.toISOString()}
                    sx={{
                      fontWeight: isDayUnderFocus ? 'bold' : 'normal',
                      width: [100, '90px'],
                      textAlign: 'right',
                      borderBottomWidth: 2,
                      borderBottomColor: isDayUnderFocus
                        ? 'primary.main'
                        : 'rgba(224, 224, 224, 1)',
                      minWidth: [100, 'unset'],
                    }}
                  >
                    <MUILink
                      component={RouterLink}
                      to={dayURL}
                      underline="hover"
                      sx={{
                        color: 'unset',
                        '&:hover,&:focus': {
                          color: 'primary.main',
                          '.MuiTypography-root': {
                            color: 'primary.main',
                            fontWeight: 600,
                            opacity: 1,
                          },
                        },
                        '& .MuiTypography-root': {
                          opacity: 0.7,
                          '&.active': {
                            opacity: 1,
                            '& .MuiTypography-root': {
                              opacity: 1,
                            },
                          },
                        },
                      }}
                    >
                      <DateTitle date={day} isDayUnderFocus={isDayUnderFocus}></DateTitle>
                    </MUILink>
                  </TableCell>
                );
              })}
              <TableCell sx={{ width: 90, textAlign: 'right' }}></TableCell>
              <TableCell sx={{ width: 90, textAlign: 'right' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {timesheet &&
              tasks.map((task) => (
                <TableRow
                  key={task.id + task.sow.id}
                  sx={isApproved ? { bgcolor: 'primary.lightBlueBg' } : {}}
                >
                  <TableCell>
                    <Stack display="block">
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        mb={1.2}
                        sx={{
                          display: ['block', 'flex'],
                        }}
                      >
                        <Typography variant="h4">{task.sow.name}</Typography>
                        <Typography
                          variant="body1"
                          component="span"
                          sx={{
                            m: ['0 !important', '8px !important'],
                          }}
                        >
                          ({task.sow.project.name})
                        </Typography>
                      </Stack>
                      <Typography
                        variant="body1"
                        sx={{
                          padding: '4px 10px',
                          borderRadius: ['5px', '40px'],
                          bgcolor: 'primary.veryLightGrey',
                          display: 'inline-flex',
                        }}
                      >
                        {task.name}
                      </Typography>
                    </Stack>
                  </TableCell>
                  {days?.map((day) => {
                    const log = timelogsCompressed.find(
                      (l) =>
                        l.sow.id === task.sow.id &&
                        l.task.id === task.id &&
                        l.date.getTime() === day.getTime(),
                    );
                    // const isDayUnderFocus = dayUnderFocus?.getTime() === day.getTime();
                    return (
                      <TableCell
                        key={day.toISOString()}
                        sx={{
                          width: [100, '90px'],
                          textAlign: 'right',
                          px: 1,
                          // bgcolor: isDayUnderFocus ? '#e1f5fe' : '',
                        }}
                      >
                        {isApproved ? (
                          formatAsTime(log?.noOfHours ?? 0)
                        ) : (
                          <EditableTimeEntry
                            log={{ date: day, noOfHours: log?.noOfHours, task }}
                            onEditingStart={(l) => {
                              setDayUnderFocus(l.date);
                            }}
                            onEditingEnd={(l, noOfHours) => {
                              setDayUnderFocus(undefined);
                              if (l.noOfHours !== noOfHours) {
                                console.log(
                                  'Should update timelogs with the server',
                                  l.noOfHours,
                                  noOfHours,
                                );
                                queueTimelogsForLogEntry(l, noOfHours);
                              }
                            }}
                          />
                        )}
                      </TableCell>
                    );
                  })}
                  <TableCell
                    sx={{
                      width: [100, '90px'],
                      textAlign: 'right',
                      px: 1,
                    }}
                  >
                    <Typography variant="h4">
                      {formatAsTime(
                        timelogsCompressed.reduce(
                          (acc, log) =>
                            acc +
                            (log.task.id === task.id && log.sow.id === task.sow.id
                              ? log.noOfHours
                              : 0),
                          0,
                        ),
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ textAlign: 'right' }}>
                    {isApproved && (
                      <IconButton disabled>
                        <LockClockIcon fontSize="small" />
                      </IconButton>
                    )}
                    {!isApproved && (
                      <Button
                        variant="outlined"
                        onClick={() => deleteTaskEntry(task)}
                        color="secondary"
                        sx={{ minWidth: 34, p: 1 }}
                      >
                        <img src="/images/delete.svg" alt="delete icon" />
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            <TableRow sx={{ backgroundColor: ' rgba(72, 120, 245, 0.05)' }}>
              <TableCell />
              {days?.map((day) => {
                const noOfHours = (timelogsCompressed || [])
                  .filter((l) => l.date.getTime() === day.getTime())
                  .reduce((acc, log) => acc + log.noOfHours, 0);
                return (
                  <TableCell
                    key={day.toISOString()}
                    sx={{
                      width: [100, '90px'],
                      textAlign: 'right',
                      px: 1,
                      // bgcolor: dayUnderFocus?.getTime() === day.getTime() ? '#e1f5fe' : '',
                    }}
                  >
                    <Typography variant="h4" color="rgba(58,47,92,0.7)">
                      {formatAsTime(noOfHours)}
                    </Typography>
                  </TableCell>
                );
              })}
              <TableCell
                sx={{
                  width: [100, '90px'],
                  textAlign: 'right',
                  px: 1,
                }}
              >
                <Typography variant="h4">
                  {formatAsTime(timelogsCompressed.reduce((acc, log) => acc + log.noOfHours, 0))}
                </Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
