import {
  Box,
  Chip,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { formatShortDate } from '../../../utils/date';
import { TitleBar } from '../../assets/components';
import { Phase, Project } from '../types';
import { getProjectById } from '../actions';
import { ChipTag } from './List';
import { StyledTableRow } from '../../common';
import { SxProps } from '@mui/system';

export default function ProjectDetails(): JSX.Element {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [details, setDetails] = useState<Project>();
  const [showProgress, setShowProgress] = useState(false);
  const location = useLocation();
  const { state } = location;
  const { phaseDetails } = state as { phaseDetails: Array<Phase> };

  useEffect(() => {
    async function getProjectdetails() {
      try {
        setShowProgress(true);
        const projectDetails = await dispatch(getProjectById(id));
        setDetails(projectDetails as unknown as Project);
      } catch (error) {
        console.log(error);
      } finally {
        setShowProgress(false);
      }
    }
    getProjectdetails();
  }, [id]);

  return (
    <>
      <TitleBar title="Client Details" />
      {showProgress && <LinearProgress />}
      {details && (
        <>
          <Box sx={{ px: 4 }}>
            <Box sx={{ display: 'flex' }} alignItems="center">
              <Box sx={{ py: 2, pr: 3 }}>
                <img
                  src="/images/company_logo.svg"
                  alt={details.name}
                  style={{
                    width: '60px',
                    height: '56px',
                  }}
                />
              </Box>
              <Stack flexDirection="column">
                <Stack sx={{ pb: 2 }}>
                  <Typography variant="h2">{details.clientName}</Typography>
                </Stack>
                <Stack>
                  <Typography variant="h4" color="primary.blueBg" sx={{ fontWeight: 400 }}>
                    Webileapps.com
                  </Typography>
                </Stack>
              </Stack>
            </Box>
            <Box sx={{ borderBottom: '1px solid #D1D1D1', opacity: 0.49 }} />
            <Box sx={{ mt: 4 }}>
              <Stack sx={{ mb: 2 }}>
                <Typography variant="h4">Client Information</Typography>
              </Stack>
              <Stack flexDirection="row" justifyContent="space-between">
                <ReuseableLayout headerText="Client" subHeaderText={details.clientName} />
                <ReuseableLayout
                  headerText="Created At"
                  subHeaderText={formatShortDate(details.createdAt)}
                />
                <ReuseableLayout
                  headerText="Updated At"
                  subHeaderText={formatShortDate(details.updatedAt)}
                />
              </Stack>
            </Box>
            <Box sx={{ mt: 4 }}>
              <ReuseableLayout
                headerText="Description"
                subHeaderText={details.description ? details.description : 'No description'}
              />
            </Box>
            <Box>
              <Box sx={{ mt: 4, mb: 3 }}>
                <Typography variant="h4">Projects</Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                <TableContainer>
                  <Table size="small" aria-label="customized table">
                    <TableHead>
                      <StyledTableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Start Date</TableCell>
                        <TableCell align="right">End Date</TableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {phaseDetails.length > 0 ? (
                        phaseDetails.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell component="th" scope="row">
                              <Chip label={row.name} icon={<ChipTag />} />
                            </TableCell>
                            <TableCell align="right">
                              {row.startDate ? formatShortDate(row.startDate) : 'N/A'}
                            </TableCell>
                            <TableCell align="right">
                              {row.endDate ? formatShortDate(row.endDate) : 'N/A'}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <Typography variant="h3" sx={{ p: 2 }}>
                          No projects to show
                        </Typography>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
export function ReuseableLayout({
  headerText,
  subHeaderText,
  sx = {},
}: {
  headerText: string;
  subHeaderText: string | null;
  sx?: SxProps;
}): JSX.Element {
  return (
    <Stack direction="column" sx={{ ...sx }}>
      <Typography variant="body1" color="primary.lightBlack" sx={{ mb: 1 }}>
        {headerText}
      </Typography>
      <Typography variant="h4" color="primary.greyBlack" sx={{ fontWeight: 400 }}>
        {subHeaderText}
      </Typography>
    </Stack>
  );
}
