import { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Box, LinearProgress } from '@mui/material';

import { GroupFormBody } from '../types';
import { createNewGroup } from './actions';
import GroupForm from './forms';
import { TitleBar } from '../../assets/components';

const initialValues: GroupFormBody = {
  name: '',
};

export default function CreateGroup(): JSX.Element {
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <TitleBar title="Create Group" />
      {showProgress && <LinearProgress />}
      <Box sx={{ p: 2 }}>
        <GroupForm
          initialValues={initialValues}
          onSubmit={async (values: GroupFormBody): Promise<void> => {
            try {
              setShowProgress(true);
              await dispatch(createNewGroup(values));
              enqueueSnackbar('Group created successfully', { variant: 'success' });
              setTimeout(history.push.bind(null, '/admin/permissions/groups'), 1);
            } catch (error) {
              enqueueSnackbar((error as Error).message, { variant: 'error' });
            } finally {
              setShowProgress(false);
            }
          }}
        />
      </Box>
    </>
  );
}
