import { Box } from '@mui/system';
import { Formik, Form } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { SubmitButton } from '../../forms/Button';
import FormDatePicker from '../../forms/FormDatePicker';
import FormSelect from '../../forms/FormSelect';
import FormTextInput from '../../forms/FormTextInput';
import { ResourceRoles } from '../constants';
import { getPhases } from '../actions';
import { RelatedPhase, ResourceBody } from '../types';
import FormUsersPicker from '../../forms/FormUsersPicker';
import { getFinanceDetailsByEmployee } from '../actions';
import { EmployeeCosts } from '../types';
import { Grid, Typography } from '@mui/material';

const validationSchema = Yup.object().shape({
  employee: Yup.object()
    .nullable()
    .shape({
      id: Yup.string().required('Employee is required'),
      name: Yup.string(),
    })
    .required('Employee is required'),
  assignedTo: Yup.object()
    .nullable()
    .shape({
      id: Yup.string().required('Assigned To is required'),
      name: Yup.string(),
    })
    .required('Assigned To is required'),
  role: Yup.string().required('Role is required'),
  capacity: Yup.number().min(0).max(80).required('Capacity is required'),
  billingRate: Yup.number().min(0).required('Billing Rate is required'),
  startDate: Yup.date().nullable(),
  endDate: Yup.date().nullable(),
});

export type FormProps = {
  onSubmit: (values: ResourceBody) => void;
  initialValues: ResourceBody;
};
export default function ResourceForm({ initialValues, onSubmit }: FormProps): JSX.Element {
  const dispatch = useDispatch();

  const [sows, setSOWs] = useState<Array<RelatedPhase>>([]);
  useEffect(() => {
    const fetchSOWs = async function () {
      const _phases = await dispatch(getPhases());
      setSOWs(_phases as unknown as Array<RelatedPhase>);
    };
    fetchSOWs();
  }, []);

  const formattedSows = sows.map((phase) => ({
    value: phase.id,
    label: `${phase.name} (${phase.project.name})`,
  }));

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ setFieldValue }) => (
        <Form>
          <Box sx={{ px: 8, '& .MuiGrid-container': { alignItems: 'center', mb: 2 } }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <Typography variant="h5">Employee</Typography>
              </Grid>
              <Grid item xs={12} sm={7}>
                <FormUsersPicker
                  name="employee"
                  onChange={async (event, value) => {
                    setFieldValue('employee', value);
                    const { id } = JSON.parse(JSON.stringify(value));
                    try {
                      const financeDetails = (await dispatch(
                        getFinanceDetailsByEmployee(id),
                      )) as unknown as EmployeeCosts;
                      const { capacity, expectedBillingPerHour } = financeDetails;
                      setFieldValue('capacity', capacity);
                      setFieldValue('billingRate', expectedBillingPerHour);
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <Typography>Assigned To</Typography>
              </Grid>

              <Grid item xs={12} sm={7}>
                <FormSelect name="assignedTo.id" options={formattedSows} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <Typography>Role</Typography>
              </Grid>

              <Grid item xs={12} sm={7}>
                <FormSelect name="role" options={ResourceRoles} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <Typography>Allocated Hours / Week 40 hours </Typography>
              </Grid>

              <Grid item xs={12} sm={7}>
                <FormTextInput name="capacity" type="text" />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <Typography>Billing Rate</Typography>
              </Grid>

              <Grid item xs={12} sm={7}>
                <FormTextInput name="billingRate" type="text" />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <Typography>Start Date</Typography>
              </Grid>

              <Grid item xs={12} sm={7}>
                <FormDatePicker name="startDate" />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>
                <Typography>End Date</Typography>
              </Grid>

              <Grid item xs={12} sm={7}>
                <FormDatePicker name="endDate" />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}></Grid>
              <Grid item xs={12} sm={7}>
                <SubmitButton label="Submit" sx={{ minWidth: 165 }} />
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
}
