import { DateRange } from '@mui/lab';
import { AnyAction } from 'redux';
import {
  CREATE_RESOURCE,
  UPDATE_RESOURCE,
  RESET_RESOURCE_SEARCH_OPTIONS,
  RESOURCE_SEARCH_OPTIONS,
  RESOURCE_SEARCH_OPTION_TYPE,
  SET_RESOURCES,
} from '../constants';
import { Resource, ResourceSearchParameters } from '../types';

function getWeekDates(): DateRange<Date> {
  const now = new Date();
  const dayOfWeek = now.getDay();
  const numDay = now.getDate();

  const start = new Date(now);
  start.setDate(numDay - dayOfWeek);
  start.setHours(0, 0, 0, 0);

  const end = new Date(now);
  end.setDate(numDay + (7 - dayOfWeek));
  end.setHours(0, 0, 0, 0);

  return [start, end];
}

function getMonthDates(): DateRange<Date> {
  const start = new Date();
  start.setDate(1);
  start.setHours(0, 0, 0, 0);

  const end = new Date();
  end.setHours(0, 0, 0, 0);
  end.setDate(1);
  end.setMonth(end.getMonth() + 1);

  return [start, end];
}

export const allResources: ResourceSearchParameters = {
  role: '',
  employee: undefined,
  assignedTo: '',
  dateRange: [null, null],
  orderBy: '',
  orderDirection: 'desc',
  limit: 25,
  offset: '',
};
export interface ResourceState {
  resources: Array<Resource>;
  searchOptions: ResourceSearchParameters;
  searchOptionType: string;
}

export const thisweek: ResourceSearchParameters = {
  ...allResources,
  dateRange: getWeekDates(),
};

export const thismonth: ResourceSearchParameters = {
  ...allResources,
  dateRange: getMonthDates(),
};

export const searchOptionsList: Record<string, ResourceSearchParameters> = {
  allResources: allResources,
  thisweek: thisweek,
  thismonth: thismonth,
};

const initialState: ResourceState = {
  resources: [],
  searchOptions: allResources,
  searchOptionType: 'thismonth',
};
export default function resourcesReducer(state = initialState, action: AnyAction): ResourceState {
  switch (action.type) {
    case SET_RESOURCES: {
      return {
        ...state,
        resources: action.resources,
      };
    }
    case CREATE_RESOURCE: {
      return {
        ...state,
        resources: [...action.resource, ...state.resources],
      };
    }

    case UPDATE_RESOURCE: {
      return {
        ...state,
        resources: state.resources.map((resource: Resource) =>
          resource.id === action.resource.id ? { ...resource, ...action.resource } : resource,
        ),
      };
    }
    // case SET_ROLES: {
    //   return {
    //     ...state,
    //     roles: action.roles,
    //   };
    // }
    case RESET_RESOURCE_SEARCH_OPTIONS: {
      return {
        ...state,
        searchOptions: initialState.searchOptions,
        searchOptionType: initialState.searchOptionType,
      };
    }
    case RESOURCE_SEARCH_OPTIONS: {
      return {
        ...state,
        searchOptions: { ...action.searchOptions },
        searchOptionType: 'custom',
      };
    }
    case RESOURCE_SEARCH_OPTION_TYPE: {
      return {
        ...state,
        searchOptionType: action.searchOptionType,
        searchOptions: {
          ...(searchOptionsList[action.searchOptionType] || allResources),
        },
      };
    }
    default:
      return state;
  }
}
