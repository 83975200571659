/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CALL_API } from '../../redux-store/middleware';
import {
  EmployeeCosts,
  PhaseBody,
  PhasesSearchParameters,
  Project,
  ProjectBody,
  ProjectSearchParameters,
  Resource,
  ResourceBody,
  ResourceSearchParameters,
  SOWTask,
  Task,
  UpdateBody,
} from './types';
import { CREATE_PROJECT, SET_PROJECTS, UPDATE_PROJECT } from './projects/constants';
import {
  CREATE_PHASE,
  CREATE_RESOURCE,
  DELETE_RESOURCE,
  LIST_RESOURCE_COSTS,
  SET_PHASES,
  SET_RESOURCES,
  SET_ROLES,
  UPDATE_PHASE,
  UPDATE_RESOURCE,
  UPDATE_RESOURCE_COST,
} from './constants';
import { ResourceCostSearchOptions } from './store/ResourceCostReducer';

const BASE_URL = '/projects/projects';
const defaultSearchParameter: ProjectSearchParameters = {
  name: '',
  description: '',
  orderBy: '',
  orderDirection: 'desc',
  limit: 100,
  offset: '',
};
export const getProjects =
  (searchOptions = defaultSearchParameter) =>
  async (dispatch: any) => {
    try {
      const searchParams = new URLSearchParams();
      if (searchOptions.name) {
        searchParams.append('name', searchOptions.name);
      }
      if (searchOptions.description) {
        searchParams.append('clientName', searchOptions.description);
      }
      if (searchOptions.orderBy) {
        searchParams.append('orderBy', searchOptions.orderBy);
      }
      if (searchOptions.orderDirection) {
        searchParams.append('orderDirection', searchOptions.orderDirection);
      }
      if (searchOptions.limit) {
        searchParams.append('limit', searchOptions.limit.toString());
      }
      if (searchOptions.offset) {
        searchParams.append('offset', searchOptions.offset);
      }
      const getProjects = await dispatch({
        [CALL_API]: {
          url: `${BASE_URL}?${searchParams}`,
          method: 'GET',
        },
      });
      dispatch({
        type: SET_PROJECTS,
        projects: getProjects,
      });
      return getProjects as Array<Project>;
    } catch (error) {
      console.log(error);
    }
  };
export const onGetMyProjects = () => async (dispatch: any) => {
  try {
    const getMyProjects = await dispatch({
      [CALL_API]: {
        url: `${BASE_URL}/myprojects`,
        method: 'GET',
      },
    });
    return getMyProjects;
  } catch (error) {
    console.log(error);
  }
};

export const onCreateProject = (body: ProjectBody) => async (dispatch: any) => {
  try {
    const createProject = await dispatch({
      [CALL_API]: {
        url: BASE_URL,
        method: 'POST',
        body,
      },
    });
    dispatch({
      type: CREATE_PROJECT,
      project: createProject,
    });
    return createProject;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateProject = (body: ProjectBody, id: string) => async (dispatch: any) => {
  try {
    const updateProject = await dispatch({
      [CALL_API]: {
        url: `${BASE_URL}/${id}`,
        method: 'PUT',
        body,
      },
    });
    dispatch({
      type: UPDATE_PROJECT,
      project: updateProject,
    });

    return updateProject;
  } catch (error) {
    console.log(error);
  }
};

export const getProjectById = (projectId: string) => async (dispatch: any) => {
  try {
    const project = await dispatch({
      [CALL_API]: {
        url: `${BASE_URL}/${projectId}`,
        method: 'GET',
      },
    });
    return project;
  } catch (error) {
    console.log(error);
  }
};

export const createPhase = (body: PhaseBody) => async (dispatch: any) => {
  try {
    const createdPhase = await dispatch({
      [CALL_API]: {
        url: `${BASE_URL}/phases`,
        method: 'POST',
        body,
      },
    });
    dispatch({
      type: CREATE_PHASE,
      phases: createdPhase,
    });
    return createdPhase;
  } catch (error) {
    console.log(error);
  }
};

const defaultSearchParameters: PhasesSearchParameters = {
  name: '',
  projectId: '',
  type: '',
  phaseStart: [null, null],
  phaseEnd: [null, null],
  orderBy: '',
  orderDirection: 'desc',
  limit: 100,
  offset: '',
};

export const getPhases =
  (searchOptions = defaultSearchParameters) =>
  async (dispatch: any) => {
    try {
      const searchParams = new URLSearchParams();

      if (searchOptions.name) {
        searchParams.append('name', searchOptions.name);
      }
      if (searchOptions.projectId) {
        searchParams.append('projectId', searchOptions.projectId);
      }
      if (searchOptions.type) {
        searchParams.append('type', searchOptions.type);
      }

      if (searchOptions.phaseStart) {
        const [phaseStartAfter, phaseStartBefore] = searchOptions.phaseStart;
        if (phaseStartAfter) {
          searchParams.append('phaseStartAfter', phaseStartAfter.toISOString());
        }
        if (phaseStartBefore) {
          searchParams.append('phaseStartBefore', phaseStartBefore.toISOString());
        }
      }
      if (searchOptions.phaseEnd) {
        const [phaseEndBefore, phaseEndAfter] = searchOptions.phaseEnd;
        if (phaseEndAfter) {
          searchParams.append('phaseEndAfter', phaseEndAfter.toISOString());
        }
        if (phaseEndBefore) {
          searchParams.append('phaseEndBefore', phaseEndBefore.toISOString());
        }
      }

      if (searchOptions.limit) {
        searchParams.append('limit', searchOptions.limit.toString());
      }
      if (searchOptions.orderBy) {
        searchParams.append('orderBy', searchOptions.orderBy);
      }

      if (searchOptions.orderDirection) {
        searchParams.append('orderDirection', searchOptions.orderDirection);
      }
      const phases = await dispatch({
        [CALL_API]: {
          url: `${BASE_URL}/phases?${searchParams.toString()}`,
          method: 'GET',
        },
      });
      dispatch({
        type: SET_PHASES,
        phases,
      });

      return phases as PhaseBody[];
    } catch (error) {
      console.error(error);
      return [];
    }
  };

export const updatePhase = (body: PhaseBody, phaseId: string) => async (dispatch: any) => {
  const { project, ...rest } = body;
  try {
    const updatedPhase = await dispatch({
      [CALL_API]: {
        url: `${BASE_URL}/phases/${phaseId}`,
        method: 'PUT',
        body: { ...rest, project: { id: project.id } },
      },
    });
    dispatch({
      type: UPDATE_PHASE,
      phases: updatedPhase,
    });
    return updatedPhase;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getPhaseById = (id: string) => async (dispatch: any) => {
  try {
    const phase = await dispatch({
      [CALL_API]: {
        url: `${BASE_URL}/phases/${id}`,
        method: 'GET',
      },
    });
    return phase;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const defaultSearchParameters_: ResourceSearchParameters = {
  role: '',
  employee: undefined,
  assignedTo: '',
  billingRate: '',
  startDate: null,
  endDate: null,
  dateRange: [null, null],
  orderBy: '',
  orderDirection: 'desc',
  limit: 100,
  offset: '',
};

export const getResources =
  (searchOptions = defaultSearchParameters_) =>
  async (dispatch: any) => {
    try {
      const searchParams = new URLSearchParams();

      if (searchOptions.role) {
        searchParams.append('role', searchOptions.role);
      }
      if (searchOptions.employee && searchOptions.employee.id) {
        searchParams.append('employee', searchOptions.employee.id);
      }
      if (searchOptions.assignedTo) {
        searchParams.append('assignedTo', searchOptions.assignedTo);
      }
      if (searchOptions.startDate) {
        searchParams.append('startDate', searchOptions.startDate.toISOString());
      }
      if (searchOptions.endDate) {
        searchParams.append('endDate', searchOptions.endDate.toISOString());
      }
      if (searchOptions.billingRate) {
        searchParams.append('billingRate', searchOptions.billingRate.toString());
      }

      if (searchOptions.orderBy) {
        searchParams.append('orderBy', searchOptions.orderBy);
      }

      if (searchOptions.orderDirection) {
        searchParams.append('orderDirection', searchOptions.orderDirection);
      }

      if (searchOptions.limit) {
        searchParams.append('limit', searchOptions.limit.toString());
      }

      if (searchOptions.offset) {
        searchParams.append('offset', searchOptions.offset.toString());
      }

      const resources = await dispatch({
        [CALL_API]: {
          url: `${BASE_URL}/resources?${searchParams.toString()}`,
          method: 'GET',
        },
      });
      dispatch({
        type: SET_RESOURCES,
        resources,
      });
      return resources as Resource[];
    } catch (error) {
      console.error(error);
      return [];
    }
  };

export const createResource = (body: ResourceBody) => async (dispatch: any) => {
  const { employee, assignedTo, ...rest } = body;
  try {
    const createdResources = await dispatch({
      [CALL_API]: {
        url: `${BASE_URL}/resources`,
        method: 'POST',
        body: { ...rest, employee: { id: employee?.id }, assignedTo: { id: assignedTo?.id } },
      },
    });
    dispatch({
      type: CREATE_RESOURCE,
      resource: createdResources,
    });
    return createdResources as Resource[];
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getRolesbyResource = () => async (dispatch: any) => {
  try {
    const roles = await dispatch({
      [CALL_API]: {
        url: `${BASE_URL}/resources/roles`,
        method: 'GET',
      },
    });
    dispatch({
      type: SET_ROLES,
      roles,
    });
    return roles as Resource[];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const deleteResource = (id: string) => async (dispatch: any) => {
  try {
    const deletedResource = await dispatch({
      [CALL_API]: {
        url: `${BASE_URL}/resources/${id}`,
        method: 'DELETE',
      },
    });
    dispatch({
      type: DELETE_RESOURCE,
      resource: deletedResource,
    });
    return deletedResource;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const onGetResourceById = (resource_id: string) => async (dispatch: any) => {
  try {
    const getResourceById = await dispatch({
      [CALL_API]: {
        url: `${BASE_URL}/resources/${resource_id}`,
        method: 'GET',
      },
    });
    return getResourceById as ResourceBody;
  } catch (error) {
    alert(error);
  }
};

export const onUpdateResource =
  (resource_id: string, body: ResourceBody) => async (dispatch: any) => {
    try {
      const updateResource = await dispatch({
        [CALL_API]: {
          url: `${BASE_URL}/resources/${resource_id}`,
          method: 'POST',
          body: body,
        },
      });
      dispatch({
        type: UPDATE_RESOURCE,
        resource: updateResource,
      });

      return updateResource;
    } catch (error) {
      throw new Error('Error updating resource ' + error);
    }
  };

const defaultSearchParameters__: ResourceCostSearchOptions = {
  employee: '',
  designation: '',
  CTC: '',
  costPerHour: '',
  billableHours: '',
};
export const getAllResourceCosts =
  (searchOptions = defaultSearchParameters__) =>
  async (dispatch: any) => {
    try {
      const searchParams = new URLSearchParams();
      if (searchOptions.employee) {
        searchParams.append('employee', searchOptions.employee);
      }
      if (searchOptions.designation) {
        searchParams.append('designation', searchOptions.designation);
      }
      if (searchOptions.CTC) {
        searchParams.append('ctc', searchOptions.CTC);
      }
      if (searchOptions.costPerHour) {
        searchParams.append('costPerHour', searchOptions.costPerHour);
      }
      if (searchOptions.billableHours) {
        searchParams.append('billableHours', searchOptions.billableHours);
      }
      const resourceCosts = await dispatch({
        [CALL_API]: {
          url: `/projects/finance/details/all?${searchParams}`,
          method: 'GET',
        },
      });
      dispatch({
        type: LIST_RESOURCE_COSTS,
        payload: resourceCosts as EmployeeCosts[],
      });
      return resourceCosts;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

export const getResourceCostById = (id: string) => async (dispatch: any) => {
  try {
    const costDetails = await dispatch({
      [CALL_API]: {
        url: `/projects/finance/details/${id}`,
        method: 'GET',
      },
    });
    return costDetails;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const updateCostById = (body: UpdateBody, id: string) => async (dispatch: any) => {
  try {
    const updatedCost = await dispatch({
      [CALL_API]: {
        url: `/projects/finance/details/${id}`,
        method: 'POST',
        body,
      },
    });
    dispatch({
      type: UPDATE_RESOURCE_COST,
      payload: updatedCost,
    });
    return updatedCost;
  } catch (error) {
    console.log(error);
  }
};

export const getFinanceDetailsByEmployee = (id: string) => async (dispatch: any) => {
  try {
    const financeDetails = await dispatch({
      [CALL_API]: {
        url: `/projects/finance/details/${id}`,
        method: 'GET',
      },
    });
    return financeDetails;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getPhaseTasks = (phaseId: string) => async (dispatch: any) => {
  try {
    const phaseTasks = (await dispatch({
      [CALL_API]: {
        url: `${BASE_URL}/phases/${phaseId}/tasks`,
        method: 'GET',
      },
    })) as SOWTask[];
    return phaseTasks;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addPhaseTask =
  (phaseId: string, taskId: string, billable: boolean) => async (dispatch: any) => {
    try {
      const phaseTasks = (await dispatch({
        [CALL_API]: {
          url: `${BASE_URL}/phases/${phaseId}/tasks`,
          method: 'POST',
          body: { taskId, billable },
        },
      })) as SOWTask[];
      return phaseTasks;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const getGlobalTasks = () => async (dispatch: any) => {
  try {
    const phaseTasks = (await dispatch({
      [CALL_API]: {
        url: `${BASE_URL}/phases/global/tasks`,
        method: 'GET',
      },
    })) as Task[];
    return phaseTasks;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const sendUnallocatedEmail = () => async (dispatch: any) => {
  try {
    await dispatch({
      [CALL_API]: {
        url: `${BASE_URL}/resources/unallocated-summary-to-self`,
        method: 'GET',
      },
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};
