import { useField } from 'formik';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material';
import { DESIGNATIONS, DesignationType } from './constants';

export type FormSelectProps = SelectProps & {
  name: string;
};

const groupedDesignations = DESIGNATIONS.reduce((prev, curr) => {
  if (prev[curr.group]) {
    prev[curr.group].push(curr);
  } else {
    prev[curr.group] = [curr];
  }
  return prev;
}, {} as Record<string, Array<DesignationType>>);

export default function DesignationsSelect({
  name,
  fullWidth = true,
  label,
  margin = 'dense',
  ...rest
}: FormSelectProps): JSX.Element {
  const [field, meta] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : '';
  const hasError = !!errorText;

  const options: Array<JSX.Element> = [];
  Object.keys(groupedDesignations).forEach((group) => {
    options.push(
      <ListSubheader sx={{ pl: 1, fontWeight: 600, color: 'rgba(0, 0, 0, 0.3)' }} key={group}>
        {group}
      </ListSubheader>,
    );
    groupedDesignations[group].forEach((designation) => {
      options.push(
        <MenuItem key={designation.code} value={designation.code}>
          {designation.name}
        </MenuItem>,
      );
    });
  });

  return (
    <FormControl fullWidth={fullWidth} margin={margin} error={hasError}>
      <InputLabel>{label}</InputLabel>
      <Select label={label} {...field} {...rest}>
        {options}
      </Select>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
}
