import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import React from 'react';
import { TableProps } from './type';
import { ButtonPrimary } from '../../forms/Button';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    height: ' 100%',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.5,
    borderSpacing: 10,
  },
});

function LeavesTable(props: TableProps): JSX.Element {
  const classes = useStyles();
  const { headers = [], data = [] } = props;
  return (
    <div>
      <h1>Leaves</h1>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow
              sx={{
                bgcolor: 'rgb(197, 206, 203)',
                '& .MuiTableCell-head': {
                  fontWeight: 'bold',
                },
              }}
            >
              {headers.map((head) => (
                <TableCell key={head.label} align="center">
                  {head.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                {headers.map((head) =>
                  head.label === 'Action' ? (
                    <TableCell
                      key={head.stateKey}
                      // component="th"
                      scope="row"
                      align="center"
                    >
                      <ButtonPrimary
                        onClick={() => props.onUpdate(row)}
                        fullWidth={false}
                        variant="outlined"
                        color="primary"
                      >
                        Edit
                      </ButtonPrimary>
                    </TableCell>
                  ) : (
                    <TableCell
                      key={head.stateKey}
                      // component="th"
                      scope="row"
                      align="center"
                    >
                      {row[head.stateKey]}
                    </TableCell>
                  ),
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default LeavesTable;
