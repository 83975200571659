import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Box, LinearProgress } from '@mui/material';
import { ResourceBody } from '../types';
import { onGetResourceById, onUpdateResource } from '../actions';
import { TitleBar } from '../../assets/components';
import ResourceForm from './Form';

const beginningOfThisMonth = new Date();
beginningOfThisMonth.setDate(1);
beginningOfThisMonth.setHours(0, 0, 0, 0);

const endOfNextMonth = new Date();
endOfNextMonth.setHours(0, 0, 0, 0);
endOfNextMonth.setDate(1);
endOfNextMonth.setMonth(endOfNextMonth.getMonth() + 2);

export default function UpdateResource(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const [showProgress, setShowProgress] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [resourceUpdateValue, setResourceUpdateValue] = useState<ResourceBody>();
  const history = useHistory();

  useEffect(() => {
    async function getDetailsByid() {
      try {
        setShowProgress(true);
        const resourcedetails = await dispatch(onGetResourceById(id));
        const { role, employee, assignedTo, startDate, endDate, capacity, billingRate } =
          resourcedetails as unknown as ResourceBody;

        setResourceUpdateValue({
          role,
          employee,
          assignedTo,
          startDate,
          endDate,
          capacity,
          billingRate,
        });
      } catch (error) {
        enqueueSnackbar((error as Error).message, {
          variant: 'error',
        });
      } finally {
        setShowProgress(false);
      }
    }
    getDetailsByid();
  }, [id]);

  return (
    <>
      <TitleBar title="Update Resource" />
      {showProgress && <LinearProgress />}
      {resourceUpdateValue && (
        <Box sx={{ p: 2 }}>
          <ResourceForm
            initialValues={resourceUpdateValue}
            onSubmit={async (values: ResourceBody): Promise<void> => {
              try {
                const { role, capacity, billingRate, startDate, endDate } = values;
                const reqBody = {
                  role,
                  capacity,
                  billingRate,
                  startDate,
                  endDate,
                };
                setShowProgress(true);
                await dispatch(onUpdateResource(id, reqBody));
                enqueueSnackbar('Resource updated successfully', { variant: 'success' });
                setTimeout(history.goBack, 1);
              } catch (error) {
                enqueueSnackbar((error as Error).message, { variant: 'error' });
              } finally {
                setShowProgress(false);
              }
            }}
          />
        </Box>
      )}
    </>
  );
}
