import {
  Stack,
  Button,
  TextFieldProps,
  FormControl,
  FormHelperText,
  Typography,
} from '@mui/material';
import { useField } from 'formik';
import { useState } from 'react';

export function FormUpload({
  name,
  text,
  margin = 'dense',
  fullWidth = true,
}: TextFieldProps & {
  name: string;
  text?: string;
}): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, { setValue }] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : '';
  const hasError = !!errorText;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [showFileName, setShowFileName] = useState<any>('');
  return (
    <>
      <FormControl fullWidth={fullWidth} margin={margin} error={hasError}>
        <Stack>
          <Button variant="outlined" component="label" sx={{ background: 'primary.white' }}>
            {text}
            <Typography sx={{ px: 1 }}>{showFileName && showFileName}</Typography>
            <input
              type="file"
              hidden
              name={name}
              onChange={(event) => {
                setShowFileName(
                  event.currentTarget.files &&
                    (event.currentTarget.files[0].name as unknown as string),
                );
                setValue(event.currentTarget.files && event.currentTarget.files[0].name);
              }}
            />
          </Button>
        </Stack>
        <FormHelperText>{errorText}</FormHelperText>
      </FormControl>
    </>
  );
}
