import { TextField, TextFieldProps } from '@mui/material';
import { useField } from 'formik';

export function TextInput({
  label = '',
  name,
  variant = 'outlined',
  fullWidth = true,
  ...rest
}: TextFieldProps & { name: string }): JSX.Element {
  const [field, meta] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : '';
  return (
    <TextField
      label={label}
      helperText={errorText}
      error={!!errorText}
      variant={variant}
      fullWidth={fullWidth}
      {...field}
      {...rest}
    />
  );
}
