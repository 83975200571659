import { Box } from '@mui/material';
import { Location } from 'history';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router';
import CreateProject from './Create';
import ProjectList from './List';
import UpdateProject from './Update';
import ProjectDetails from './Details';
import { DrawerRouterLayout } from '../../common/layouts';

export default function ProjectRouter(): JSX.Element {
  const { path } = useRouteMatch();
  const location = useLocation<{ background: Location }>();
  const background = location.state && location.state.background;
  return (
    <>
      <Switch location={background || location}>
        <Route exact path={`${path}/create`} component={CreateProject} />
        <Route path={`${path}/update/:id`} component={UpdateProject} />
        <Route path={`${path}/:id`} component={ProjectDetails} />
        <Route exact path={path} component={ProjectList} />
      </Switch>
      <DrawerRouterLayout open={!!background} anchor="right">
        <Box role="presentation">
          <Switch>
            <Route exact path={`${path}/create`} component={CreateProject} />
            <Route path={`${path}/update/:id`} component={UpdateProject} />
            <Route exact path={`${path}/:id`} component={ProjectDetails} />
          </Switch>
        </Box>
      </DrawerRouterLayout>
    </>
  );
}
