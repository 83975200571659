import { Button, Toolbar, Typography, useTheme } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useHistory } from 'react-router';

export function TitleBar({ title }: { title: string }): JSX.Element {
  const history = useHistory();
  const theme = useTheme();
  return (
    <Toolbar
      sx={{
        // borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 'auto',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...(theme.mixins as any).flexCenterLeft,
      }}
    >
      <Button sx={{ minWidth: 'unset' }} onClick={() => history.goBack()}>
        <ChevronLeftIcon />
      </Button>
      <Typography variant="h2">{title}</Typography>
    </Toolbar>
  );
}
