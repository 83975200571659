import { Box, LinearProgress, Typography } from '@mui/material';

export default function LinearProgressWithSegments({ value }: { value: number }): JSX.Element {
  return (
    <Box
      sx={{
        position: 'relative',
        mt: 1,
        '& .MuiTypography-root.MuiTypography-body1': {
          position: 'absolute',
          left: 0,
          top: 0,
          display: 'inline-block',
          height: '100%',
          '& span.MuiTypography-root.MuiTypography-body1': {
            bgcolor: 'primary.white',
            width: '1px',
            height: '15px',
            mr: 0,
          },
          '& .MuiTypography-h6': {
            mr: 0,
            fontSize: 10,
            fontWeight: 400,
            position: 'absolute',
            left: '-5px',
            top: 'calc(100% + 10px)',
          },
        },
      }}
    >
      <LinearProgress
        variant="determinate"
        value={value}
        sx={{
          height: 15,
          borderRadius: '2px',
          bgcolor: 'primary.blueBgLight',
          '& .MuiLinearProgress-bar': {
            bgcolor: 'primary.blueBg',
          },
        }}
      />
      <Typography variant="body1" style={{ left: '25%' }}>
        <Typography component="span" variant="body1"></Typography>
        <Typography component="span" variant="h6">
          25%
        </Typography>
      </Typography>
      <Typography variant="body1" style={{ left: '50%' }}>
        <Typography component="span" variant="body1"></Typography>
        <Typography component="span" variant="h6">
          50%
        </Typography>
      </Typography>
      <Typography variant="body1" style={{ left: '75%' }}>
        <Typography component="span" variant="body1"></Typography>
        <Typography component="span" variant="h6">
          75%
        </Typography>
      </Typography>
    </Box>
  );
}
