import { AnyAction } from 'redux';
import { ADD_GROUP, SET_GROUPS, UPDATE_GROUP } from '../groups/constants';
import { Group } from '../types';

export type GroupsState = Array<Group>;

export default function GroupsReducer(state: GroupsState = [], action: AnyAction): GroupsState {
  switch (action.type) {
    case SET_GROUPS:
      return action.groups;
    case ADD_GROUP:
      return [...state, action.group];
    case UPDATE_GROUP:
      return state.map((group: Group) => {
        if (group.id === action.group.id) {
          return action.group;
        }
        return group;
      });
    case 'LOGOUT':
      return [];
    default:
      return state;
  }
}
