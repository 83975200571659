import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { UpdateTicketFormBody } from '../../redux-store/types/tickets';
import { SubmitButton } from '../forms/Button';
import FormTextInput from '../forms/FormTextInput';
import FormUsersPicker from '../forms/FormUsersPicker';

const Schema = Yup.object().shape({
  title: Yup.string().required('Title is Required'),
  status: Yup.string().required('Status is required'),
  assignedTo: Yup.object()
    .nullable()
    .shape({
      id: Yup.string(),
      name: Yup.string().required('Name is required'),
      email: Yup.string().required('Email is required'),
    }),
});

export interface TicketFormProp {
  onSubmit: (values: UpdateTicketFormBody) => void;
  initialValues: UpdateTicketFormBody;
}
export default function UpdateForm({ onSubmit, initialValues }: TicketFormProp): JSX.Element {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Schema}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <FormTextInput
            label="Title"
            name="title"
            type="text"
            placeholder="Ex.Created New Ticket"
            disabled={isSubmitting}
          />
          <FormTextInput
            label="Status"
            name="status"
            type="text"
            placeholder="Open/Close"
            disabled={isSubmitting}
          />
          <FormUsersPicker label="Assigned To" disabled={isSubmitting} name="assignedTo" />

          <Box sx={{ float: 'right', mt: '10px' }}>
            <SubmitButton label="Submit" />
          </Box>
        </Form>
      )}
    </Formik>
  );
}
