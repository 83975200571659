import {
  Box,
  Button,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import CheckIcon from '@mui/icons-material/Check';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { TitleBar } from '../../assets/components';
import { addPhaseTask, getGlobalTasks, getPhaseTasks } from '../actions';
import { SOWTask, Task } from '../types';
import { LoadingButton } from '@mui/lab';

function TableHeadCell({ sx, ...props }: TableCellProps): JSX.Element {
  return <TableCell sx={{ fontWeight: 'bold', ...sx }} {...props} />;
}

type SOWTaskWithUpdateState = SOWTask & {
  updateState: boolean;
};
export default function ManageTasks(): JSX.Element {
  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [showProgress, setShowProgress] = useState(false);
  const [sowTasks, setSOWTasks] = useState<Array<SOWTaskWithUpdateState>>([]);

  async function getTasks() {
    try {
      setShowProgress(true);
      const tasks = (await dispatch(getPhaseTasks(id))) as unknown as Array<SOWTask>;
      setSOWTasks(tasks.map((task) => ({ ...task, updateState: false })));
    } catch (error) {
      enqueueSnackbar((error as Error).message, {
        variant: 'error',
      });
    } finally {
      setShowProgress(false);
    }
  }

  useEffect(() => {
    getTasks();
  }, [id]);

  async function addGlobalTasks() {
    try {
      setShowProgress(true);
      const globalTasks = (await dispatch(getGlobalTasks())) as unknown as Array<Task>;
      await Promise.all(
        globalTasks.map((task) => dispatch(addPhaseTask(id, task.id, task.billable))),
      );
      enqueueSnackbar('Tasks added', {
        variant: 'success',
      });
      await getTasks();
    } catch (error) {
      enqueueSnackbar((error as Error).message, {
        variant: 'error',
      });
    } finally {
      setShowProgress(false);
    }
  }

  const handleToggleBillability = async (selectedTask: SOWTaskWithUpdateState) => {
    console.log('Toggle update state for grant ' + selectedTask.task.name);
    if (selectedTask.updateState) {
      return;
    }
    setSOWTasks(
      sowTasks.map((sowTask) =>
        sowTask === selectedTask ? { ...sowTask, updateState: !sowTask.updateState } : sowTask,
      ),
    );
    try {
      const updatedTask = (await dispatch(
        addPhaseTask(id, selectedTask.task.id, !selectedTask.billable),
      )) as unknown as SOWTask;
      setSOWTasks(
        sowTasks.map((sowTask) =>
          sowTask === selectedTask ? { ...updatedTask, updateState: false } : sowTask,
        ),
      );
      enqueueSnackbar(`Successfully changed billability for '${updatedTask.task.name}'`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar((error as Error).message, { variant: 'error' });
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (
    <>
      <TitleBar title="Manage Tasks' billability" />
      {showProgress && <LinearProgress />}
      <Box sx={{ px: 10, py: 3 }}>
        <Typography variant="h1" mb={4}>
          {sowTasks.length > 0 ? (sowTasks[0].sow?.name as string) : ''}
        </Typography>
        {sowTasks.length > 0 && (
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  bgcolor: 'primary.veryLightGrey',
                  '&.MuiTableRow-head .MuiTableCell-root': { padding: '16px' },
                }}
              >
                <TableHeadCell>Billable</TableHeadCell>
                <TableHeadCell>Task Name</TableHeadCell>
                <TableHeadCell>Default Billability</TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sowTasks.map((sowTask) => (
                <TableRow key={sowTask.id}>
                  <TableCell sx={{ p: 0 }}>
                    {sowTask.updateState ? (
                      <LoadingButton loading />
                    ) : (
                      <Button onClick={handleToggleBillability.bind(null, sowTask)}>
                        {sowTask.billable ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>{sowTask.task.name}</TableCell>
                  <TableCell>{sowTask.task.billable && <CheckIcon />}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        {sowTasks.length === 0 && (
          <Stack direction="column" spacing={3} alignItems="center" py={3}>
            <Typography>No Tasks Found</Typography>
            <LoadingButton loading={showProgress} onClick={() => addGlobalTasks()}>
              Add Global Tasks
            </LoadingButton>
          </Stack>
        )}
      </Box>
    </>
  );
}
