export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT = 'LOGOUT';

export interface LoginPayload {
  email: string;
  password: string;
}
export interface AuthState {
  token: string | null;
  role: string | null;
  name: string | null;
}
interface OnLoginAction {
  type: typeof LOGIN_SUCCESS;
  body: LoginPayload;
}

export type AuthTypes = OnLoginAction;
