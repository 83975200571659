import React from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Paper, Typography, Link, Divider, Box, Stack } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import InfoIcon from '@mui/icons-material/Info';
import { onLogin } from '../../redux-store/actions/login';
import { LoginPayload } from '../../redux-store/types/login';
import { TextInput } from '../forms/textInput';
import { SubmitButton } from '../forms/Button';

const domain = '@webileapps.com';
interface Values {
  userId: string;
  password: string;
}

const validationSchema = yup.object().shape({
  userId: yup.string().required('Email is required'),
  password: yup.string().required('Password is required'),
});

const classes = {
  paperStyle: {
    padding: 20,
    height: '65vh',
    width: 532,
    margin: '65px auto',
    borderRadius: '16px',
    maxWidth: '100%',
  },
  image: {
    width: 50,
    marginTop: 20,
  },
  InfoIcon: {
    fontSize: 'medium',
    color: '#3090C7',
    cssFloat: 'left',
    padding: '0px 10px',
  },
  InfoDiv: {
    width: '100%',
    margin: '20px 0',
    backgroundColor: '#e6ffff',
    borderRadius: 15,
  },
};

export default function Login(): JSX.Element {
  const initialValues: Values = { userId: '', password: '' };
  const dispatch = useDispatch();
  const onSubmit = async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
    try {
      const { userId, password } = values;
      const reqBody: LoginPayload = { email: `${userId}${domain}`, password };
      await dispatch(onLogin(reqBody));
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      console.error(error);
    }
  };

  return (
    <Box
      sx={{
        height: '100vh',
        bgcolor: '#3A2F5C',
        width: '100%',
        p: [2, 0],
      }}
    >
      <Grid>
        <Grid container justifyContent="center">
          <img alt="logo" src="/images/webileapps.svg" style={classes.image} />
        </Grid>
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ handleSubmit }) => (
            <Box component="form" onSubmit={handleSubmit}>
              <Paper elevation={5} style={classes.paperStyle}>
                <h2 style={{ marginBottom: '40px' }}>Admin Login</h2>
                <TextInput
                  label="Email Address"
                  name="userId"
                  sx={{ mb: 3 }}
                  InputProps={{ endAdornment: <div>{domain}</div> }}
                ></TextInput>
                <TextInput
                  label="Password"
                  name="password"
                  type="password"
                  sx={{ mb: 3 }}
                ></TextInput>
                <SubmitButton label="Log In" fullWidth={true} />
                <div style={classes.InfoDiv}>
                  <InfoIcon style={classes.InfoIcon} />
                  <h5>This login is only for ADMIN!</h5>
                </div>
                <Divider variant="middle" />
                <Stack>
                  <Typography variant="caption">
                    <Link component={RouterLink} to="/employee/login">
                      Employee Login
                    </Link>
                  </Typography>
                  <Typography variant="caption">
                    <Link href="#">Forgot password?</Link>
                  </Typography>
                </Stack>
              </Paper>
            </Box>
          )}
        </Formik>
      </Grid>
    </Box>
  );
}
