import {
  Box,
  Divider,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllResourceCosts, getResources } from '../actions';
import Button from '@mui/material/Button';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { ResourceCostState } from '../store/ResourceCostReducer';
import { RootState } from '../../../redux-store/reducers';
import { OnlyIfPermission, TableBodyCell, TableCellText, TableHeadCell } from '../../common';
import { formatDesignation } from '../../users/constants';
import { formatAmount } from './utils';
import Cost_Billing_Filters from './Filters';
import { PaginationLayout } from '../../time-tracking/approve/CustomLayouts';
import { ResourceState } from '../store/resourceReducer';

export default function CostsList(): JSX.Element {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { resourceCosts, searchOptions } = useSelector<RootState, ResourceCostState>(
    (state) => state.delivery.resourceCosts,
  );
  const { resources } = useSelector<RootState, ResourceState>((state) => state.delivery.resources);

  const [showProgress, setShowProgress] = useState(false);

  useEffect(() => {
    async function fetchUsers() {
      try {
        setShowProgress(true);
        await dispatch(getAllResourceCosts(searchOptions));
      } catch (error) {
        enqueueSnackbar((error as Error).message, { variant: 'error' });
      } finally {
        setShowProgress(false);
      }
    }
    fetchUsers();
  }, [searchOptions]);
  useEffect(() => {
    async function getResourceList() {
      try {
        setShowProgress(true);
        await dispatch(getResources());
      } catch (error) {
        console.log(error);
      } finally {
        setShowProgress(false);
      }
    }
    getResourceList();
  }, []);

  return (
    <Box sx={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
      <Box
        sx={{
          width: '100%',
          zIndex: '999',
          px: 2,
          flex: '0 1 auto',
          position: 'relative',
        }}
      >
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          pb={2.6}
          pt={1}
        >
          <Typography variant="h2">Costs &amp; Default Billing Rates</Typography>
        </Stack>
        <Divider
          sx={{
            width: 'calc(100% + 64px)',
            ml: -4,
          }}
        />
        <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
          <Cost_Billing_Filters />
        </Stack>
        <Divider
          sx={{
            width: 'calc(100% + 64px)',
            ml: -4,
          }}
        />
      </Box>

      {showProgress && <LinearProgress />}
      {resourceCosts && (
        <Box sx={{ px: 2, flex: '1 1 auto', overflow: 'auto' }}>
          <TableContainer sx={{ p: 0 }}>
            <Table aria-label="cost-bill-table">
              <TableHead>
                <TableRow
                  sx={{
                    '& .MuiTableCell-root': {
                      '&:first-of-type': { borderLeft: 0 },
                      '&:last-child': { borderRight: 0 },
                    },
                  }}
                >
                  <TableHeadCell sx={{ borderLeft: 'none' }}>
                    <TableCellText
                      text="Employee Name"
                      sx={{ fontWeight: 700 }}
                      showDropDown={true}
                    />
                  </TableHeadCell>
                  <TableHeadCell>
                    <TableCellText text="Designation" />
                  </TableHeadCell>
                  <TableHeadCell>
                    <TableCellText sx={{ textAlign: 'right' }} text="Billable Hours" />
                  </TableHeadCell>
                  <TableHeadCell>
                    <TableCellText sx={{ textAlign: 'right' }} text="CTC (per year)" />
                  </TableHeadCell>
                  <TableHeadCell>
                    <TableCellText sx={{ textAlign: 'right' }} text="Cost Per Hour" />
                  </TableHeadCell>
                  <TableHeadCell>
                    <TableCellText sx={{ textAlign: 'right' }} text="Billing Per Hour" />
                  </TableHeadCell>
                  <OnlyIfPermission name="delivery:finance:update">
                    <TableHeadCell>
                      <TableCellText sx={{ textAlign: 'right' }} text="Actions" />
                    </TableHeadCell>
                  </OnlyIfPermission>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  '& .MuiTableCell-root': {
                    '&:first-of-type': { borderLeft: 0 },
                    '&:last-child': { borderRight: 0 },
                  },
                }}
              >
                {resourceCosts.map((cost, index) => (
                  <TableRow key={cost.employee.id}>
                    <TableBodyCell sx={{ borderLeft: 'none' }}>
                      <Button
                        variant="text"
                        component={Link}
                        to={{
                          pathname: `${url}/${cost.employee.id}`,
                          state: {
                            background: location,
                            resources: resources.filter((r) => r.employee.id === cost.employee.id),
                          },
                        }}
                      >
                        <Typography sx={{ px: 1 }}>{index + 1}.</Typography>

                        {cost.employee.name}
                      </Button>
                    </TableBodyCell>
                    <TableBodyCell>{formatDesignation(cost.employee.designation)}</TableBodyCell>
                    <TableBodyCell sx={{ textAlign: 'right' }}>
                      {cost.capacity} / week
                    </TableBodyCell>
                    <TableBodyCell sx={{ textAlign: 'right' }}>
                      {formatAmount(+cost.ctc)}
                    </TableBodyCell>
                    <TableBodyCell sx={{ textAlign: 'right' }}>
                      {formatAmount(+cost.costPerHour)}
                    </TableBodyCell>
                    <TableBodyCell sx={{ textAlign: 'right' }}>
                      {formatAmount(+cost.expectedBillingPerHour)}
                    </TableBodyCell>
                    <OnlyIfPermission name="delivery:finance:update">
                      <TableBodyCell sx={{ textAlign: 'right' }}>
                        <Button
                          variant="text"
                          component={Link}
                          to={{
                            pathname: `${url}/update/${cost.employee.id}`,
                          }}
                        >
                          <img src="/images/updateIcon.svg" alt="Edit" />
                        </Button>
                      </TableBodyCell>
                    </OnlyIfPermission>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      <PaginationLayout
        totalPageCount={25}
        // onPageChange={handlePageChange as unknown as (page: number) => void}
        // onPageSizeChange={handlePageSizeChange as unknown as (pageSize: number) => void}
      />
    </Box>
  );
}
