/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CALL_API } from '../middleware';
import {
  ADD_ASSET,
  Allocation,
  AllocationBody,
  Asset,
  AssetBody,
  AssetFormBody,
  AssetsSearchOptions,
  SET_ASSETS,
  UPDATE_ASSET,
} from '../types/asset';

export const getAssets = (searchOptions: AssetsSearchOptions) => async (dispatch: any) => {
  try {
    const searchParams = new URLSearchParams();
    if (searchOptions.type) {
      searchParams.append('type', searchOptions.type);
    }
    if (searchOptions.serialNumber) {
      searchParams.append('serialNumber', searchOptions.serialNumber);
    }
    if (typeof searchOptions.active !== 'undefined') {
      searchParams.append('active', searchOptions.active.toString());
    }
    if (searchOptions.allocatedTo) {
      searchParams.append('allocatedTo', searchOptions.allocatedTo.id);
    } else if (searchOptions.allocationType === 'none') {
      searchParams.append('allocatedTo', 'none');
    } else if (searchOptions.allocationType === 'not-none') {
      searchParams.append('allocatedTo', 'not-none');
    }
    if (searchOptions.createdAt) {
      const [createdAfter, createdBefore] = searchOptions.createdAt;
      if (createdAfter) {
        searchParams.append('createdAfter', createdAfter.toISOString());
      }
      if (createdBefore) {
        searchParams.append('createdBefore', createdBefore.toISOString());
      }
    }
    if (searchOptions.allocatedAt) {
      const [allocatedAfter, allocatedBefore] = searchOptions.allocatedAt;
      if (allocatedAfter) {
        searchParams.append('allocatedAfter', allocatedAfter.toISOString());
      }
      if (allocatedBefore) {
        searchParams.append('allocatedBefore', allocatedBefore.toISOString());
      }
    }
    if (searchOptions.updatedAt) {
      const [updatedAfter, updatedBefore] = searchOptions.updatedAt;
      if (updatedAfter) {
        searchParams.append('updatedAfter', updatedAfter.toISOString());
      }
      if (updatedBefore) {
        searchParams.append('updatedBefore', updatedBefore.toISOString());
      }
    }
    if (searchOptions.orderBy) {
      searchParams.append('orderBy', searchOptions.orderBy);
    }
    if (searchOptions.orderDirection) {
      searchParams.append('orderDirection', searchOptions.orderDirection);
    }
    if (searchOptions.limit) {
      searchParams.append('limit', searchOptions.limit.toString());
    }
    if (searchOptions.offset) {
      searchParams.append('offset', searchOptions.offset.toString());
    }
    const assets = await dispatch({
      [CALL_API]: {
        url: `/assets/assets/search?${searchParams.toString()}`,
        method: 'GET',
      },
    });
    dispatch({
      type: SET_ASSETS,
      assets,
    });
    return assets as Asset[];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const createNewAsset = (assetFormBody: AssetFormBody) => async (dispatch: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { allocatedTo, allocatedSince, active, ...assetBody } = assetFormBody;
  try {
    let asset = await dispatch({
      [CALL_API]: {
        url: `/assets/assets`,
        method: 'POST',
        body: assetBody as AssetBody,
      },
    });
    if (allocatedTo) {
      const { id } = asset;
      const allocationBody = {
        asset: {
          id,
        },
        allocatedTo: {
          id: allocatedTo.id,
        },
        allocatedSince: allocatedSince || new Date(),
        description: 'First time allocation of new asset to ' + allocatedTo.name,
      };
      const payload = await dispatch({
        [CALL_API]: {
          url: `/assets/allocations`,
          method: 'POST',
          body: allocationBody as unknown as AllocationBody,
        },
      });
      asset = payload.asset;
    }
    dispatch({
      type: ADD_ASSET,
      asset,
    });
    return asset;
  } catch (error) {
    throw new Error('Error creating new asset. ' + error);
  }
};

export const updateAsset =
  (
    id: string,
    assetBody: AssetBody,
    updatedAllocation: AllocationBody,
    existingAllocation?: Allocation | null,
  ) =>
  async (dispatch: any) => {
    try {
      const { type, description, serialNumber, dateOfPurchase, active } = assetBody;
      let asset = await dispatch({
        [CALL_API]: {
          url: `/assets/assets/${id}`,
          method: 'POST',
          body: { type, description, serialNumber, dateOfPurchase, active },
        },
      });
      const shouldDeallocate =
        existingAllocation &&
        existingAllocation.allocatedTo &&
        (!updatedAllocation.allocatedTo ||
          existingAllocation.allocatedTo.id !== updatedAllocation.allocatedTo.id);

      if (shouldDeallocate) {
        const allocationBody = {
          asset: {
            id,
          },
          allocatedTo: null,
          allocatedSince: new Date(),
          description: 'Deallocation of asset',
        };
        const payload = await dispatch({
          [CALL_API]: {
            url: `/assets/allocations`,
            method: 'POST',
            body: allocationBody as unknown as AllocationBody,
          },
        });
        asset = payload.asset as Asset;
      }

      const shouldAllocate =
        updatedAllocation &&
        updatedAllocation.allocatedTo &&
        !(
          existingAllocation &&
          existingAllocation.allocatedTo?.id === updatedAllocation.allocatedTo.id
        );
      if (shouldAllocate) {
        const allocationBody = {
          asset: {
            id,
          },
          allocatedTo: {
            id: updatedAllocation.allocatedTo?.id,
          },
          allocatedSince: updatedAllocation.allocatedSince || new Date(),
          description: 'Reallocation of asset to ' + updatedAllocation.allocatedTo?.name,
        };
        const payload = await dispatch({
          [CALL_API]: {
            url: `/assets/allocations`,
            method: 'POST',
            body: allocationBody as unknown as AllocationBody,
          },
        });
        asset = payload.asset as Asset;
      }
      dispatch({
        type: UPDATE_ASSET,
        asset,
      });

      return asset;
    } catch (error) {
      throw new Error('Error updating asset. ' + error);
    }
  };

export const getAssetDetail = (assetId: string) => async (dispatch: any) => {
  try {
    const asset = await dispatch({
      [CALL_API]: {
        url: `/assets/assets/${assetId}`,
        method: 'GET',
      },
    });
    return asset as Asset;
  } catch (error) {
    throw new Error('Unable to fetch asset. ' + error);
  }
};
