import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
} from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import React, { FormEvent, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../../redux-store/reducers';
import * as Yup from 'yup';
import {
  CandidatesSearchParameters,
  CANDIDATES_SEARCH_OPTIONS,
  RESET_CANDIDATES_SEARCH_OPTIONS,
} from '../types';
import FormSelect from '../../forms/FormSelect';
import FormTextInput from '../../forms/FormTextInput';
import FormDatePicker from '../../forms/FormDatePicker';
import { candidateStatuses } from './constants';
import { CampaignsState } from '../store/campaigns-reducer';
import { getCampaigns } from '../campaigns/actions';

const candidateStatusesWithAny = [{ label: 'Any', value: 'any' }, ...candidateStatuses];
const vaildationschema = Yup.object().shape({
  status: Yup.string().nullable(),
  campaignId: Yup.string(),
  name: Yup.string(),
  phone: Yup.string(),
  email: Yup.string(),
  limit: Yup.number(),
  createdBefore: Yup.date().nullable(true),
  createdAfter: Yup.date().nullable(true),
  updatedBefore: Yup.date().nullable(true),
  updatedAfter: Yup.date().nullable(true),
});

export type SearchOptionDialogTypes = DialogProps;

export default function CandidatesSearchOptions(props: SearchOptionDialogTypes): JSX.Element {
  const searchOptions = useSelector<RootStateType, Partial<CandidatesSearchParameters>>(
    (state) => state.ats.candidates.searchOptions,
  );

  const campaigns = useSelector<RootStateType, CampaignsState>((state) => state.ats.campaigns);
  useEffect(() => {
    async function _getCampaigns() {
      dispatch(getCampaigns());
    }
    if (campaigns.length == 0) {
      _getCampaigns();
    }
  }, []);
  const dispatch = useDispatch();
  const formikRef = useRef<FormikProps<Partial<CandidatesSearchParameters>>>(null);

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setValues(searchOptions);
    }
  }, [searchOptions]);

  const initialValues = {
    ...searchOptions,
  };

  const handleApply = (e: React.MouseEvent<HTMLElement>) => {
    if (formikRef.current) {
      formikRef.current.handleSubmit(e as unknown as FormEvent<HTMLFormElement>);
    }
  };
  const handleReset = () => {
    dispatch({ type: RESET_CANDIDATES_SEARCH_OPTIONS });
  };

  const formattedCampaigns = [
    { value: 'any', label: 'Any' },
    ...(campaigns || [{}]).map((campaign) => ({
      value: campaign.id,
      label: campaign.name || campaign.id,
    })),
  ];

  return (
    <Dialog scroll="paper" {...props}>
      <DialogTitle>Search Options</DialogTitle>
      <DialogContent dividers>
        <Formik
          innerRef={formikRef}
          validationSchema={vaildationschema}
          initialValues={initialValues}
          onSubmit={(values) => {
            dispatch({ type: CANDIDATES_SEARCH_OPTIONS, searchOptions: values });
            props.onClose &&
              props.onClose(
                new Event('submit', { cancelable: true, bubbles: true }),
                'escapeKeyDown',
              );
          }}
          enableReinitialize
        >
          {() => (
            <Form>
              <Divider>Filters</Divider>
              <FormSelect label="Campaign" name="campaignId" options={formattedCampaigns} />
              <FormSelect name="status" label="Status" options={candidateStatusesWithAny} />
              <FormTextInput name="name" label="Name" />
              <FormTextInput name="email" label="Email" />
              <FormTextInput name="phone" label="Phone" />
              <Divider>Filters by Date</Divider>
              <FormDatePicker name="createdBefore" label="Created Before" />
              <FormDatePicker name="createdAfter" label="Created After" />
              <FormDatePicker name="updatedBefore" label="Updated Before" />
              <FormDatePicker name="updatedAfter" label="Updated After" />
              <Divider>Sorting</Divider>
              <FormSelect
                name="orderBy"
                label="Sort By"
                options={[
                  { label: 'Created At', value: 'createdAt' },
                  { label: 'Updated At', value: 'updatedAt' },
                  { label: 'Name', value: 'name' },
                  { label: 'Status', value: 'status' },
                ]}
              />
              <FormSelect
                name="orderDirection"
                label="Sort Order"
                options={[
                  { label: 'Ascending', value: 'asc' },
                  { label: 'Descending', value: 'desc' },
                ]}
              />
              <Divider>Pagination</Divider>
              <FormSelect
                name="limit"
                label="Items per page"
                options={['10', '25', '50'].map((option) => ({ label: option, value: option }))}
              />
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReset} color="primary">
          Reset
        </Button>
        <Button onClick={handleApply} variant="contained" color="primary">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}
