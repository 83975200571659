/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, Dispatch, ReactNode, Reducer, useContext, useReducer } from 'react';
import { reducer as timesheetListReducer } from './timesheet-list.reducer';
import { reducer as teamReducer } from './team.reducer';

export type RootStateType = {
  timesheets: TimesheetSearchState;
  team: TeamState;
};

const combineReducers = (reducers: { [key: string]: Reducer<any, Action> }) => {
  return ((state, action) => {
    return Object.keys(reducers).reduce((acc, prop) => {
      const r = reducers[prop];
      return {
        ...acc,
        [prop]: { ...r(acc[prop], action) },
      };
    }, state);
  }) as Reducer<any, any>;
};

const reducer = combineReducers({ timesheets: timesheetListReducer, team: teamReducer });

const initialState = reducer({}, { type: '@@init' });

import { Action, TimesheetSearchState } from '../types';
import { TeamState } from './team.reducer';

const Store = createContext<[RootStateType, Dispatch<Action>]>([initialState, () => null]);
Store.displayName = 'TimeTrackingStore';
export const useTimeStore = (): [RootStateType, Dispatch<Action>] => useContext(Store);

export function TimeStoreProvider({ children }: { children: ReactNode }): JSX.Element {
  const [rootState, dispatch] = useReducer(reducer, initialState);
  return <Store.Provider value={[rootState, dispatch]}>{children}</Store.Provider>;
}
