import { combineReducers } from 'redux';
import projectsReducer, { ProjectState } from './projectReducer';
import phasesReducer, { PhasesState } from './phases.reducer';
import resourcesReducer, { ResourceState } from './resourceReducer';
import ResourceCostReducer, { ResourceCostState } from './ResourceCostReducer';

const deliveryReducer = combineReducers({
  projects: projectsReducer,
  phases: phasesReducer,
  resources: resourcesReducer,
  resourceCosts: ResourceCostReducer,
});

export type DeliveryState = {
  projects: ProjectState;
  phases: PhasesState;
  resources: ResourceState;
  resourceCosts: ResourceCostState;
};

export default deliveryReducer;
