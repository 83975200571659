import { OutlinedInput } from '@mui/material';
import { styled } from '@mui/styles';
import { useEffect, useState } from 'react';
import { formatAsTime } from '../common';
import { TaskWithSOW, Timelog } from '../types';

export declare type TimeLogEntry = Omit<
  Timelog,
  'id' | 'status' | 'noOfHours' | 'employee' | 'notes' | 'sow' | 'task'
> & {
  noOfHours?: number;
  task: TaskWithSOW;
};

function parseTime(text: string): number {
  //if text contains :, then it is in HH:MM format
  if (text.includes(':')) {
    const [hours, minutes] = text.split(':').map((s) => parseInt(s, 10));
    return hours + Math.round(100 * (minutes / 60)) / 100;
  }
  return NaN;
}
const EditableInput = styled(OutlinedInput)({
  padding: '6px 4px',
  background: 'rgba(217, 217, 217, 0.2)',
  '& .MuiOutlinedInput-input': {
    textAlign: 'inherit',
    padding: '5px 10px',
    fontSize: 14,
    fontWeight: 400,
    color: '#5C5E70',
    boxShadow: 'none',
    '&:focus': {
      fontWeight: 600,
      color: '#3A2F5C',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '&.Mui-focused': {
    background: '#fff',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#3A2F5C',
      borderWidth: '1px',
    },
  },
});

export default function EditableTimeEntry({
  log,
  onEditingStart = (l) => {
    console.log('Editing Started', l);
  },
  onEditingEnd = (l, noOfHours) => {
    console.log('Editing Ended', l, noOfHours);
  },
}: {
  log: TimeLogEntry;
  onEditingStart?: (log: TimeLogEntry) => void;
  onEditingEnd?: (log: TimeLogEntry, noOfHours: number | undefined) => void;
}): JSX.Element {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState<number | undefined>(log.noOfHours);
  const [text, setText] = useState<string>(String(log.noOfHours ?? ''));
  function setNumericValue(event: React.ChangeEvent<HTMLInputElement>): void {
    setText(event.target.value);
  }
  useEffect(() => {
    if (editing) {
      onEditingStart(log);
      if (value === undefined || value === 0) {
        setText('');
      } else {
        // setText('' + value);
      }
    } else {
      const timeVal = parseTime(text);
      const number = parseFloat(text);
      const val = !isNaN(timeVal) ? timeVal : !isNaN(number) ? number : undefined;
      if (val) {
        setText(formatAsTime(+val));
      } else {
        setText('');
      }
      setValue(val);
      onEditingEnd(log, val);
    }
  }, [editing]);
  return (
    <EditableInput
      value={text}
      onFocus={(event) => {
        setEditing(true);
        event.target.select();
      }}
      onBlur={() => setEditing(false)}
      onChange={setNumericValue}
    />
  );
}
