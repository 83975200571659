import {
  Box,
  Button,
  CardMedia,
  Grid,
  LinearProgress,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { formatShortDate } from '../../../utils/date';
import { TitleBar } from '../../assets/components';
import { DataValues } from '../../ats/components';
import { Phase, SOWTask } from '../types';
import { getPhaseById, getPhaseTasks } from '../actions';
import { ENGAGEMENT_TYPES } from '../constants';
import { ProgressBarCard } from '../../common';

function TableHeadCell({ sx, ...props }: TableCellProps): JSX.Element {
  return <TableCell sx={{ fontWeight: 'bold', ...sx }} {...props} />;
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

export default function PhaseDetails(): JSX.Element {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [details, setDetails] = useState<Phase>();
  const [showProgress, setShowProgress] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [sowTasks, setSOWTasks] = useState<Array<SOWTask>>([]);

  async function getTasks() {
    try {
      setShowProgress(true);
      const tasks = (await dispatch(getPhaseTasks(id))) as unknown as Array<SOWTask>;
      setSOWTasks(tasks);
    } catch (error) {
      enqueueSnackbar((error as Error).message, {
        variant: 'error',
      });
    } finally {
      setShowProgress(false);
    }
  }

  useEffect(() => {
    async function getPhasedetails() {
      try {
        setShowProgress(true);
        const phaseDetails = await dispatch(getPhaseById(id));
        setDetails(phaseDetails as unknown as Phase);
        await getTasks();
      } catch (error) {
        console.log(error);
      } finally {
        setShowProgress(false);
      }
    }
    getPhasedetails();
  }, [id]);
  const theme = useTheme();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const themeMixins = theme.mixins as any;
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      <TitleBar title={details ? details.name : ''} />
      {showProgress && <LinearProgress />}
      {details && (
        <Box sx={{ px: 8 }}>
          <Grid container sx={{ p: 2 }}>
            <Grid item xs={4}>
              <DataValues label="Client Name" value={details.project?.name} />
            </Grid>
            <Grid item xs={4}>
              <DataValues label="Engagement Type" value={ENGAGEMENT_TYPES[details.type]} />
            </Grid>
            <Grid item xs={2}>
              <DataValues label="Start Date" value={formatShortDate(details.startDate)} />
            </Grid>
            <Grid item xs={2}>
              <DataValues label="End Date" value={formatShortDate(details.endDate)} />
            </Grid>
            {/* <DataValues label="Created At" value={formatShortDate(details.createdAt)} half />
            <DataValues label="Updated At" value={formatShortDate(details.updatedAt)} half /> */}
          </Grid>
          <Box
            sx={{
              width: 'calc(100% + 160px)',
              ml: -10,
              bgcolor: 'primary.veryLightGrey',
              px: 10,
              mt: 3,
            }}
          >
            <Grid container sx={{ p: 2, pb: 4, width: '90%' }} spacing={2}>
              <Grid item xs={4}>
                <ProgressBarCard
                  percentage={55}
                  primaryColor="#1376E3"
                  secondaryColor="#D0E4F9"
                  title="Total Hours"
                  totalValue="1024"
                  mainLegendLabel="Billable"
                  mainLegendValue="600hrs"
                  secondLegendLabel="Non - Billable"
                  secondLegendValue="424hrs"
                />
              </Grid>
              <Grid item xs={4}>
                <ProgressBarCard
                  percentage={55}
                  primaryColor="#39BC95"
                  secondaryColor="#BFF1E2"
                  title="Budget"
                  totalValue={details.budget ? details.budget.toString() : '---'}
                  mainLegendLabel="Payed"
                  mainLegendValue="₹10,50,000"
                  secondLegendLabel="Due"
                  secondLegendValue="₹9,50,000"
                />
              </Grid>
              <Grid item xs={4}>
                <Stack
                  sx={{
                    justifyContent: 'center',
                    height: '100%',
                    width: '100%',
                    background: '#FFFFFF',
                    ...themeMixins.standardBorder,
                    borderRadius: '2px',
                    px: 3,
                    py: 1.2,
                  }}
                >
                  <Typography variant="h4" mb={2}>
                    Team
                  </Typography>
                  <Stack
                    sx={{
                      ...themeMixins.flexCenterLeft,
                    }}
                  >
                    <Stack
                      sx={{
                        ...themeMixins.flexCenterLeft,
                        width: 'auto',
                        mr: 1,
                        '& .MuiCardMedia-root': {
                          ml: -2,
                          '&:nth-child(1)': {
                            ml: 0,
                          },
                        },
                      }}
                    >
                      <CardMedia
                        component="img"
                        alt="logo"
                        src="/images/webileapps.svg"
                        sx={{
                          height: '28px',
                          width: '28px',
                          borderRadius: '50%',
                          bgcolor: 'primary.darkBlue',
                          p: 0.2,
                        }}
                      />
                      <CardMedia
                        component="img"
                        alt="logo"
                        src="/images/webileapps.svg"
                        sx={{
                          height: '28px',
                          width: '28px',
                          borderRadius: '50%',
                          bgcolor: 'primary.darkBlue',
                          p: 0.2,
                        }}
                      />
                      <CardMedia
                        component="img"
                        alt="logo"
                        src="/images/webileapps.svg"
                        sx={{
                          height: '28px',
                          width: '28px',
                          borderRadius: '50%',
                          bgcolor: 'primary.darkBlue',
                          p: 0.2,
                        }}
                      />
                    </Stack>
                    <Typography variant="body1">6 Members</Typography>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Stack sx={{ ...themeMixins.flexCenterSpaceBetween }}>
              <Tabs
                className="normal-tabs"
                value={value}
                onChange={handleChange}
                aria-label="basic-tabs"
              >
                <Tab label="Tasks" {...a11yProps(0)} />
                <Tab label="Team" {...a11yProps(1)} />
              </Tabs>
              <Button variant="contained">Add Task</Button>
            </Stack>
            <TabPanel value={value} index={0}>
              {sowTasks.length > 0 && (
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        bgcolor: 'primary.veryLightGrey',
                        '&.MuiTableRow-head .MuiTableCell-root': { padding: '16px' },
                      }}
                    >
                      <TableHeadCell>Task</TableHeadCell>
                      <TableHeadCell>Billable Type</TableHeadCell>
                      <TableHeadCell>Hours</TableHeadCell>
                      <TableHeadCell>Billable Amount</TableHeadCell>
                      <TableHeadCell>Costs</TableHeadCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sowTasks.map((sowTask) => (
                      <TableRow key={sowTask.id}>
                        <TableCell>
                          <Typography variant="h3" sx={{ color: 'secondary.main' }}>
                            {sowTask.task.name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1" fontSize={16}>
                            {sowTask.billable ? 'Billable' : 'Non - Billable'}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h3" sx={{ color: 'secondary.main' }}>
                            0:00
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h3" sx={{ color: 'secondary.main' }}>
                            ---
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h3" sx={{ color: 'primary.darkBlue' }}>
                            ---
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow sx={{ '& .MuiTableCell-root': { borderBottom: 0 } }}>
                      <TableCell>
                        <Typography variant="h3" sx={{ color: '#000' }}>
                          Total
                        </Typography>
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <Typography variant="h3" sx={{ color: '#000' }}>
                          0:00
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h3" sx={{ color: '#000' }}>
                          ---
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h3" sx={{ color: '#000' }}>
                          ---
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      bgcolor: 'primary.veryLightGrey',
                      '&.MuiTableRow-head .MuiTableCell-root': { padding: '16px' },
                    }}
                  >
                    <TableHeadCell>Employee</TableHeadCell>
                    <TableHeadCell>Role</TableHeadCell>
                    <TableHeadCell>Hours</TableHeadCell>
                    <TableHeadCell>Billable Amount</TableHeadCell>
                    <TableHeadCell>Costs</TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Button variant="text" sx={{ p: 0 }}>
                        Rajiv Narayana
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">Chief Architect</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h3" sx={{ color: 'secondary.main' }}>
                        0:00
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h3" sx={{ color: 'secondary.main' }}>
                        ---
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h3" sx={{ color: 'primary.darkBlue' }}>
                        ---
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ '& .MuiTableCell-root': { borderBottom: 0 } }}>
                    <TableCell>
                      <Typography variant="h3" sx={{ color: '#000' }}>
                        Total
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <Typography variant="h3" sx={{ color: '#000' }}>
                        0:00
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h3" sx={{ color: '#000' }}>
                        ---
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h3" sx={{ color: '#000' }}>
                        ---
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TabPanel>
          </Box>
        </Box>
      )}
    </>
  );
}
