/* eslint-disable no-unused-vars */
import React from 'react';
import { ButtonPrimary } from '../forms/Button';
import { Formik, Form, FormikState } from 'formik';
import FormTextInput from '../forms/FormTextInput';
import * as Yup from 'yup';
import { Box } from '@mui/system';
import FormDatePicker from '../forms/FormDatePicker';
import FormSelect from '../forms/FormSelect';
import { Divider } from '@mui/material';
import { UserPayload } from '../../redux-store/types/users';
import { DEPARTMENTS, SUB_DEPARTMENTS, BLOOD_GROUPS } from './constants';
import DesignationsSelect from './FormDesignationsSelect';

const Schema = Yup.object().shape({
  name: Yup.string().min(2, 'Too Short!').required('Required Name!'),
  email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
  designation: Yup.string().min(2, 'Too Short!').required('Required designation!'),
  joining_date: Yup.date(),
  department: Yup.string().min(2, 'Too short'),
  subDepartment: Yup.string().min(2, 'Too short'),
  additionalDetails: Yup.object().shape({
    type: Yup.string().min(2, 'Too short'),
    empId: Yup.string().min(2, 'Too short'),
    phoneNumber: Yup.string().min(7, 'Too short').max(13, 'Too long'),
    pan: Yup.string().min(2, 'Too short'),
    bloodGroup: Yup.string(),
    address: Yup.string().min(7, 'Too short'),
    aadhar: Yup.string().matches(
      /^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$/,
      'Invalid aadhar number',
    ),
  }),
});

export type UserFormProps = {
  onSubmit: (values: UserPayload) => void;
  initialValues: UserPayload;
};

export default function UserForm(props: UserFormProps): JSX.Element {
  return (
    <Formik
      initialValues={props.initialValues}
      validateOnMount={false}
      validationSchema={Schema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        await props.onSubmit(values);
        resetForm(values as unknown as Partial<FormikState<UserPayload>>);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Divider sx={{ mt: 1 }}>Primary Details</Divider>
          <FormTextInput label="Name" name="name" type="text" placeholder="Name" />
          <FormTextInput label="Email" name="email" type="email" placeholder="Email" />
          <DesignationsSelect label="Designation" name="designation" />
          <Divider sx={{ mt: 2 }}>Employment Details</Divider>
          <FormDatePicker label="Joining Date" name="joining_date" />
          <FormSelect
            label="Employment Type"
            name="additionalDetails.type"
            options={[
              { value: 'FullTime', label: 'Full Time' },
              { value: 'Intern', label: 'Intern' },
              { value: 'Contractor', label: 'Contractor' },
              { value: 'PartTime', label: 'Part Time' },
            ]}
          ></FormSelect>
          <FormSelect
            name="department"
            label="Department"
            options={DEPARTMENTS.map((department) => ({
              label: department,
              value: department,
            }))}
          />
          <FormSelect
            name="subDepartment"
            label="Sub Department"
            options={SUB_DEPARTMENTS.map((subDepartment) => ({
              label: subDepartment,
              value: subDepartment,
            }))}
          />
          <FormTextInput
            label="Employee Id"
            name="additionalDetails.empId"
            type="text"
            placeholder="Employee Id"
          />
          <Divider sx={{ mt: 2 }}>Contact Details</Divider>
          <FormTextInput
            label="Phone Number"
            name="additionalDetails.phoneNumber"
            type="text"
            placeholder="Phone Number"
          />
          <FormDatePicker label="Date of birth" name="additionalDetails.dob" />
          <FormSelect
            label="Blood Group"
            name="additionalDetails.bloodGroup"
            type="text"
            placeholder="Blood Group"
            options={BLOOD_GROUPS.map((bloodGroup) => ({
              label: bloodGroup,
              value: bloodGroup,
            }))}
          />
          <FormTextInput
            label="Address"
            name="additionalDetails.address"
            multiline
            rows={3}
            type="text"
            placeholder="Address"
          />
          <FormTextInput
            label="Personal Email"
            name="additionalDetails.personalEmail"
            type="email"
          />
          <FormTextInput
            label="Emergency Contact Phone Number"
            name="additionalDetails.emergencyContact"
            type="text"
          />
          <FormTextInput label="Father's Name" name="additionalDetails.fatherName" type="text" />
          <FormTextInput label="Spouse's Name" name="additionalDetails.spouseName" type="text" />

          <Divider sx={{ mt: 2 }}>Finance Details</Divider>
          <FormTextInput
            label="Pan No"
            name="additionalDetails.pan"
            type="text"
            placeholder="Pan No"
          />
          <FormTextInput
            label="AADHAR No"
            name="additionalDetails.aadhar"
            type="text"
            placeholder=""
          />
          <Divider sx={{ mt: 2 }} />
          <Box sx={{ my: 1 }} display="flex" justifyContent="flex-end">
            <ButtonPrimary fullWidth={false} variant="outlined" disabled={isSubmitting}>
              Submit
            </ButtonPrimary>
          </Box>
        </Form>
      )}
    </Formik>
  );
}
