import { Box } from '@mui/material';
import { Location } from 'history';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router';
import { DrawerRouterLayout } from '../common/layouts';
import EmployeeDetails from '../delivery/resources/Employee';
import ProjectSummary from './sows/sow';
// import TaskReports from './tasks';
import TeamReports from './team/team';

export default function ReportsRouter(): JSX.Element {
  const { url } = useRouteMatch();
  const location = useLocation<{ background: Location }>();
  const background = location.state && location.state.background;
  return (
    <>
      <Switch location={background || location}>
        <Route path={`${url}/sow`} component={ProjectSummary} />
        {/* <Route path={`${url}/tasks`} component={TaskReports} /> */}
        <Route path={`${url}/team/:id`} component={EmployeeDetails} />
        <Route path={`${url}/team`} component={TeamReports} />
      </Switch>
      <DrawerRouterLayout open={!!background} anchor="right">
        <Box role="presentation">
          <Switch>
            <Route path={`${url}/team/:id`} component={EmployeeDetails} />
          </Switch>
        </Box>
      </DrawerRouterLayout>
    </>
  );
}
