import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import { RootState } from '../../../redux-store/reducers';
import { ProjectState } from '../store/projectReducer';
import { getPhases, getProjects } from '../actions';
import { OnlyIfPermission, TableBodyCell, TableCellText, TableHeadCell } from '../../common';
import { PaginationLayout } from '../../time-tracking/approve/CustomLayouts';
import ProjectFilters from './Filters';
import { PROJECT_SEARCH_OPTIONS } from './constants';
import { Phase } from '../types';

export default function ProjectList(): JSX.Element {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const location = useLocation();
  const [showProgress, setShowProgress] = useState(false);
  const [phasesList, setPhasesList] = useState<Array<Phase>>();

  const { projects, searchOptions } = useSelector<RootState, ProjectState>(
    (state) => state.delivery.projects,
  );

  useEffect(() => {
    async function getProjectsList() {
      try {
        setShowProgress(true);
        await dispatch(getProjects(searchOptions));
      } catch (error) {
        console.log(error);
      } finally {
        setShowProgress(false);
      }
    }
    getProjectsList();
  }, [searchOptions]);
  useEffect(() => {
    async function getPhasesList() {
      try {
        setShowProgress(true);
        const phases_ = await dispatch(getPhases());
        setPhasesList(phases_ as unknown as Array<Phase>);
      } catch (error) {
        console.log(error);
      } finally {
        setShowProgress(false);
      }
    }
    getPhasesList();
  }, []);

  const handlePageSizeChange = (pageSize: number) => {
    dispatch({
      type: PROJECT_SEARCH_OPTIONS,
      searchOptions: {
        ...searchOptions,
        limit: pageSize,
      },
    });
  };

  return (
    <Box sx={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
      <Box
        sx={{
          width: '100%',
          zIndex: '999',
          px: 2,
          flex: '0 1 auto',
          position: 'relative',
        }}
      >
        <Stack flexDirection="row" alignItems="center" justifyContent="space-between" pb={2}>
          <Typography variant="h2">Clients</Typography>
          <Grid item>
            <OnlyIfPermission name="delivery:projects:create">
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={{ pathname: `${url}/create` }}
                fullWidth={false}
              >
                Add Client
              </Button>
            </OnlyIfPermission>
          </Grid>
        </Stack>
        <Divider
          sx={{
            width: 'calc(100% + 64px)',
            ml: -4,
          }}
        />
        <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
          <ProjectFilters />
        </Stack>
        <Divider
          sx={{
            width: 'calc(100% + 64px)',
            ml: -4,
          }}
        />
        {showProgress && <LinearProgress />}
      </Box>
      <Box sx={{ flex: '1 1 auto', overflow: 'auto' }}>
        <TableContainer
          sx={{
            p: 0,
          }}
        >
          <Table aria-label="projects table">
            <TableHead>
              <TableRow
                sx={{
                  '& .MuiTableCell-root': {
                    '&:first-of-type': { borderLeft: 0 },
                    '&:last-child': { borderRight: 0 },
                  },
                }}
              >
                <TableHeadCell sx={{ borderLeft: 'none' }}>
                  <TableCellText
                    text="Client Name"
                    sx={{ fontWeight: 700, textAlign: 'left' }}
                    showDropDown={true}
                  />
                </TableHeadCell>
                <TableHeadCell>
                  <TableCellText text="Projects" />
                </TableHeadCell>
                <TableHeadCell>
                  <TableCellText text="About" />
                </TableHeadCell>
                <TableHeadCell sx={{ borderLeft: 'none' }}>
                  <TableCellText text="Action" />
                </TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                '& .MuiTableCell-root': {
                  '&:first-of-type': { borderLeft: 0 },
                  '&:last-child': { borderRight: 0 },
                },
              }}
            >
              {projects.map((project, index) => (
                <TableRow key={project.id}>
                  <TableBodyCell sx={{ borderLeft: 'none', width: '30%' }}>
                    <Button
                      component={Link}
                      to={{
                        pathname: `${url}/${project.id}`,
                        state: {
                          background: location,
                          phaseDetails: phasesList?.filter(
                            (phase) => phase.project.id === project.id,
                          ),
                        },
                      }}
                      sx={{ textTransform: 'none', justifyContent: 'space-between' }}
                    >
                      <Typography sx={{ px: 1 }}>{index + 1}.</Typography>
                      <img src="/images/company_logo.svg" alt="logo" />
                      <Typography sx={{ ml: 3 }} color="primary.blueBg">
                        {project.clientName}
                      </Typography>
                    </Button>
                  </TableBodyCell>
                  <TableBodyCell sx={{ width: '40%' }}>
                    <>
                      <Chip
                        label={project.name}
                        icon={<ChipTag />}
                        sx={{
                          '& .MuiChip-label': {
                            px: 1,
                          },
                        }}
                      />
                    </>
                  </TableBodyCell>
                  <TableBodyCell sx={{ width: '25%' }}>{project.description}</TableBodyCell>
                  <TableBodyCell>
                    <OnlyIfPermission name="delivery:projects:update">
                      <Button
                        component={Link}
                        to={{
                          pathname: `${url}/update/${project.id}`,
                        }}
                      >
                        <img src="/images/updateIcon.svg" alt="Edit" />
                      </Button>
                    </OnlyIfPermission>
                  </TableBodyCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <PaginationLayout
        totalPageCount={10}
        // onPageChange={handlePageChange as unknown as (page: number) => void}
        onPageSizeChange={handlePageSizeChange as unknown as (pageSize: number) => void}
      />
    </Box>
  );
}

export function ChipTag(): JSX.Element {
  return (
    <>
      <img
        src="/images/chiptag.svg"
        alt="tag"
        style={{
          paddingLeft: '8px',
        }}
      />
    </>
  );
}
