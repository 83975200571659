import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import { generatePath, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import TrackWeek from './TrackWeek';
import { formatDateRange } from '../../../utils/date';
import React, { MouseEvent, MouseEventHandler, SyntheticEvent, useEffect, useState } from 'react';
import TrackDay from './TrackDay';
import { useExtractParams } from '../constants';
import { Employee, RelatedType, SOWType, Timelog, Timesheet } from '../types';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import {
  createTimesheet,
  getMySowsWithTasks,
  getTeamMembers,
  getTimeLogs,
  getTimesheets,
  submitTimeSheetByEmployeeId,
} from '../actions';
import FilteredAutocomplete from '../../forms/Autocomplete/filtered-autocomplete';
import { BasicDatePicker, TimesheetStatusChip } from '../common';

//TODO: Handle invalid route params.

export default function TimeTrack(): JSX.Element {
  const history = useHistory();
  const [showLoading, setShowLoading] = useState(false);
  const [otherEmployeeName, setOtherEmployeeName] = useState<string | null>(null);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    employeeId,
    isEditingOwnTimesheet,
    isCurrentWeek,
    isCurrentDay,
    date,
    nextUrl,
    prevUrl,
    weekStart,
    weekEnd,
    weekUrl,
    dayUrl,
    path,
    mode,
  } = useExtractParams();
  const [timesheet, setTimesheet] = useState<Timesheet>();
  const [timelogs, setTimelogs] = useState<Array<Timelog>>();
  const [sows, setSOWs] = useState<Array<SOWType>>();
  const [users, setUsers] = useState<Array<Employee>>([]);
  const [initialShowAddEntryDialog, setInitialShowAddEntryDialog] = useState(false);
  const handleAddEntryButtonClick: MouseEventHandler = (event: MouseEvent) => {
    event.preventDefault();
    setInitialShowAddEntryDialog(true);
  };
  const TrackDayComponent = () => {
    return (
      <TrackDay
        timesheet={timesheet}
        timelogs={timelogs}
        sows={sows}
        setTimelogs={setTimelogs}
        initialShowAddEntryDialog={initialShowAddEntryDialog}
        setInitialShowAddEntryDialog={setInitialShowAddEntryDialog}
      />
    );
  };
  const TrackDayComponentMemoized = React.memo(TrackDayComponent);
  const handleEmployeeChange = (event: SyntheticEvent, value: RelatedType) => {
    if (value == null) {
      return;
    }
    if (value.id) {
      const url = generatePath(path, {
        mode,
        year: weekStart.getFullYear().toString(),
        month: (weekStart.getMonth() + 1).toString(),
        day: weekStart.getDate().toString(),
        employeeId: value.id,
      });
      history.push(url);
    }
  };

  async function submitTimesheet(event: SyntheticEvent) {
    event.preventDefault();
    if (timesheet) {
      const answer = confirm('Are you sure you want to submit this timesheet?');
      if (!answer) {
        return;
      }
      setShowLoading(true);
      try {
        const t = await dispatch(
          submitTimeSheetByEmployeeId({
            employeeId: employeeId,
            startDate: weekStart,
            endDate: weekEnd,
          }),
        );
        setTimesheet(t as unknown as Timesheet);
        enqueueSnackbar('Timesheet submitted for approval', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar((error as Error).message, { variant: 'error' });
      } finally {
        setShowLoading(false);
      }
    }
  }
  useEffect(() => {
    setTimesheet(undefined);
    setTimelogs(undefined);
    async function fetchTimeSheetForWeek() {
      try {
        setShowLoading(true);
        const [members, logs, resp, sowsWithTasks] = await Promise.all([
          dispatch(getTeamMembers(weekStart)),
          dispatch(getTimeLogs(employeeId, weekStart)),
          dispatch(getTimesheets({ employee: { id: employeeId, name: '' }, startDate: weekStart })),
          dispatch(getMySowsWithTasks(employeeId, weekStart)),
        ]);
        setUsers(members as unknown as Array<Employee>);
        setTimelogs(logs as unknown as Array<Timelog>);
        setSOWs(sowsWithTasks as unknown as Array<SOWType>);

        const sheets = (resp as unknown as { timesheets: Array<Timesheet> }).timesheets;
        if (sheets.length > 0) {
          const sheet = sheets[0];
          if (!isEditingOwnTimesheet) {
            setOtherEmployeeName(sheet.employee.name);
          }
          setTimesheet(sheet);
          return;
        }

        if (weekStart.getTime() < Date.now()) {
          const createdTimesheet = await dispatch(
            createTimesheet({ employeeId, startDate: weekStart }),
          );
          setTimesheet(createdTimesheet as unknown as Timesheet);
        }
      } catch (err) {
        enqueueSnackbar((err as Error).message, { variant: 'error' });
      } finally {
        setShowLoading(false);
      }
    }
    setTimesheet(undefined);
    fetchTimeSheetForWeek();
  }, [employeeId, weekStart.toISOString()]);
  useEffect(() => {
    setOtherEmployeeName(null);
  }, [employeeId]);

  function handleDatePickerDateChange(d: Date | null) {
    if (d) {
      const date = new Date(d);
      date.setUTCHours(12, 0, 0, 0);
      const url = generatePath(path, {
        mode,
        year: date.getFullYear().toString(),
        month: (date.getMonth() + 1).toString(),
        day: date.getDate().toString(),
        employeeId,
      });
      history.push(url);
    }
  }
  const theme = useTheme();
  return (
    <>
      <Stack pb={2} direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h2">Track Time</Typography>
      </Stack>
      <Divider />
      {otherEmployeeName && (
        <>
          <Alert severity="info" sx={{ alignItems: 'center' }}>
            <Typography variant="body2" component="span">
              You are now editing {otherEmployeeName}&apos;s timesheet.&nbsp;
            </Typography>
            <Link to={generatePath(path, { mode })}>Resume editing own timesheet</Link>
          </Alert>
        </>
      )}
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        pb={2}
        sx={{
          pt: [2, 4],
          '.date-toggle-arrows': {
            '&.MuiToggleButtonGroup-root': {
              borderRadius: '2px 0 0 2px',
              borderRight: 0,
              '& .MuiToggleButton-root': {
                minWidth: 30,
                borderRight: '1px solid #BEBFC6',
                padding: '10px 5px',
                borderRadius: 0,
                '& svg': {
                  height: '14px',
                },
                '&:last-child': {
                  borderRight: 0,
                },
              },
            },
          },
          display: ['block', 'flex'],
        }}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            flexGrow: 1,
            mb: 1,
          }}
        >
          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              width: ['100%', 'auto'],
            }}
          >
            <ToggleButtonGroup color="primary" className="date-toggle-arrows">
              <ToggleButton value="prev" component={Link} to={prevUrl} size="small">
                <ArrowBackIosNewIcon fontSize="small" />
              </ToggleButton>
              <ToggleButton value="next" component={Link} to={nextUrl} size="small">
                <ArrowForwardIosIcon fontSize="small" />
              </ToggleButton>
            </ToggleButtonGroup>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: 'center',
                justifyContent: 'space-between',
                border: '1px solid #BEBFC6',
                borderRadius: '0 2px 2px 0',
                height: 34,
                padding: '7px 20px',
                width: ['100%', 'auto'],
              }}
            >
              {mode === 'week' && (
                <>
                  {isCurrentWeek && (
                    <Typography
                      variant="h4"
                      sx={{
                        display: ['none', 'block'],
                      }}
                    >
                      This week:{' '}
                    </Typography>
                  )}
                  <Typography variant="h4" component="span">
                    {formatDateRange(weekStart, weekEnd)}
                  </Typography>
                </>
              )}
              {mode === 'day' && (
                <>
                  {isCurrentDay && <Typography variant="h4">Today: </Typography>}
                  <Typography variant="h4" component="span">
                    {date.toLocaleDateString(undefined, {
                      weekday: 'long',
                      month: 'short',
                      day: 'numeric',
                    })}
                  </Typography>
                </>
              )}
              <BasicDatePicker value={date} onChange={handleDatePickerDateChange} />
            </Stack>
          </Stack>
        </Stack>
        {showLoading && <CircularProgress size={20} />}
        {timesheet && (
          <Stack
            sx={{
              margin: ['15px 0 !important', 0],
              height: ['32px', 'auto'],
              bgcolor: ['primary.skyBlueBg', 'primary.white'],
              alignItems: ['center', 'unset'],
              justifyContent: ['center', 'unset'],
              borderRadius: ['16px', 0],
              '& .MuiChip-root': {
                width: ['100%', 'auto'],
              },
            }}
          >
            <TimesheetStatusChip variant="text" status={timesheet.status} />
          </Stack>
        )}
        {users.length > 0 && (
          <FilteredAutocomplete<Employee>
            options={users}
            value={users.find((user) => user.id === employeeId)}
            label="Team Members"
            onChange={handleEmployeeChange}
            sx={{
              padding: '5px 10px',
              fontSize: '14px',
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ...(theme.mixins as any).standardBorder,
              borderRadius: '5px',
              '&.Mui-selected': {
                borderRadius: '5px !important',
                backgroundColor: 'rgba(217, 217, 217, 0.22)',
                color: 'primary.darkBlue',
                fontWeight: 400,
                '&:hover': {
                  backgroundColor: 'rgba(217, 217, 217, 0.4)',
                },
              },
              ml: ['0 !important', '16px !important'],
              width: ['50%', 'auto'],
            }}
            extraPopperStyles={{ maxWidth: '350px' }}
            hint="Search for an employee"
          />
        )}
        <ToggleButtonGroup value={mode} color="primary">
          <ToggleButton value="day" component={Link} to={dayUrl}>
            Day
          </ToggleButton>
          <ToggleButton value="week" component={Link} to={weekUrl}>
            Week
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      {mode === 'day' && (
        <>
          {timesheet && timesheet.status !== 'APPROVED' && (
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
              sx={{
                my: [1, 3],
              }}
            >
              <>
                <Button
                  variant="text"
                  color="secondary"
                  onClick={handleAddEntryButtonClick}
                  sx={{
                    p: ['10px', 0],
                    '&:hover,&:focus': { bgcolor: ['success.main', 'primary.white'] },
                    '& .MuiButton-startIcon': { ml: 0 },
                    bgcolor: ['success.main', 'primary.white'],
                    textAlign: 'left',
                    height: [60, 'auto'],
                    '& .MuiTypography-root': {
                      color: ['primary.white', 'primary.darkBlue'],
                      fontSize: [16, 18],
                      lineHeight: ['20px', 1.167],
                    },
                  }}
                  startIcon={<img src="/images/plus-icon.svg" alt="plus icon" />}
                >
                  <Typography variant="h3">Add New Entry</Typography>
                </Button>
                <Button
                  disabled={showLoading}
                  variant="contained"
                  color="primary"
                  onClick={submitTimesheet}
                  sx={{
                    fontSize: [16, 14],
                    lineHeight: ['20px', 1.75],
                    height: [60, 'auto'],
                  }}
                >
                  {timesheet?.status === 'SUBMITTED' ? 'Resubmit Timesheet' : `Submit Timesheet`}
                </Button>
              </>
            </Stack>
          )}
          <TrackDayComponentMemoized />
        </>
      )}
      {mode === 'week' && (
        <TrackWeek
          timesheet={timesheet}
          timelogs={timelogs}
          sows={sows}
          setTimelogs={setTimelogs}
          submitTimesheet={submitTimesheet}
        />
      )}
    </>
  );
}
