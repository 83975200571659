import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
} from '@mui/material';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { RESET_SEARCH_OPTIONS, SET_SEARCH_OPTIONS } from '../../redux-store/types/asset';
import { TicketSearchParameters } from '../../redux-store/types/tickets';
import { Form } from 'formik';
//import FormTextInput from '../forms/FormTextInput';
import FormSelect from '../forms/FormSelect';
import { FormDateRangePicker } from '../forms/FormDateRangePicker';
import FormUsersPicker from '../forms/FormUsersPicker';
import { getCategories } from '../../redux-store/actions/tickets';

const schema = Yup.object().shape({
  createdAt: Yup.array().min(2).max(2).of(Yup.date().nullable(true)).required(),
  updatedAt: Yup.array().min(2).max(2).of(Yup.date().nullable(true)).required(),
  orderDirection: Yup.string().required('Sort Direction is required'),
});

export type SearchOptionDialogTypes = DialogProps;

export default function TicketsSearchOptions(props: SearchOptionDialogTypes): JSX.Element {
  const [categories, setCategories] = useState<Array<string>>([]);
  const formikRef = useRef<FormikProps<TicketSearchParameters>>(null);
  const dispatch = useDispatch();
  const searchOptions = useSelector<RootStateType, TicketSearchParameters>(
    (rootState) => rootState.tickets.searchOptions,
  );

  const handleApplyClick = (e: React.MouseEvent<HTMLElement>) => {
    if (formikRef.current) {
      formikRef.current.handleSubmit(e as unknown as FormEvent<HTMLFormElement>);
    }
  };

  const handleReset = () => {
    dispatch({ type: RESET_SEARCH_OPTIONS });
  };
  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setValues(searchOptions);
    }
  }, [searchOptions]);

  useEffect(() => {
    async function _getCategories() {
      const c = await dispatch(getCategories());
      setCategories(c as unknown as Array<string>);
    }
    _getCategories();
  }, []);

  return (
    <Dialog scroll="paper" {...props}>
      <DialogTitle>Search Options</DialogTitle>
      <DialogContent dividers>
        <Formik
          innerRef={formikRef}
          validationSchema={schema}
          initialValues={searchOptions}
          onSubmit={(values) => {
            dispatch({ type: SET_SEARCH_OPTIONS, searchOptions: values });
            props.onClose &&
              props.onClose(
                new Event('submit', { cancelable: true, bubbles: true }),
                'escapeKeyDown',
              );
          }}
          enableReinitialize
        >
          {() => (
            <Form>
              <Divider>Filters</Divider>
              <FormSelect
                name="category"
                label="Category"
                options={categories.map((category) => ({ value: category, label: category }))}
              />
              <FormSelect
                name="subcategory"
                label="Sub Category"
                options={[
                  {
                    label: 'Request Allocation',
                    value: 'requestdeAllocation',
                  },
                  {
                    label: 'Request DeAllocation',
                    value: 'requestdeAllocation',
                  },
                  {
                    label: 'Problem',
                    value: 'problem',
                  },
                  {
                    label: 'Appraisal',
                    value: 'appraisal',
                  },
                  {
                    label: 'Complaint',
                    value: 'complaint',
                  },
                ]}
              />
              <FormSelect
                name="status"
                label="Status"
                options={[
                  { label: 'Open', value: 'open' },
                  { label: 'Close', value: 'close' },
                ]}
              />
              <FormUsersPicker name="assignedTo" label="Assigned To" placeholder="Assigned to" />
              <Divider>Filters by Date</Divider>
              <FormDateRangePicker
                name="createdAt"
                startLabel="Create After"
                endLabel="Create Before"
              />
              <FormDateRangePicker
                name="updatedAt"
                startLabel="Updated After"
                endLabel="Updated Before"
              />
              <Divider>Sorting</Divider>
              <FormSelect
                name="orderBy"
                label="Sort By"
                options={[
                  { label: 'Created At', value: 'createdAt' },
                  { label: 'Updated At', value: 'updatedAt' },
                ]}
              />
              <FormSelect
                name="orderDirection"
                label="Sort Order"
                options={[
                  { label: 'Ascending', value: 'asc' },
                  { label: 'Descending', value: 'desc' },
                ]}
              />
              <Divider>Pagination</Divider>
              <FormSelect
                name="limit"
                label="Items per page"
                options={['10', '25', '50'].map((option) => ({ label: option, value: option }))}
              />
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReset} color="primary">
          Reset
        </Button>
        <Button onClick={handleApplyClick} variant="contained" color="primary">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}
