import {
  Box,
  Button,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { RootStateType } from '../../../redux-store/reducers';
import { formatShortDate } from '../../../utils/date';
import { CampaignsState } from '../store/campaigns-reducer';
import { getCampaigns } from './actions';
import { Link } from 'react-router-dom';

export default function CampaignsList(): JSX.Element {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [showProgress, setShowProgress] = useState(false);
  const campaigns = useSelector<RootStateType, CampaignsState>((state) => state.ats.campaigns);
  useEffect(() => {
    async function fetchAssets() {
      try {
        setShowProgress(true);
        await dispatch(getCampaigns());
      } catch (error) {
        enqueueSnackbar((error as Error).message, { variant: 'error' });
      } finally {
        setShowProgress(false);
      }
    }
    fetchAssets();
  }, []);

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
        <Typography variant="h4">Campaigns</Typography>
        <Button
          component={Link}
          to={{ pathname: `${url}/add`, state: { background: location } }}
          variant="outlined"
          fullWidth={false}
        >
          Create Campaign
        </Button>
      </Stack>
      {showProgress && <LinearProgress />}
      <TableContainer component={Paper}>
        <Table aria-label="campaigns list">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Position</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Description</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Start Date</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {campaigns.map((campaign) => (
              <TableRow key={campaign.id}>
                <TableCell>
                  <Button
                    to={{
                      pathname: `${url}/${campaign.id}`,
                      state: { background: location },
                    }}
                    component={RouterLink}
                  >
                    {campaign.name}
                  </Button>
                </TableCell>
                <TableCell>{campaign.position}</TableCell>
                <TableCell>{campaign.description}</TableCell>
                <TableCell>{formatShortDate(campaign.startDate)}</TableCell>
                <TableCell>
                  <Button
                    to={{
                      pathname: `${url}/update/${campaign.id}`,
                      state: { background: location },
                    }}
                    component={RouterLink}
                  >
                    Update
                  </Button>
                  <Button
                    to={{
                      pathname: `/admin/ats/candidates`,
                      state: { campaignId: campaign.id },
                    }}
                    component={RouterLink}
                  >
                    View Candidates
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
