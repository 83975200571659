import { combineReducers } from 'redux';
import { PermissionsList } from '../types';
import groupsReducer, { GroupsState } from './groups-reducer';
import permissionsReducer from './permissions-reducer';

export type PermissionsState = {
  groups: GroupsState;
  permissions: PermissionsList;
};
export default combineReducers({ groups: groupsReducer, permissions: permissionsReducer });
