import { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Box, LinearProgress } from '@mui/material';

import { onCreateHolidays } from '../../redux-store/actions/holidays';
import { HolidayPayload } from '../../redux-store/types/holidays';

import HolidayForm from './Forms';
import { TitleBar } from '../assets/components';
const initialValues: HolidayPayload = {
  name: '',
  date: new Date(),
};

function CreateHoliday(): JSX.Element {
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <TitleBar title="Create Holiday" />
      {showProgress && <LinearProgress />}
      <Box sx={{ p: 2 }}>
        <HolidayForm
          initialValues={initialValues}
          onSubmit={async (values: HolidayPayload): Promise<void> => {
            try {
              setShowProgress(true);
              await dispatch(onCreateHolidays(values));
              enqueueSnackbar('Holiday created successfully', { variant: 'success' });
              setTimeout(history.push.bind(null, '/holidays'), 1);
            } catch (error) {
              enqueueSnackbar((error as Error).message, { variant: 'error' });
            } finally {
              setShowProgress(false);
            }
          }}
        />
      </Box>
    </>
  );
}
export default CreateHoliday;
