import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Note } from '../types';
import { useSnackbar } from 'notistack';
import { Grid, LinearProgress } from '@mui/material';
import { formatShortDate } from '../../../utils/date';
import { TitleBar } from '../../assets/components';
import { getNotesById } from './actions';
import { DataValues } from '../components';

export default function NotesDetail(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [details, setDetails] = useState<Note>();
  const [showProgress, setShowProgress] = useState(false);

  useEffect(() => {
    async function getNotesValues() {
      try {
        setShowProgress(true);
        const resultValue = await dispatch(getNotesById(id));
        setDetails(resultValue as unknown as Note);
      } catch (error) {
        enqueueSnackbar((error as Error).message, {
          variant: 'error',
        });
      } finally {
        setShowProgress(false);
      }
    }
    getNotesValues();
  }, [id]);

  return (
    <>
      <TitleBar title="Notes Details" />
      {showProgress && <LinearProgress />}
      {details && (
        <Grid container sx={{ px: 2 }}>
          <DataValues label="Candidate" value={details.candidate.name} />
          <DataValues label="Note" value={details.note} />
          <DataValues label="Entered By" value={details.enteredBy.name} />
          <DataValues label="Entered At" value={formatShortDate(details.createdAt)} />
        </Grid>
      )}
    </>
  );
}
