import { AnyAction } from 'redux';
import {
  ADD_ASSET,
  Asset,
  AssetsSearchOptions,
  REMOVE_ASSET,
  RESET_SEARCH_OPTIONS,
  SET_ASSETS,
  SET_SEARCH_OPTIONS,
  SET_SEARCH_OPTIONS_TYPE,
  UPDATE_ASSET,
} from '../types/asset';
export interface AssetsState {
  assets: Array<Asset>;
  searchOptions: AssetsSearchOptions;
  searchOptionType: string;
}

export const unallocatedAssetsSearchOptions: AssetsSearchOptions = {
  type: '',
  serialNumber: '',
  active: true,
  allocationType: 'none',
  allocatedTo: null,
  orderBy: 'updatedAt',
  orderDirection: 'desc',
  createdAt: [null, null],
  allocatedAt: [null, null],
  updatedAt: [null, null],
  limit: 10,
  offset: 0,
};

export const inActiveAssetsSearchOptions: AssetsSearchOptions = {
  ...unallocatedAssetsSearchOptions,
  active: false,
  allocationType: 'any',

  orderBy: 'createdAt',
};

export const recentlyUpdatedAssetsSearchOptions: AssetsSearchOptions = {
  ...unallocatedAssetsSearchOptions,
  allocationType: 'any',
  orderBy: 'updatedAt',
};

export const recentlyAllocatedAssetsSearchOptions: AssetsSearchOptions = {
  ...unallocatedAssetsSearchOptions,
  allocationType: 'not-none',
  orderBy: 'allocatedAt',
};

export const searchOptionsType: Record<string, AssetsSearchOptions> = {
  unallocated: unallocatedAssetsSearchOptions,
  inactive: inActiveAssetsSearchOptions,
  recentlyUpdated: recentlyUpdatedAssetsSearchOptions,
  recentlyAllocated: recentlyAllocatedAssetsSearchOptions,
};

const initialState: AssetsState = {
  assets: [],
  searchOptions: unallocatedAssetsSearchOptions,
  searchOptionType: 'unallocated',
};

export default function assetsReducer(state = initialState, action: AnyAction): AssetsState {
  switch (action.type) {
    case ADD_ASSET:
      return {
        ...state,
        assets: [action.asset, ...state.assets],
      };
    case REMOVE_ASSET:
      return {
        ...state,
        assets: state.assets.filter((asset: Asset) => asset.id !== action.assetId),
      };
    case UPDATE_ASSET:
      return {
        ...state,
        assets: state.assets.map((asset: Asset) =>
          asset.id === action.asset.id ? { ...asset, ...action.asset } : asset,
        ),
      };
    case SET_ASSETS:
      return {
        ...state,
        assets: action.assets,
      };
    case SET_SEARCH_OPTIONS:
      return {
        ...state,
        searchOptions: { ...action.searchOptions },
        searchOptionType: 'custom',
      };
    case RESET_SEARCH_OPTIONS:
      return {
        ...state,
        searchOptions: { ...unallocatedAssetsSearchOptions },
        searchOptionType: 'unallocated',
      };
    case SET_SEARCH_OPTIONS_TYPE:
      return {
        ...state,
        searchOptionType: action.searchOptionType,
        searchOptions: {
          ...(searchOptionsType[action.searchOptionType] || unallocatedAssetsSearchOptions),
        },
      };
  }

  return state;
}
