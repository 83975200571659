import { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Box, LinearProgress } from '@mui/material';
import { CandidateFormBody } from '../types';
import { createNewCandidate } from './actions';
import CandidateForm from './forms';
import { TitleBar } from '../../assets/components';

const initialValues: CandidateFormBody = {
  status: 'applied',
  resume: '',
  name: '',
  email: '',
  phone: '',
  linkedin: '',
  currentSalary: '',
  expectedSalary: '',
  totalExperience: 0,
  relevantExperience: 0,
  campaign: { id: '' },
  noticePeriod: '',
  noticePeriodNegotiable: '',
  holdingAnyOffer: '',
  lastWorkingDay: '2022-05-01',
};

export default function CreateCandidate(): JSX.Element {
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return (
    <>
      <TitleBar title="New Candidate" />
      {showProgress && <LinearProgress />}
      <Box sx={{ p: 2 }}>
        <CandidateForm
          initialValues={initialValues}
          onSubmit={async (values: CandidateFormBody): Promise<void> => {
            try {
              setShowProgress(true);
              await dispatch(createNewCandidate(values));
              enqueueSnackbar('Candidate created successfully', { variant: 'success' });
              setTimeout(history.goBack, 1);
            } catch (error) {
              enqueueSnackbar((error as Error).message, { variant: 'error' });
            } finally {
              setShowProgress(false);
            }
          }}
        />
      </Box>
    </>
  );
}
