import { useEffect, useMemo, useState } from 'react';
import { useField } from 'formik';
import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import { onSearchUsers } from '../../../redux-store/actions/users';

export type AutocompleteUser = {
  id: string;
  name: string;
  email: string;
};

export type FormUsersPickerProps<T> = Omit<
  AutocompleteProps<T, boolean, boolean, boolean>,
  'renderInput' | 'options'
> & {
  name: string;
  disabled?: boolean;
  margin?: 'dense' | 'none';
  fullWidth?: boolean;
  label?: string;
};

function FormUsersPicker({
  name,
  fullWidth = true,
  disabled = false,
  margin = 'dense',
  label,
  ...rest
}: FormUsersPickerProps<AutocompleteUser>): JSX.Element {
  const [field, meta, { setValue }] = useField(name);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onChange, ...restField } = field;
  const dispatch = useDispatch();
  const errorText =
    meta.error && meta.touched
      ? typeof meta.error === 'string'
        ? meta.error
        : (Object.values(meta.error).pop() as string)
      : '';
  const hasError = !!errorText;
  const [inputValue, setInputValue] = useState('');
  const [users, setUsers] = useState<AutocompleteUser[]>([]);

  const handleInputChange = (event: React.SyntheticEvent, value: string) => {
    setInputValue(value);
  };

  //https://dmitripavlutin.com/react-throttle-debounce/
  const debouncedChangeHandler = useMemo(() => debounce(handleInputChange, 400), []);
  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, []);

  //Fetch results from the server for search term.
  useEffect(() => {
    let active = true;
    if (field.value) {
      setUsers([field.value]);
    }
    async function fetchUsersByText(text: string) {
      const users = await dispatch(onSearchUsers(text));
      if (!active) return;
      setUsers(users as unknown as Array<AutocompleteUser>);
    }
    inputValue.length > 2 ? fetchUsersByText(inputValue) : setUsers([]);
    return () => {
      active = false;
    };
  }, [field.value, inputValue]);
  return (
    <Autocomplete
      options={users}
      clearOnBlur
      onInputChange={debouncedChangeHandler}
      onChange={(event, value) => {
        setValue(value);
      }}
      noOptionsText="Start typing or try different term"
      disablePortal
      filterOptions={(x) => x}
      {...restField}
      {...rest}
      getOptionLabel={(option) => `${option.name} (${option.email})`}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth={fullWidth}
          margin={margin}
          label={label}
          disabled={disabled}
          error={hasError}
          helperText={errorText}
        />
      )}
    />
  );
}

export default FormUsersPicker;
