import { Box, Stack } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { TicketDetails } from '../../redux-store/types/tickets';
import { SubmitButton } from '../forms/Button';
import FormTextInput from '../forms/FormTextInput';

const Schema = Yup.object().shape({
  comment: Yup.string(),
});

export interface TicketCommentProp {
  onSubmit: (values: TicketDetails) => void;
  initialValues: TicketDetails;
}
//const [change, setOnChange] = useState('');

export default function Comment({ onSubmit, initialValues }: TicketCommentProp): JSX.Element {
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={async (values, { setSubmitting }) => {
          // await dispatch(updateTicket(title));
          await onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Stack sx={{ m: '10px' }}>
              <FormTextInput
                label="Comment"
                name="title"
                type="text"
                placeholder="Write Comment"
                multiline
                rows={2}
                disabled={isSubmitting}
              />
            </Stack>
            <Box sx={{ float: 'right', mt: '10px', mr: '10px' }}>
              <SubmitButton label="Comment" />
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
}
