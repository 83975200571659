import React from 'react';
import { useField } from 'formik';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Box } from '@mui/system';
import { TextField } from '@mui/material';
function FormDatePicker(
  props: React.RefAttributes<HTMLDivElement> & {
    name: string;
    label?: string;
    disabled?: boolean;
    fullWidth?: boolean;
  },
): JSX.Element {
  const [field, meta, { setValue }] = useField(props.name);
  const onChange = (date: Date | null) => {
    setValue(date);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        className="text-input"
        inputFormat="MM/dd/yyyy"
        {...field}
        {...props}
        value={field.value}
        onChange={onChange}
        renderInput={(params) => <TextField {...params} fullWidth={true} margin="dense" />}
      />
      {meta.touched && meta.error ? <Box sx={{ color: 'red' }}>{meta.error}</Box> : null}
    </LocalizationProvider>
  );
}

export default FormDatePicker;
