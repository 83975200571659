import { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Box, LinearProgress } from '@mui/material';

import { CampaignFormBody } from '../types';
import { createNewCampaign } from './actions';
import CampaignForm from './forms';
import { TitleBar } from '../../assets/components';

const dateAfterAMonth = new Date();
dateAfterAMonth.setMonth(dateAfterAMonth.getMonth() + 1);
const initialValues: CampaignFormBody = {
  name: '',
  description: '',
  status: 'active',
  position: '',
  startDate: new Date(),
  endDate: dateAfterAMonth,
};

export default function CreateCampaign(): JSX.Element {
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <TitleBar title="Create Campaign" />
      {showProgress && <LinearProgress />}
      <Box sx={{ p: 2 }}>
        <CampaignForm
          initialValues={initialValues}
          onSubmit={async (values: CampaignFormBody): Promise<void> => {
            try {
              setShowProgress(true);
              await dispatch(createNewCampaign(values));
              enqueueSnackbar('Campaign created successfully', { variant: 'success' });
              setTimeout(history.push.bind(null, '/admin/ats/campaigns'), 1);
            } catch (error) {
              enqueueSnackbar((error as Error).message, { variant: 'error' });
            } finally {
              setShowProgress(false);
            }
          }}
        />
      </Box>
    </>
  );
}
