import {
  Box,
  Typography,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  SelectProps,
  Select,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Grid,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { allCandidates, CandidatesState } from '../store/candidates-reducer';
import { getCandidates } from './actions';
import { RootStateType } from '../../../redux-store/reducers';
import { CANDIDATES_SEARCH_OPTIONS, SET_SEARCH_OPTIONS_TYPE } from '../types';
import CandidatesSearchOptions from './searchOptions';
import { ButtonPrimary } from '../../forms/Button';
import { getStatusLabel } from './constants';

export default function CandidatesList(): JSX.Element {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const location = useLocation<{ campaignId?: string }>();
  const [initialCampaignId, setInitialCampaignId] = useState(location.state?.campaignId);
  // console.log('initialCampaignId', initialCampaignId);
  const [showProgress, setShowProgress] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [openOptions, setOpenOptionsType] = useState(false);
  const { candidates, searchOptions, searchOptionType } = useSelector<
    RootStateType,
    CandidatesState
  >((state) => state.ats.candidates);

  useEffect(() => {
    dispatch({
      type: CANDIDATES_SEARCH_OPTIONS,
      searchOptions: { ...allCandidates, campaignId: initialCampaignId || 'any' },
    });
    setInitialCampaignId('any');
  }, []);

  useEffect(() => {
    async function fetchCandidates() {
      try {
        setShowProgress(true);
        await dispatch(getCandidates({ ...searchOptions }));
      } catch (error) {
        enqueueSnackbar((error as Error).message, { variant: 'error' });
      } finally {
        setShowProgress(false);
      }
    }
    // console.log('Search Options', searchOptions, initialCampaignId);
    (!initialCampaignId || initialCampaignId == 'any') && fetchCandidates();
  }, [searchOptions]);

  const handleOptionsTypeChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    if (value === 'custom') {
      setOpenOptionsType(true);
    } else {
      dispatch({ type: SET_SEARCH_OPTIONS_TYPE, searchOptionType: value });
    }
  };

  return (
    <Box>
      <CandidatesSearchOptions
        open={openOptions}
        onClose={() => {
          setOpenOptionsType(false);
        }}
      />
      <Typography variant="h4" sx={{ mb: 2 }}>
        Candidates
      </Typography>
      <Grid pb={2} spacing={2} container justifyContent="space-between">
        <Grid item>
          <Stack spacing={2} direction="row">
            <SearchOptionsSelect
              defaultValue={searchOptionType}
              onChange={handleOptionsTypeChange}
              autoWidth
            />
            <ButtonPrimary
              variant="outlined"
              onClick={() => {
                setOpenOptionsType(true);
              }}
            >
              Filters
            </ButtonPrimary>
          </Stack>
        </Grid>
        <Button
          component={Link}
          to={{ pathname: `${url}/add`, state: { background: location } }}
          variant="outlined"
          fullWidth={false}
        >
          Add Candidate
        </Button>
      </Grid>
      {showProgress && <LinearProgress />}
      <TableContainer component={Paper}>
        <Table aria-label="Candidates list">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Campaign</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Email</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Phone</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Total Experience</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Relevant</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {candidates.map((candidate) => (
              <TableRow key={candidate.id}>
                <TableCell>
                  <Button
                    to={{ pathname: `${url}/${candidate.id}`, state: { background: location } }}
                    component={RouterLink}
                    sx={{ textTransform: 'none' }}
                  >
                    {candidate.name}
                  </Button>
                </TableCell>
                <TableCell>{getStatusLabel(candidate.status)}</TableCell>
                <TableCell>{candidate.campaign?.name}</TableCell>
                <TableCell>{candidate.email}</TableCell>
                <TableCell>{candidate.phone}</TableCell>
                <TableCell>{candidate.totalExperience}</TableCell>
                <TableCell>{candidate.relevantExperience}</TableCell>
                <TableCell>
                  <Button
                    to={{
                      pathname: `${url}/${candidate.id}/notes`,
                      state: { background: location },
                    }}
                    component={RouterLink}
                    sx={{ textTransform: 'none' }}
                  >
                    View Notes
                  </Button>
                  <Button
                    to={{
                      pathname: `${url}/${candidate.id}/add-assessment`,
                      state: { background: location },
                    }}
                    component={RouterLink}
                    sx={{ textTransform: 'none' }}
                  >
                    Add Assessment
                  </Button>
                  <Button
                    to={{
                      pathname: `${url}/update/${candidate.id}`,
                      state: { background: location },
                    }}
                    component={RouterLink}
                    sx={{ textTransform: 'none' }}
                  >
                    Update
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function SearchOptionsSelect(props: SelectProps<string>) {
  return (
    <Select label="Quick Filters" variant="standard" {...props}>
      <MenuItem value="allCandidates">All Candidates</MenuItem>
      <MenuItem value="custom">Custom</MenuItem>
    </Select>
  );
}
