import { Box, Divider } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { UpdateUsersFormBody } from '../../redux-store/types/users';
import { SubmitButton } from '../forms/Button';
import FormUsersPicker from '../forms/FormUsersPicker';

const Schema = Yup.object().shape({
  manager: Yup.object()
    .nullable()
    .shape({
      id: Yup.string().required('ID is required'),
      name: Yup.string().required('Name is required'),
      email: Yup.string().required('Email is required'),
    }),
});

export interface UpdateManagerFormProp {
  onSubmit: (values: UpdateUsersFormBody) => void;
  initialValues: UpdateUsersFormBody;
}

export default function UpdateManagerForm({
  onSubmit,
  initialValues,
}: UpdateManagerFormProp): JSX.Element {
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={async (values, { setSubmitting }) => {
          await onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Divider>Current Manager</Divider>
            <FormUsersPicker label="Assign Manager" disabled={isSubmitting} name="manager" />
            <Box sx={{ float: 'right', mt: '10px' }}>
              <SubmitButton label="Submit" />
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
}
