import { Action, Timesheet, TimesheetSearchOptions, TimesheetSearchState } from '../types';
export const RESET_SEARCH_OPTIONS = 'RESET_SEARCH_OPTIONS';
export const SET_SEARCH_OPTIONS = 'SET_SEARCH_OPTIONS';
export const SET_SEARCH_OPTIONS_TYPE = 'SET_SEARCH_OPTIONS_TYPE';
export const CHANGE_PAGE_NUMBER = 'CHANGE_PAGE_NUMBER';
export const UPDATE_TIMESHEET = 'UPDATE_TIMESHEET';
export const SET_TIMESHEETS = 'SET_TIMESHEETS';

function getWeekStartForDate(_date: Date) {
  const d = new Date(_date);
  const day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  const weekStart = new Date(d.setDate(diff));
  weekStart.setUTCHours(12, 0, 0, 0);
  return weekStart;
}

export const commonOptions: TimesheetSearchOptions = {
  pageSize: 25,
  sortBy: 'date',
  sortDirection: 'desc',
};

export const allTimesheets: TimesheetSearchOptions = {
  ...commonOptions,
};

export const pendingTimesheets: TimesheetSearchOptions = {
  ...commonOptions,
  status: 'SUBMITTED',
};

export const pendingThisWeek: TimesheetSearchOptions = {
  ...commonOptions,
  status: 'SUBMITTED',
  startDate: [getWeekStartForDate(new Date()), null],
};

export const approvedThisWeek: TimesheetSearchOptions = {
  ...commonOptions,
  startDate: [getWeekStartForDate(new Date()), null],
  status: 'APPROVED',
};
export const notSubmittedThisWeek: TimesheetSearchOptions = {
  ...commonOptions,
  status: 'CREATED',
  startDate: [getWeekStartForDate(new Date()), null],
};

const getLastWeekStart = (): Date => {
  const d = new Date();
  d.setDate(d.getDate() - 7);
  return getWeekStartForDate(d);
};
const lastWeekDate = new Date();
lastWeekDate.setDate(new Date().getDate() - 7);

const pendingLastWeek: TimesheetSearchOptions = {
  ...pendingTimesheets,
  startDate: [getLastWeekStart(), null],
};

const initialState: TimesheetSearchState = {
  searchOptionType: 'allTimesheets',
  searchOptions: allTimesheets,
  page: 1,
  timesheets: [],
};

const searchOptionsMapping: { [k: string]: TimesheetSearchOptions } = {
  allTimesheets: allTimesheets,
  pendingThisWeek: pendingThisWeek,
  pendingTimesheets: pendingTimesheets,
  approvedThisWeek: approvedThisWeek,
  notSubmittedThisWeek: notSubmittedThisWeek,
  pendingLastWeek: pendingLastWeek,
};

export function reducer(state = initialState, action: Action): TimesheetSearchState {
  if (action.type === RESET_SEARCH_OPTIONS) {
    return initialState;
  }
  if (action.type === SET_SEARCH_OPTIONS) {
    return {
      ...state,
      searchOptions: action.searchOptions as TimesheetSearchOptions,
      searchOptionType: 'custom',
      page: 1,
    };
  }

  if (action.type === SET_SEARCH_OPTIONS_TYPE) {
    const options = searchOptionsMapping[action.searchOptionType as string] || state.searchOptions;
    return {
      ...state,
      searchOptionType: action.searchOptionType as string,
      searchOptions: options,
      page: 1,
    };
  }

  if (action.type === CHANGE_PAGE_NUMBER) {
    return {
      ...state,
      page: action.page as number,
    };
  }

  if (action.type === UPDATE_TIMESHEET) {
    return {
      ...state,
      timesheets: state.timesheets.map((timesheet) => {
        if (timesheet.id === (action.timesheet as Timesheet).id) {
          return action.timesheet as Timesheet;
        }
        return timesheet;
      }),
    };
  }

  if (action.type === SET_TIMESHEETS) {
    return {
      ...state,
      timesheets: action.timesheets as Timesheet[],
    };
  }

  return state;
}
