import { Box } from '@mui/material';
import { Location } from 'history';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router';
import { DrawerRouterLayout } from '../../common/layouts';
import EmployeeDetails from '../resources/Employee';
import CostUpdate from './CostUpdate';
import CostsList from './List';

export default function PhaseRouter(): JSX.Element {
  const { path } = useRouteMatch();
  const location = useLocation<{ background: Location }>();
  const background = location.state && location.state.background;
  return (
    <>
      <Switch location={background || location}>
        <Route path={`${path}/update/:id`} component={CostUpdate} />
        <Route exact path={path} component={CostsList} />
      </Switch>
      <DrawerRouterLayout open={!!background} anchor="right">
        <Box role="presentation">
          <Switch>
            <Route path={`${path}/update/:id`} component={CostUpdate} />
            <Route path={`${path}/:id`} component={EmployeeDetails} />
          </Switch>
        </Box>
      </DrawerRouterLayout>
    </>
  );
}
