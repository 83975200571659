import { Box, LinearProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { TitleBar } from '../../assets/components';
import { ProjectBody } from '../types';
import { getProjectById, updateProject } from '../actions';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import ProjectForm from './Form';

export default function UpdateProject(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const [showProgress, setShowProgress] = useState(false);
  const [project, setProject] = useState<ProjectBody>();
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    async function fetchProject() {
      try {
        setShowProgress(true);
        const _project = await dispatch(getProjectById(id));
        setProject(_project as unknown as ProjectBody);
      } catch (error) {
        enqueueSnackbar((error as Error).message, {
          variant: 'error',
        });
      } finally {
        setShowProgress(false);
      }
    }
    fetchProject();
  }, [id]);

  const onSubmit = async (values: ProjectBody) => {
    try {
      const { name, clientName, description } = values;
      const reqBody = {
        name: name,
        clientName: clientName,
        description: description,
        // websiteUrl: websiteUrl,
        // email: email,
        // phoneNo: phoneNumber,
        // address: address,
        // // logo,
        // url,
        // email,
        // phoneNo,
        // address,
      };
      setShowProgress(true);
      await dispatch(updateProject(reqBody, id));
      enqueueSnackbar('Client Updated Successfully', { variant: 'success' });
      setTimeout(history.goBack, 1);
    } catch (error) {
      enqueueSnackbar((error as Error).message, { variant: 'error' });
    } finally {
      setShowProgress(false);
    }
  };
  return (
    <>
      <TitleBar title="Update Client Information" />
      {showProgress && <LinearProgress />}
      {project && (
        <Box sx={{ m: 2 }}>
          <ProjectForm initialValues={project} onSubmit={onSubmit} />
        </Box>
      )}
    </>
  );
}
