import { Dialog } from '@mui/material';
import { Location } from 'history';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router';
import { TimeStoreProvider, useTimeStore } from './store/context-store';
import TimesheetDetail from './approve/TimesheetDetail';
import TimesheetsList from './approve/TimesheetsList';
import TimeTrack from './track/Track';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getTeamMembers } from './actions';
import { Employee } from './types';
import { SET_TEAM_USERS } from './store/team.reducer';
import ReportsRouter from './reports';

export default function Router(): JSX.Element {
  const { path } = useRouteMatch();
  const history = useHistory();
  const location = useLocation<{ background: Location }>();
  const background = location.state && location.state.background;
  return (
    <TimeStoreProvider>
      <TeamMembersLoader />
      <Switch location={background || location}>
        <Route
          path={`${path}/track/:mode?/:year?/:month?/:day?/:employeeId?`}
          component={TimeTrack}
        />
        <Route path={`${path}/sheets`} component={TimesheetsList} />
        <Route path={`${path}/reports`} component={ReportsRouter} />
        <Redirect exact path={path} to={`${path}/track`} />
        <Redirect exact path={`${path}/track`} to={`${path}/track/day`} />
      </Switch>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={!!background}
        onClose={() => {
          history.goBack();
        }}
        sx={{
          '& .MuiPaper-root': { width: '100%' },
          '& .MuiDialog-paper': {
            m: [0, '32px'],
            maxHeight: ['unset', 'calc(100% - 64px)'],
            height: ['100vh', 'unset'],
          },
        }}
      >
        <Switch>
          <Route path={`${path}/sheets/:timeSheetId`} component={TimesheetDetail} />
        </Switch>
      </Dialog>
    </TimeStoreProvider>
  );
}

function TeamMembersLoader(): JSX.Element {
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_rootState, dispatchToTimeStore] = useTimeStore();
  useEffect(() => {
    async function fetchMembers() {
      const _members = await dispatch(getTeamMembers(new Date()));
      dispatchToTimeStore({ type: SET_TEAM_USERS, users: _members as unknown as Array<Employee> });
    }
    fetchMembers();
  }, []);
  return <></>;
}
