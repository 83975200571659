/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Dispatch } from 'redux';
import { CALL_API } from '../middleware';
import { LoginPayload, LOGIN_SUCCESS, LOGOUT_SUCCESS } from '../types/login';

export const onLogin = (body: LoginPayload) => async (dispatch: Dispatch) => {
  try {
    const data = await dispatch({
      type: CALL_API,
      [CALL_API]: {
        url: `/users/admin/user-login`, //changed from /login to /user-login for all users
        method: 'POST',
        body,
      },
    });
    dispatch({
      type: LOGIN_SUCCESS,
      body: { ...data },
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const googleLogin = (token: string) => async (dispatch: Dispatch) => {
  try {
    const data = await dispatch({
      type: CALL_API,
      [CALL_API]: {
        url: `/users/users/google-login`,
        method: 'POST',
        body: { token },
      },
    });
    dispatch({
      type: LOGIN_SUCCESS,
      body: { ...data },
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const logout = () => ({
  type: LOGOUT_SUCCESS,
});
