/* eslint-disable no-unused-vars */
import { ButtonPrimary } from '../../forms/Button';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormSelect from '../../forms/FormSelect';
import { Box } from '@mui/system';
import { Stack, Typography } from '@mui/material';
const Schema = Yup.object().shape({
  leaveAssignTo: Yup.string().nullable(true).required('Select Assign To!'),
});

function AssignToForm(props: any) {
  return (
    <>
      <Box>
        <h3>Assign To</h3>
        <Formik
          initialValues={{
            id: props.data._id,
            leaveAssignTo: '',
          }}
          validateOnMount={false}
          validationSchema={Schema}
          onSubmit={async (values, { setSubmitting }) => {
            await props.onAssignToLeave(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form>
              <Box sx={{ display: 'flex', flexDirection: 'row', m: '10px' }}>
                <Typography component="span" sx={{ color: 'grey', mr: '5px' }}>
                  Employee:
                </Typography>
                <Typography component="span">{props.data.employee.name}</Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', m: '10px' }}>
                <Typography component="span" sx={{ color: 'grey', mr: '5px' }}>
                  Details:
                </Typography>
                <Typography component="span">{props.data.details}</Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', m: '10px' }}>
                <Typography component="span" sx={{ color: 'grey', mr: '5px' }}>
                  Start Date:
                </Typography>
                <Typography component="span">{props.data.startDateVal}</Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', m: '10px' }}>
                <Typography component="span" sx={{ color: 'grey', mr: '5px' }}>
                  End Date:
                </Typography>
                <Typography component="span">{props.data.endDateVal}</Typography>
              </Box>
              <Stack sx={{ m: '10px' }}>
                <FormSelect label="Assign To" name="leaveAssignTo" disabled={isSubmitting}>
                  <option value="">Select</option>
                  {/* Logged In managers list should be added here */}
                </FormSelect>
              </Stack>
              <Box sx={{ float: 'right', mt: '10px' }}>
                <ButtonPrimary fullWidth={false} variant="outlined" disabled={isSubmitting}>
                  Submit
                </ButtonPrimary>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
}
export default AssignToForm;
