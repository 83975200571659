import {
  Box,
  Button,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { RootStateType } from '../../../redux-store/reducers';
import { GroupsState } from '../store/groups-reducer';
import { getGroups } from './actions';
import { Link } from 'react-router-dom';

export default function GroupsList(): JSX.Element {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [showProgress, setShowProgress] = useState(false);
  const groups = useSelector<RootStateType, GroupsState>((state) => state.permissions.groups);
  useEffect(() => {
    async function fetchAll() {
      try {
        setShowProgress(true);
        await dispatch(getGroups({}));
      } catch (error) {
        enqueueSnackbar((error as Error).message, { variant: 'error' });
      } finally {
        setShowProgress(false);
      }
    }
    fetchAll();
  }, []);
  return (
    <Box>
      <Stack direction="row" spacing={2} sx={{ mb: 2 }} justifyContent="space-between">
        <Typography variant="h4">Groups</Typography>
        <Button
          component={Link}
          to={{ pathname: `${url}/add`, state: { background: location } }}
          variant="outlined"
          fullWidth={false}
        >
          Create Group
        </Button>
      </Stack>
      {showProgress && <LinearProgress />}
      <TableContainer component={Paper}>
        <Table aria-label="groups list">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Members</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Sub Groups</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Permissions</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groups.map((group) => (
              <TableRow key={group.id}>
                <TableCell>
                  <Button
                    to={{ pathname: `${url}/${group.id}`, state: { background: location } }}
                    component={RouterLink}
                    sx={{ textTransform: 'none' }}
                  >
                    {group.name}
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    to={{ pathname: `${url}/${group.id}`, state: { background: location } }}
                    component={RouterLink}
                    sx={{ textTransform: 'none' }}
                  >
                    {group.membersCount} members
                  </Button>
                  <Button
                    to={{
                      pathname: `${url}/${group.id}/add-member`,
                      state: { background: location },
                    }}
                    component={RouterLink}
                  >
                    <PersonAddIcon />
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    to={{ pathname: `${url}/${group.id}`, state: { background: location } }}
                    component={RouterLink}
                    sx={{ textTransform: 'none' }}
                  >
                    {group.subgroupsCount} groups
                  </Button>
                  <Button
                    to={{
                      pathname: `${url}/${group.id}/add-subgroup`,
                      state: { background: location },
                    }}
                    component={RouterLink}
                  >
                    <GroupAddIcon />
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    to={{
                      pathname: `${url}/${group.id}/permissions`,
                      state: { background: location },
                    }}
                    component={RouterLink}
                    sx={{ textTransform: 'none' }}
                  >
                    Manage Permissions
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    to={{
                      pathname: `${url}/update/${group.id}`,
                      state: { background: location },
                    }}
                    component={RouterLink}
                  >
                    Update
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
