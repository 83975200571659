import { LinearProgress, Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { TitleBar } from '../../assets/components';
import { AssessmentFormBody } from '../types';
import { getAssessmentbyId, updateAssessment } from './actions';
import AssessmentForm from './form';

export default function UpdateAssessment(): JSX.Element {
  const [details, setDetails] = useState<AssessmentFormBody>();
  const { id } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation<{ candidateId?: string }>();
  const { state } = location;
  const { candidateId } = state || {};
  const [showProgress, setShowProgress] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    async function getAssessmentDetails() {
      try {
        const result = await dispatch(getAssessmentbyId(id));
        setDetails(result as unknown as AssessmentFormBody);
      } catch (error) {
        console.log(error);
      }
    }
    getAssessmentDetails();
  }, [id]);
  return (
    <>
      <TitleBar title="Update Assessment" />
      {showProgress && <LinearProgress />}
      {details && (
        <Box sx={{ p: 2 }}>
          <AssessmentForm
            initialValues={details}
            onSubmit={async (values: AssessmentFormBody): Promise<void> => {
              try {
                setShowProgress(true);
                const { date, score, comment, interviewer, status, type } = values;
                const reqBody = {
                  candidate: { id: `${candidateId}` },
                  date: `${date}`,
                  score: `${score}`,
                  comment: `${comment}`,
                  interviewer: {
                    id: `${interviewer?.id}`,
                  },
                  status: `${status}`,
                  type: `${type}`,
                };
                await dispatch(updateAssessment(id, reqBody));
                setTimeout(history.push.bind(null, '../../assessments'), 1);
                enqueueSnackbar('Assessment updated successfully', { variant: 'success' });
              } catch (error) {
                enqueueSnackbar((error as Error).message, { variant: 'error' });
              } finally {
                setShowProgress(false);
              }
            }}
          />
        </Box>
      )}
    </>
  );
}
