import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Box, LinearProgress } from '@mui/material';
import { CandidateFormBody } from '../types';
import { updateCandidate, getCandidateDetail } from './actions';
import { TitleBar } from '../../assets/components';
import CandidateForm from './forms';
export default function UpdateCandidate(): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<{ id: string }>();

  const [candidate, setCandidate] = useState<CandidateFormBody>();
  const [showProgress, setShowProgress] = useState<boolean>(true);

  useEffect(() => {
    async function fetchCandidate() {
      try {
        setShowProgress(true);
        const _candidate = await dispatch(getCandidateDetail(id));
        setCandidate(_candidate as unknown as CandidateFormBody);
      } catch (error) {
        enqueueSnackbar((error as Error).message, {
          variant: 'error',
        });
      } finally {
        setShowProgress(false);
      }
    }
    fetchCandidate();
  }, [id]);
  return (
    <>
      <TitleBar title="Update Candidate" />
      {showProgress && <LinearProgress />}
      {candidate && (
        <Box sx={{ p: 2 }}>
          <CandidateForm
            initialValues={candidate}
            onSubmit={async (values: CandidateFormBody): Promise<void> => {
              try {
                setShowProgress(true);
                await dispatch(updateCandidate(id, values));
                setTimeout(history.push.bind(null, '../../candidates'), 1);
                enqueueSnackbar('Candidate updated successfully', { variant: 'success' });
              } catch (error) {
                enqueueSnackbar((error as Error).message, { variant: 'error' });
              } finally {
                setShowProgress(false);
              }
            }}
          />
        </Box>
      )}
    </>
  );
}
