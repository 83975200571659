import * as Yup from 'yup';

import { Form, Formik } from 'formik';
import { Box } from '@mui/material';

import FormTextInput from '../../forms/FormTextInput';
import { SubmitButton } from '../../forms/Button';

import { GroupFormBody } from '../types';

const Schema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
});
export type GroupFormProps = {
  onSubmit: (values: GroupFormBody) => void;
  initialValues: GroupFormBody;
};
export default function GroupForm({ onSubmit, initialValues }: GroupFormProps): JSX.Element {
  return (
    <Formik
      initialValues={initialValues}
      validateOnMount={false}
      validationSchema={Schema}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit(values);
        setSubmitting(false);
      }}
    >
      {() => (
        <Form>
          <FormTextInput label="Name" name="name" type="text" placeholder="Name" />
          <Box sx={{ m: '10px', float: 'right' }}>
            <SubmitButton label="Submit" />
          </Box>
        </Form>
      )}
    </Formik>
  );
}
