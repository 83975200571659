import * as Yup from 'yup';

import { Form, Formik } from 'formik';
import { Box, Stack } from '@mui/material';

import FormDatePicker from '../forms/FormDatePicker';
import FormTextInput from '../forms/FormTextInput';
import { SubmitButton } from '../forms/Button';

import { HolidayPayload } from '../../redux-store/types/holidays';

const Schema = Yup.object().shape({
  name: Yup.string().required('Required Name!'),
  date: Yup.date().required('Required date!'),
});
export type HolidayFormProps = {
  onSubmit: (values: HolidayPayload) => void;
  initialValues: HolidayPayload;
};
export default function HolidayForm({ onSubmit, initialValues }: HolidayFormProps): JSX.Element {
  return (
    <Formik
      initialValues={initialValues}
      validateOnMount={false}
      validationSchema={Schema}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Stack sx={{ m: '10px' }}>
            <FormTextInput
              label="Name"
              name="name"
              type="text"
              placeholder="Name"
              disabled={isSubmitting}
            />
          </Stack>
          <Stack sx={{ m: '10px' }}>
            <FormDatePicker label="Date" disabled={isSubmitting} name="date" />
          </Stack>
          <Box sx={{ m: '10px', float: 'right' }}>
            <SubmitButton label="Submit" />
          </Box>
        </Form>
      )}
    </Formik>
  );
}
