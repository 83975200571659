import { Box, LinearProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { createNewAsset } from '../../redux-store/actions/assets';
import { AssetFormBody } from '../../redux-store/types/asset';
import { TitleBar } from './components';
import AssetForm from './Form';

const initialValues: AssetFormBody = {
  type: '',
  active: true,
  serialNumber: '',
  dateOfPurchase: new Date(),
  description: '',
  allocatedTo: null,
  allocatedSince: null,
};
export default function CreateAsset(): JSX.Element {
  const [showProgress, setShowProgress] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <TitleBar title="Create New Asset" />
      {showProgress && <LinearProgress />}
      <Box sx={{ p: 2 }}>
        <AssetForm
          initialValues={initialValues}
          onSubmit={async (values) => {
            try {
              setShowProgress(true);
              await dispatch(createNewAsset(values));
              enqueueSnackbar('Asset created successfully', { variant: 'success' });
              setTimeout(history.push.bind(null, '/admin/assets'), 1);
            } catch (error) {
              enqueueSnackbar((error as Error).message, { variant: 'error' });
            } finally {
              setShowProgress(false);
            }
          }}
        />
      </Box>
    </>
  );
}
