import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import AssessmentRouter from './assessment/router';
import CampaignsRouter from './campaigns/router';
import CandidatesRouter from './candidates/router';
import NotesRouter from './notes/router';

export default function ATSRouter(): JSX.Element {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/candidates`} component={CandidatesRouter} />
      <Route path={`${path}/campaigns`} component={CampaignsRouter} />
      <Route path={`${path}/assessments`} component={AssessmentRouter} />
      <Route path={`${path}/notes`} component={NotesRouter} />

      <Route exact path={path} render={() => <Redirect to={`${path}/campaigns`} />} />
    </Switch>
  );
}
