import { AppBar, Tab, Tabs, Toolbar } from '@mui/material';
import { Redirect, Route, Switch, useLocation, useParams, useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import CreateAssessment from '../assessment/create';
import CandidateAssessment from './assessment';
import CandidateDetail from './detail';
import CandidateNotes from './notes';

export default function DetailRouter(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const location = useLocation<{ background?: Location }>();
  const history = useHistory();
  const { path } = useRouteMatch();
  const match = useRouteMatch<{ slug: string }>(`${path}/:slug`);
  const slug = match?.params?.slug || 'details';

  function handleTabClick(route: string) {
    if (location.state?.background) {
      history.replace(`/admin/ats/candidates/${id}/${route}`, {
        background: location.state.background,
      });
    } else {
      history.replace(`/admin/ats/candidates/${id}/${route}`);
    }
  }

  return (
    <>
      <AppBar position="sticky" sx={{ backgroundColor: 'white' }} elevation={1}>
        <Toolbar>
          <Tabs value={slug}>
            <Tab
              value="details"
              label="Details"
              component="a"
              onClick={handleTabClick.bind(null, 'details')}
            />
            <Tab
              value="notes"
              label="Notes"
              component="a"
              onClick={handleTabClick.bind(null, 'notes')}
            />
            <Tab
              value="assessments"
              label="Assessments"
              component="a"
              onClick={handleTabClick.bind(null, 'assessments')}
            />
          </Tabs>
        </Toolbar>
      </AppBar>
      <Switch location={location}>
        <Route path={`${path}/details`} component={CandidateDetail} />
        <Route path={`${path}/notes`} component={CandidateNotes} />
        <Route path={`${path}/assessments/addAssessment`} component={CreateAssessment} />
        <Route path={`${path}/assessments`} component={CandidateAssessment} />
        <Redirect
          exact
          path={`${path}`}
          to={
            location.state
              ? {
                  pathname: `${path}/details`,
                  state: { background: location.state.background },
                }
              : `${path}/details`
          }
        />
      </Switch>
    </>
  );
}
