import { Box, Stack } from '@mui/material';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { getCategories, getSubCategories } from '../../redux-store/actions/tickets';
import { ButtonPrimary } from '../forms/Button';
import FormSelect from '../forms/FormSelect';
import FormTextInput from '../forms/FormTextInput';

const Schema = Yup.object().shape({
  category: Yup.string().required('Category is required'),
  subcategory: Yup.string().required('Subcategory is required'),
  title: Yup.string().required('Title is required'),
  description: Yup.string(),
});

export interface TicketFormBody {
  category: string;
  subcategory: string;
  title: string;
  description: string;
}

export interface TicketFormProp {
  onSubmit: (values: TicketFormBody) => void;
  initialValues: TicketFormBody;
}

export default function TicketForm({ onSubmit, initialValues }: TicketFormProp): JSX.Element {
  const [categories, setCategories] = useState<Array<string>>([]);
  const [subcategories, setSubcategories] = useState<Array<string>>([]);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const dispatch = useDispatch();

  // responsible for fetching the categories from the api and setting it
  // values set goes in categories

  useEffect(() => {
    async function _getCategories() {
      const c = await dispatch(getCategories());
      setCategories(c as unknown as Array<string>);
    }
    _getCategories();
  }, []);

  // responsible for fetching the subcategories from the api and setting it
  // values set goes in subcategories

  useEffect(() => {
    async function _getSubcategories(category: string) {
      const s = await dispatch(getSubCategories(category));
      setSubcategories(s as unknown as Array<string>);
    }
    selectedCategory && _getSubcategories(selectedCategory);
  }, [selectedCategory]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={async (values, { setSubmitting }) => {
          await onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values, isSubmitting }) => {
          values.category != selectedCategory && setSelectedCategory(values.category);

          return (
            <Form>
              <Stack sx={{ m: '10px' }}>
                <FormSelect
                  label="Category"
                  name="category"
                  placeholder="Category"
                  disabled={isSubmitting}
                  options={categories.map((category) => ({ value: category, label: category }))}
                />
              </Stack>

              <Stack sx={{ m: '10px' }}>
                <FormSelect
                  label="Subcategory"
                  name="subcategory"
                  placeholder="SubCategory"
                  disabled={isSubmitting}
                  options={subcategories.map((category) => ({
                    value: category,
                    label: category,
                  }))}
                />
              </Stack>

              <Stack sx={{ m: '10px' }}>
                <FormTextInput
                  label="Title"
                  name="title"
                  type="text"
                  placeholder="Title"
                  disabled={isSubmitting}
                />
              </Stack>

              <Stack sx={{ m: '10px' }}>
                <FormTextInput
                  label="Description"
                  name="description"
                  type="text"
                  placeholder="Description"
                  disabled={isSubmitting}
                  multiline
                  rows={3}
                />
              </Stack>

              <Box sx={{ float: 'right', mt: '10px', mr: '10px' }}>
                <ButtonPrimary fullWidth={false} variant="contained" disabled={isSubmitting}>
                  Submit
                </ButtonPrimary>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
