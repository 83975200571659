import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
} from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import React, { FormEvent, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../../redux-store/reducers';
import * as Yup from 'yup';
import { AssessmentSearchParameters, RESET_SEARCH_OPTIONS } from '../types';
import { SET_SEARCH_OPTIONS } from '../../../redux-store/types/asset';
import FormSelect from '../../forms/FormSelect';
import FormDatePicker from '../../forms/FormDatePicker';
import FormUsersPicker from '../../forms/FormUsersPicker';

const vaildationschema = Yup.object().shape({
  status: Yup.string(),
  type: Yup.string(),
  interviewer: Yup.object()
    .nullable()
    .shape({
      id: Yup.string(),
      name: Yup.string().required('Name is required'),
      email: Yup.string().required('Email is required'),
    }),
  limit: Yup.number(),
  createdBefore: Yup.date().nullable(true),
  createdAfter: Yup.date().nullable(true),
  updatedBefore: Yup.date().nullable(true),
  updatedAfter: Yup.date().nullable(true),
});

export type SearchOptionDialogTypes = DialogProps;

export default function AssessmentSearchOptions(props: SearchOptionDialogTypes): JSX.Element {
  const searchOptions = useSelector<RootStateType, Partial<AssessmentSearchParameters>>(
    (state) => state.ats.assessment.searchOptions,
  );
  const dispatch = useDispatch();
  const formikRef = useRef<FormikProps<Partial<AssessmentSearchParameters>>>(null);
  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setValues(searchOptions);
    }
  }, [searchOptions]);

  const handleApply = (e: React.MouseEvent<HTMLElement>) => {
    if (formikRef.current) {
      formikRef.current.handleSubmit(e as unknown as FormEvent<HTMLFormElement>);
    }
  };
  const handleReset = () => {
    dispatch({ type: RESET_SEARCH_OPTIONS });
  };

  const initialValues = {
    ...searchOptions,
    interviewer: {
      id: '',
      name: '',
      email: '',
    },
  };

  return (
    <Dialog scroll="paper" {...props}>
      <DialogTitle>Assessment Filters</DialogTitle>
      <DialogContent dividers>
        <Formik
          innerRef={formikRef}
          validationSchema={vaildationschema}
          initialValues={initialValues}
          onSubmit={(values) => {
            dispatch({ type: SET_SEARCH_OPTIONS, searchOptions: values });
            props.onClose &&
              props.onClose(
                new Event('submit', { cancelable: true, bubbles: true }),
                'escapeKeyDown',
              );
          }}
          enableReinitialize
        >
          {() => (
            <Form>
              <Divider>Filters</Divider>
              <FormSelect
                name="status"
                label="Status"
                options={[
                  {
                    label: 'Recommended',
                    value: 'recommended',
                  },
                  {
                    label: 'Hold',
                    value: 'hold',
                  },
                  {
                    label: 'Rejected',
                    value: 'rejected',
                  },
                  {
                    label: 'In Progress',
                    value: 'in progress',
                  },
                ]}
              />
              <FormSelect
                name="type"
                label="Type"
                options={[
                  { label: 'Preliminary Coding Round', value: 'preliminary-coding-round' },
                  { label: 'Coding Round', value: 'coding-round' },
                  { label: 'Internal', value: 'internal' },
                  { label: 'HR Round', value: 'hr-round' },
                ]}
              />
              <FormUsersPicker name="interviewer" label="Interviewer" />
              <Divider>Filters by Date</Divider>
              <FormDatePicker name="createdBefore" label="Created Before" />
              <FormDatePicker name="createdAfter" label="Created After" />
              <FormDatePicker name="updatedBefore" label="Updated Before" />
              <FormDatePicker name="updatedAfter" label="Updated After" />
              <Divider>Sorting</Divider>
              <FormSelect
                name="orderBy"
                label="Sort By"
                options={[
                  { label: 'Created At', value: 'createdAt' },
                  { label: 'Updated At', value: 'updatedAt' },
                  { label: 'Interviewer', value: 'interviewer' },
                  { label: 'Name', value: 'name' },
                  { label: 'Status', value: 'status' },
                ]}
              />
              <FormSelect
                name="orderDirection"
                label="Sort Order"
                options={[
                  { label: 'Ascending', value: 'asc' },
                  { label: 'Descending', value: 'desc' },
                ]}
              />
              <Divider>Pagination</Divider>
              <FormSelect
                name="limit"
                label="Items per page"
                options={['10', '25', '50'].map((option) => ({ label: option, value: option }))}
              />
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReset} color="primary">
          Reset
        </Button>
        <Button onClick={handleApply} variant="contained" color="primary">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}
