import {
  Button,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell as MUITableCell,
  TableCellProps,
  TableContainer,
  TableHead as MUITableHead,
  TableHeadProps,
  TableRow,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router';
import { formatShortDate } from '../../../utils/date';
import { TitleBar } from '../../assets/components';
import { Grant } from '../types';
import { getGroupPermissions, grantPermissionToGroup, revokePermissionFromGroup } from './actions';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { LoadingButton } from '@mui/lab';
import { generatePath } from 'react-router';

function TableCell({ children, sx, ...rest }: TableCellProps) {
  return (
    <MUITableCell sx={{ p: 0.5, ...sx }} {...rest}>
      {children}
    </MUITableCell>
  );
}

function TableHead({ children, sx, ...rest }: TableHeadProps) {
  return (
    <MUITableHead sx={{ p: 0, ...sx }} {...rest}>
      {children}
    </MUITableHead>
  );
}

type GrantWithUpdateState = Grant & {
  updateState: boolean;
};

export default function Router(): JSX.Element {
  const { url: path } = useRouteMatch();
  const { state } = useLocation();
  const { id: groupId } = useParams<{ id: string }>();
  const [grants, setGrants] = useState<Array<GrantWithUpdateState>>([]);
  const [showProgress, setShowProgress] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function fetchPermissions() {
      try {
        setShowProgress(true);
        const _p = await dispatch(getGroupPermissions(groupId));
        const p: Array<GrantWithUpdateState> = (_p as unknown as Grant[]).map((g) => ({
          ...g,
          updateState: false,
        }));
        setGrants(p);
      } catch (error) {
        enqueueSnackbar((error as Error).message, { variant: 'error' });
      } finally {
        setShowProgress(false);
      }
    }
    fetchPermissions();
  }, []);

  const handleToggleUpdateState = async (selectedGrant: GrantWithUpdateState) => {
    console.log('Toggle update state for grant ' + selectedGrant.permission.name);
    if (selectedGrant.updateState) {
      return;
    }
    setGrants(
      grants.map((grant) =>
        grant === selectedGrant ? { ...grant, updateState: !grant.updateState } : grant,
      ),
    );
    try {
      const updatedGrant = (await dispatch(
        selectedGrant.allowed
          ? revokePermissionFromGroup(groupId, selectedGrant.permission.name)
          : grantPermissionToGroup(groupId, selectedGrant.permission.name),
      )) as unknown as Grant;
      setGrants(
        grants.map((grant) =>
          grant === selectedGrant ? { ...updatedGrant, updateState: false } : grant,
        ),
      );
      enqueueSnackbar(
        `Successfully ${updatedGrant.allowed ? 'granted' : 'revoked'} permission '${
          updatedGrant.permission.name
        }'`,
        {
          variant: 'success',
        },
      );
    } catch (error) {
      enqueueSnackbar((error as Error).message, { variant: 'error' });
    }
  };

  return (
    <>
      <TitleBar title="Manage Permissions" />
      {showProgress && <LinearProgress />}
      <Switch>
        <Route
          path={`${path}/:category`}
          render={({ match }) => (
            <FilteredGrants
              grants={grants.filter((grant) =>
                grant.permission.name.startsWith(match.params.category),
              )}
              handleToggleUpdateState={handleToggleUpdateState}
            />
          )}
        />
        <Route
          exact
          path={`${path}/`}
          render={() => <Redirect to={{ pathname: `${path}/users`, state }} />}
        />
      </Switch>
    </>
  );
}

function FilteredGrants({
  grants,
  handleToggleUpdateState,
}: {
  grants: Array<GrantWithUpdateState>;
  handleToggleUpdateState: (grant: GrantWithUpdateState) => void;
}): JSX.Element {
  const {
    params: { category },
    path,
  } = useRouteMatch<{ category: string }>();
  const { state } = useLocation();
  const history = useHistory();
  const handleSelectChange = (event: SelectChangeEvent) => {
    const updatedCategory = event.target.value;
    history.replace({
      pathname: generatePath(path, { category: updatedCategory }),
      state,
    });
  };
  return (
    <>
      <Stack
        py={1}
        px={2}
        direction="row"
        spacing={2}
        justifyContent="flex-start"
        alignItems={'center'}
      >
        <Typography>Filter by</Typography>
        <FormControl fullWidth={false}>
          <InputLabel id="demo-simple-select-label">Category</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Filter by category"
            value={category}
            variant="outlined"
            onChange={handleSelectChange}
          >
            <MenuItem value="users">Users</MenuItem>
            <MenuItem value="permissions">Permissions</MenuItem>
            <MenuItem value="timesheets">Time Sheets</MenuItem>
            <MenuItem value="ats">Hiring</MenuItem>
            <MenuItem value="tickets">Tickets</MenuItem>
            <MenuItem value="assets">Assets</MenuItem>
            <MenuItem value="leaves">Leaves</MenuItem>
            <MenuItem value="delivery">Delivery</MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <TableContainer>
        <Table aria-label="groups list" size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Permission</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Granted To</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Granted By</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {grants.map((grant) => (
              <TableRow key={grant.permission.name}>
                <TableCell sx={{ p: 0 }}>
                  {grant.updateState ? (
                    <LoadingButton loading />
                  ) : (
                    <Button onClick={handleToggleUpdateState.bind(null, grant)}>
                      {grant.allowed ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                    </Button>
                  )}
                </TableCell>
                <TableCell>{grant.permission.description}</TableCell>
                <TableCell>
                  {grant.permissionOwner === 'self' ? 'Self' : grant.group?.name}
                </TableCell>
                <TableCell>
                  {grant.grantedBy?.name} {grant.grantedBy && ' on '}{' '}
                  {formatShortDate(grant.createdAt)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
