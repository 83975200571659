export type DesignationType = { name: string; code: string; group: string };

export const DESIGNATIONS: Array<DesignationType> = [
  { code: 'ER06', name: 'Trainee Software Engineer', group: 'Engineering' },
  { code: 'ER05', name: 'Associate Software Engineer', group: 'Engineering' },
  { code: 'ER04', name: 'Software Engineer', group: 'Engineering' },
  { code: 'ER03', name: 'Senior Software Engineer', group: 'Engineering' },
  { code: 'ER02', name: 'Technical Lead', group: 'Engineering' },
  { code: 'ER01', name: 'Chief Architect', group: 'Engineering' },
  { code: 'ER07', name: 'Chief Solutions Architect', group: 'Engineering' },

  { code: 'QA06', name: 'QA Intern', group: 'QA' },
  { code: 'QA05', name: 'Associate QA', group: 'QA' },
  { code: 'QA04', name: 'QA', group: 'QA' },
  { code: 'QA03', name: 'Senior QA', group: 'QA' },
  { code: 'QA02', name: 'QA Lead', group: 'QA' },
  { code: 'QA01', name: 'QA Architect', group: 'QA' },

  { code: 'DZ05', name: 'Trainee Designer', group: 'Design' },
  { code: 'DZ04', name: 'Associate UI/UX Designer', group: 'Design' },
  { code: 'DZ03', name: 'UI/UX Designer', group: 'Design' },
  { code: 'DZ02', name: 'UI/UX Lead', group: 'Design' },
  { code: 'DZ01', name: 'UI/UX Architect', group: 'Design' },

  { code: 'WM02', name: 'Program Manager', group: 'Program Management' },
  { code: 'WM01', name: 'Senior Program Manager', group: 'Program Management' },

  { code: 'ZA02', name: 'Business Analyst', group: 'Business Analysis' },
  { code: 'ZA01', name: 'Senior Business Analyst', group: 'Business Analysis' },

  { code: 'HR03', name: 'HR Intern', group: 'HR' },
  { code: 'HR02', name: 'HR Associate', group: 'HR' },
  { code: 'HR01', name: 'HR Manager', group: 'HR' },

  { code: 'FI03', name: 'Finance Associate', group: 'Finance' },
  { code: 'FI02', name: 'Chartered Accountant', group: 'Finance' },
  { code: 'FI01', name: 'Director of Finance', group: 'Finance' },

  { code: 'OF02', name: 'Office Boy', group: 'Office Staff' },
  { code: 'OF01', name: 'Facility Manager', group: 'Office Staff' },

  { code: 'CX03', name: 'CTO', group: 'CX0' },
  { code: 'CX02', name: 'COO', group: 'CX0' },
  { code: 'CX01', name: 'CEO', group: 'CX0' },
];
export const DEPARTMENTS = [
  'Engineering',
  'Design',
  'Finance',
  'Delivery',
  'QA',
  'Core',
  'Business Analyst',
  'HR',
];
export const SUB_DEPARTMENTS = [
  'NodeJS',
  'React',
  'QA',
  'Program Management',
  'Founders',
  'Finance',
  'BA',
  'Magento',
  'Angular',
  'Java',
  'None',
];

export const BLOOD_GROUPS = ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'];

export const formatDesignation = (designation: string | undefined): string => {
  const designationObj = DESIGNATIONS.find((d) => d.code === designation);
  return designationObj ? designationObj.name : designation || '';
};
