import {
  Box,
  Chip,
  Divider,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { TableBodyCell, TableHeadCell } from '../../common';
import { useExtractParams, useReport } from '../constants';
// import TeamReportsHeader from './sow-period-filter-header';
import { getSOWWiseTeamReport } from '../../time-tracking/actions';
import { EmployeeWithHours, SOWType } from '../../time-tracking/types';
import ReportFilters from './Filters';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getPhases } from '../../delivery/actions';
import { useSnackbar } from 'notistack';
import LinearProgressWithSegments from '../../common/LinearProgress';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Link as RouterLink } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
function formatHours(hours: number) {
  return hours?.toFixed(1);
}

const currencyFormatter = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
});

function formatAmount(amount: number) {
  return currencyFormatter.format(amount);
}

type EmployeeWithSOW = EmployeeWithHours & {
  costPerHour: number;
  allocatedHours: number;
  billingRates: Array<number>;
  sow: string;
  billableHours: string;
  team: string[];
  costTowardsProject: number;
};

export default function ProjectSummary(): JSX.Element {
  const { dateLabel, periodLabel, period, prevUrl, nextUrl } = useExtractParams();
  const dispatch = useDispatch();
  const { showLoading, results } = useReport<EmployeeWithSOW>(getSOWWiseTeamReport);
  const [sows, setSows] = useState<Array<SOWType>>([]);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    async function getAllPhases() {
      try {
        const _sows = await dispatch(getPhases());
        setSows(_sows as unknown as Array<SOWType>);
      } catch (error) {
        enqueueSnackbar(`Could not fetch SOWS: ${(error as Error).message}`, { variant: 'error' });
      }
    }
    getAllPhases();
  }, []);
  const sowOptions = [{ name: 'Any', id: '' }].concat(
    sows
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .filter((sow: any) => sow.project.name !== 'WebileApps')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .map((sow: any) => ({
        name: sow.name,
        id: sow.id,
      })),
  );
  const totalAllocatedHours =
    results && results.map(({ allocatedHours }) => allocatedHours).reduce((a, b) => a + b, 0);
  const totalBillableHours =
    results && results.map(({ noOfBillableHours }) => noOfBillableHours).reduce((a, b) => a + b, 0);
  const totalBillableAmount =
    results && results.map(({ billableAmount }) => billableAmount).reduce((a, b) => a + b, 0);
  const totalCostPerHour =
    results && results.map(({ costPerHour }) => costPerHour).reduce((a, b) => a + b, 0);

  const totalAmount =
    totalAllocatedHours && totalAllocatedHours && totalCostPerHour && totalCostPerHour
      ? totalAllocatedHours * totalCostPerHour
      : 0;
  const totalNonBillableAmount = totalBillableAmount ? totalAmount - totalBillableAmount : 0;
  return (
    <Box sx={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
      <Box
        sx={{
          width: '100%',
          zIndex: '999',
          px: [0, 2],
          flex: '0 1 auto',
          position: 'relative',
        }}
      >
        <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h2" mr={4}>
            Reports
          </Typography>
          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
            }}
          >
            {period !== 'all' && (
              <Stack
                direction="row"
                sx={{
                  alignItems: 'center',
                  width: ['100%', 'auto'],
                  '.date-toggle-arrows': {
                    '&.MuiToggleButtonGroup-root': {
                      borderRadius: '2px 0 0 2px',
                      borderRight: 0,
                      '& .MuiToggleButton-root': {
                        minWidth: 30,
                        borderRight: '1px solid #BEBFC6',
                        padding: '10px 5px',
                        borderRadius: 0,
                        '& svg': {
                          height: '14px',
                        },
                        '&:last-child': {
                          borderRight: 0,
                        },
                      },
                    },
                  },
                }}
              >
                <ToggleButtonGroup color="primary" className="date-toggle-arrows">
                  <ToggleButton
                    sx={{ py: '6px', px: 1.5, borderRadius: 20 }}
                    value="prev"
                    component={RouterLink}
                    to={prevUrl}
                    size="small"
                  >
                    <ArrowBackIosNewIcon fontSize="small" />
                  </ToggleButton>
                  <ToggleButton
                    sx={{ py: '6px', px: 1.5, borderRadius: 20 }}
                    value="next"
                    component={RouterLink}
                    to={nextUrl}
                    size="small"
                  >
                    <ArrowForwardIosIcon fontSize="small" />
                  </ToggleButton>
                </ToggleButtonGroup>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    border: '1px solid #BEBFC6',
                    borderRadius: '0 2px 2px 0',
                    height: 34,
                    padding: '7px 20px',
                    width: ['100%', 'auto'],
                  }}
                >
                  <Typography variant="h4">{periodLabel}</Typography>
                  <Typography variant="h4">{dateLabel}</Typography>
                  {/* {!isCurrentPeriod && (
                    <RouterLink to={currentPeriodUrl}>{returnToCurrentPeriodLabel}</RouterLink>
                  )} */}
                </Stack>
              </Stack>
            )}
          </Stack>
        </Stack>
        <Divider
          sx={{
            width: 'calc(100% + 64px)',
            ml: -4,
            mt: 2,
          }}
        />
        <Stack
          alignItems="center"
          justifyContent="space-between"
          sx={{
            width: '100%',
          }}
        >
          <ReportFilters datavalues={sowOptions} />
        </Stack>
        <Divider
          sx={{
            width: 'calc(100% + 64px)',
            ml: -4,
          }}
        />
        <Stack
          sx={{
            py: 2,
            px: 4,
            width: 'calc(100% + 64px)',
            ml: -4,
            bgcolor: 'primary.veryLightGrey',
            flexDirection: 'row',
            '& .MuiBox-root': {
              minWidth: 200,
              '& span.MuiTypography-root': {
                width: 10,
                height: 10,
                borderRadius: '1px',
                display: 'inline-block',
                mr: 1,
              },
            },
          }}
        >
          <Box>
            <Typography variant="h6" fontWeight={400} mb={1}>
              Allocated Hours
            </Typography>
            <Typography variant="h2">
              {formatHours(totalAllocatedHours as unknown as number)}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6" fontWeight={400} mb={1}>
              Logged Hours
            </Typography>
            <Typography variant="h2">
              {formatHours(totalBillableHours as unknown as number)}
            </Typography>
          </Box>
          <Stack sx={{ flexGrow: 1, flexDirection: 'row', alignItems: 'flex-start' }}>
            <Box>
              <Stack flexDirection="row" alignItems="center" justifyContent="space-between" mb={1}>
                <Typography variant="h6" fontWeight={400} width={150}>
                  <Typography
                    component="span"
                    sx={{
                      bgcolor: 'primary.blueBg',
                    }}
                  ></Typography>
                  Billable
                </Typography>
                <Typography variant="h6">
                  {formatAmount(totalBillableAmount as unknown as number)}
                </Typography>
              </Stack>
              <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h6" fontWeight={400} width={150}>
                  <Typography
                    component="span"
                    sx={{
                      bgcolor: 'primary.blueBgLight',
                    }}
                  ></Typography>
                  Non - Billable
                </Typography>
                <Typography variant="h6">
                  {totalNonBillableAmount > 0
                    ? formatAmount(totalNonBillableAmount)
                    : formatAmount(0)}
                </Typography>
              </Stack>
            </Box>
            <Box sx={{ flexGrow: 1, ml: 5 }}>
              <LinearProgressWithSegments
                value={totalBillableAmount ? (totalBillableAmount / totalAmount) * 100 : 0}
              />
            </Box>
          </Stack>
        </Stack>
        <Divider
          sx={{
            width: 'calc(100% + 64px)',
            ml: -4,
          }}
        />
        {showLoading && (
          <LinearProgress sx={{ position: 'absolute', left: 0, bottom: 0, width: '100%' }} />
        )}
      </Box>
      {results && results.length > 0 ? (
        <Box
          sx={{
            flex: '1 1 auto',
            width: 'calc(100% + 32px)',
            ml: -2,
            overflow: 'auto',
          }}
        >
          <MUITable results={results} />
        </Box>
      ) : (
        <Paper variant="outlined" sx={{ p: 5, m: 2 }}>
          <Typography variant="h4"> No Results Found</Typography>
        </Paper>
      )}
    </Box>
  );
}

function MUITable({ results }: { results: Array<EmployeeWithSOW> }): JSX.Element {
  const totalBillableHours = results
    .map(({ noOfBillableHours }) => noOfBillableHours)
    .reduce((a, b) => a + b, 0);
  const totalBillableAmount = results
    .map(({ billableAmount }) => billableAmount)
    .reduce((a, b) => a + b, 0);
  const totalCostToProject = results
    .map(({ costTowardsProject }) => costTowardsProject)
    .reduce((a, b) => a + b, 0);
  const totalAllocatedHours = results
    .map(({ allocatedHours }) => allocatedHours)
    .reduce((a, b) => a + b, 0);
  return (
    <TableContainer sx={{ width: '100%' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableHeadCell>SOW</TableHeadCell>
            <TableHeadCell>Team</TableHeadCell>
            <TableHeadCell sx={{ textAlign: 'right' }}>Allocated Hours</TableHeadCell>
            <TableHeadCell sx={{ textAlign: 'right' }}>Logged Hours</TableHeadCell>
            <TableHeadCell sx={{ textAlign: 'right' }}>Billable Hours</TableHeadCell>
            <TableHeadCell sx={{ textAlign: 'right' }}>Cost to Project</TableHeadCell>
            <TableHeadCell sx={{ textAlign: 'right' }}>Billable Amount</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((employee, i) => (
            <TableRow key={employee.id}>
              <TableBodyCell>
                {i + 1}
                {'.   '}
                {employee.sow}
              </TableBodyCell>
              <TableBodyCell>
                {employee.team &&
                  employee.team.map((employeeName) => (
                    <>
                      <Chip label={employeeName} sx={{ m: 1, p: 2 }} icon={<PersonIcon />} />
                    </>
                  ))}
              </TableBodyCell>
              <TableBodyCell sx={{ textAlign: 'right' }}>
                {formatHours(employee.allocatedHours)}
              </TableBodyCell>
              <TableBodyCell sx={{ textAlign: 'right' }}>
                {formatHours(employee.noOfHours)}
              </TableBodyCell>
              <TableBodyCell sx={{ textAlign: 'right' }}>
                {formatHours(employee.noOfBillableHours)}
              </TableBodyCell>
              <TableBodyCell sx={{ textAlign: 'right' }}>
                {formatAmount(employee.costTowardsProject)}
              </TableBodyCell>
              <TableBodyCell sx={{ textAlign: 'right' }}>
                {formatAmount(employee.billableAmount)}
              </TableBodyCell>
            </TableRow>
          ))}
          <TableRow>
            <TableHeadCell>Total</TableHeadCell>
            <TableHeadCell></TableHeadCell>
            <TableHeadCell sx={{ textAlign: 'right' }}>
              {formatHours(totalAllocatedHours)}
            </TableHeadCell>
            <TableHeadCell sx={{ textAlign: 'right' }}>
              {formatHours(results.map(({ noOfHours }) => noOfHours).reduce((a, b) => a + b, 0))}
            </TableHeadCell>
            <TableHeadCell sx={{ textAlign: 'right' }}>
              {formatHours(totalBillableHours)}
            </TableHeadCell>
            <TableHeadCell sx={{ textAlign: 'right' }}>
              {formatAmount(totalCostToProject)}
            </TableHeadCell>
            <TableHeadCell sx={{ textAlign: 'right' }}>
              {formatAmount(totalBillableAmount)}
            </TableHeadCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
