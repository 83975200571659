import {
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { CSVLink } from 'react-csv';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { formatDesignation } from '../../users/constants';
import { TableHeadCell } from '../common';
import { EmployeeWithHours } from '../types';
import { useReport } from './constants';
import TeamReportsHeader from './sow-period-filter-header';

function formatHours(hours: number) {
  return hours.toFixed(1);
}

const currencyFormatter = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
});

function formatAmount(amount: number) {
  return currencyFormatter.format(amount);
}

export default function TeamReports(): JSX.Element {
  const { showLoading, results } = useReport<EmployeeWithHours>();
  return (
    <>
      <Stack direction="row" spacing={2} alignItems="center">
        <TeamReportsHeader />
        <CSVLink data={results || []} filename="april-billable-hours.csv" style={{ marginLeft: 0 }}>
          <CloudDownloadIcon color="secondary" />
        </CSVLink>
      </Stack>
      {showLoading && <LinearProgress />}
      {results && results.length > 0 && <MUITable results={results} />}
    </>
  );
}

function MUITable({ results }: { results: Array<EmployeeWithHours> }): JSX.Element {
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableHeadCell>#</TableHeadCell>
            <TableHeadCell>Name</TableHeadCell>
            <TableHeadCell>Designation</TableHeadCell>
            <TableHeadCell sx={{ textAlign: 'right' }}>Total Hours</TableHeadCell>
            <TableHeadCell sx={{ textAlign: 'right' }}>Billable Hours</TableHeadCell>
            <TableHeadCell sx={{ textAlign: 'right' }}>Billable Amount</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableHeadCell></TableHeadCell>
            <TableHeadCell>Total</TableHeadCell>
            <TableHeadCell></TableHeadCell>
            <TableHeadCell sx={{ textAlign: 'right' }}>
              {formatHours(results.map(({ noOfHours }) => noOfHours).reduce((a, b) => a + b, 0))}
            </TableHeadCell>
            <TableHeadCell sx={{ textAlign: 'right' }}>
              {formatHours(
                results
                  .map(({ noOfBillableHours }) => noOfBillableHours)
                  .reduce((a, b) => a + b, 0),
              )}
            </TableHeadCell>
            <TableHeadCell sx={{ textAlign: 'right' }}>
              {formatAmount(
                results.map(({ billableAmount }) => billableAmount).reduce((a, b) => a + b, 0),
              )}
            </TableHeadCell>
          </TableRow>
          {results.map((employee, i) => (
            <TableRow key={employee.id}>
              <TableCell>{i + 1}</TableCell>
              <TableCell>{employee.name}</TableCell>
              <TableCell>{formatDesignation(employee.designation)}</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>{formatHours(employee.noOfHours)}</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                {formatHours(employee.noOfBillableHours)}
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                {formatAmount(employee.billableAmount)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
