export const GET_LEAVES_BY_USER = 'GET_LEAVES_BY_USER';
export const GET_LEAVES_BY_USER_SUCCESS = 'GET_LEAVES_BY_USER_SUCCESS';
export const CREATE_LEAVE = 'CREATE_LEAVE';
export const CREATE_LEAVE_SUCCESS = 'CREATE_LEAVE_SUCCESS';
export const UPDATE_LEAVE = 'UPDATE_LEAVE';
export const UPDATE_LEAVE_SUCCESS = 'UPDATE_LEAVE_SUCCESS';
export const UPDATE_LEAVE_STATUS = 'UPDATE_LEAVE_STATUS';
export const UPDATE_LEAVE_STATUS_SUCCESS = 'UPDATE_LEAVE_STATUS_SUCCESS';

export interface LeavePayload {
  employee: Object;
  details: string;
  startDate: string;
  endDate: string;
  leaveType: string;
  appliedBy: Object;
}

export interface GetLeavePayload {
  startDate: string;
  endDate: string;
  status: string;
  employeeId: string;
}

export interface UpdateLeavePayload extends LeavePayload {
  id: string;
  status: string;
}

export interface UpdateLeaveStatusPayload {
  id: string;
  status: string;
  actionPerformedBy: string;
}

interface OnGetLeavesByUserAction {
  type: typeof GET_LEAVES_BY_USER_SUCCESS;
}

interface OnCreateLeaveAction {
  type: typeof CREATE_LEAVE_SUCCESS;
  body: LeavePayload;
}

interface OnUpdateLeaveAction {
  type: typeof UPDATE_LEAVE_SUCCESS;
  body: UpdateLeavePayload;
}

interface OnUpdateLeaveStatusAction {
  type: typeof UPDATE_LEAVE_STATUS_SUCCESS;
  body: UpdateLeaveStatusPayload;
}

export type LeaveTypes =
  | OnGetLeavesByUserAction
  | OnCreateLeaveAction
  | OnUpdateLeaveAction
  | OnUpdateLeaveStatusAction;
