import { Box, Divider, Grid, Paper, Link, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import GSILogin from '../external/gsi/google-signin';
import { Link as RouterLink } from 'react-router-dom';
import { TextInput } from '../forms/textInput';
import { SubmitButton } from '../forms/Button';
import { googleLogin } from '../../redux-store/actions/login';
import { useDispatch } from 'react-redux';

const GOOGLE_CLIENT_ID = '246661916136-a7uhsletbsllhi74o013f8f9hb12lvl6.apps.googleusercontent.com';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function SigninWithGoogle(): JSX.Element {
  const responseGoogle = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    console.log(response);
  };
  return (
    <GoogleLogin
      clientId={GOOGLE_CLIENT_ID}
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      isSignedIn={true}
      scope="email"
      style={{ fontWeight: 600 }}
      buttonText="Login with @webileapps email"
    />
  );
}

function SigninWithGSI(): JSX.Element {
  const dispatch = useDispatch();
  const handleOnSuccess = async (response: { credential: string; clientId: string }) => {
    // console.log(response.credential);
    await dispatch(googleLogin(response.credential));
  };
  return (
    <GSILogin
      clientId={GOOGLE_CLIENT_ID}
      onSuccess={handleOnSuccess}
      config={{
        type: 'standard',
        theme: 'filled_blue',
      }}
    />
  );
}

const validationSchema = yup.object().shape({
  phone: yup.string().required('Phone number is required'),
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function SigninWithPhone(): JSX.Element {
  const onSubmit = async (values: unknown) => {
    console.log(values);
  };
  return (
    <Formik onSubmit={onSubmit} initialValues={{ phone: '' }} validationSchema={validationSchema}>
      {({ handleSubmit }) => (
        <Box component="form" onSubmit={handleSubmit}>
          <Typography sx={{ mb: 2, textAlign: 'center' }}>Sign in with phone</Typography>
          <TextInput label="Phone" name="phone" sx={{ mb: 3 }}></TextInput>
          <SubmitButton label="Get OTP" fullWidth={true} />
        </Box>
      )}
    </Formik>
  );
}
export default function EmployeeLogin(): JSX.Element {
  return (
    <Box
      sx={{
        height: '100vh',
        bgcolor: '#3A2F5C',
        width: '100%',
        p: [2, 0],
      }}
    >
      <Grid>
        <Grid container justifyContent="center">
          <img alt="logo" src="/images/webileapps.svg" style={{ width: 50, marginTop: 20 }} />
        </Grid>
        <Paper elevation={5} sx={{ p: 3, width: 532, margin: '65px auto', maxWidth: '100%' }}>
          <Grid container justifyContent="center" alignItems="center" direction={'column'}>
            <Typography sx={{ mb: 2, textAlign: 'center' }}>Sign in with Google</Typography>
            <SigninWithGSI />
            <Divider sx={{ m: 2, alignSelf: 'stretch' }}>or</Divider>
            <Typography variant="caption">
              <Link component={RouterLink} to="/login">
                Sign in as Admin
              </Link>
            </Typography>
          </Grid>
        </Paper>
      </Grid>
    </Box>
  );
}
