import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, Chip, Stack, TableCell, TableCellProps, Typography, useTheme } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { mapStatus } from './constants';

export function formatAsTime(value: number): string {
  if (value === 0) {
    return '0:00';
  }
  const sign = value < 0 ? '-' : '';
  value = Math.abs(value);
  const minutesFraction = value - Math.trunc(value);
  return `${sign}${Math.trunc(value)}:${(minutesFraction * 60).toFixed(0).padStart(2, '0')}`;
}

export function TableHeadCell({ sx, ...props }: TableCellProps): JSX.Element {
  return (
    <TableCell
      sx={{
        px: 1,
        py: 3,
        backgroundColor: 'primary.lightBlueBg',
        ...sx,
      }}
      {...props}
    />
  );
}
export function TableBodyCell({ sx, ...props }: TableCellProps): JSX.Element {
  const theme = useTheme();
  return (
    <TableCell
      sx={{
        px: 2,
        py: 2,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...(theme.mixins as any).standardBorder,
        '& td': {
          '&: nth-last-child(1)': {
            borderRight: 'none',
          },
        },
        ...sx,
      }}
      {...props}
    />
  );
}

export function DateTitle({
  date,
  isDayUnderFocus = false,
}: {
  date: Date;
  isDayUnderFocus?: boolean;
}): JSX.Element {
  return (
    <>
      <Typography
        variant="body1"
        lineHeight="25px"
        fontWeight={isDayUnderFocus ? '600' : '400'}
        className={isDayUnderFocus ? 'active' : ''}
      >
        {date.toLocaleDateString('en-US', { weekday: 'short' })}
        <Typography
          variant="body2"
          component="span"
          sx={{ display: 'block', fontWeight: isDayUnderFocus ? '600' : '400' }}
        >
          {date.toLocaleDateString('en-Us', { month: 'short', day: 'numeric' })}
        </Typography>
      </Typography>
    </>
  );
}

export function TimesheetStatusChip({
  status,
  variant = 'button',
}: {
  status: string;
  variant?: string;
}): JSX.Element {
  const color = { CREATED: 'error', APPROVED: 'success', SUBMITTED: 'warning' }[status];
  return (
    <>
      {variant === 'text' && mapStatus(status) === 'Not Submitted' ? (
        <Typography variant="h4" fontSize="14px" display="flex" alignItems="center" color="error">
          <img src="/images/error.svg" alt="error icon" style={{ marginRight: '8px' }} />
          {mapStatus(status)}
        </Typography>
      ) : (
        <Chip
          sx={{ minWidth: '135px' }}
          label={mapStatus(status)}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          color={(color || 'default') as any}
          icon={status === 'APPROVED' ? <LockIcon /> : undefined}
        />
      )}
    </>
  );
}

export function StrongSpan({ children }: { children: React.ReactNode }): JSX.Element {
  return <Box component="strong">{children}</Box>;
}

export function BasicDatePicker({
  value,
  onChange,
}: {
  value: Date;
  onChange: (date: Date | null) => void;
}): JSX.Element {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        label="Basic example"
        value={value}
        onChange={onChange}
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <Stack
            ref={inputRef}
            sx={{
              justifyContent: 'center',
              marginLeft: '0 !important',
            }}
          >
            {InputProps?.endAdornment}
          </Stack>
        )}
      />
    </LocalizationProvider>
  );
}
