import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Grid,
} from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { FormEvent, MouseEvent, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { RootStateType } from '../../redux-store/reducers';
import {
  AssetsSearchOptions,
  RESET_SEARCH_OPTIONS,
  SET_SEARCH_OPTIONS,
} from '../../redux-store/types/asset';
import FormCheckbox from '../forms/FormCheckbox';
import { FormDateRangePicker } from '../forms/FormDateRangePicker';
import FormSelect from '../forms/FormSelect';
import FormTextInput from '../forms/FormTextInput';
import FormUsersPicker from '../forms/FormUsersPicker';

const schema = Yup.object().shape({
  allocationType: Yup.string().required('Allocation type is required'),
  createdAt: Yup.array().min(2).max(2).of(Yup.date().nullable(true)).required(),
  allocatedAt: Yup.array().min(2).max(2).of(Yup.date().nullable(true)).required(),
  updatedAt: Yup.array().min(2).max(2).of(Yup.date().nullable(true)).required(),
  orderDirection: Yup.string().required('Sort direction is required'),
});

export type SearchOptionDialogTypes = DialogProps;

export default function SearchOptions(props: SearchOptionDialogTypes): JSX.Element {
  const formikRef = useRef<FormikProps<AssetsSearchOptions>>(null);
  const dispatch = useDispatch();
  const searchOptions = useSelector<RootStateType, AssetsSearchOptions>(
    (rootState) => rootState.assets.searchOptions,
  );
  const handleApplyClick = (e: MouseEvent<HTMLElement>) => {
    if (formikRef.current) {
      formikRef.current.handleSubmit(e as unknown as FormEvent<HTMLFormElement>);
    }
  };
  const handleReset = () => {
    dispatch({ type: RESET_SEARCH_OPTIONS });
  };
  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setValues(searchOptions);
    }
  }, [searchOptions]);
  return (
    <Dialog scroll="paper" {...props}>
      <DialogTitle>Search Options</DialogTitle>
      <DialogContent dividers>
        <Formik
          innerRef={formikRef}
          validationSchema={schema}
          initialValues={searchOptions}
          onSubmit={(values) => {
            dispatch({ type: SET_SEARCH_OPTIONS, searchOptions: values });
            props.onClose &&
              props.onClose(
                new Event('submit', { cancelable: true, bubbles: true }),
                'escapeKeyDown',
              );
          }}
          enableReinitialize
        >
          {() => (
            <Form>
              <Divider>Filters</Divider>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormSelect
                    options={['Laptop', 'Desktop', 'Tablet', 'Phone'].map((type) => ({
                      value: type,
                      label: type,
                    }))}
                    name="type"
                    label="Type"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextInput name="serialNumber" label="Serial Number" />
                </Grid>
              </Grid>

              <FormCheckbox name="active" label="Asset currently active" />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormSelect
                    name="allocationType"
                    label="Allocation Type"
                    options={[
                      { label: 'None', value: 'none' },
                      { label: 'Any', value: 'any' },
                      { label: 'Exactly', value: 'exactly' },
                      { label: 'Someone', value: 'not-none' },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormUsersPicker name="allocatedTo" label="Allocated To" />
                </Grid>
              </Grid>
              <Divider>Filters By Date</Divider>
              <FormDateRangePicker name="createdAt" startLabel="Created After" endLabel="Before" />
              <FormDateRangePicker
                name="allocatedAt"
                startLabel="Allocated After"
                endLabel="Before"
              />
              <FormDateRangePicker name="updatedAt" startLabel="Updted After" endLabel="Before" />
              <Divider>Sorting</Divider>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormSelect
                    name="orderBy"
                    label="Sort By"
                    options={[
                      { label: 'Created At', value: 'createdAt' },
                      { label: 'Updated At', value: 'updatedAt' },
                      { label: 'Allocated At', value: 'allocatedAt' },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormSelect
                    name="orderDirection"
                    label="Sort Order"
                    options={[
                      { label: 'Ascending', value: 'asc' },
                      { label: 'Descending', value: 'desc' },
                    ]}
                  />
                </Grid>
              </Grid>
              <Divider>Pagination</Divider>
              <FormSelect
                name="limit"
                label="Items per page"
                options={['10', '25', '50'].map((option) => ({ label: option, value: option }))}
              />
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReset} color="primary">
          Reset
        </Button>
        <Button onClick={handleApplyClick} variant="contained" color="primary">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}
