export const GET_HOLIDAYS = 'GET_HOLIDAYS';
export const CREATE_HOLIDAY = 'CREATE_HOLIDAY';
export const UPDATE_HOLIDAY = 'UPDATE_HOLIDAY';

export interface HolidayPayload {
  _id?: string;
  dayOfWeek?: string;
  name: string;
  date: Date | string;
  year?: string;
}

interface OnGetHolidaysAction {
  type: typeof GET_HOLIDAYS;
}

interface OnCreateHolidayAction {
  type: typeof CREATE_HOLIDAY;
  body: HolidayPayload;
}

export type HolidayTypes = OnGetHolidaysAction | OnCreateHolidayAction;
