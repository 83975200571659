// import { ReportProblemRounded } from "@mui/icons-material";
// import { string } from "yup/lib/locale";
export const GET_USERS = 'GET_USERS';
export const CREATE_USERS = 'CREATE_USERS';
export const UPDATE_USERS = 'UPDATE_USERS';
export const ACTIVATE_USERS = 'ACTIVATE_USERS';
export const DEACTIVATE_USERS = 'DEACTIVATE_USERS';
export const UPDATE_USERS_MANAGER = 'UPDATE_USERS_MANAGER';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const CREATE_USERS_SUCCESS = 'CREATE_USERS_SUCCESS';
export const UPDATE_USERS_SUCCESS = 'UPDATE_USERS_SUCCESS';
export const ACTIVATE_USERS_SUCCESS = 'ACTIVATE_USERS_SUCCESS';
export const DEACTIVATE_USERS_SUCCESS = 'DEACTIVATE_USERS_SUCCESS';
export const UPDATE_MANAGER = 'UPDATE_MANAGER';
export const UPDATE_USERS_MANAGER_SUCCESS = 'UPDATE_USERS_MANAGER_SUCCESS';
export const SET_UNASSIGN_MANAGER = 'SET_UNASSIGN_MANAGER';
export const SET_USERS_SEARCH_OPTIONS = 'SET_USERS_SEARCH_OPTIONS';
export const SET_USERS_SEARCH_OPTIONS_TYPE = 'SET_USERS_SEARCH_OPTIONS_TYPE';
export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';

export interface UserAdditionalDetails {
  address: string;
  bloodGroup: string;
  empId: string;
  pan: string;
  aadhar: string;
  phoneNumber: string;
  fatherName: string;
  spouseName: string;
  emergencyContact: string;
  personalEmail: string;
  dob: Date;
  type: string;
  [k: string]: string | Date;
}
export interface UserPayload {
  name: string;
  email: string;
  designation: string;
  joining_date: string | Date;
  department: string;
  subDepartment: string;
  additionalDetails?: UserAdditionalDetails;
}
export interface UserUpdatePayload extends UserPayload {
  id: string;
  manager: string;
}
export interface UserUpdateManagerPayload {
  user_id: string;
  id: string;
}
interface OnGetUsersAction {
  type: typeof GET_USERS_SUCCESS;
}

interface OnCreateUsersAction {
  type: typeof CREATE_USERS_SUCCESS;
  body: UserPayload;
}

interface OnUpdateUsersAction {
  type: typeof UPDATE_USERS_SUCCESS;
  body: UserUpdatePayload;
}

interface OnActivateUsersAction {
  type: typeof ACTIVATE_USERS_SUCCESS;
}
interface OnDeactivateUsersAction {
  type: typeof DEACTIVATE_USERS_SUCCESS;
}
interface OnUpdateUsersManagerAction {
  type: typeof UPDATE_USERS_MANAGER_SUCCESS;
  body: UserUpdateManagerPayload;
}

export interface Employee {
  id: string;
  name?: string;
  joining_date?: Date;
  designation?: string;
  department: string;
  subDepartment: string;
  role?: string;
  active?: boolean;
  manager?: Employee;
  email: string;
}

export interface UserDetails extends Employee {
  blocked: boolean;
  additionalDetails?: UserAdditionalDetails;
  history: UsersHistory[];
}

export interface AssignManager {
  id: string | null;
  name?: string;
  email?: string;
}

interface UsersHistory {
  id: string;
  userId: string;
  description: string;
  changedBy: Employee;
  createdAt: Date;
}

export interface UsersSearchParameter {
  term?: string;
  searchField?: string;
  limit: number;
  sortBy?: string;
  sortDirection: string;
}
export interface UpdateUsersFormBody {
  id?: string;
  name?: string;
  email?: string;
  designation?: string;
  joining_date?: string;
  manager?: Employee;
}

export interface UserSearchParameters {
  name?: string;
  email?: string;
  designation?: string;
  joiningDateBefore?: Date | string;
  joiningDateAfter?: Date | string;
  leavingDateBefore?: Date | string;
  leavingDateAfter?: Date | string;
  employmentType?: string;
  department?: string;
  subDepartment?: string;
  manager?: Partial<Employee> | null;
  pan?: string;
  isActive?: boolean | string;
  sortBy: string;
  sortDirection: string;
  limit: number;
  page: number;
}

export interface UserData {
  users: Employee[];
  limit: number;
  page: number;
  totalMatches: number;
}
export type UserTypes =
  | OnCreateUsersAction
  | OnGetUsersAction
  | OnUpdateUsersAction
  | OnActivateUsersAction
  | OnDeactivateUsersAction
  | OnUpdateUsersManagerAction;
