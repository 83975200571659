import {
  Button,
  Divider,
  LinearProgress,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import LockClockIcon from '@mui/icons-material/LockClock';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { formatAsTime } from '../common';
import { useExtractParams } from '../constants';
import { Timelog, TrackEntryProps } from '../types';
import { AddTimeDialogFormType, TimeDialogForm } from './TimeDialogForm';
import { addTimelog, deleteTimelog, updateTimelog } from '../actions';

const initialValues: AddTimeDialogFormType = {
  sow: {
    id: '',
    name: '',
    project: {
      id: '',
      name: '',
    },
  },
  task: {
    id: '',
    name: '',
  },
  notes: '',
  noOfHours: 0,
};

export default function TrackDay({
  timelogs,
  sows,
  setTimelogs,
  initialShowAddEntryDialog,
  setInitialShowAddEntryDialog,
}: TrackEntryProps): JSX.Element {
  const [showLoading, setShowLoading] = useState(false);
  const [showAddEntryDialog] = useState(initialShowAddEntryDialog || false);
  const [timeEntryUnderEdit, setTimeEntryUnderEdit] = useState<Timelog | null>(null);
  const { date, employeeId } = useExtractParams();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const timelogsForTheDay = timelogs?.filter((t) => t.date.getDate() === date.getDate());
  const weekTotal = timelogs?.reduce((acc, t) => acc + t.noOfHours, 0);
  return (
    <>
      <TimeDialogForm
        date={date}
        title="Add New Entry"
        open={showAddEntryDialog}
        sows={sows}
        initialValues={initialValues}
        onClose={() => setInitialShowAddEntryDialog(false)}
        onSubmit={async (values) => {
          try {
            setShowLoading(true);
            const timelog = await dispatch(
              addTimelog({ ...values, date, employee: { id: employeeId, name: 'Employee' } }),
            );
            setTimelogs([...(timelogs || []), timelog]);
            enqueueSnackbar('Time entry added successfully', { variant: 'success' });
            setInitialShowAddEntryDialog(false);
          } catch (error) {
            enqueueSnackbar((error as Error).message, { variant: 'error' });
          }
          setShowLoading(false);
        }}
      />
      <TimeDialogForm
        date={date}
        title="Update Time Entry"
        open={!!timeEntryUnderEdit}
        sows={sows}
        allowDelete
        initialValues={timeEntryUnderEdit || initialValues}
        onClose={() => setTimeEntryUnderEdit(null)}
        onDelete={async () => {
          try {
            if (!timeEntryUnderEdit || !timelogs) {
              return;
            }
            setShowLoading(true);
            await dispatch(deleteTimelog(timeEntryUnderEdit.id));
            setTimelogs((timelogs || []).filter((t) => t.id !== timeEntryUnderEdit.id));
            enqueueSnackbar('Time entry deleted successfully', { variant: 'success' });
            setTimeEntryUnderEdit(null);
          } catch (error) {
            enqueueSnackbar((error as Error).message, { variant: 'error' });
          } finally {
            setShowLoading(false);
          }
        }}
        onSubmit={async (values) => {
          try {
            if (!timeEntryUnderEdit || !timelogs) {
              return;
            }
            setShowLoading(true);
            const { id } = timeEntryUnderEdit;
            const _t = await dispatch(
              updateTimelog(id, {
                ...values,
                date,
                employee: { id: employeeId, name: 'Employee' },
              }),
            );
            const timelog = _t as unknown as Timelog;
            setTimelogs(timelogs.map((t) => (t.id === timelog.id ? timelog : t)));
            enqueueSnackbar('Time entry updated successfully', { variant: 'success' });
            setTimeEntryUnderEdit(null);
          } catch (error) {
            enqueueSnackbar((error as Error).message, { variant: 'error' });
          }
          setShowLoading(false);
        }}
      />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {<DayTabs timelogs={timelogs} />}
        <Stack alignItems={'end'} sx={{ px: 2, py: 1.5 }}>
          <Typography variant="h4" sx={{ marginBottom: '5px', color: 'primary.grey' }}>
            Week Total
          </Typography>
          <Typography variant="h4">{formatAsTime(weekTotal || 0)}</Typography>
        </Stack>
      </Stack>
      <Divider />
      {showLoading && <LinearProgress />}
      {timelogs && timelogsForTheDay && timelogsForTheDay.length > 0 && (
        <Table
          sx={{
            '& .MuiTypography-h3, & .MuiTypography-h4': {
              fontSize: [14, 18],
            },
            '& .MuiTypography-h4': {
              ml: [0, 1],
            },
          }}
        >
          <TableBody>
            {timelogsForTheDay.map((log) => (
              <TableRow key={log.id}>
                <TableCell>
                  <Stack direction="row" flexWrap="wrap" alignItems="center" mb={1}>
                    <Typography variant="h3" color="secondary">
                      {log.sow.project.name} / {log.sow.name}
                    </Typography>
                    <Typography variant="h4" sx={{ color: 'primary.grey' }} ml={1}>
                      ({log.task.name})
                    </Typography>
                  </Stack>
                  <Typography color="secondary">{log.notes}</Typography>
                </TableCell>
                {/* <TableCell>{log.task.name}</TableCell>
                <TableCell>{log.notes}</TableCell> */}
                <TableCell sx={{ textAlign: 'right' }}>
                  <Typography variant="h3">{formatAsTime(log.noOfHours)}</Typography>
                </TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  {log.status === 'APPROVED' && (
                    <Button
                      variant="outlined"
                      disabled
                      endIcon={<LockClockIcon fontSize="small" />}
                    >
                      Approved
                    </Button>
                  )}
                  {log.status !== 'APPROVED' && (
                    <Button variant="text" onClick={() => setTimeEntryUnderEdit(log)}>
                      <img src="/images/edit.svg" alt="edit icon" />
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
            <TableRow sx={{ background: 'rgba(72, 120, 245, 0.05)' }}>
              <TableCell>
                <Typography variant="h4" fontWeight="400" color="secondary" textAlign="right">
                  Total
                </Typography>
              </TableCell>
              {/* <TableCell />
              <TableCell /> */}
              <TableCell sx={{ textAlign: 'right' }}>
                <Typography variant="h3">
                  {formatAsTime(timelogsForTheDay.reduce((acc, t) => acc + t.noOfHours, 0))}
                </Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      )}
      {timelogs && timelogsForTheDay?.length == 0 && (
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>No timelogs for this day</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </>
  );
}

function DayTabs({ timelogs }: { timelogs?: Array<Timelog> }): JSX.Element {
  const { date, weekStart, mode, employeeId, path } = useExtractParams();
  const dates = new Array(7).fill(0).map((_, i) => {
    const d = new Date(weekStart);
    d.setDate(d.getDate() + i);
    return d;
  });
  return (
    <Tabs
      value={date.toISOString()}
      sx={{
        '& .MuiTab-root': {
          textAlign: 'left',
          '& div': {
            alignItems: 'flex-start',
          },
          '& h4': { color: 'primary.grey', marginBottom: '5px' },
          '& h6': { color: 'primary.grey', fontWeight: 400 },
          '&.Mui-selected': {
            '& h4': { color: 'primary.darkBlue' },
            '& h6': { color: 'primary.darkBlue', fontWeight: 600 },
          },
        },
        '& .MuiTabs-scroller': {
          overflow: 'auto !important',
        },
      }}
    >
      {dates.map((d) => (
        <Tab
          key={d.toISOString()}
          label={
            <Stack alignItems={'end'}>
              <Typography variant="h4">
                {d.toLocaleDateString(undefined, { weekday: 'short' })}
              </Typography>
              <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                {formatAsTime(
                  timelogs
                    ?.filter((t) => t.date.getDate() === d.getDate())
                    .reduce((acc, t) => acc + t.noOfHours, 0) || 0,
                )}
              </Typography>
            </Stack>
          }
          value={d.toISOString()}
          component={RouterLink}
          to={generatePath(path, {
            mode: mode,
            year: d.getFullYear().toString(),
            month: (d.getMonth() + 1).toString(),
            day: d.getDate().toString(),
            employeeId: employeeId,
          })}
        />
      ))}
    </Tabs>
  );
}
