import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import { RootState } from '../../../redux-store/reducers';
import { getPhases, getResources, sendUnallocatedEmail } from '../actions';
import { ResourceState } from '../store/resourceReducer';
import { formatShortDate } from '../../../utils/date';
import { ResourceRoles, RESOURCE_SEARCH_OPTIONS } from '../constants';
import { OnlyIfPermission, TableBodyCell, TableCellText, TableHeadCell } from '../../common';
import AllocationsFilters from './Filters';
import { PaginationLayout } from '../../time-tracking/approve/CustomLayouts';
import { ChipTag } from '../projects/List';
import { Phase } from '../types';

export default function ResourceList(): JSX.Element {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const location = useLocation();
  const [showProgress, setShowProgress] = useState(false);
  const [phasesList, setPhasesList] = useState<Array<Phase>>();

  const { resources, searchOptions } = useSelector<RootState, ResourceState>(
    (state) => state.delivery.resources,
  );

  const handlePageSizeChange = (pageSize: number) => {
    dispatch({
      type: RESOURCE_SEARCH_OPTIONS,
      searchOptions: {
        ...searchOptions,
        limit: pageSize,
      },
    });
  };
  useEffect(() => {
    async function getResourceList() {
      try {
        setShowProgress(true);
        await dispatch(getResources(searchOptions));
      } catch (error) {
        console.log(error);
      } finally {
        setShowProgress(false);
      }
    }
    getResourceList();
  }, [searchOptions]);
  useEffect(() => {
    async function getPhasesList() {
      try {
        setShowProgress(true);
        const phases_ = await dispatch(getPhases());
        setPhasesList(phases_ as unknown as Array<Phase>);
      } catch (error) {
        console.log(error);
      } finally {
        setShowProgress(false);
      }
    }
    getPhasesList();
  }, []);

  async function sendMeUnallocatedEmail() {
    await dispatch(sendUnallocatedEmail());
  }

  return (
    <Box sx={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
      <Box
        sx={{
          width: '100%',
          zIndex: '999',
          px: 2,
          flex: '0 1 auto',
          position: 'relative',
        }}
      >
        <Stack flexDirection="row" alignItems="center" justifyContent="space-between" pb={2}>
          <Typography variant="h2">Allocations</Typography>
          <Grid item>
            <OnlyIfPermission name="delivery:allocations:list">
              <Button
                sx={{ mr: 2 }}
                variant="contained"
                fullWidth={false}
                onClick={sendMeUnallocatedEmail}
              >
                Email me UnAllocated
              </Button>
            </OnlyIfPermission>
            <OnlyIfPermission name="delivery:allocations:create">
              <Button
                variant="contained"
                component={Link}
                to={{ pathname: `${url}/create` }}
                fullWidth={false}
              >
                New Allocation
              </Button>
            </OnlyIfPermission>
          </Grid>
        </Stack>
        <Divider
          sx={{
            width: 'calc(100% + 64px)',
            ml: -4,
          }}
        />
        <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
          <AllocationsFilters />
        </Stack>
        <Divider
          sx={{
            width: 'calc(100% + 64px)',
            ml: -4,
          }}
        />
      </Box>
      {showProgress && <LinearProgress />}
      <Box
        sx={{
          flex: '1 1 auto',
          px: 0,
          overflow: 'auto',
        }}
      >
        <TableContainer sx={{ p: 0 }}>
          <Table aria-label="resource table">
            <TableHead>
              <TableRow
                sx={{
                  '& .MuiTableCell-root': {
                    '&:first-of-type': { borderLeft: 0 },
                    '&:last-child': { borderRight: 0 },
                  },
                }}
              >
                <TableHeadCell
                  sx={{
                    borderLeft: 'none',
                  }}
                >
                  <TableCellText
                    text="Employee Name"
                    sx={{ fontWeight: 700, textAlign: 'left' }}
                    showDropDown={true}
                  />
                </TableHeadCell>
                <TableHeadCell>
                  <TableCellText text="Role" />
                </TableHeadCell>
                <TableHeadCell>
                  <TableCellText text="SOW" />
                </TableHeadCell>
                <TableHeadCell>
                  <TableCellText text="Client" />
                </TableHeadCell>
                <TableHeadCell>
                  <TableCellText text="Start Date" />
                </TableHeadCell>
                <TableHeadCell>
                  <TableCellText text="End Date" />
                </TableHeadCell>
                <TableHeadCell>
                  <TableCellText text="Allocated Hr / Week" />
                </TableHeadCell>
                <TableHeadCell>
                  <TableCellText text="Billing Rate" />
                </TableHeadCell>
                <OnlyIfPermission name="delivery:allocations:update">
                  <TableHeadCell>
                    {' '}
                    <TableCellText text="Actions" />
                  </TableHeadCell>
                </OnlyIfPermission>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                '& .MuiTableCell-root': {
                  '&:first-of-type': { borderLeft: 0 },
                  '&:last-child': { borderRight: 0 },
                },
              }}
            >
              {resources.map((resource, index) => (
                <TableRow key={resource.id}>
                  <TableBodyCell sx={{ borderLeft: 'none' }}>
                    <Button
                      component={Link}
                      to={{
                        pathname: `${url}/${resource.employee.id}`,
                        state: {
                          background: location,
                          resources: resources.filter(
                            (r) => r.employee.id === resource.employee.id,
                          ),
                        },
                      }}
                      sx={{ textTransform: 'none', justifyContent: 'space-between' }}
                    >
                      <Typography sx={{ px: 1 }}>{index + 1}.</Typography>
                      {resource.employee.name}
                    </Button>
                  </TableBodyCell>
                  <TableBodyCell>
                    {ResourceRoles.find((r) => r.value == resource.role)?.label}
                  </TableBodyCell>
                  <TableBodyCell>
                    <>
                      <Chip
                        label={resource.assignedTo.name}
                        icon={<ChipTag />}
                        sx={{
                          '& .MuiChip-label': {
                            px: 1,
                          },
                        }}
                      />
                    </>
                  </TableBodyCell>
                  <TableBodyCell>
                    <Button
                      component={Link}
                      to={{
                        pathname: `${url}/projects/${resource.assignedTo.project.id}`,
                        state: {
                          background: location,
                          phaseDetails: phasesList?.filter(
                            (phase) => phase.project.id === resource.assignedTo.project.id,
                          ),
                        },
                      }}
                      sx={{ textTransform: 'none', justifyContent: 'space-between' }}
                    >
                      <img src="/images/company_logo.svg" alt="logo" />
                      <Typography sx={{ ml: 3 }} color="primary.blueBg">
                        {resource.assignedTo.project.name}
                      </Typography>
                    </Button>
                  </TableBodyCell>
                  <TableBodyCell>{formatShortDate(resource.startDate)}</TableBodyCell>
                  <TableBodyCell>{formatShortDate(resource.endDate)}</TableBodyCell>
                  <TableBodyCell>{resource.capacity}</TableBodyCell>
                  <TableBodyCell>{resource.billingRate}</TableBodyCell>
                  <TableBodyCell>
                    <OnlyIfPermission name="delivery:allocations:update">
                      <Button
                        sx={{ py: 0.25 }}
                        to={{
                          pathname: `${url}/update/${resource.id}`,
                        }}
                        component={Link}
                      >
                        <img src="/images/updateIcon.svg" alt="Edit" />
                      </Button>
                    </OnlyIfPermission>
                  </TableBodyCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <PaginationLayout
        totalPageCount={25}
        // onPageChange={handlePageChange as unknown as (page: number) => void}
        onPageSizeChange={handlePageSizeChange as unknown as (pageSize: number) => void}
      />
    </Box>
  );
}
