import { Box, Drawer } from '@mui/material';
import { Location } from 'history';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router';
import CreatePhase from './Create';
import PhaseList from './List';
import UpdatePhase from './Update';
import PhaseDetails from './Details';
import ManageTasks from './ManageTasks';

export default function PhaseRouter(): JSX.Element {
  const { url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation<{ background: Location }>();
  const background = location.state && location.state.background;
  return (
    <>
      <Switch location={background || location}>
        <Route exact path={`${url}/create`} component={CreatePhase} />
        <Route path={`${url}/update/:id`} component={UpdatePhase} />
        <Route exact path={`${url}/tasks/:id`} component={ManageTasks} />
        <Route path={`${url}/:id`} component={PhaseDetails} />
        <Route exact path={url} component={PhaseList} />
      </Switch>
      <Drawer
        open={!!background}
        anchor="right"
        onClose={() => {
          history.goBack();
        }}
      >
        <Box sx={{ width: 420 }} role="presentation">
          <Switch>
            <Route exact path={`${url}/create`} component={CreatePhase} />
            <Route path={`${url}/update/:id`} component={UpdatePhase} />
            <Route exact path={`${url}/:id`} component={PhaseDetails} />
            <Route exact path={`${url}/tasks/:id`} component={ManageTasks} />
          </Switch>
        </Box>
      </Drawer>
    </>
  );
}
