import { Stack, Tab, Tabs, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Link as RouterLink,
  useRouteMatch,
  generatePath,
  useHistory,
  useLocation,
} from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Formik } from 'formik';
import { getPhases } from '../../delivery/actions';
import { SOWType } from '../types';
import FormSelect from '../../forms/FormSelect';
import { periods, useExtractParams } from './constants';
import { LocationDescriptor } from 'history';

function useTabUrls() {
  const { search, pathname } = useLocation();
  const pathComponents = pathname.split('/').slice(0, -1);
  return {
    teamReportsUrl: { pathname: pathComponents.concat('team').join('/'), search },
    projectSummaryReportUrl: { pathname: pathComponents.concat('sow').join('/'), search },
    taskReportsUrl: { pathname: pathComponents.concat('tasks').join('/'), search },
  };
}

export default function TeamReportsHeader(): JSX.Element {
  const {
    dateLabel,
    isCurrentPeriod,
    periodLabel,
    sow,
    period,
    prevUrl,
    nextUrl,
    startDateString,
    currentPeriodUrl,
    returnToCurrentPeriodLabel,
  } = useExtractParams();

  const { projectSummaryReportUrl, teamReportsUrl, taskReportsUrl } = useTabUrls();
  const { path } = useRouteMatch();
  const pathComponent = path.split('/').pop();
  const history = useHistory();

  function handleChange(newSOW: string | undefined, newPeriod: string) {
    if (newSOW == sow && newPeriod == period) {
      return;
    }
    const searchParams = new URLSearchParams({
      period: newPeriod,
      startDate: startDateString,
    });
    if (newSOW) {
      searchParams.set('sow', newSOW);
    }

    //navigate to new url
    const location: LocationDescriptor<unknown> = {
      pathname: generatePath(path),
      search: searchParams.toString(),
    };
    history.replace(location);
  }

  function handleSOWChange(newSow: string) {
    handleChange(newSow, period);
  }

  function handlePeriodChange(newPeriod: string) {
    handleChange(sow, newPeriod);
  }

  const periodValues: Array<{ label: string; value: string }> = [...periods] as Array<{
    label: string;
    value: string;
  }>;
  const dispatch = useDispatch();
  const [sows, setSows] = useState<Array<SOWType>>([]);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    async function getAllPhases() {
      try {
        const _sows = await dispatch(getPhases());
        setSows(_sows as unknown as Array<SOWType>);
      } catch (error) {
        enqueueSnackbar(`Could not fetch SOWS: ${(error as Error).message}`, { variant: 'error' });
      }
    }
    getAllPhases();
  }, []);
  const sowOptions = [{ label: 'Any', value: '' }].concat(
    sows
      .filter((sow) => sow.project.name !== 'WebileApps')
      .map((sow) => ({
        label: sow.name,
        value: sow.id,
      })),
  );
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
      flexGrow={1}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        {period !== 'all' && (
          <ToggleButtonGroup color="primary">
            <ToggleButton
              sx={{ py: '6px', px: 1.5, borderRadius: 20 }}
              value="prev"
              component={RouterLink}
              to={prevUrl}
              size="small"
            >
              <ArrowBackIcon fontSize="small" />
            </ToggleButton>
            <ToggleButton
              sx={{ py: '6px', px: 1.5, borderRadius: 20 }}
              value="next"
              component={RouterLink}
              to={nextUrl}
              size="small"
            >
              <ArrowForwardIcon fontSize="small" />
            </ToggleButton>
          </ToggleButtonGroup>
        )}
        <Typography variant="subtitle1">{periodLabel}</Typography>
        <Typography variant="subtitle1">{dateLabel}</Typography>
        {!isCurrentPeriod && (
          <RouterLink to={currentPeriodUrl}>{returnToCurrentPeriodLabel}</RouterLink>
        )}
      </Stack>
      <Tabs value={pathComponent}>
        <Tab
          value={'sow'}
          component={RouterLink}
          label="Project Summary"
          to={projectSummaryReportUrl}
        />
        <Tab value={'team'} label="Team" component={RouterLink} to={teamReportsUrl} />
        <Tab value={'tasks'} label="Tasks" component={RouterLink} to={taskReportsUrl} />
      </Tabs>
      <Formik
        initialValues={{ sow, period }}
        onSubmit={() => {
          console.log('Form Submitted');
        }}
      >
        {() => (
          <Stack direction="row">
            <FormSelect
              label="SOW"
              value={sow}
              options={sowOptions}
              name="sow"
              sx={{ width: '150px', mr: 4 }}
              onChange={async (event) => {
                handleSOWChange(event.target.value as string);
              }}
            />
            <FormSelect
              label="Period"
              value={period}
              options={periodValues}
              name="period"
              sx={{ width: '150px' }}
              onChange={async (event) => {
                handlePeriodChange(event.target.value as string);
              }}
            />
          </Stack>
        )}
      </Formik>
    </Stack>
  );
}
