import { Box, Drawer } from '@mui/material';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router';
import { Location } from 'history';
import CreateTicket from './CreateTicket';
import Details from './Details';
import TicketsData from './Tickets';
import UpdateTicket from './UpdateTicket';

export default function TicketRouter(): JSX.Element {
  const { path } = useRouteMatch();
  const history = useHistory();
  const location = useLocation<{ background: Location }>();
  const background = location.state && location.state.background;

  return (
    <>
      <Switch location={background || location}>
        <Route path={`${path}/add`} component={CreateTicket} />
        <Route path={`${path}/:id`} component={Details} />
        <Route path={`${path}/update/:id`} component={UpdateTicket} />
        <Route exact path={path} component={TicketsData} />
      </Switch>
      <Drawer
        open={!!background}
        anchor="right"
        onClose={() => {
          history.goBack();
        }}
      >
        <Box sx={{ width: 420 }} role="presentation">
          <Switch>
            <Route exact path={`${path}/add`} component={CreateTicket} />
            <Route path={`${path}/update/:id`} component={UpdateTicket} />
            <Route exact path={path} component={TicketsData} />
            <Route exact path={`${path}/:id`} component={Details} />
          </Switch>
        </Box>
      </Drawer>
    </>
  );
}
