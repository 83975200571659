import {
  Button,
  Grid,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  SelectProps,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { onGetTickets } from '../../redux-store/actions/tickets';
import { RootStateType } from '../../redux-store/reducers';
import { TicketState } from '../../redux-store/reducers/tickets';
import { SET_SEARCH_OPTIONS_TYPE } from '../../redux-store/types/tickets';
import { TicketDetails } from '../../redux-store/types/tickets';
//import SearchOptions from "../assets/SearchOptions";
import { ButtonPrimary } from '../forms/Button';
import { useSnackbar } from 'notistack';
import TicketsSearchOptions from './SearchOptions';

const ticketheaders = ['No', 'Title', 'Category', 'Created By', 'Assigned To', 'Actions'];

export default function TicketsData(): JSX.Element {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [showProgress, setShowProgress] = useState(false);
  const [openOptions, setOpenOptionsType] = useState(false);
  const { tickets, searchOptions, searchOptionType } = useSelector<RootStateType, TicketState>(
    (state) => state.tickets,
  );

  useEffect(() => {
    async function _onGetTickets() {
      try {
        setShowProgress(true);
        (await dispatch(onGetTickets(searchOptions))) as unknown as TicketDetails[];
      } catch (error) {
        enqueueSnackbar((error as Error).message, { variant: 'error' });
      } finally {
        setShowProgress(false);
      }
    }
    _onGetTickets();
  }, [searchOptions]);

  const handleOptionsTypeChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    if (value === 'custom') {
      setOpenOptionsType(true);
    } else {
      dispatch({ type: SET_SEARCH_OPTIONS_TYPE, searchOptionType: value });
    }
  };

  return (
    <>
      <TicketsSearchOptions
        open={openOptions}
        onClose={() => {
          setOpenOptionsType(false);
        }}
      ></TicketsSearchOptions>

      <Grid pb={2} spacing={2} container justifyContent="space-between">
        <Grid item>
          <Stack spacing={2} direction="row">
            <SearchOptionsSelect
              defaultValue={searchOptionType}
              onChange={handleOptionsTypeChange}
              autoWidth
            />
            <ButtonPrimary
              variant="outlined"
              onClick={() => {
                setOpenOptionsType(true);
              }}
            >
              Custom Filters
            </ButtonPrimary>
          </Stack>
        </Grid>
        <Grid item>
          <Button
            component={Link}
            variant="outlined"
            to={{ pathname: `${url}/add`, state: { background: location } }}
          >
            Create Ticket
          </Button>
        </Grid>
      </Grid>
      {showProgress && <LinearProgress />}

      <TableContainer component={Paper}>
        <Table aria-label="tickets table">
          <TableHead>
            <TableRow>
              {ticketheaders.map((head) => (
                <TableCell sx={{ fontWeight: 'bold' }} key={head}>
                  {head}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tickets.map((ticket, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Button
                    to={{
                      pathname: `/admin/tickets/${ticket.id}`,
                      state: { background: location },
                    }}
                    component={Link}
                  >
                    {ticket.ticketNumber || ticket.id}
                  </Button>
                </TableCell>
                <TableCell>{ticket.title}</TableCell>
                <TableCell>{`${ticket.category}-${ticket.subcategory}`}</TableCell>
                <TableCell>{ticket.createdBy.name}</TableCell>
                <TableCell>{ticket.assignedTo ? ticket.assignedTo.name : 'None'}</TableCell>
                <TableCell>
                  <Button
                    to={{
                      pathname: `/admin/tickets/update/${ticket.id}`,
                      state: { background: location },
                    }}
                    component={Link}
                  >
                    Update
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
function SearchOptionsSelect(props: SelectProps<string>) {
  return (
    <Select label="Quick Filters" variant="standard" {...props}>
      <MenuItem value="assignedToMeAndOpen">Assigned To Me</MenuItem>
      <MenuItem value="createdByMe">Created By Me</MenuItem>
      <MenuItem value="all">All Tickets</MenuItem>
      <MenuItem value="unallocatedOpen">Unallocated Open</MenuItem>
      <MenuItem value="allocatedOpen">Allocated Open</MenuItem>
      <MenuItem value="recentlyClosed">Recently Closed</MenuItem>
      <MenuItem value="recentlyCreated">Recently Created</MenuItem>
    </Select>
  );
}
