import { AnyAction } from 'redux';
import { RESET_SEARCH_OPTIONS } from '../types/asset';
import { LOGOUT_SUCCESS } from '../types/login';
import {
  GET_USERS_SUCCESS,
  CREATE_USERS_SUCCESS,
  UPDATE_USERS_SUCCESS,
  ACTIVATE_USERS_SUCCESS,
  Employee,
  SET_USERS_SEARCH_OPTIONS,
  SET_USERS_SEARCH_OPTIONS_TYPE,
  UserData,
  SET_PAGE_NUMBER,
  UPDATE_MANAGER,
  UserSearchParameters,
  SET_UNASSIGN_MANAGER,
} from '../types/users';

export type UsersState = UserData & {
  searchOptionType: string;
  searchOptions: UserSearchParameters;
};

export const allUsers: UserSearchParameters = {
  name: '',
  email: '',
  designation: '',
  joiningDateBefore: '',
  joiningDateAfter: '',
  leavingDateBefore: '',
  leavingDateAfter: '',
  employmentType: '',
  department: '',
  subDepartment: '',
  manager: null,
  pan: '',
  isActive: true,
  sortBy: 'name',
  sortDirection: 'asc',
  limit: 100,
  page: 1,
};

export const recentlyUpdated: UserSearchParameters = {
  ...allUsers,
  sortBy: 'updatedAt',
};

export const noManagers: UserSearchParameters = {
  ...allUsers,
  manager: { id: 'none', name: 'None' },
};

export const recentlyJoined: UserSearchParameters = {
  ...allUsers,
  sortBy: 'joining_date',
};

export const trainees: UserSearchParameters = {
  ...allUsers,
  designation: 'trainee',
};

export const searchOptionsType: Record<string, UserSearchParameters> = {
  allUsers: allUsers,
  recentlyUpdated: recentlyUpdated,
  noManagers: noManagers,
  recentlyJoined: recentlyJoined,
  trainees: trainees,
};

const initialState: UsersState = {
  users: [],
  limit: 100,
  page: 0,
  totalMatches: 0,
  searchOptionType: 'allUsers',
  searchOptions: allUsers,
};
export default function usersReducer(state = initialState, action: AnyAction): UsersState {
  switch (action.type) {
    case GET_USERS_SUCCESS: {
      return {
        ...state,
        ...action.users,
      };
    }
    case CREATE_USERS_SUCCESS: {
      return {
        ...state,
        users: [action.body, ...state.users],
      };
    }
    case UPDATE_USERS_SUCCESS: {
      return {
        ...state,
        users: state.users.map((user: Employee) =>
          user.id === action.user.id ? { ...user, ...action.user } : user,
        ),
      };
    }
    case ACTIVATE_USERS_SUCCESS: {
      const { body } = action;
      return {
        ...state,
        ...body,
      };
    }
    case UPDATE_MANAGER: {
      return {
        ...state,
        users: state.users.map((user: Employee) =>
          user.id === action.user.id ? { ...user, ...action.body } : user,
        ),
      };
    }
    case SET_UNASSIGN_MANAGER: {
      return {
        ...state,
        users: state.users.map((user: Employee) =>
          user.id === action.user.id ? { ...user, ...action.body } : user,
        ),
      };
    }

    case SET_USERS_SEARCH_OPTIONS: {
      return {
        ...state,
        searchOptions: { ...action.searchOptions },
        searchOptionType: 'custom',
      };
    }
    case SET_USERS_SEARCH_OPTIONS_TYPE: {
      return {
        ...state,
        searchOptionType: action.searchOptionType,
        searchOptions: {
          ...(searchOptionsType[action.searchOptionType] || allUsers),
        },
      };
    }
    case RESET_SEARCH_OPTIONS: {
      return {
        ...state,
        searchOptions: allUsers,
        searchOptionType: 'allUsers',
      };
    }
    case SET_PAGE_NUMBER: {
      return {
        ...state,
        searchOptions: { ...action.searchOptions },
        searchOptionType: 'custom',
      };
    }
    case LOGOUT_SUCCESS:
      return initialState;
  }
  return state;
}
