import { Box, Drawer } from '@mui/material';

import { Location } from 'history';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router';

import HolidaysList from './HolidaysList';
import CreateHoliday from './CreateHoliday';
import UpdateHoliday from './UpdateHoliday';

export default function HolidaysRouter(): JSX.Element {
  const { path } = useRouteMatch();
  const history = useHistory();
  const location = useLocation<{ background: Location }>();
  const background = location.state && location.state.background;

  return (
    <>
      <Switch location={background || location}>
        <Route path={`${path}/add`} component={CreateHoliday} />
        <Route path={`${path}/update/:id`} component={UpdateHoliday} />
        <Route exact path={path} component={HolidaysList} />
      </Switch>
      <Drawer
        open={!!background}
        anchor="right"
        onClose={() => {
          history.goBack();
        }}
      >
        <Box sx={{ width: 420 }} role="presentation">
          <Switch>
            <Route exact path={`${path}/add`} component={CreateHoliday} />
            <Route path={`${path}/update/:id`} component={UpdateHoliday} />
          </Switch>
        </Box>
      </Drawer>
    </>
  );
}
