import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import ProjectSummary from './sow';
import TaskReports from './tasks';
import TeamReports from './team';

export default function ReportsRouter(): JSX.Element {
  const { url } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${url}/sow`} component={ProjectSummary} />
      <Route path={`${url}/tasks`} component={TaskReports} />
      <Route path={`${url}/team`} component={TeamReports} />
      <Redirect exact path={url} to={`${url}/sow`} />
    </Switch>
  );
}
