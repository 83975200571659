import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { LinearProgress } from '@mui/material';
import { useHistory, useParams } from 'react-router';
import { UpdateUsersFormBody } from '../../redux-store/types/users';
import { useDispatch } from 'react-redux';
import {
  onGetUsersbyId,
  onUpdateManagerUsers,
  unassignManager,
} from '../../redux-store/actions/users';
import { useSnackbar } from 'notistack';
import { TitleBar } from '../assets/components';
import UpdateManagerForm from './UpdateManagerForm';

export default function UpdateManager(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const [userDetails, setUserDetails] = useState<UpdateUsersFormBody>();
  const dispatch = useDispatch();
  const [showProgress, setShowProgress] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  async function onGetUsers() {
    const userBody = await dispatch(onGetUsersbyId(id));
    const { manager } = userBody as unknown as UpdateUsersFormBody;
    setUserDetails({
      manager,
    });
  }
  useEffect(() => {
    onGetUsers();
  }, [id]);

  return (
    <>
      <TitleBar title="Assign Manager" />
      {showProgress && <LinearProgress />}
      {userDetails && (
        <Box sx={{ p: 2 }}>
          <UpdateManagerForm
            initialValues={userDetails}
            onSubmit={async (values) => {
              try {
                const { manager } = values;
                manager && manager !== null
                  ? await dispatch(onUpdateManagerUsers(id, manager))
                  : await dispatch(unassignManager(id));
                enqueueSnackbar(`Manager Updated Successfully`, { variant: 'success' });
                setShowProgress(false);
                setTimeout(history.goBack.bind(null), 1);
              } catch (error) {
                enqueueSnackbar((error as Error).message, { variant: 'error' });
              } finally {
                setShowProgress(false);
              }
            }}
          />
        </Box>
      )}
    </>
  );
}
