import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
} from '@mui/material';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import { FormEvent, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { RESET_SEARCH_OPTIONS } from '../../redux-store/types/asset';
import { Form } from 'formik';
import FormSelect from '../forms/FormSelect';
import FormTextInput from '../forms/FormTextInput';
import { UserSearchParameters, SET_USERS_SEARCH_OPTIONS } from '../../redux-store/types/users';
import FormUsersPicker from '../forms/FormUsersPicker';
import FormDatePicker from '../forms/FormDatePicker';
import { DEPARTMENTS, SUB_DEPARTMENTS } from './constants';
import DesignationsSelect from './FormDesignationsSelect';

const schema = Yup.object().shape({
  name: Yup.string(),
  email: Yup.string(),
  designation: Yup.string(),
  employmentType: Yup.string(),
  department: Yup.string(),
  subDepartment: Yup.string(),
  manager: Yup.object()
    .nullable()
    .shape({
      id: Yup.string().required('Employee is required'),
      name: Yup.string(),
    }),
  pan: Yup.string(),
});

export type SearchOptionDialogTypes = DialogProps;

export default function UsersSearchOptions(props: SearchOptionDialogTypes): JSX.Element {
  const formikRef = useRef<FormikProps<UserSearchParameters>>(null);
  const dispatch = useDispatch();
  const searchOptions = useSelector<RootStateType, UserSearchParameters>(
    (rootState) => rootState.users.searchOptions,
  );

  const handleApplyClick = (e: React.MouseEvent<HTMLElement>) => {
    if (formikRef.current) {
      formikRef.current.handleSubmit(e as unknown as FormEvent<HTMLFormElement>);
    }
  };

  const handleReset = () => {
    dispatch({ type: RESET_SEARCH_OPTIONS });
  };
  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setValues(searchOptions);
    }
  }, [searchOptions]);

  return (
    <Dialog scroll="paper" {...props}>
      <DialogTitle>Search Options</DialogTitle>
      <DialogContent dividers>
        <Formik
          innerRef={formikRef}
          validationSchema={schema}
          initialValues={searchOptions}
          onSubmit={(values) => {
            dispatch({ type: SET_USERS_SEARCH_OPTIONS, searchOptions: values });
            props.onClose &&
              props.onClose(
                new Event('submit', { cancelable: true, bubbles: true }),
                'escapeKeyDown',
              );
          }}
          enableReinitialize
        >
          {() => (
            <Form>
              <Divider>Important</Divider>
              <DesignationsSelect name="designation" label="Designation" />
              <FormSelect
                name="employmentType"
                label="Employment Type"
                options={[
                  { label: 'Full Time', value: 'FullTime' },
                  { label: 'Intern', value: 'Intern' },
                  { label: 'Contractor', value: 'Contractor' },
                ]}
              />
              <FormSelect
                name="department"
                label="Department"
                options={DEPARTMENTS.map((department) => ({
                  label: department,
                  value: department,
                }))}
              />
              <FormSelect
                name="subDepartment"
                label="Sub Department"
                options={SUB_DEPARTMENTS.map((subDepartment) => ({
                  label: subDepartment,
                  value: subDepartment,
                }))}
              />
              <FormUsersPicker name="manager" label="Manager" />
              <Divider>Other</Divider>
              <FormTextInput name="name" label="Name" />
              <FormTextInput name="email" label="Email" />
              <FormTextInput name="employeeId" label="Employee ID" />
              <FormTextInput name="pan" label="Pan" />
              <FormSelect
                name="isActive"
                label="Active"
                options={[
                  { label: 'Active', value: 'true' },
                  { label: 'Inactive', value: 'false' },
                ]}
              />
              <FormDatePicker name="joiningBefore" label="Joining Before" />
              <FormDatePicker name="joiningAfter" label="Joining After" />

              <Divider>Sorting</Divider>
              <FormSelect
                name="sortBy"
                label="Sort By"
                options={[
                  { label: 'Name', value: 'name' },
                  { label: 'Created At', value: 'createdAt' },
                  { label: 'Updated At', value: 'updatedAt' },
                  { label: 'Joining Date', value: 'joining_date' },
                ]}
              />
              <FormSelect
                name="sortDirection"
                label="Sort Order"
                options={[
                  { label: 'Ascending', value: 'asc' },
                  { label: 'Descending', value: 'desc' },
                ]}
              />
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReset} color="primary">
          Reset
        </Button>
        <Button onClick={handleApplyClick} variant="contained" color="primary">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}
