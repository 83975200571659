import React, { useEffect, useState } from 'react';
import { TitleBar } from '../../assets/components';
import { useHistory, useParams } from 'react-router';
import { getResourceCostById, updateCostById } from '../actions';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import UpdateForm from './UpdateForm';
import { Box } from '@mui/system';
import { UpdateBody } from '../types';
import { LinearProgress } from '@mui/material';

export default function CostUpdate(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const [showProgress, setShowProgress] = useState(false);
  const [details, setDetails] = useState<UpdateBody>();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    async function fetchUserCostById() {
      try {
        setShowProgress(true);
        const costDetails = await dispatch(getResourceCostById(id));
        setDetails(costDetails as unknown as UpdateBody);
      } catch (error) {
        enqueueSnackbar((error as Error).message, { variant: 'error' });
      } finally {
        setShowProgress(false);
      }
    }
    fetchUserCostById();
  }, [id]);

  const onSubmit = async (values: UpdateBody) => {
    try {
      const { capacity, ctc, expectedBillingPerHour, trackTime } = values;
      const reqBody = {
        employeeId: id,
        capacity,
        ctc,
        expectedBillingPerHour,
        trackTime,
      };
      setShowProgress(true);
      await dispatch(updateCostById(reqBody, id));
      enqueueSnackbar('Cost Updated Successfully', { variant: 'success' });
      setTimeout(history.goBack, 1);
    } catch (error) {
      enqueueSnackbar((error as Error).message, { variant: 'error' });
    } finally {
      setShowProgress(false);
    }
  };
  return (
    <>
      <TitleBar title="Update Costs and Billing Rates" />
      {showProgress && <LinearProgress />}
      <Box sx={{ m: 2 }}>
        {details && <UpdateForm initialValues={details} onSubmit={onSubmit} />}
      </Box>
    </>
  );
}
