// the types of objects are
// id is string
// description is string
// title is string
// created by is object

import { DateRange } from '@mui/lab/DateRangePicker/RangeTypes';
import { Employee } from './users';
export const GET_TICKETS = 'GET_TICKETS';
export const CREATE_TICKETS = 'CREATE_TICKETS';
export const GET_USERS_TICKETS_SUCCESS = 'GET_USERS_TICKETS_SUCCESS';
export const CREATE_TICKETS_SUCCESS = 'CREATE_TICKETS_SUCCESS';

export interface TicketPayload {
  category: string;
  subcategory: string;
  title: string;
  description: string;
}

export interface TicketSearchParameters {
  assignedTo?: null | string;
  createdBy?: string;
  category?: string;
  subcategory?: string;
  status?: string;
  createdAt?: DateRange<Date>;
  updatedAt?: DateRange<Date>;
  closedAt?: DateRange<Date>;
  orderBy?: string;
  orderDirection?: 'asc' | 'desc';
  limit?: number;
  offset?: number;
}

export interface TicketDetails {
  id: string;
  ticketNumber: string;
  assignedTo: Employee;
  active: boolean;
  closedAt: null;
  status: string;
  subcategory: string;
  category: string;
  title: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  updatedBy: string;
  history: HistoryDetails[];
  createdBy: Employee;
}

export interface HistoryDetails {
  id: string;
  title: string;
  ticket: TicketSubDetails;
  updatedBy: Employee;
}

export interface UpdateTicketFormBody {
  id?: string;
  title: string;
  status: string;
  assignedTo?: Employee;
}

interface TicketSubDetails {
  id: string;
  ticketnumber: string;
  assignedTo: Employee;
  active: boolean;
  closedAt: null;
  status: string;
  subcategory: string;
  category: string;
  title: string;
  description: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  updatedBy: string;
}

export interface TicketComment {
  title: string;
}

export interface AssignedObject {
  id: string | null;
  name?: string;
  email?: string;
}

export const ADD_TICKET = 'ADD_TICKET';
export const UPDATE_TICKET = 'UPDATE_TICKET';
export const REMOVE_TICKET = 'REMOVE_TICKET';
export const SET_TICKETS = 'SET_TICKETS';
export const SET_SEARCH_OPTIONS = 'SET_SEARCH_OPTIONS';
export const RESET_SEARCH_OPTIONS = 'RESET_SEARCH_OPTIONS';
export const SET_SEARCH_OPTIONS_TYPE = 'SET_SEARCH_OPTIONS_TYPE';
