/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PendingLeavesTable from '../PendingLeavesTable';
import AssignToForm from './AssignToForm';
import { HeaderProps } from '../../forms/Table/type';
import { onGetLeaves, onUpdateLeaveStatus } from '../actions';
import moment from 'moment';
import { Modal } from 'react-responsive-modal';
import { ButtonPrimary } from '../../forms/Button';
import { Stack } from '@mui/material';
import { Box } from '@mui/system';

const headers: HeaderProps[] = [
  {
    label: 'Start Date',
    stateKey: 'startDateVal',
  },
  {
    label: 'End Date',
    stateKey: 'endDateVal',
  },
  {
    label: 'Details',
    stateKey: 'details',
  },
  {
    label: 'Leave Type',
    stateKey: 'leaveType',
  },
  {
    label: 'Applied By',
    stateKey: 'appliedby',
  },
  {
    label: 'Approved By',
    stateKey: 'approvedby',
  },
  {
    label: 'Status',
    stateKey: 'status',
  },
  {
    label: 'Action',
    stateKey: '',
  },
];

function Leaves(props: any) {
  const [leaves, setLeaves] = useState<Array<{ key: string; value: string }>>([]);
  const [isResponseOpen, setIsResponseOpen] = useState<boolean>(false);
  const [responseHeading, setResponseHeading] = useState<string>('');
  const [responseMessage, setResponseMessage] = useState<string>('');
  const [isApproveOpen, setIsApproveOpen] = useState<boolean>(false);
  const [confirmMessage, setConfirmMessage] = useState<string>('');
  const [isConfirmSubmitting, setIsConfirmSubmitting] = useState<boolean>(false);
  const [updateStatus, setUpdateStatus] = useState<string>('');
  const [updateStatusObj, setUpdateStatusObj] = useState<Object>({});
  const [assignTo, setAssignTo] = useState<Object>({});
  const [isAssignToOpen, setIsAssignToOpen] = useState<boolean>(false);
  async function _onGetLeaves() {
    let employeeObj: any = {};
    employeeObj['startDate'] = '';
    employeeObj['endDate'] = '';
    employeeObj['status'] = '';
    employeeObj['employeeId'] = props.id;
    const leavesBody = await props.onGetLeaves(); //Here need to get subordinates leaves
    let requiredData =
      leavesBody.body &&
      Object.keys(leavesBody.body).map((key: any) => {
        let item = leavesBody.body[key];
        item['startDateVal'] = moment(item.startDate).format('Do MMM YYYY');
        item['endDateVal'] = moment(item.endDate).format('Do MMM YYYY');
        if (item.employee) {
          item['employeename'] = item.employee.name;
        }
        if (item.appliedBy) {
          item['appliedby'] = item.appliedBy.name;
        }
        if (item.approvedBy) {
          item['approvedby'] = item.approvedBy.name;
        }
        return item;
      });
    setIsApproveOpen(false);
    setLeaves(requiredData);
  }
  const handleResponseClose = () => {
    setIsResponseOpen(false);
  };
  const handleApproveClose = () => {
    setIsApproveOpen(false);
  };
  const onApprove = (values: any) => {
    setUpdateStatus('A');
    setUpdateStatusObj(values);
    setConfirmMessage('Do you really want to Approve !!!');
    setIsApproveOpen(true);
  };
  const onReject = (values: any) => {
    setUpdateStatus('R');
    setUpdateStatusObj(values);
    setConfirmMessage('Do you really want to Reject !!!');
    setIsApproveOpen(true);
  };
  const onApproveUser = async () => {
    let reqObj = {
      id: (updateStatusObj as any)._id,
      status: updateStatus == 'A' ? 'Approved' : updateStatus == 'R' ? 'Rejected' : '',
      actionPerformedBy: props.id,
    };
    let response = await props.onUpdateLeaveStatus(reqObj);
    if (!response) {
      //Unknown err
      setResponseHeading('Failed');
      setResponseMessage('Something went wrong. Please try again.');
      setIsResponseOpen(true);
    } else if (response.error) {
      //Failed
      setResponseHeading('Failed');
      setResponseMessage(response.error.message);
      setIsResponseOpen(true);
    } else {
      //Success
      _onGetLeaves();
      setResponseHeading('Success');
      setResponseMessage(response.body.message);
      setIsResponseOpen(true);
    }
  };
  const onAssignTo = (values: any) => {
    setAssignTo(values);
    setIsAssignToOpen(true);
  };
  const onAssignToLeave = () => {
    //Handling Asssign To functionality
  };
  const handleAssignToClose = () => {
    setIsAssignToOpen(false);
  };
  useEffect(() => {
    console.log(`Props id ${props.id}`);
    _onGetLeaves();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Stack>
        <Stack direction="row" sx={{ mt: '67px' }}>
          <Box
            sx={{
              width: '100%',
              margin: '30px',
              overflowX: 'hidden',
              bgcolor: 'primary.white',
            }}
          >
            <PendingLeavesTable
              data={leaves}
              headers={headers}
              onApprove={onApprove}
              onReject={onReject}
              onAssignTo={onAssignTo}
            />
          </Box>
        </Stack>
      </Stack>
      <Modal
        open={isResponseOpen}
        onClose={handleResponseClose}
        center
        //container={refModal.current}
        focusTrapped
      >
        <Stack>
          <h3>{responseHeading}</h3>
          <span>{responseMessage}</span>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Box sx={{ m: '10px' }}>
              <ButtonPrimary
                onClick={handleResponseClose}
                variant="outlined"
                fullWidth={false}
                color="primary"
              >
                Ok
              </ButtonPrimary>
            </Box>
          </Box>
        </Stack>
      </Modal>
      <Modal
        open={isApproveOpen}
        onClose={handleApproveClose}
        center
        //container={refModal.current}
        focusTrapped
      >
        <Stack>
          <h3>Confirmation</h3>
          <span>{confirmMessage}</span>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Box sx={{ m: '10px' }}>
              <ButtonPrimary
                onClick={onApproveUser}
                variant="outlined"
                fullWidth={false}
                color="primary"
                disabled={isConfirmSubmitting}
              >
                Yes
              </ButtonPrimary>
            </Box>
            <Box sx={{ m: '10px' }}>
              <ButtonPrimary
                onClick={handleApproveClose}
                variant="outlined"
                fullWidth={false}
                color="secondary"
                disabled={isConfirmSubmitting}
              >
                No
              </ButtonPrimary>
            </Box>
          </Box>
        </Stack>
      </Modal>
      {assignTo ? (
        <Modal
          open={isAssignToOpen}
          onClose={handleAssignToClose}
          center
          //container={refModal.current}
          focusTrapped
        >
          <AssignToForm onAssignToLeave={onAssignToLeave} data={assignTo} />
        </Modal>
      ) : null}
    </>
  );
}
export default connect(
  (state: any) => {
    const { authReducer = {} } = state;
    return { id: authReducer?.id };
  },
  {
    onGetLeaves,
    onUpdateLeaveStatus,
  },
)(Leaves);
