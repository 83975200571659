import React, { useContext } from 'react';
import {
  AppBar,
  Box,
  Button,
  CardMedia,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  styled,
  TableCell,
  TableCellProps,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux-store/actions/login';
import { RootState } from '../../redux-store/reducers';
import { HeaderToggleContext } from '../../HeaderToggleContext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SxProps } from '@mui/system';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export function WAAppBar(): JSX.Element {
  const dispatch = useDispatch();
  const { headerToggle, setHeaderToggle } = useContext(HeaderToggleContext);
  return (
    <AppBar sx={{ bgcolor: 'primary.darkBlue' }} position="sticky">
      <Toolbar>
        <Button
          onClick={() => setHeaderToggle(!headerToggle)}
          sx={{
            px: 1,
            minWidth: 'unset',
            display: ['block', 'none'],
            mr: 2,
          }}
        >
          <CardMedia
            sx={{ width: 20 }}
            component="img"
            alt="logo"
            src={`/images/${headerToggle ? 'close' : 'toggle-bar'}.svg`}
          />
        </Button>
        <CardMedia
          component="img"
          alt="logo"
          src="/images/webileapps.svg"
          sx={{
            height: '35px',
            width: '35px',
          }}
        />
        <Typography
          variant="h6"
          sx={{
            px: 2,
            fontSize: '20px',
            fontWeight: 400,
            display: ['none', 'block'],
            color: 'primary.white',
          }}
        >
          WebileApps: Employee Self Service
        </Typography>
        <Stack direction="row" justifyContent="flex-end" flexGrow={1}>
          <Tooltip title="Logout">
            <IconButton
              sx={{ color: '#fff' }}
              size="small"
              onClick={() => {
                dispatch(logout());
              }}
            >
              <LogoutIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

export interface IDataValues {
  label: string;
  value?: string | number;
  half?: boolean;
}

const isUrl = (value: string) =>
  !!value &&
  !/\S+@\S+\.\S+/.test(value) &&
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g.test(
    value,
  );
const formatAsLink = (value: string) => {
  return value.startsWith('http') ? value : `https://${value}`;
};
export function DataValues({ label, value, half = false }: IDataValues): JSX.Element {
  return (
    <Grid item xs={half ? 6 : 12} sx={{ mt: 0.5 }}>
      <Typography variant="caption" sx={{ color: 'text.secondary' }}>
        {label}
      </Typography>
      {typeof value === 'string' && isUrl(value) ? (
        <Typography>
          <a href={formatAsLink(value)} target="_blank" rel="noopener noreferrer">
            {value}
          </a>
        </Typography>
      ) : (
        <Typography variant="h6">{value}</Typography>
      )}
    </Grid>
  );
}
export function OnlyIfPermission({
  name,
  children,
}: {
  name: string;
  children: React.ReactNode;
}): JSX.Element | null {
  const isAllowed =
    useSelector<RootState, boolean>((state) => state.authReducer.role === 'super_admin') ||
    useSelector<RootState, boolean | undefined>((state) =>
      state.permissions.permissions?.includes(name),
    );
  return isAllowed ? <>{children}</> : null;
}

export function TableHeadCell({ sx, ...props }: TableCellProps): JSX.Element {
  return (
    <TableCell
      sx={{
        py: 3,
        px: 2,
        mx: 4,
        border: '0.2px solid rgba(190, 191, 198, 0.4)',
        ...sx,
      }}
      {...props}
    />
  );
}
export function TableBodyCell({ sx, ...props }: TableCellProps): JSX.Element {
  return (
    <TableCell
      sx={{
        py: 3,
        px: 2,
        mx: 3,
        border: '0.2px solid rgba(190, 191, 198, 0.4)',
        ...sx,
      }}
      {...props}
    />
  );
}
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
export function TableCellText({
  text,
  sx,
  showDropDown,
}: {
  text: string;
  sx?: TypographyProps;
  showDropDown?: boolean;
}): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Typography variant="h5" sx={{ fontSize: 13, color: 'primary.darkBlue', opacity: 0.8, ...sx }}>
      {text}
      {showDropDown && (
        <>
          <IconButton
            aria-label="dropdown"
            size="small"
            disableTouchRipple
            sx={{
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <img
              src="/images/dropdownIcon.svg"
              alt="dropdown"
              style={{
                marginLeft: '20px',
                cursor: 'pointer',
              }}
            />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            anchorOrigin={{
              vertical: 35,
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 5,
              horizontal: 'center',
            }}
            PaperProps={{
              style: {
                backgroundColor: 'rgba(79, 79, 86, 0.98)',
                minWidth: '14%',
              },
            }}
            sx={{
              left: '-31px',
            }}
          >
            <MenuItem onClick={handleClose}>
              <CustomTypography text="Sort" sx={{ px: 2 }} />
              <ExpandMoreIcon sx={{ ml: 'auto', color: '#FFFFFF' }} />
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <CustomTypography text="Ascending" sx={{ px: 3 }} />
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <CustomTypography text="Descending" sx={{ px: 3 }} />
            </MenuItem>
            <Box sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.42)', mt: 2 }} />
            <MenuItem onClick={handleClose}>
              <CustomTypography text="Search" sx={{ py: 1, px: 2 }} />
            </MenuItem>
          </Menu>
        </>
      )}
    </Typography>
  );
}

function CustomTypography({ text, sx = {} }: { text: string; sx?: SxProps }): JSX.Element {
  return (
    <>
      <Typography variant="h5" sx={{ fontSize: 13, color: '#FFFFFF', py: 1, px: 2, ...sx }}>
        {text}
      </Typography>
    </>
  );
}
export function LegendWithData({
  label,
  value,
  legendColor,
}: {
  label: string;
  value: string;
  legendColor: string;
}): JSX.Element {
  const theme = useTheme();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const themeMixins = theme.mixins as any;
  return (
    <Stack sx={{ ...themeMixins.flexCenterSpaceBetween, mb: 1 }}>
      <Typography sx={{ ...themeMixins.flexCenterSpaceBetween, fontSize: 12 }} variant="body1">
        <Box
          sx={{
            display: 'inline-block',
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            mr: 1,
            bgcolor: legendColor,
          }}
        ></Box>
        {label}
      </Typography>
      <Typography variant="body1" sx={{ color: 'primary.grey', fontSize: 12 }}>
        {value}
      </Typography>
    </Stack>
  );
}

export function ProgressBarCard({
  percentage,
  primaryColor,
  secondaryColor,
  title,
  totalValue,
  mainLegendLabel,
  mainLegendValue,
  secondLegendLabel,
  secondLegendValue,
}: {
  percentage: number;
  primaryColor: string;
  secondaryColor: string;
  title: string;
  totalValue: string;
  mainLegendLabel: string;
  mainLegendValue: string;
  secondLegendLabel: string;
  secondLegendValue: string;
}): JSX.Element {
  const theme = useTheme();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const themeMixins = theme.mixins as any;
  return (
    <Stack
      sx={{
        width: '100%',
        background: '#FFFFFF',
        ...themeMixins.standardBorder,
        borderRadius: '2px',
        px: 3,
        py: 1.2,
        alignItems: 'center',
        flexDirection: 'row',
      }}
    >
      <Box sx={{ width: '75px', mr: 1.5 }}>
        <CircularProgressbar
          value={percentage}
          text={`${percentage}%`}
          styles={buildStyles({
            textColor: '#003270',
            pathColor: primaryColor,
            trailColor: secondaryColor,
            textSize: '20px',
          })}
        />
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h6" sx={{ opacity: 0.8, mb: 0.5 }}>
          {title}
        </Typography>
        <Typography variant="h3" mb={2}>
          {totalValue}
        </Typography>
        <LegendWithData
          label={mainLegendLabel}
          value={mainLegendValue}
          legendColor={primaryColor}
        />
        <LegendWithData
          label={secondLegendLabel}
          value={secondLegendValue}
          legendColor={secondaryColor}
        />
      </Box>
    </Stack>
  );
}
