import { AnyAction } from 'redux';
import { GET_HOLIDAYS, CREATE_HOLIDAY, UPDATE_HOLIDAY, HolidayPayload } from '../types/holidays';

export interface HolidaysState {
  holidays: Array<HolidayPayload>;
}

const initialState: HolidaysState = {
  holidays: [],
};

function holidayReducer(state = initialState, action: AnyAction): HolidaysState {
  switch (action.type) {
    case GET_HOLIDAYS: {
      return {
        holidays: action.holidays,
      };
    }
    case CREATE_HOLIDAY: {
      return {
        holidays: [action.holiday, ...state.holidays],
      };
    }
    case UPDATE_HOLIDAY: {
      const updatedHolidays = state.holidays.map((holiday: HolidayPayload) => {
        return holiday._id === action.holiday._id ? { ...holiday, ...action.holiday } : holiday;
      });
      return {
        holidays: [...updatedHolidays],
      };
    }
    default:
      return state;
  }
}
export default holidayReducer;
