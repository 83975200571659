import { Divider, Grid, LinearProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import { formatShortDate } from '../../../utils/date';
import { Candidate } from '../types';
import { getCandidateDetail } from './actions';
import { DataValues } from '../components';
import { getStatusLabel } from './constants';

export default function CandidateDetail(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const [candidate, setCandidate] = useState<Candidate>();
  const [showProgress, setShowProgress] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    async function getCandidatesIDdata() {
      try {
        setShowProgress(true);
        const details = await dispatch(getCandidateDetail(id));
        setCandidate(details as unknown as Candidate);
      } catch (error) {
        enqueueSnackbar((error as Error).message, {
          variant: 'error',
        });
      } finally {
        setShowProgress(false);
      }
    }
    getCandidatesIDdata();
  }, [id]);

  return (
    <>
      {showProgress && <LinearProgress />}
      {candidate && (
        <>
          <Grid container sx={{ px: 2 }}>
            <DataValues label="Name" value={candidate.name} />
            <DataValues label="Status" value={getStatusLabel(candidate.status)} half />
            <DataValues label="Campaign" value={candidate.campaign?.name} half />
            <DataValues label="Current Salary" value={candidate.currentSalary} half />
            <DataValues label="Expected Salary" value={candidate.expectedSalary} half />
            <DataValues label="Total Experience" value={candidate.totalExperience} half />
            <DataValues label="Relevant Experience" value={candidate.relevantExperience} half />
            <DataValues label="Resume" value={candidate.resume} />
            <DataValues label="Notice Period" value={candidate.noticePeriod} half />
            <DataValues
              label="Notice Period Negotiable"
              value={candidate.noticePeriodNegotiable}
              half
            />
            <DataValues label="Holding Any Offer" value={candidate.holdingAnyOffer} half />
            <DataValues label="Last Working Day" value={candidate.lastWorkingDay} half />
          </Grid>
          <Divider sx={{ mt: 2 }}>Contact Details</Divider>
          <Grid container sx={{ px: 2 }}>
            <DataValues label="Email" value={candidate.email} />
            <DataValues label="Phone" value={candidate.phone} />
            <DataValues label="LinkedIn" value={candidate.linkedin} />
            <DataValues label="Candidate ID" value={candidate.id} />
            <DataValues label="Created On" value={formatShortDate(candidate.createdAt)} half />
            <DataValues label="Updated On" value={formatShortDate(candidate.updatedAt)} half />
          </Grid>
        </>
      )}
    </>
  );
}
