import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { Fragment } from 'react';
import { formatShortDate, getDayOfWeek } from '../../../utils/date';
import { formatAsTime } from '../common';
import { Timelog } from '../types';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function TimeLogsDetailed({
  timelogs,
  startDate,
}: {
  timelogs: Array<Timelog>;
  startDate: Date;
}): JSX.Element {
  const dates = new Array(7).fill(0).map((_, i) => {
    const date = new Date(startDate);
    date.setUTCHours(12, 0, 0, 0);
    date.setDate(date.getDate() + i);
    return date;
  });
  return (
    <>
      {dates.map((date) => {
        const logs = timelogs.filter((timelog) => timelog.date.getTime() === date.getTime());
        const total = logs.reduce((acc, curr) => acc + curr.noOfHours, 0);
        return (
          <Fragment key={date.toISOString()}>
            <Accordion
              sx={{
                boxShadow: 'none',
                borderBottom: '2px solid #fff',
                '&.Mui-expanded': { m: 0 },
                '&::before': { display: 'none' },
              }}
            >
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon sx={{ color: 'primary.lightGrey' }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  bgcolor: 'primary.lightBlueBg',
                  px: [0, 2],
                }}
              >
                <TableContainer>
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            border: 'none',
                          }}
                        >
                          <Typography variant="h4">
                            {formatShortDate(date)}
                            {`, `}
                            {getDayOfWeek(date)}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ border: 'none', textAlign: 'right' }}></TableCell>
                        <TableCell sx={{ border: 'none', textAlign: 'right' }}></TableCell>
                        <TableCell sx={{ border: 'none', textAlign: 'right' }}>
                          <Typography variant="h4">{formatAsTime(total)}</Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  p: 2,
                  pr: [0, 5],
                  pl: [0, 2],
                }}
              >
                <>
                  <TableContainer>
                    <Table size="small">
                      <TableBody>
                        {logs.length === 0 ? (
                          <TableRow>
                            <TableCell colSpan={2} sx={{ py: 2, border: 'none' }}>
                              <Typography variant="body1">No time tracked</Typography>
                            </TableCell>
                          </TableRow>
                        ) : (
                          logs.map((log) => (
                            <TableRow key={log.id}>
                              <TableCell
                                sx={{
                                  py: 2,
                                  border: 'none',
                                  minWidth: [200, 'unset'],
                                }}
                              >
                                <Typography variant="body1">
                                  {log.sow.name} ({log.sow.project.name})
                                </Typography>
                              </TableCell>
                              <TableCell sx={{ py: 2, border: 'none' }}>
                                <Typography variant="body1">{log.task.name}</Typography>
                              </TableCell>
                              <TableCell sx={{ py: 2, border: 'none' }}>
                                <Typography variant="body1">{log.notes}</Typography>
                              </TableCell>
                              <TableCell sx={{ py: 2, border: 'none', textAlign: 'right' }}>
                                <Typography variant="h4" sx={{ color: 'secondary.main' }}>
                                  {formatAsTime(log.noOfHours)}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              </AccordionDetails>
            </Accordion>
          </Fragment>
        );
      })}
    </>
  );
}
