/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CALL_API } from '../../../redux-store/middleware';
import { SET_CANDIDATES, ADD_CANDIDATE, UPDATE_CANDIDATE } from './constants';
import { Candidate, CandidateFormBody, CandidatesSearchParameters } from '../types';

const BASE_URL = '/ats/candidates';

export const defaultSearchParameter: Partial<CandidatesSearchParameters> = {
  status: '',
  campaignId: '',
  name: '',
  phone: '',
  email: '',
  limit: 10,
  orderBy: '',
  orderDirection: '',
};

export const getCandidates =
  (searchOptions = defaultSearchParameter) =>
  async (dispatch: any) => {
    try {
      const searchParams = new URLSearchParams();

      if (searchOptions.campaignId && searchOptions.campaignId !== 'any') {
        searchParams.append('campaignId', searchOptions.campaignId);
      }
      if (searchOptions.status && searchOptions.status !== 'any') {
        searchParams.append('status', searchOptions.status);
      }

      if (searchOptions.name) {
        searchParams.append('name', searchOptions.name);
      }
      if (searchOptions.email) {
        searchParams.append('email', searchOptions.email);
      }

      if (searchOptions.limit) {
        searchParams.append('limit', searchOptions.limit.toString());
      }
      if (searchOptions.orderBy) {
        searchParams.append('orderBy', searchOptions.orderBy);
      }

      if (searchOptions.orderDirection) {
        searchParams.append('orderDirection', searchOptions.orderDirection);
      }
      const candidates = await dispatch({
        [CALL_API]: {
          url: `/ats/candidates?${searchParams.toString()}`,
          method: 'GET',
        },
      });
      dispatch({
        type: SET_CANDIDATES,
        candidates,
        campaignId: searchOptions.campaignId,
      });

      return candidates as Candidate[];
    } catch (error) {
      console.error(error);
      return [];
    }
  };

export const createNewCandidate = (candidate: CandidateFormBody) => async (dispatch: any) => {
  const { ...candidateBody } = candidate;
  try {
    const candidate = await dispatch({
      [CALL_API]: {
        url: BASE_URL,
        method: 'POST',
        body: candidateBody,
      },
    });
    dispatch({
      type: ADD_CANDIDATE,
      candidate,
    });
    return candidate;
  } catch (error) {
    throw new Error('Error creating new candidate. ' + error);
  }
};

export const updateCandidate =
  (id: string, candidateBody: CandidateFormBody) => async (dispatch: any) => {
    try {
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        id: _id,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        createdAt,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        updatedAt,
        ...rest
      } = candidateBody as unknown as Record<string, any>;
      const candidate = await dispatch({
        [CALL_API]: {
          url: `${BASE_URL}/${id}`,
          method: 'POST',
          body: rest,
        },
      });
      dispatch({
        type: UPDATE_CANDIDATE,
        candidate,
      });
      return candidate;
    } catch (error) {
      throw new Error('Error updating candidate. ' + error);
    }
  };

export const getCandidateDetail = (candidateId: string) => async (dispatch: any) => {
  try {
    const candidate = await dispatch({
      [CALL_API]: {
        url: `${BASE_URL}/${candidateId}`,
        method: 'GET',
      },
    });
    return candidate as Candidate;
  } catch (error) {
    throw new Error('Unable to fetch candidate. ' + error);
  }
};
