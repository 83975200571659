import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { TitleBar } from '../../assets/components';
import FormUsersPicker from '../../forms/FormUsersPicker';
import { Box, LinearProgress } from '@mui/material';
import { SubmitButton } from '../../forms/Button';
import { Employee } from '../types';
import { useState } from 'react';
import { addMember } from './actions';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

const validationSchema = Yup.object().shape({
  employee: Yup.object()
    .nullable()
    .shape({
      id: Yup.string().required('Employee is required'),
      name: Yup.string(),
    })
    .required('Employee is required'),
});

const initialValues = {
  employee: undefined,
};

export default function AddMember(): JSX.Element {
  const [showLoading, setShowLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { id: groupId } = useParams<{ id: string }>();
  const onSubmit = async (values: { employee?: Employee }) => {
    try {
      setShowLoading(true);
      await dispatch(addMember(groupId, values.employee || { id: '' }));
      setTimeout(history.goBack.bind(null), 1);
    } catch (error) {
      enqueueSnackbar((error as Error).message, { variant: 'error' });
    } finally {
      setShowLoading(false);
    }
  };
  return (
    <>
      <TitleBar title="Add Member" />
      {showLoading && <LinearProgress />}
      <Box sx={{ p: 2 }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {() => (
            <Form>
              <FormUsersPicker name="employee" label="Member" />
              <Box sx={{ m: '10px', float: 'right' }}>
                <SubmitButton label="Submit" />
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
}
