import {
  Grid,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { getTicketsbyId } from '../../redux-store/actions/tickets';
import { TicketDetails } from '../../redux-store/types/tickets';
import { TitleBar } from '../assets/components';
import { useSnackbar } from 'notistack';
import { formatShortDate } from '../../utils/date';
import UpdateComment from './UpdateComment';

const historyHeaders = ['Updated On', 'Assigned To', 'Updated By', 'Comment'];

interface DataValues {
  label: string;
  value?: string;
}

function DataValues({ label, value }: DataValues) {
  return (
    <>
      <Typography variant="caption" sx={{ mt: 1.5, color: 'text.secondary' }}>
        {label}
      </Typography>
      <Typography variant="h6">{value}</Typography>
    </>
  );
}

function DataColumnValues1({
  dv1,
  dv2,
  dv3,
}: {
  dv1: DataValues;
  dv2: DataValues;
  dv3: DataValues;
}) {
  return (
    <Grid container direction="column">
      <Grid item xs={6}>
        <DataValues {...dv1} />
      </Grid>
      <Grid item xs={6}>
        <DataValues {...dv2} />
      </Grid>
      <Grid item xs={6}>
        <DataValues {...dv3} />
      </Grid>
    </Grid>
  );
}

function DataColumnValues2({
  dv1,
  dv2,
  dv3,
  dv4,
  dv5,
}: {
  dv1: DataValues;
  dv2: DataValues;
  dv3: DataValues;
  dv4: DataValues;
  dv5: DataValues;
}) {
  return (
    <Grid container direction="column">
      <Grid item xs={6}>
        <DataValues {...dv1} />
      </Grid>
      <Grid item xs={6}>
        <DataValues {...dv2} />
      </Grid>
      <Grid item xs={6}>
        <DataValues {...dv3} />
      </Grid>
      <Grid item xs={6}>
        <DataValues {...dv4} />
      </Grid>
      <Grid item xs={6}>
        <DataValues {...dv5} />
      </Grid>
    </Grid>
  );
}

export default function Details(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const [ticketdetails, setticketdetails] = useState<TicketDetails>();
  const [showProgress, setShowProgress] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    async function getTicketsIDdata() {
      try {
        setShowProgress(true);
        const ticketIdDetails = await dispatch(getTicketsbyId(id));
        setticketdetails(ticketIdDetails as unknown as TicketDetails);
      } catch (error) {
        enqueueSnackbar((error as Error).message, {
          variant: 'error',
        });
      } finally {
        setShowProgress(false);
      }
    }
    getTicketsIDdata();
  }, [id]);

  return (
    <>
      <TitleBar title="Ticket Details" />
      {showProgress && <LinearProgress />}
      {ticketdetails && (
        <Stack sx={{ paddingX: 2 }}>
          <Grid container>
            <Grid item xs={6}>
              <DataColumnValues1
                dv1={{ label: 'Ticket No', value: ticketdetails.ticketNumber }}
                dv2={{ label: 'Category', value: ticketdetails.category }}
                dv3={{ label: 'Status', value: ticketdetails.status }}
              />
            </Grid>

            <Grid item xs={6}>
              <DataColumnValues2
                dv1={{ label: 'Subcategory', value: ticketdetails.subcategory }}
                dv2={{ label: 'Created On', value: formatShortDate(ticketdetails.createdAt) }}
                dv3={{ label: 'Created By', value: ticketdetails.createdBy.name }}
                dv4={{ label: 'Title', value: ticketdetails.title }}
                dv5={{ label: 'Description', value: ticketdetails.description }}
              />
            </Grid>
          </Grid>

          <UpdateComment />

          <Typography variant="h6" sx={{ mt: 2.5 }}>
            History
          </Typography>

          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {historyHeaders.map((head) => (
                    <TableCell sx={{ fontWeight: 'bold' }} key={head}>
                      {head}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody component={Paper}>
                {ticketdetails.history?.map((history) => (
                  <TableRow key={history.id}>
                    <TableCell>{formatShortDate(history.ticket.updatedAt)}</TableCell>
                    <TableCell>{ticketdetails.assignedTo?.name}</TableCell>
                    <TableCell>{history.updatedBy.name}</TableCell>
                    <TableCell>{history.title}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      )}
    </>
  );
}
