import {
  Button,
  Divider,
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableCell as MUITableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Location } from 'history';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { TitleBar } from '../../assets/components';
import { getGroupDetail } from './actions';
import { Group } from '../types';
import { DataValues } from '../../ats/components';
import { formatShortDate } from '../../../utils/date';

function TableCell({ sx, ...rest }: TableCellProps): JSX.Element {
  return <MUITableCell sx={{ p: 0.5, ...sx }} {...rest} />;
}
export default function GroupDetail(): JSX.Element {
  const location = useLocation<{ background?: Location }>();
  const { id } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const [group, setGroup] = useState<Group>();
  const [showProgress, setShowProgress] = useState(false);
  const dispatch = useDispatch();
  const background = location.state?.background || location;
  useEffect(() => {
    async function getDetail() {
      try {
        setShowProgress(true);
        const details = await dispatch(getGroupDetail(id));
        setGroup(details as unknown as Group);
      } catch (error) {
        enqueueSnackbar((error as Error).message, {
          variant: 'error',
        });
      } finally {
        setShowProgress(false);
      }
    }
    getDetail();
  }, [id]);

  async function removeSubgroup(subgroupId: string) {
    console.log('Removing subgroup', subgroupId);
  }
  async function removeMember(memberId: string) {
    console.log('Removing member', memberId);
  }
  return (
    <>
      <TitleBar title="Group Details" />
      {showProgress && <LinearProgress />}

      {group && (
        <Grid container sx={{ px: 2 }}>
          <DataValues label="Name" value={group.name} />
          <DataValues label="Created By" value={group.createdBy?.name} half />
          <DataValues label="Created On" value={formatShortDate(group.createdAt)} half />
          <Divider sx={{ mt: 2 }}>
            Sub Groups ({group.subgroupsCount})
            <Button
              to={{
                pathname: `/admin/permissions/groups/${group.id}/add-subgroup`,
                state: { background },
              }}
              component={RouterLink}
            >
              <AddIcon />
            </Button>
          </Divider>
          <TableContainer>
            <Table aria-label="Sub groups list" size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Group Name</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Added On</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Added By</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {group.subGroups.map((subGroup) => (
                  <TableRow key={subGroup.id}>
                    <TableCell>{subGroup.name}</TableCell>
                    <TableCell>{formatShortDate(subGroup.subgroupSince)}</TableCell>
                    <TableCell>{subGroup.addedBy?.name}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          removeSubgroup(subGroup.id);
                        }}
                      >
                        <DeleteOutlineIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider sx={{ mt: 2 }}>
            Direct Members ({group.membersCount})
            <Button
              to={{
                pathname: `/admin/permissions/groups/${group.id}/add-member`,
                state: { background },
              }}
              component={RouterLink}
            >
              <AddIcon />
            </Button>
          </Divider>
          <TableContainer>
            <Table aria-label="Members list" size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Employee</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Member Since</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Added By</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {group.members.map((member) => (
                  <TableRow key={member.id}>
                    <TableCell>{member.employee.name}</TableCell>
                    <TableCell>{formatShortDate(member.memberSince)}</TableCell>
                    <TableCell>{member.addedBy?.name}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          removeMember(member.id);
                        }}
                      >
                        <DeleteOutlineIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </>
  );
}
