import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Box, LinearProgress } from '@mui/material';
import { GroupFormBody } from '../types';
import GroupForm from './forms';
import { updateGroup, getGroupDetail } from './actions';
import { TitleBar } from '../../assets/components';
export default function UpdateGroup(): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<{ id: string }>();

  const [group, setGroup] = useState<GroupFormBody>();
  const [showProgress, setShowProgress] = useState<boolean>(true);

  useEffect(() => {
    async function fetchGroup() {
      try {
        setShowProgress(true);
        const _group = await dispatch(getGroupDetail(id));
        setGroup(_group as unknown as GroupFormBody);
      } catch (error) {
        enqueueSnackbar((error as Error).message, {
          variant: 'error',
        });
      } finally {
        setShowProgress(false);
      }
    }
    fetchGroup();
  }, [id]);
  return (
    <>
      <TitleBar title="Update Group" />
      {showProgress && <LinearProgress />}
      {group && (
        <Box sx={{ p: 2 }}>
          <GroupForm
            initialValues={group}
            onSubmit={async (values: GroupFormBody): Promise<void> => {
              try {
                setShowProgress(true);
                await dispatch(updateGroup(id, values));
                setTimeout(history.push.bind(null, '../../groups'), 1);
                enqueueSnackbar('Group updated successfully', { variant: 'success' });
              } catch (error) {
                enqueueSnackbar((error as Error).message, { variant: 'error' });
              } finally {
                setShowProgress(false);
              }
            }}
          />
        </Box>
      )}
    </>
  );
}
