import { Divider } from '@mui/material';
import { Box } from '@mui/system';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { AssetFormBody } from '../../redux-store/types/asset';
import { SubmitButton } from '../forms/Button';

import FormCheckbox from '../forms/FormCheckbox';
import FormDatePicker from '../forms/FormDatePicker';
import FormSelect from '../forms/FormSelect';
import FormTextInput from '../forms/FormTextInput';
import FormUsersPicker from '../forms/FormUsersPicker';

const Schema = Yup.object().shape({
  type: Yup.string().required('Type is required'),
  serialNumber: Yup.string().required('Serial number is required'),
  dateOfPurchase: Yup.date().required('Date of purchase is required'),
  description: Yup.string().required('Description is required'),
  allocatedTo: Yup.object()
    .nullable()
    .shape({
      id: Yup.string().required('Allocated to is required'),
    }),
});

export type AssetFormProps = {
  onSubmit: (values: AssetFormBody) => void;
  initialValues: AssetFormBody;
};

export default function AssetForm({ onSubmit, initialValues }: AssetFormProps): JSX.Element {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Schema}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <FormSelect
            options={['Laptop', 'Desktop', 'Tablet', 'Phone'].map((type) => ({
              value: type,
              label: type,
            }))}
            name="type"
            label="Type"
            disabled={isSubmitting}
          />
          <FormTextInput
            label="Serial Number"
            name="serialNumber"
            type="text"
            placeholder="73t93bdeih829gfe"
            disabled={isSubmitting}
          />
          {initialValues.serialNumber && (
            <FormCheckbox label="Active" name="active" disabled={isSubmitting} />
          )}
          <FormTextInput
            label="Description"
            name="description"
            type="text"
            placeholder="Dell Inspiron 2020"
            disabled={isSubmitting}
          />
          <FormDatePicker label="Date of Purchase" disabled={isSubmitting} name="dateOfPurchase" />
          <Divider>Current Allocation</Divider>
          <FormUsersPicker label="Allocated To" disabled={isSubmitting} name="allocatedTo" />
          <FormDatePicker label="Allocated Since" disabled={isSubmitting} name="allocatedSince" />
          <Box sx={{ float: 'right', mt: '10px' }}>
            <SubmitButton label="Submit" />
          </Box>
        </Form>
      )}
    </Formik>
  );
}
