import {
  Button,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  SelectProps,
  Stack,
  Table,
  TableBody,
  TableCell as MUITableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { useLocation, useRouteMatch } from 'react-router';
import ReplayIcon from '@mui/icons-material/Replay';
import FilterListIcon from '@mui/icons-material/FilterList';
import { onGetUsers } from '../../redux-store/actions/users';
import { RootStateType } from '../../redux-store/reducers';
import { formatShortDate } from '../../utils/date';
import { ButtonPrimary } from '../forms/Button';
import { UsersState } from '../../redux-store/reducers/users';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import {
  SET_USERS_SEARCH_OPTIONS,
  SET_USERS_SEARCH_OPTIONS_TYPE,
} from '../../redux-store/types/users';
import UsersSearchOptions from './SearchOptions';
import { formatDesignation } from './constants';
import { OnlyIfPermission } from '../common';

function TableHeadCell({ title, sx, ...props }: { title: string } & TableCellProps): JSX.Element {
  return (
    <MUITableCell sx={{ fontWeight: 'bold', ...sx }} {...props}>
      {title}
    </MUITableCell>
  );
}

function TableCell({ sx, ...props }: TableCellProps): JSX.Element {
  return <MUITableCell sx={{ px: 1, ...sx }} {...props} />;
}
export default function UsersList(): JSX.Element {
  const { users, searchOptions, searchOptionType } = useSelector<RootStateType, UsersState>(
    (state) => state.users,
  );

  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [showProgress, setShowProgress] = useState(false);
  const [openOptionsModal, setOpenOptionsModal] = useState(false);

  useEffect(() => {
    async function fetchUsers() {
      try {
        setShowProgress(true);
        await dispatch(onGetUsers(searchOptions));
      } catch (error) {
        enqueueSnackbar((error as Error).message, { variant: 'error' });
      } finally {
        setShowProgress(false);
      }
    }
    fetchUsers();
  }, [searchOptions]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOptionsTypeChange = (event: any) => {
    const value = event.target.value;
    if (value === 'custom') {
      setOpenOptionsModal(true);
    } else {
      dispatch({ type: SET_USERS_SEARCH_OPTIONS_TYPE, searchOptionType: value });
    }
  };

  return (
    <>
      <UsersSearchOptions
        open={openOptionsModal}
        onClose={() => {
          setOpenOptionsModal(false);
        }}
      />
      <Typography variant="h4">Team</Typography>
      <Grid pb={2} spacing={2} container justifyContent="space-between">
        <Grid item>
          <Stack spacing={2} direction="row">
            <SearchOptionsSelect
              value={searchOptionType}
              onChange={handleOptionsTypeChange}
              autoWidth
            />
            <ButtonPrimary
              variant="outlined"
              endIcon={<FilterListIcon />}
              onClick={() => {
                setOpenOptionsModal(true);
              }}
            >
              More
            </ButtonPrimary>
            <ButtonPrimary
              variant="outlined"
              startIcon={<ReplayIcon />}
              onClick={() => {
                dispatch({ type: SET_USERS_SEARCH_OPTIONS, searchOptions: { ...searchOptions } });
              }}
            >
              Reload
            </ButtonPrimary>
          </Stack>
        </Grid>
        <Grid item>
          <OnlyIfPermission name="users:users:create">
            <ButtonPrimary
              component={Link}
              variant="outlined"
              to={{ pathname: `${url}/add`, state: { background: location } }}
            >
              Add Employee
            </ButtonPrimary>
          </OnlyIfPermission>
        </Grid>
      </Grid>
      {showProgress && <LinearProgress />}
      <TableContainer component={Paper}>
        <Table aria-label="users grid" size="small">
          <TableHead>
            <TableRow>
              <TableHeadCell title="#" />
              <TableHeadCell title="Name" />
              <TableHeadCell title="Designation" />
              <TableHeadCell title="Department" />
              <TableHeadCell title="Email" />
              <TableHeadCell title="Date of Joining" />
              <TableHeadCell title="Manager" />
              <TableHeadCell title="Actions" />
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user, i) => (
              <TableRow key={user.id}>
                <TableCell>{i + 1}</TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell>{formatDesignation(user.designation)}</TableCell>
                <TableCell>
                  {[user.department, user.subDepartment].filter((x) => !!x).join(' / ')}
                </TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{formatShortDate(user.joining_date)}</TableCell>
                <TableCell>
                  {!user.manager ? (
                    <>
                      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
                        Assign
                      </Typography>
                      <IconButton
                        size="small"
                        to={{
                          pathname: `${url}/manager/${user.id}`,
                          state: { background: location },
                        }}
                        component={Link}
                      >
                        <PersonAddAlt1OutlinedIcon />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
                        {user.manager.name}
                      </Typography>
                      <IconButton
                        size="small"
                        to={{
                          pathname: `${url}/manager/${user.id}`,
                          state: { background: location },
                        }}
                        component={Link}
                      >
                        <PersonRemoveIcon />
                      </IconButton>
                    </>
                  )}
                </TableCell>
                <TableCell>
                  <Button
                    sx={{ py: 0.25 }}
                    to={{
                      pathname: `${url}/${user.id}`,
                      state: { background: location },
                    }}
                    component={Link}
                  >
                    View
                  </Button>
                  <OnlyIfPermission name="users:users:update">
                    <Button
                      sx={{ py: 0.25 }}
                      to={{
                        pathname: `${url}/update/${user.id}`,
                        state: { background: location },
                      }}
                      component={Link}
                    >
                      Update
                    </Button>
                  </OnlyIfPermission>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function SearchOptionsSelect(props: SelectProps) {
  return (
    <Select label="Quick Filters" variant="standard" {...props}>
      <MenuItem value="allUsers">Active Users</MenuItem>
      <MenuItem value="recentlyUpdated">Recently Updated</MenuItem>
      <MenuItem value="noManagers">No Managers</MenuItem>
      <MenuItem value="recentlyJoined">Recently Joined</MenuItem>
      <MenuItem value="trainees">Trainees</MenuItem>
      <MenuItem value="custom">Custom</MenuItem>
    </Select>
  );
}
