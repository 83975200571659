import { Box, LinearProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import { getAssetDetail, updateAsset } from '../../redux-store/actions/assets';
import { Allocation, Asset, AssetFormBody } from '../../redux-store/types/asset';
import { TitleBar } from './components';
import AssetForm from './Form';

export default function UpdateAsset(): JSX.Element {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<{ id: string }>();
  const [showProgress, setShowProgress] = useState(false);
  const [asset, setAsset] = useState<AssetFormBody>();
  const [currentAllocation, setCurrentAllocation] = useState<Allocation | null>();
  useEffect(() => {
    async function fetchAsset() {
      try {
        setShowProgress(true);
        const _asset = await dispatch(getAssetDetail(id));
        const { currentAllocation, type, serialNumber, dateOfPurchase, description, active } =
          _asset as unknown as Asset;
        setCurrentAllocation(currentAllocation);
        const allocatedTo =
          currentAllocation && currentAllocation.allocatedTo
            ? {
                id: currentAllocation.allocatedTo.id,
                name: currentAllocation.allocatedTo.name,
                email: currentAllocation.allocatedTo.email,
              }
            : null;
        setAsset({
          type,
          serialNumber,
          dateOfPurchase,
          description,
          active,
          allocatedTo,
          allocatedSince: currentAllocation ? currentAllocation.allocatedSince : null,
        });
      } catch (error) {
        enqueueSnackbar((error as Error).message, {
          variant: 'error',
        });
      } finally {
        setShowProgress(false);
      }
    }
    fetchAsset();
  }, [id]);
  const history = useHistory();
  return (
    <>
      <TitleBar title="Update Asset" />
      {showProgress && <LinearProgress />}
      {asset && (
        <Box sx={{ p: 2 }}>
          <AssetForm
            initialValues={asset}
            onSubmit={async (values) => {
              const { allocatedTo, allocatedSince, ...rest } = values;
              const allocation = {
                allocatedTo,
                allocatedSince,
              };
              setShowProgress(true);
              await dispatch(updateAsset(id, rest, allocation, currentAllocation));
              setShowProgress(false);
              setTimeout(history.push.bind(null, '/admin/assets'), 1);
            }}
          />
        </Box>
      )}
    </>
  );
}
