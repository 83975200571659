import { Box, Drawer } from '@mui/material';
import { Location } from 'history';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router';
import CreateResource from './Create';
import ResourceDetails from './Details';
import EmployeeDetails from './Employee';
import ResourceList from './List';
import UpdateResource from './Update';

export default function ResourceRouter(): JSX.Element {
  const { path } = useRouteMatch();
  const history = useHistory();
  const location = useLocation<{ background: Location }>();
  const background = location.state && location.state.background;
  return (
    <>
      <Switch location={background || location}>
        <Route exact path={`${path}/create`} component={CreateResource} />
        <Route path={`${path}/projects/:id`} component={ResourceDetails} />
        <Route path={`${path}/update/:id`} component={UpdateResource} />
        <Route path={`${path}/:id`} component={EmployeeDetails} />
        <Route exact path={path} component={ResourceList} />
      </Switch>
      <Drawer
        open={!!background}
        anchor="right"
        onClose={() => {
          history.goBack();
        }}
        PaperProps={{
          style: {
            width: '45%',
            paddingBottom: '50px',
          },
        }}
      >
        <Box role="presentation">
          <Switch>
            <Route exact path={`${path}/create`} component={CreateResource} />
            <Route exact path={`${path}/projects/:id`} component={ResourceDetails} />
            <Route path={`${path}/update/:id`} component={UpdateResource} />
            <Route exact path={`${path}/:id`} component={EmployeeDetails} />
          </Switch>
        </Box>
      </Drawer>
    </>
  );
}
