import {
  Divider,
  Grid,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { getAssetDetail } from '../../redux-store/actions/assets';
import { Asset } from '../../redux-store/types/asset';
import { formatShortDate } from '../../utils/date';
import { TitleBar } from './components';

type KeyValue = {
  label: string;
  value?: string;
};

function KeyValue({ label, value }: KeyValue) {
  return (
    <>
      <Typography variant="caption" sx={{ mt: 1.5, color: 'text.secondary' }}>
        {label}
      </Typography>
      <Typography variant="h6">{value}</Typography>
    </>
  );
}

function KeyValueTwoColumns({ kv1, kv2 }: { kv1: KeyValue; kv2: KeyValue }) {
  return (
    <Grid container sx={{ mt: 1 }}>
      <Grid item xs={6}>
        <KeyValue {...kv1} />
      </Grid>
      <Grid item xs={6}>
        <KeyValue {...kv2} />
      </Grid>
    </Grid>
  );
}

export default function AssetDetail(): JSX.Element {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [showProgress, setShowProgress] = useState(false);
  const { id } = useParams<{ id: string }>();
  const [asset, setAsset] = useState<Asset>();
  useEffect(() => {
    async function fetchAsset() {
      try {
        setShowProgress(true);

        const _asset = await dispatch(getAssetDetail(id));

        setAsset(_asset as unknown as Asset);
      } catch (error) {
        enqueueSnackbar((error as Error).message, {
          variant: 'error',
        });
      } finally {
        setShowProgress(false);
      }
    }
    fetchAsset();
  }, [id]);
  return (
    <>
      <TitleBar title="Asset Details" />
      {showProgress && <LinearProgress />}
      {asset && (
        <Stack sx={{ paddingX: 2 }}>
          <KeyValueTwoColumns
            kv1={{ label: 'Serial No', value: asset.serialNumber }}
            kv2={{ label: 'Type', value: asset.type }}
          />
          <KeyValueTwoColumns
            kv1={{ label: 'Status', value: asset.active ? 'Active' : 'Inactive' }}
            kv2={{ label: 'Description', value: asset.description }}
          />
          <KeyValueTwoColumns
            kv1={{ label: 'Purchased On', value: formatShortDate(asset.dateOfPurchase) }}
            kv2={{ label: 'Last Updated', value: formatShortDate(asset.updatedAt) }}
          />
          <KeyValueTwoColumns
            kv1={{
              label: 'Allocated To',
              value:
                asset.currentAllocation && asset.currentAllocation.allocatedTo
                  ? asset.currentAllocation.allocatedTo?.name
                  : 'None',
            }}
            kv2={{
              label:
                !!asset.currentAllocation && !asset.currentAllocation.allocatedTo
                  ? 'Deallocated Since'
                  : 'Allocated Since',
              value: !asset.currentAllocation
                ? 'Never'
                : formatShortDate(asset.currentAllocation?.allocatedSince),
            }}
          />
          <Divider sx={{ mt: 2 }}>History</Divider>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Employee</TableCell>
                  <TableCell>Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {asset.allocations?.map((allocation) => (
                  <TableRow key={allocation.id}>
                    <TableCell>{formatShortDate(allocation.allocatedSince)}</TableCell>
                    <TableCell>{allocation.allocatedTo ? 'Allocated' : 'Deallocated'}</TableCell>
                    <TableCell>{allocation.allocatedTo?.name}</TableCell>
                    <TableCell>{allocation.description}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      )}
    </>
  );
}
