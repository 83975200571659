import { Box, Drawer } from '@mui/material';
import { Location } from 'history';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router';
import UserDetail from './Detail';
import UsersList from './List';
import CreateUser from './CreateUser';
import UpdateUser from './UpdateUser';
import UpdateManager from './UpdateManager';

export default function Router(): JSX.Element {
  const { path } = useRouteMatch();
  const history = useHistory();
  const location = useLocation<{ background: Location }>();
  const background = location.state && location.state.background;

  return (
    <>
      <Switch location={background || location}>
        <Route path={`${path}/add`} component={CreateUser} />
        <Route path={`${path}/update/:id`} component={UpdateUser} />
        <Route path={`${path}/:id`} component={UserDetail} />
        <Route path={`${path}//manager:id`} component={UpdateManager} />
        <Route exact path={path} component={UsersList} />
      </Switch>
      <Drawer
        open={!!background}
        anchor="right"
        onClose={() => {
          history.goBack();
        }}
      >
        <Box sx={{ width: 420 }} role="presentation">
          <Switch>
            <Route path={`${path}/add`} component={CreateUser} />
            <Route path={`${path}/update/:id`} component={UpdateUser} />
            <Route path={`${path}/manager/:id`} component={UpdateManager} />
            <Route path={`${path}/:id`} component={UserDetail} />
          </Switch>
        </Box>
      </Drawer>
    </>
  );
}
