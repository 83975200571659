import { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Box, LinearProgress } from '@mui/material';
import { ResourceBody } from '../types';
import { createResource } from '../actions';
import { TitleBar } from '../../assets/components';
import ResourceForm from './Form';

const beginningOfThisMonth = new Date();
beginningOfThisMonth.setDate(1);
beginningOfThisMonth.setHours(0, 0, 0, 0);

const endOfNextMonth = new Date();
endOfNextMonth.setHours(0, 0, 0, 0);
endOfNextMonth.setDate(1);
endOfNextMonth.setMonth(endOfNextMonth.getMonth() + 2);

const initialValues: ResourceBody = {
  role: '',
  employee: null,
  assignedTo: null,
  capacity: 0,
  billingRate: 0,
  startDate: beginningOfThisMonth,
  endDate: endOfNextMonth,
};

export default function CreateResource(): JSX.Element {
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return (
    <>
      <TitleBar title="New Resource Allocation" />
      {showProgress && <LinearProgress />}
      <Box sx={{ p: 2 }}>
        <ResourceForm
          initialValues={initialValues}
          onSubmit={async (values: ResourceBody): Promise<void> => {
            try {
              setShowProgress(true);
              await dispatch(createResource(values));
              enqueueSnackbar('Resource created successfully', { variant: 'success' });
              setTimeout(history.goBack, 1);
            } catch (error) {
              enqueueSnackbar((error as Error).message, { variant: 'error' });
            } finally {
              setShowProgress(false);
            }
          }}
        />
      </Box>
    </>
  );
}
