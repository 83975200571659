export function formatShortDate(date?: string | Date | null): string {
  if (!date) {
    return '';
  }
  return new Date(date).toLocaleDateString('en-In', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
}

export function formatLongDate(date?: string | Date | null): string {
  if (!date) {
    return '';
  }
  return new Date(date).toLocaleDateString('en-In', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
}

export function getDayOfWeek(date: string | Date): string | undefined {
  const dayOfWeek = new Date(date).getDay();
  return isNaN(dayOfWeek)
    ? undefined
    : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][dayOfWeek];
}

export function formatDateRange(startDate: string | Date, endDate: string | Date): string {
  const start = new Date(startDate);
  const end = new Date(endDate);
  if (start.getFullYear() == end.getFullYear()) {
    const year = start.getFullYear();
    const startMonth = start.toLocaleDateString('en-US', { month: 'short' });
    if (start.getMonth() == end.getMonth()) {
      return `${start.getDate()} ${startMonth} - ${end.getDate()} ${startMonth} ${year}`;
    }
    const endMonth = end.toLocaleDateString('en-US', { month: 'short' });
    return `${start.getDate()} ${startMonth} - ${end.getDate()} ${endMonth} ${year}`;
  }
  return `${formatShortDate(start)} - ${formatShortDate(end)}`;
}
