import { DateRange } from '@mui/lab/DateRangePicker/RangeTypes';

export type Record = {
  createdAt: string;
  updatedAt: string;
};

export type Employee = {
  id: string;
  name: string;
  designation?: string;
  email?: string;
};

export type AllocationBody = {
  description?: string;
  allocatedSince: Date | null;
  allocatedTo: null | Employee;
  asset?: Asset;
};

export type Allocation = AllocationBody &
  Record & {
    id: string;
  };

export type AssetBody = {
  type: string;
  active: boolean;
  serialNumber: string;
  dateOfPurchase: Date;
  description: string;
};

export type Asset = AssetBody &
  Record & {
    id: string;
    active: boolean;
    currentAllocation: null | Allocation;
    allocations?: Array<Allocation>;
  };

export type AssetFormBody = AssetBody & {
  allocatedTo: null | Employee;
  allocatedSince: null | Date;
};

export type AssetsSearchOptions = {
  type?: string;
  serialNumber?: string;
  active: boolean;
  allocationType: 'any' | 'none' | 'exactly' | 'not-none';
  allocatedTo: Employee | null;
  createdAt?: DateRange<Date>;
  allocatedAt?: DateRange<Date>;
  updatedAt?: DateRange<Date>;
  orderBy?: 'updatedAt' | 'createdAt' | 'allocatedAt' | 'type' | 'active';
  orderDirection?: 'asc' | 'desc';
  limit?: number;
  offset?: number;
};

export const ADD_ASSET = 'ADD_ASSET';
export const UPDATE_ASSET = 'UPDATE_ASSET';
export const REMOVE_ASSET = 'REMOVE_ASSET';
export const SET_ASSETS = 'SET_ASSETS';
export const SET_SEARCH_OPTIONS = 'SET_SEARCH_OPTIONS';
export const RESET_SEARCH_OPTIONS = 'RESET_SEARCH_OPTIONS';
export const SET_SEARCH_OPTIONS_TYPE = 'SET_SEARCH_OPTIONS_TYPE';
