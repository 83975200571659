import { LocationDescriptor } from 'history';
import { useMemo } from 'react';
import { generatePath, useLocation, useRouteMatch } from 'react-router';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getTeamReportForSOW } from '../actions';
import { formatDateRange } from '../../../utils/date';
import { getMonthStart, getSemiMonthStart, getWeekStart, getYearStart } from '../constants';

export const periods = [
  { value: 'week', label: 'Week' },
  { value: 'semimonth', label: 'Semi Month' },
  { value: 'month', label: 'Month' },
  { value: 'quarter', label: 'Quarter' },
  { value: 'year', label: 'Year' },
  // { value: 'all', label: 'All' },
];

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
export type PeriodValues = 'week' | 'semimonth' | 'month' | 'quarter' | 'year' | 'all';

function getDateAfterInterval(startDate: Date, period: PeriodValues) {
  const endDate = new Date(startDate);
  endDate.setUTCHours(12, 0, 0, 0);
  if (period === 'week') {
    endDate.setDate(endDate.getDate() + 6);
  } else if (period === 'month') {
    endDate.setMonth(startDate.getMonth() + 1);
    endDate.setDate(0);
  } else if (period === 'semimonth') {
    if (startDate.getDate() < 15) {
      endDate.setDate(15);
    } else {
      endDate.setMonth(startDate.getMonth() + 1);
      endDate.setDate(0);
    }
  } else if (period === 'quarter') {
    endDate.setMonth(startDate.getMonth() + 3);
    endDate.setDate(0);
  } else if (period === 'year') {
    endDate.setFullYear(startDate.getFullYear() + 1);
    endDate.setMonth(0);
    endDate.setDate(0);
  }
  return endDate;
}

function getRelativeStartDate(startDate: Date, period: PeriodValues, multiplier: -1 | 0 | 1): Date {
  const nextStartDate = new Date(startDate);
  nextStartDate.setUTCHours(12, 0, 0, 0);
  if (period === 'week') {
    nextStartDate.setDate(nextStartDate.getDate() + multiplier * 7);
    return getWeekStart(nextStartDate);
  }
  if (period === 'month') {
    nextStartDate.setMonth(nextStartDate.getMonth() + multiplier * 1);
    return getMonthStart(nextStartDate);
  }
  if (period === 'semimonth') {
    nextStartDate.setDate(startDate.getDate() + multiplier * 16);
    return getSemiMonthStart(nextStartDate);
  }
  if (period === 'quarter') {
    const month = nextStartDate.getMonth();
    nextStartDate.setMonth(Math.floor(month / 3) * 3 + multiplier * 3);
    return getMonthStart(nextStartDate);
  }
  if (period === 'year') {
    nextStartDate.setFullYear(nextStartDate.getFullYear() + multiplier * 1);
    return getYearStart(nextStartDate);
  }
  return nextStartDate;
}

const defaultPeriod = 'week';

export function useExtractParams(): {
  startDate: Date;
  endDate: Date;
  startDateString: string;
  period: PeriodValues;
  sow?: string;
  prevUrl: LocationDescriptor<unknown>;
  nextUrl: LocationDescriptor<unknown>;
  dateLabel: string;
  periodLabel: string;
  isCurrentPeriod: boolean;
  currentPeriodUrl: LocationDescriptor<unknown>;
  returnToCurrentPeriodLabel: string;
} {
  const query = useQuery();
  const { path } = useRouteMatch();

  //default values for period is "month"
  const startDateFromString = new Date(query.get('startDate') ?? '');
  const period = isNaN(startDateFromString.getTime())
    ? defaultPeriod
    : ((query.has('period') ? query.get('period') : defaultPeriod) as PeriodValues);
  const startDate = getRelativeStartDate(
    isNaN(startDateFromString.getTime()) ? new Date() : startDateFromString,
    period,
    0,
  );
  startDate.setUTCHours(12, 0, 0, 0);
  const sow = query.get('sow') || undefined;
  const endDate = getDateAfterInterval(startDate, period);

  const nextSearchParams = new URLSearchParams({
    period,
  });
  if (sow) {
    nextSearchParams.set('sow', sow);
  }
  nextSearchParams.set('startDate', getRelativeStartDate(startDate, period, 1).toISOString());
  const prevSearchParams = new URLSearchParams(nextSearchParams.toString());
  prevSearchParams.set('startDate', getRelativeStartDate(startDate, period, -1).toISOString());

  const nextUrl = {
    pathname: generatePath(path),
    search: nextSearchParams.toString(),
  };
  const prevUrl = {
    pathname: generatePath(path),
    search: prevSearchParams.toString(),
  };
  const dateLabel = period === 'all' ? '' : formatDateRange(startDate, endDate);
  const isCurrentPeriod =
    period === 'all'
      ? true
      : startDate.getTime() <= new Date().getTime() && endDate.getTime() >= new Date().getTime();
  const periodLabel =
    period === 'all'
      ? `All Time:`
      : `${isCurrentPeriod ? 'This ' : ''}${periods.find((p) => p.value === period)?.label}:`;

  const nowPeriodSearchParams = new URLSearchParams(nextSearchParams.toString());
  nowPeriodSearchParams.set('startDate', getRelativeStartDate(new Date(), period, 0).toISOString());
  const currentPeriodUrl = {
    pathname: generatePath(path),
    search: nowPeriodSearchParams.toString(),
  };
  const returnToCurrentPeriodLabel = `Return to this ${
    periods.find((p) => p.value === period)?.label
  }`;
  return {
    startDate,
    endDate,
    startDateString: startDate.toISOString(),
    period,
    sow,
    prevUrl,
    nextUrl,
    dateLabel,
    periodLabel,
    isCurrentPeriod,
    currentPeriodUrl,
    returnToCurrentPeriodLabel,
  };
}

export function useReport<T>(api = getTeamReportForSOW): {
  showLoading: boolean;
  results?: Array<T>;
} {
  const [showLoading, setShowLoading] = useState(false);
  const [results, setResults] = useState<Array<T>>();
  const { startDate, endDate, sow = '', period } = useExtractParams();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    async function fetchDataForReport() {
      try {
        setShowLoading(true);
        const results = await dispatch(api({ startDate, endDate, sow }));
        setResults(results as unknown as Array<T>);
      } catch (err) {
        enqueueSnackbar((err as Error).message, { variant: 'error' });
      } finally {
        setShowLoading(false);
      }
    }
    fetchDataForReport();
  }, [startDate.toISOString(), endDate.toISOString(), sow, period]);
  return { showLoading, results };
}
