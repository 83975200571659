import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';
import { useField } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import { GroupShort } from './types';
import { getGroups } from './groups/actions';

export type FormGroupsPickerProps<T> = Omit<
  AutocompleteProps<T, boolean, boolean, boolean>,
  'renderInput' | 'options'
> & {
  name: string;
  disabled?: boolean;
  margin?: 'dense' | 'none';
  fullWidth?: boolean;
  label?: string;
};
export function FormGroupsPicker({
  name,
  fullWidth = true,
  disabled = false,
  margin = 'dense',
  label,
  ...rest
}: FormGroupsPickerProps<GroupShort>): JSX.Element {
  const [field, meta, { setValue }] = useField(name);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onChange, ...restField } = field;
  const dispatch = useDispatch();
  const errorText = meta.error && meta.touched ? meta.error : '';
  const hasError = !!errorText;
  const [inputValue, setInputValue] = useState('');
  const [users, setUsers] = useState<GroupShort[]>([]);

  const handleInputChange = (event: React.SyntheticEvent, value: string) => {
    setInputValue(value);
  };

  //https://dmitripavlutin.com/react-throttle-debounce/
  const debouncedChangeHandler = useMemo(() => debounce(handleInputChange, 400), []);
  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, []);

  //Fetch results from the server for search term.
  useEffect(() => {
    let active = true;
    if (field.value) {
      setUsers([field.value]);
    }
    async function fetchUsersByText(text: string) {
      const groups = await dispatch(
        getGroups({
          name: text,
        }),
      );
      if (!active) return;
      setUsers(groups as unknown as Array<GroupShort>);
    }
    inputValue.length > 2 ? fetchUsersByText(inputValue) : setUsers([]);
    return () => {
      active = false;
    };
  }, [field.value, inputValue]);
  return (
    <Autocomplete
      options={users}
      clearOnBlur
      onInputChange={debouncedChangeHandler}
      onChange={(event, value) => {
        setValue(value);
      }}
      noOptionsText="Start typing or try different term"
      disablePortal
      filterOptions={(x) => x}
      {...restField}
      {...rest}
      getOptionLabel={(option) => `${option.name}`}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth={fullWidth}
          margin={margin}
          label={label}
          disabled={disabled}
          error={hasError}
          helperText={errorText}
        />
      )}
    />
  );
}
