import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

type Props = DialogProps & {
  title?: string;
  content: string;
  actions: {
    label: string;
    onClick: () => void;
  }[];
};

// ✔️ create the dialog you want to use
export default function SimpleDialog({ title, ...props }: Props): JSX.Element {
  return (
    <Dialog {...props}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText>{props.content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {props.actions.map((action, index) => (
          <Button variant="contained" color="primary" key={index} onClick={action.onClick}>
            {action.label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
}
