import {
  Divider,
  Stack,
  Drawer,
  Toolbar,
  Typography,
  ListItemText,
  List,
  ListItemIcon as MUIListItemIcon,
  ListItemIconProps as MUIListItemIconProps,
  ListItemButton as MUIListItemButton,
  ListItemButtonProps as MUIListItemButtonProps,
  Collapse,
  Box,
  CardMedia,
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../../redux-store/reducers';
import { AuthState } from '../../../redux-store/types/login';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { HeaderToggleContext } from '../../../HeaderToggleContext';

const ListItemButton = ({
  children,
  sx,
  ...props
}: { children: ReactNode } & MUIListItemButtonProps): JSX.Element => (
  <MUIListItemButton sx={{ pl: 2, pr: 1, ...sx }} {...props}>
    {children}
  </MUIListItemButton>
);

const ListItemIcon = ({
  children,
  sx,
  ...props
}: { children: ReactNode } & MUIListItemIconProps): JSX.Element => (
  <MUIListItemIcon sx={{ minWidth: 27, ...sx }} {...props}>
    {children}
  </MUIListItemIcon>
);

const drawerWidth = 220;
const PermissionsContext = createContext<{ permissions?: Array<string> | null; role?: string }>({
  permissions: [],
});

const usePermissions = () => {
  return useContext(PermissionsContext);
};

function WithPermission({
  permission = undefined,
  component: Component,
  ...props
}: {
  permission?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<any>;
}) {
  const { permissions, role } = usePermissions();
  if (!role || !permissions) {
    return null;
  }
  if (permission && !permissions.includes(permission)) {
    return null;
  }
  return <Component {...props} />;
}

function RoutedListItem({
  permission = undefined,
  ...props
}: { permission?: string } & RoutedListItemSimpleProps) {
  return <WithPermission permission={permission} component={RoutedListItemSimple} {...props} />;
}

type RoutedListItemSimpleProps = {
  route: string;
  children: ReactNode;
  inset?: boolean;
};

function RoutedListItemSimple({
  route,
  children,
  inset = false,
}: RoutedListItemSimpleProps): JSX.Element {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { setHeaderToggle } = useContext(HeaderToggleContext);
  const redirectTo = (path: string) => {
    history.push(`${path}`);
    setHeaderToggle(false);
  };
  return (
    <ListItemButton
      selected={url.startsWith(route)}
      sx={{
        pl: inset ? 5 : 2,
        '& .MuiTypography-root': { fontSize: 14, color: '#33364D' },
        '& .MuiListItemText-root': { margin: 0 },
        '& .MuiCardMedia-img': {
          width: 15,
          '&.icon-dark': {
            display: ['none', 'block'],
          },
          '&.icon-white': {
            display: ['block', 'none'],
          },
        },
        '&.Mui-selected': {
          backgroundColor: '#4878F5',
          '&:hover': {
            backgroundColor: '#4878F5',
          },
          '& .MuiTypography-root': { color: '#fff', fontWeight: 600 },
          '& .MuiCardMedia-img': {
            '&.icon-dark': {
              display: 'none',
            },
            '&.icon-white': {
              display: 'block',
            },
          },
        },
      }}
      onClick={() => redirectTo(route)}
    >
      {children}
    </ListItemButton>
  );
}

function ListGroup({
  permission = undefined,
  ...props
}: { permission?: string } & ListGroupSimpleProps) {
  return <WithPermission permission={permission} component={ListGroupSimple} {...props} />;
}

type ListGroupSimpleProps = {
  baseURL: string;
  title: string;
  icon: ReactNode;
  children: React.ReactNode;
};

function ListGroupSimple({ baseURL, title, icon, children }: ListGroupSimpleProps): JSX.Element {
  const { url } = useRouteMatch();
  const [open, setOpen] = useState(url.startsWith(baseURL));
  const toggleOpen = () => {
    setOpen(!open);
  };
  useEffect(() => {
    setOpen(url.startsWith(baseURL));
  }, [url, baseURL]);
  return (
    <>
      <ListItemButton onClick={toggleOpen}>
        <ListItemIcon
          sx={{
            '& .MuiCardMedia-img': {
              width: 15,
              '&.icon-dark': {
                display: ['none', 'block'],
              },
              '&.icon-white': {
                display: ['block', 'none'],
              },
            },
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          sx={{
            '& .MuiTypography-root': { fontSize: 14, color: '#33364D' },
          }}
          primary={title}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  );
}

export default function DrawerComponent({
  permissions = [],
}: {
  permissions?: Array<string> | null;
}): JSX.Element {
  const { name, role } = useSelector<RootStateType, AuthState>(({ authReducer }) => authReducer);
  const { headerToggle } = useContext(HeaderToggleContext);
  return (
    <PermissionsContext.Provider value={{ permissions, role: role ?? undefined }}>
      <Drawer
        variant="permanent"
        open={false}
        sx={{
          width: ['100%', drawerWidth],
          flexShrink: 0,
          transition: 'all 0.2s ease-in-out',
          position: ['fixed', 'relative'],
          top: ['56px', 0],
          left: [headerToggle ? 0 : '-200vw', 0],
          zIndex: [999999, 0],
          height: ['calc(100vh - 56px)', 'auto'],
          bgcolor: ['primary.darkBlue', 'primary.white'],
          [`& .MuiDrawer-paper`]: {
            width: ['100%', drawerWidth],
            boxSizing: 'border-box',
            position: ['relative', 'fixed'],
            bgcolor: ['primary.darkBlue', 'primary.white'],
          },
        }}
      >
        <Toolbar
          sx={{
            display: ['none', 'block'],
          }}
        />
        <Box
          sx={{
            height: ['100%', 'calc(100vh - 64px)'],
            '& .MuiSvgIcon-root': {
              color: ['primary.white', 'unset'],
            },
            '& .MuiListItemButton-root': {
              '& .MuiListItemText-root .MuiTypography-root': {
                color: ['primary.white', 'primary.darkBlue'],
                fontSize: ['16px', '14px'],
              },
              '&.Mui-selected': {
                '& .MuiListItemText-root .MuiTypography-root': {
                  color: 'primary.white',
                },
              },
            },
          }}
        >
          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              paddingBlock: 2,
              px: 2,
              border: [0, '1px solid rgb(255, 255, 255)'],
            }}
          >
            <AccountCircle fontSize={'large'} />
            <Typography
              sx={{
                px: 1,
                fontSize: [18, 16],
                textTransform: 'capitalize',
                color: ['primary.white', 'primary.darkBlue'],
              }}
            >
              {name}
            </Typography>
          </Stack>
          <Divider />
          {/* {role == 'super_admin' ? ( */}
          <List>
            <ListGroup
              baseURL="/time"
              title="Time"
              icon={
                <>
                  <CardMedia
                    component="img"
                    alt="timer icon"
                    src="/images/timer.svg"
                    className="icon-dark"
                  />
                  <CardMedia
                    component="img"
                    alt="timer icon"
                    src="/images/timer-white.svg"
                    className="icon-white"
                  />
                </>
              }
            >
              <List component="div" disablePadding>
                <RoutedListItem inset route="/time/track">
                  <ListItemText primary="Track" />
                </RoutedListItem>
                <RoutedListItem inset route="/time/sheets">
                  <ListItemText primary="Time Sheet" />
                </RoutedListItem>
                {/* <RoutedListItem
                  inset
                  route="/time/reports"
                  permission="timesheets:timesheets:read-any"
                >
                  <ListItemText primary="Reports" />
                </RoutedListItem> */}
              </List>
            </ListGroup>
            <ListGroup
              baseURL="/delivery"
              title="Delivery"
              icon={
                <>
                  <CardMedia
                    component="img"
                    alt="delivery icon"
                    src="/images/delivery.svg"
                    className="icon-dark"
                  />
                  <CardMedia
                    component="img"
                    alt="delivery icon"
                    src="/images/delivery-white.svg"
                    className="icon-white"
                  />
                </>
              }
              permission="delivery:projects:read"
            >
              <List component="div" disablePadding>
                <RoutedListItem inset route="/delivery/phases">
                  <ListItemText primary="SOWs" />
                </RoutedListItem>
                <RoutedListItem inset route="/delivery/projects">
                  <ListItemText primary="Clients" />
                </RoutedListItem>
                <RoutedListItem inset route="/delivery/allocations">
                  <ListItemText primary="Allocations" />
                </RoutedListItem>
                <RoutedListItem inset route="/delivery/costs">
                  <ListItemText primary="Team expenses" />
                </RoutedListItem>
              </List>
            </ListGroup>
            <ListGroup
              baseURL="/admin/reports"
              title="Reports"
              icon={
                <>
                  <CardMedia
                    component="img"
                    alt="reports-icon"
                    src="/images/reports.svg"
                    className="icon-dark"
                  />
                  <CardMedia
                    component="img"
                    alt="reports-icon"
                    src="/images/reports-white.svg"
                    className="icon-white"
                  />
                </>
              }
            >
              <List component="div" disablePadding>
                <RoutedListItem inset route="/admin/reports/sow">
                  <ListItemText primary="Project Summary" />
                </RoutedListItem>
                <RoutedListItem inset route="/admin/reports/team">
                  <ListItemText primary="Team" />
                </RoutedListItem>
                <RoutedListItem inset route="/admin/reports/tasks">
                  <ListItemText primary="Task" />
                </RoutedListItem>
              </List>
            </ListGroup>
            <RoutedListItem route="/admin/users" permission="users:users:list">
              <ListItemIcon>
                <CardMedia
                  component="img"
                  alt="team-icon"
                  src="/images/team.svg"
                  className="icon-dark"
                />
                <CardMedia
                  component="img"
                  alt="team-icon"
                  src="/images/team-white.svg"
                  className="icon-white"
                />
              </ListItemIcon>
              <ListItemText primary="Team" />
            </RoutedListItem>
            <ListGroup
              baseURL="/admin/ats"
              title="Hiring"
              icon={
                <>
                  <CardMedia
                    component="img"
                    alt="hiring-icon"
                    src="/images/hiring.svg"
                    className="icon-dark"
                  />
                  <CardMedia
                    component="img"
                    alt="hiring-icon"
                    src="/images/hiring-white.svg"
                    className="icon-white"
                  />
                </>
              }
              permission="ats:campaign:read"
            >
              <List component="div" disablePadding>
                <RoutedListItem inset route="/admin/ats/campaigns">
                  <ListItemText primary="Campaigns" />
                </RoutedListItem>
                <RoutedListItem inset route="/admin/ats/candidates">
                  <ListItemText primary="Candidates" />
                </RoutedListItem>
                <RoutedListItem inset route="/admin/ats/notes">
                  <ListItemText primary="Notes" />
                </RoutedListItem>
                <RoutedListItem inset route="/admin/ats/assessments">
                  <ListItemText primary="Assessments" />
                </RoutedListItem>
              </List>
            </ListGroup>
            <RoutedListItem route="/admin/assets" permission="assets:assets:list">
              <ListItemIcon>
                <CardMedia
                  component="img"
                  alt="assets-icon"
                  src="/images/assets.svg"
                  className="icon-dark"
                />
                <CardMedia
                  component="img"
                  alt="assets-icon"
                  src="/images/assets-white.svg"
                  className="icon-white"
                />
              </ListItemIcon>
              <ListItemText primary="Assets" />
            </RoutedListItem>
            <RoutedListItem route="/admin/tickets" permission="tickets:tickets:list">
              <ListItemIcon>
                <CardMedia
                  component="img"
                  alt="tickets-icon"
                  src="/images/tickets.svg"
                  className="icon-dark"
                />
                <CardMedia
                  component="img"
                  alt="tickets-icon"
                  src="/images/tickets-white.svg"
                  className="icon-white"
                />
              </ListItemIcon>
              <ListItemText primary="Tickets" />
            </RoutedListItem>
            <ListGroup
              baseURL="/admin/permissions"
              title="Permissions"
              icon={
                <>
                  <CardMedia
                    component="img"
                    alt="permissions-icon"
                    src="/images/permissions.svg"
                    className="icon-dark"
                  />
                  <CardMedia
                    component="img"
                    alt="permissions-icon"
                    src="/images/permissions-white.svg"
                    className="icon-white"
                  />
                </>
              }
              permission="permissions:permissions:list"
            >
              <List component="div" disablePadding>
                <RoutedListItem inset route="/admin/permissions/groups">
                  <ListItemText primary="Groups" />
                </RoutedListItem>
              </List>
            </ListGroup>
            <RoutedListItem route="/holidays" permission="leaves:holidays:list">
              <ListItemIcon>
                <CardMedia
                  component="img"
                  alt="holidays-icon"
                  src="/images/holidays.svg"
                  className="icon-dark"
                />
                <CardMedia
                  component="img"
                  alt="holidays-icon"
                  src="/images/holidays-white.svg"
                  className="icon-white"
                />
              </ListItemIcon>
              <ListItemText primary="Holidays" />
            </RoutedListItem>
          </List>
          {/* ) : (
          <List>
            <RoutedListItem route="/pendingLeaves">
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Pending Leaves" />
            </RoutedListItem>
            <RoutedListItem route="/leaves">
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Leaves" />
            </RoutedListItem>
          </List>
        )} */}
        </Box>
      </Drawer>
    </PermissionsContext.Provider>
  );
}
