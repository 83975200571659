/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CALL_API } from '../../../redux-store/middleware';
import { Note, NotesFormBody, NotesSearchParameters } from '../types';
import { ADD_NOTE, SET_NOTES, UPDATE_NOTE } from './constants';

export const defaultSearchOption: Partial<NotesSearchParameters> = {
  candidateId: '',
};

export const onGetNotes =
  (searchOptions = defaultSearchOption) =>
  async (dispatch: any) => {
    try {
      const searchParams = new URLSearchParams();

      if (searchOptions.candidateId) {
        searchParams.append('candidateId', searchOptions.candidateId);
      }
      if (searchOptions.limit) {
        searchParams.append('limit', searchOptions.limit.toString());
      }
      const notes = await dispatch({
        [CALL_API]: {
          url: `/ats/notes?${searchParams.toString()}`,
          method: 'GET',
        },
      });
      dispatch({
        type: SET_NOTES,
        notes,
      });
      return notes as Array<Note>;
    } catch (error) {
      console.log(error);
    }
  };

export const createNote = (body: NotesFormBody) => async (dispatch: any) => {
  try {
    const note = await dispatch({
      [CALL_API]: {
        url: `/ats/notes`,
        method: 'POST',
        body,
      },
    });
    dispatch({
      type: ADD_NOTE,
      notes: note,
    });
    return note as Note;
  } catch (error) {
    console.log(error);
  }
};

export const getNotesById = (id: string) => async (dispatch: any) => {
  try {
    const notesDetails = await dispatch({
      [CALL_API]: {
        url: `/ats/notes/${id}`,
        method: 'GET',
      },
    });
    return notesDetails as Note;
  } catch (error) {
    console.log(error);
  }
};

export const updateNote = (id: string, body: NotesFormBody) => async (dispatch: any) => {
  try {
    const updatedNote = await dispatch({
      [CALL_API]: {
        url: `/ats/notes/${id}`,
        method: 'POST',
        body,
      },
    });
    dispatch({
      type: UPDATE_NOTE,
      note: updatedNote,
    });
    return updatedNote as Note;
  } catch (error) {
    console.log(error);
  }
};
