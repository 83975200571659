import { Button, ButtonProps } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';

export function ButtonPrimary({
  type = 'submit',
  variant = 'contained',
  ...props
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
ButtonProps & { component?: any; to?: any }): JSX.Element {
  return <Button type={type} variant={variant} fullWidth {...props}></Button>;
}

export function SubmitButton({
  label = 'Submit',
  variant = 'contained',
  color = 'primary',
  ...props
}: ButtonProps & { label: string }): JSX.Element {
  const formikContext = useFormikContext();
  const { isSubmitting: disabled } = formikContext;

  return (
    <Button
      type="submit"
      // fullWidth={fullWidth}
      variant={variant}
      color={color}
      {...props}
      disabled={disabled}
    >
      {label} {disabled ? '...' : ''}
    </Button>
  );
}
