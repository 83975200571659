import {
  Box,
  Button,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  SelectProps,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useLocation, useRouteMatch } from 'react-router-dom';
import { RootStateType } from '../../../redux-store/reducers';
import { ButtonPrimary } from '../../forms/Button';
import { AssessmentState } from '../store/assessment-reducer';
import { getAssessments } from './actions';
import { SET_SEARCH_OPTIONS_TYPE } from './constants';
import AssessmentSearchOptions from './searchOptions';

const headers = ['Candidate', 'Score', 'Comment', 'Status', 'Type', 'Actions'];

export default function AssessmentList(): JSX.Element {
  const { assessments, searchOptions, searchOptionType } = useSelector<
    RootStateType,
    AssessmentState
  >((state) => state.ats.assessment);
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [showProgress, setShowProgress] = useState(false);
  const [openOptions, setOpenOptionsType] = useState(false);

  useEffect(() => {
    async function getAssessment() {
      try {
        setShowProgress(true);
        await dispatch(getAssessments(searchOptions));
      } catch (error) {
        enqueueSnackbar((error as Error).message, { variant: 'error' });
      } finally {
        setShowProgress(false);
      }
    }
    getAssessment();
  }, [searchOptions]);

  const handleOptionsTypeChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    if (value === 'custom') {
      setOpenOptionsType(true);
    } else {
      dispatch({ type: SET_SEARCH_OPTIONS_TYPE, searchOptionType: value });
    }
  };

  return (
    <Box>
      <AssessmentSearchOptions
        open={openOptions}
        onClose={() => {
          setOpenOptionsType(false);
        }}
      />
      <Typography variant="h4" sx={{ mb: 2 }}>
        Assessments
      </Typography>
      <Stack pb={2} spacing={2} direction="row" justifyContent="space-between">
        <Stack spacing={2} direction="row">
          <SearchOptionsSelect
            defaultValue={searchOptionType}
            onChange={handleOptionsTypeChange}
            autoWidth
          />
          <ButtonPrimary
            variant="outlined"
            onClick={() => {
              setOpenOptionsType(true);
            }}
          >
            Filters
          </ButtonPrimary>
        </Stack>
        <Button
          component={RouterLink}
          to={{ pathname: `${url}/addAssessment`, state: { background: location } }}
          variant="outlined"
          fullWidth={false}
        >
          New Assessment
        </Button>
      </Stack>

      <div style={{ float: 'right' }}></div>
      {showProgress && <LinearProgress />}
      <TableContainer component={Paper}>
        <Table aria-label="assessment list">
          <TableHead>
            <TableRow>
              {headers.map((head) => (
                <TableCell sx={{ fontWeight: 'bold' }} key={head}>
                  {head}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {assessments.map((assessment) => (
              <TableRow key={assessment.id}>
                <TableCell>
                  <Button
                    to={{
                      pathname: `/admin/ats/candidates/${assessment.candidate.id}`,
                      state: { background: { pathname: `/admin/ats/candidates` } },
                    }}
                    component={RouterLink}
                    sx={{ textTransform: 'none' }}
                  >
                    {assessment.candidate.name}
                  </Button>
                </TableCell>
                <TableCell>{assessment.score}</TableCell>
                <TableCell>{assessment.comment}</TableCell>
                <TableCell>{assessment.status}</TableCell>
                <TableCell>{assessment.type}</TableCell>
                <TableCell>
                  <Button
                    to={{
                      pathname: `${url}/${assessment.id}`,
                      state: { background: location },
                    }}
                    component={RouterLink}
                    sx={{ textTransform: 'none' }}
                  >
                    View
                  </Button>
                  <Button
                    to={{
                      pathname: `${url}/update/${assessment.id}`,
                      state: { background: location, candidateId: assessment.candidate.id },
                    }}
                    component={RouterLink}
                    sx={{ textTransform: 'none' }}
                  >
                    Update
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
function SearchOptionsSelect(props: SelectProps<string>) {
  return (
    <Select label="Quick Filters" variant="standard" {...props}>
      <MenuItem value="allAssessments">All Assessments</MenuItem>
      <MenuItem value="custom">Custom</MenuItem>
    </Select>
  );
}
