import { Button, Stack, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import FilteredAutocompleteMultiple from '../../forms/Autocomplete/filtered-autocomplete-multiple';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux-store/reducers';
import { PhasesState } from '../../delivery/store/phases.reducer';
import { getPhases } from '../../delivery/actions';
import {
  FormattedEngagementTypes,
  PHASE_SEARCH_OPTIONS,
  RESET_PHASE_SEARCH_OPTIONS,
} from '../../delivery/constants';
import ReuseableLayoutHeader from '../../common/layouts';
import { useExtractParams } from '../../time-tracking/constants';
import { BasicDatePicker } from '../../time-tracking/common';
import { Phase } from '../types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const allChecked = {
  name: 'All',
  id: '',
};

export function SowFilters(): JSX.Element {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { searchOptions } = useSelector<RootState, PhasesState>((state) => state.delivery.phases);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedType, setSelectedType] = useState<Array<any>>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedClients, setSelectedClients] = useState<Array<any>>();
  const [phases_, setPhases] = useState<Array<Phase>>();

  function onTypeValuesChange(values: Array<string>) {
    setSelectedType(values);
  }
  function onClientValuesChange(values: Array<string>) {
    setSelectedClients(values);
  }

  const { date } = useExtractParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [startDate, setStartDate] = useState(date);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [endDate, setEndDate] = useState(date);
  const [startDateChanged, setStartDateChanged] = useState(false);
  const [endDateChanged, setEndDateChanged] = useState(false);
  function handleDatePickerStartDateChange(d: Date | null) {
    if (d) {
      const date = new Date(d);
      setStartDate(date);
      setStartDateChanged(true);
      dispatch({
        type: PHASE_SEARCH_OPTIONS,
        searchOptions: {
          ...searchOptions,
          phaseStart: [date],
        },
      });
    }
  }

  function handleDatePickerEndDateChange(d: Date | null) {
    if (d) {
      const date = new Date(d);
      setEndDate(date);
      setEndDateChanged(true);
      dispatch({
        type: PHASE_SEARCH_OPTIONS,
        searchOptions: {
          ...searchOptions,
          phaseEnd: [date],
        },
      });
    }
  }

  useEffect(() => {
    if (selectedClients && selectedClients.length > 0) {
      dispatch({
        type: PHASE_SEARCH_OPTIONS,
        searchOptions: {
          ...searchOptions,
          name: selectedClients.map((client) => client.id).join(','),
        },
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { name, ...rest } = searchOptions;
      dispatch({
        type: PHASE_SEARCH_OPTIONS,
        searchOptions: rest,
      });
    }
  }, [selectedClients]);

  useEffect(() => {
    async function getPhasesList() {
      try {
        const phases = await dispatch(getPhases());
        setPhases(phases as unknown as Array<Phase>);
      } catch (error) {
        console.log(error);
      }
    }
    getPhasesList();
  }, []);

  useEffect(() => {
    if (selectedType && selectedType.length > 0) {
      dispatch({
        type: PHASE_SEARCH_OPTIONS,
        searchOptions: {
          ...searchOptions,
          type: selectedType?.map((type) => type.id).join(','),
        },
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { type, ...rest } = searchOptions;
      dispatch({
        type: PHASE_SEARCH_OPTIONS,
        searchOptions: {
          ...rest,
        },
      });
    }
  }, [selectedType]);

  const formattedEngagementTypes = FormattedEngagementTypes.map((type) => ({
    name: type.label,
    id: type.value,
  }));

  const formattedProjects = phases_?.map((phase) => ({
    name: phase.name,
    id: phase.name,
  }));

  const showClearButton =
    (selectedType && selectedType.length > 0) ||
    (selectedClients && selectedClients.length > 0) ||
    startDateChanged === true ||
    endDateChanged === true;

  const handleClear = () => {
    setSelectedType([]);
    setSelectedClients([]);
    setStartDateChanged(false);
    setEndDateChanged(false);
    dispatch({
      type: RESET_PHASE_SEARCH_OPTIONS,
    });
  };

  return (
    <>
      <ReuseableLayoutHeader>
        <FilteredAutocompleteMultiple
          label="Client"
          searchPlaceholder="Search Client"
          options={formattedProjects as Array<{ name: string; id: string }>}
          onChange={onClientValuesChange}
          value={selectedClients}
          filterId="client"
          sx={{ minWidth: 270 }}
        />
        <FilteredAutocompleteMultiple
          label="Type"
          searchPlaceholder="Search Project"
          options={formattedEngagementTypes}
          onChange={onTypeValuesChange}
          value={selectedType}
          filterId="type"
          disableSearch={true}
        />
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRight: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
            fontWeight: 600,
            display: 'flex',
            minWidth: 150,
            px: 2,
            py: 1.5,
          }}
        >
          <Typography variant="h5" sx={{ color: 'secondary.main' }}>
            Start Date
          </Typography>
          <BasicDatePicker value={date} onChange={handleDatePickerStartDateChange} />
        </Stack>
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRight: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
            fontWeight: 600,
            display: 'flex',
            minWidth: 150,
            px: 2,
            py: 1.5,
          }}
        >
          <Typography variant="h5" sx={{ color: 'secondary.main' }}>
            End Date{' '}
          </Typography>
          <BasicDatePicker value={date} onChange={handleDatePickerEndDateChange} />
        </Stack>
      </ReuseableLayoutHeader>

      {showClearButton && (
        <Button variant="text" sx={{ ml: 1 }} onClick={handleClear}>
          Clear
        </Button>
      )}
    </>
  );
}
