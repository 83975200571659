import { Action, Employee } from '../types';

export type TeamState = {
  users: Array<Employee>;
};

export const SET_TEAM_USERS = 'SET_TEAM_USERS';

const initialState: TeamState = {
  users: [],
};

export function reducer(state = initialState, action: Action): TeamState {
  switch (action.type) {
    case SET_TEAM_USERS:
      return {
        ...state,
        users: action.users as Array<Employee>,
      };
    default:
      return state;
  }
}
