import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import LeavesTable from './LeavesTable';
import { HeaderProps } from '../forms/Table/type';
import { onGetLeaves, onCreateLeave, onUpdateLeave } from './actions';
import CreateLeaveForm from './CreateLeaveForm';
import UpdateLeaveForm from './UpdateLeaveForm';
import moment from 'moment';
import { Modal } from 'react-responsive-modal';
import { ButtonPrimary } from '../forms/Button';
import { Stack } from '@mui/material';
import { Box } from '@mui/system';

const headers: HeaderProps[] = [
  {
    label: 'Start Date',
    stateKey: 'startDateVal',
  },
  {
    label: 'End Date',
    stateKey: 'endDateVal',
  },
  {
    label: 'Details',
    stateKey: 'details',
  },
  {
    label: 'Leave Type',
    stateKey: 'leaveType',
  },
  {
    label: 'Applied By',
    stateKey: 'appliedby',
  },
  {
    label: 'Approved By',
    stateKey: 'approvedby',
  },
  {
    label: 'Status',
    stateKey: 'status',
  },
  {
    label: 'Action',
    stateKey: '',
  },
];

function Leaves(props: any) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [leaves, setLeaves] = useState<Array<{ key: string; value: string }>>([]);
  const [isResponseOpen, setIsResponseOpen] = useState<boolean>(false);
  const [responseHeading, setResponseHeading] = useState<string>('');
  const [responseMessage, setResponseMessage] = useState<string>('');
  const [updateLeave, setUpdateLeave] = useState<any>({});
  const [isUpdateOpen, setIsUpdateOpen] = useState<boolean>(false);
  async function _onGetLeaves() {
    let employeeObj: any = {};
    employeeObj['startDate'] = '';
    employeeObj['endDate'] = '';
    employeeObj['status'] = '';
    employeeObj['employeeId'] = props.id;
    const leavesBody = await props.onGetLeaves(); //Need to get self leaves
    let requiredData =
      leavesBody.body &&
      Object.keys(leavesBody.body).map((key: any) => {
        let item = leavesBody.body[key];
        item['startDateVal'] = moment(item.startDate).format('Do MMM YYYY');
        item['endDateVal'] = moment(item.endDate).format('Do MMM YYYY');
        if (item.employee) {
          item['employeename'] = item.employee.name;
        }
        if (item.appliedBy) {
          item['appliedby'] = item.appliedBy.name;
        }
        if (item.approvedBy) {
          item['approvedby'] = item.approvedBy.name;
        }
        return item;
      });
    setIsOpen(false);
    setIsUpdateOpen(false);
    setLeaves(requiredData);
    console.log(requiredData);
  }
  const handleClose = () => {
    setIsOpen(false);
  };
  async function onSubmit(values: any) {
    console.log(`on sumit ${props.id}`);
    let empObj: any = {};
    empObj['id'] = values.forEmployee != '' ? values.forEmployee : props.id;
    let appliedObj: any = {};
    appliedObj['id'] = props.id;
    let reqObj: any = {};
    reqObj['employee'] = empObj;
    reqObj['details'] = values.details;
    reqObj['startDate'] = values.startDate;
    reqObj['endDate'] = values.endDate;
    reqObj['leaveType'] = values.leaveType;
    reqObj['appliedBy'] = appliedObj;

    let leave = await props.onCreateLeave(reqObj);
    if (!leave) {
      //Unknown err
      setResponseHeading('Failed');
      setResponseMessage('Something went wrong. Please try again.');
      setIsResponseOpen(true);
    } else if (leave.error) {
      //Failed
      setResponseHeading('Failed');
      setResponseMessage(leave.error.message);
      setIsResponseOpen(true);
    } else {
      //Success
      _onGetLeaves();
      setResponseHeading('Success');
      setResponseMessage('Leave created successfully !!!');
      setIsResponseOpen(true);
    }
  }
  const onUpdate = (value: any) => {
    setUpdateLeave(value);
    setIsUpdateOpen(true);
  };
  const handleUpdateClose = () => {
    setIsUpdateOpen(false);
  };
  async function onUpdateLeave(values: any) {
    let response = await props.onUpdateLeave(values);
    if (!response) {
      //Unknown err
      setResponseHeading('Failed');
      setResponseMessage('Something went wrong. Please try again.');
      setIsResponseOpen(true);
    } else if (response.error) {
      //Failed
      setResponseHeading('Failed');
      setResponseMessage(response.error.message);
      setIsResponseOpen(true);
    } else {
      //Success
      _onGetLeaves();
      setResponseHeading('Success');
      setResponseMessage(response.body.message);
      setIsResponseOpen(true);
    }
  }

  const openLeaveUser = () => {
    setIsOpen(true);
  };
  const handleResponseClose = () => {
    setIsResponseOpen(false);
  };
  useEffect(() => {
    console.log(`Props id ${props.id}`);
    _onGetLeaves();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Stack>
        <Stack direction="row" sx={{ mt: '67px' }}>
          <Box
            sx={{
              width: '100%',
              margin: '30px',
              overflowX: 'hidden',
              bgcolor: 'primary.white',
            }}
          >
            <div style={{ float: 'right' }}>
              <ButtonPrimary onClick={openLeaveUser} variant="outlined" fullWidth={false}>
                Create Leave
              </ButtonPrimary>
            </div>
            <LeavesTable data={leaves} headers={headers} onUpdate={onUpdate} />
          </Box>
        </Stack>
      </Stack>
      <Modal
        open={isOpen}
        onClose={handleClose}
        center
        //container={refModal.current}
        focusTrapped
      >
        <CreateLeaveForm onSubmit={onSubmit} />
      </Modal>
      <Modal
        open={isResponseOpen}
        onClose={handleResponseClose}
        center
        //container={refModal.current}
        focusTrapped
      >
        <Stack>
          <h3>{responseHeading}</h3>
          <span>{responseMessage}</span>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Box sx={{ m: '10px' }}>
              <ButtonPrimary
                onClick={handleResponseClose}
                variant="outlined"
                fullWidth={false}
                color="primary"
              >
                Ok
              </ButtonPrimary>
            </Box>
          </Box>
        </Stack>
      </Modal>
      {updateLeave ? (
        <Modal
          open={isUpdateOpen}
          onClose={handleUpdateClose}
          center
          //container={refModal.current}
          focusTrapped
        >
          <UpdateLeaveForm onUpdateLeave={onUpdateLeave} data={updateLeave} />
        </Modal>
      ) : null}
    </>
  );
}
export default connect(
  (state: any) => {
    const { authReducer = {} } = state;
    return { id: authReducer?.id };
  },
  {
    onGetLeaves,
    onCreateLeave,
    onUpdateLeave,
  },
)(Leaves);
