import {
  Box,
  Chip,
  Grid,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { onGetUsersbyId } from '../../../redux-store/actions/users';
import { UserDetails } from '../../../redux-store/types/users';
import { formatShortDate } from '../../../utils/date';
import { TitleBar } from '../../assets/components';
import { StyledTableRow } from '../../common';
import { ReuseableLayout } from '../projects/Details';
import { ChipTag } from '../projects/List';
import { Resource } from '../types';

export default function EmployeeDetails(): JSX.Element {
  const [users_details, setDetails] = useState<UserDetails>();
  const [showProgress, setShowProgress] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const { state } = location;
  const { resources } = state as { resources: Array<Resource> };

  useEffect(() => {
    async function getUsersbyId() {
      try {
        setShowProgress(true);
        const user_details = await dispatch(onGetUsersbyId(id));
        setDetails(user_details as unknown as UserDetails);
      } catch (error) {
        console.log(error);
      } finally {
        setShowProgress(false);
      }
    }
    getUsersbyId();
  }, [id]);
  return (
    <>
      <TitleBar title="Employee Details" />
      {showProgress && <LinearProgress />}
      {users_details && (
        <Box sx={{ px: 4 }}>
          <Box sx={{ display: 'flex' }} alignItems="center">
            <Box sx={{ px: 2, py: 1, backgroundColor: '#EFF3F8', mr: 2 }}>
              <img src="/images/personIcon.svg" alt="person" />
            </Box>
            <Stack flexDirection="column">
              <Stack sx={{ pb: 2 }}>
                <Typography variant="h2">{users_details.name}</Typography>
              </Stack>
              <Stack flexDirection="row">
                <Stack sx={{ pr: 3, borderRight: '1px solid #1376E3' }}>
                  <Typography variant="h4" sx={{ fontWeight: 400 }}>
                    {users_details?.additionalDetails?.phoneNumber
                      ? users_details?.additionalDetails?.phoneNumber
                      : 'N/A'}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="h4" color="primary.blueBg" sx={{ fontWeight: 400, pl: 3 }}>
                    {users_details.email}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Box>
          <Box sx={{ borderBottom: '1px solid #D1D1D1', opacity: 0.49, mt: 3 }} />
          <Box sx={{ mt: 4 }}>
            <Stack sx={{ mb: 2 }}>
              <Typography variant="h4">Employee Information</Typography>
            </Stack>
            <Stack flexDirection="column">
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <ReuseableLayout
                    headerText="Designation"
                    subHeaderText={users_details?.designation as string}
                  />
                </Grid>
                <Grid item xs={4}>
                  <ReuseableLayout
                    headerText="Department"
                    subHeaderText={users_details.department ? users_details.department : '---'}
                  />
                </Grid>
                <Grid item xs={4}>
                  <ReuseableLayout
                    headerText="Manager"
                    subHeaderText={users_details?.manager?.name || '---'}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} sx={{ mt: 2, mb: 3 }}>
                <Grid item xs={4}>
                  <ReuseableLayout
                    headerText="Joining Date"
                    subHeaderText={formatShortDate(
                      users_details?.joining_date?.toString() as string,
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <ReuseableLayout headerText="CTC" subHeaderText="---" />
                </Grid>
              </Grid>
            </Stack>
          </Box>
          <Box>
            <Box sx={{ mt: 4, mb: 3 }}>
              <Typography variant="h4">Allocations Last Month</Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
              <TableContainer>
                <Table size="small" aria-label="customized table">
                  <TableHead>
                    <StyledTableRow>
                      <TableCell>Name</TableCell>
                      <TableCell align="right">Start Date</TableCell>
                      <TableCell align="right">End Date</TableCell>
                      <TableCell align="right">Project Lead</TableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {resources.length > 0 ? (
                      resources.map((resource) => (
                        <TableRow key={resource.id}>
                          <TableCell component="th" scope="row">
                            <Chip label={resource.assignedTo.name} icon={<ChipTag />} />
                          </TableCell>
                          <TableCell align="right">{formatShortDate(resource.startDate)}</TableCell>
                          <TableCell align="right">{formatShortDate(resource.endDate)}</TableCell>
                          <TableCell align="right">---</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            No Projects to Show
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
