import { AnyAction } from 'redux';
import {
  ADD_ASSESSMENT,
  SET_ASSESSMENT,
  SET_SEARCH_OPTIONS,
  UPDATE_ASSESSMENT,
} from '../assessment/constants';
import { AssessmentDataType, AssessmentSearchParameters, SET_SEARCH_OPTIONS_TYPE } from '../types';

export const allAssessment: Partial<AssessmentSearchParameters> = {
  status: '',
  type: '',
  candidateId: '',
  interviewer: {
    id: '',
    name: '',
    email: '',
  },
  orderBy: '',
  orderDirection: '',
};

export interface AssessmentState {
  assessments: Array<AssessmentDataType>;
  searchOptions: Partial<AssessmentSearchParameters>;
  searchOptionType: string;
}

export const searchOptionsType: Record<string, Partial<AssessmentSearchParameters>> = {
  allAssessments: allAssessment,
};

const initialState = {
  assessments: [],
  searchOptions: allAssessment,
  searchOptionType: 'allAssessments',
};
export default function AssessmentReducer(
  state = initialState,
  action: AnyAction,
): AssessmentState {
  switch (action.type) {
    case SET_ASSESSMENT: {
      return {
        ...state,
        assessments: action.assessments,
      };
    }
    case ADD_ASSESSMENT: {
      return {
        ...state,
        assessments: [action.assessment, ...state.assessments],
      };
    }
    case UPDATE_ASSESSMENT: {
      return {
        ...state,
        assessments: state.assessments.map((assessment: AssessmentDataType) =>
          assessment.id === action.assessment.id
            ? { ...assessment, ...action.assessment }
            : assessment,
        ),
      };
    }
    case SET_SEARCH_OPTIONS: {
      return {
        ...state,
        searchOptions: { ...action.searchOptions },
      };
    }
    case SET_SEARCH_OPTIONS_TYPE:
      return {
        ...state,
        searchOptionType: action.searchOptionType,
        searchOptions: {
          ...(searchOptionsType[action.searchOptionType] || allAssessment),
        },
      };

    default:
      return state;
  }
}
