import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
} from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import React, { FormEvent, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../../redux-store/reducers';
import * as Yup from 'yup';
import { NotesSearchParameters, RESET_SEARCH_OPTIONS } from '../types';
import { SET_SEARCH_OPTIONS } from '../../../redux-store/types/asset';
import FormSelect from '../../forms/FormSelect';
import FormTextInput from '../../forms/FormTextInput';
import FormDatePicker from '../../forms/FormDatePicker';

const vaildationschema = Yup.object().shape({
  candidateId: Yup.string(),
  limit: Yup.number(),
  createdBefore: Yup.date().nullable(true),
  createdAfter: Yup.date().nullable(true),
});

export type SearchOptionDialogTypes = DialogProps;

export default function NotesSearchOptions(props: SearchOptionDialogTypes): JSX.Element {
  const searchOptions = useSelector<RootStateType, Partial<NotesSearchParameters>>(
    (state) => state.ats.notes.searchOptions,
  );

  const dispatch = useDispatch();
  const formikRef = useRef<FormikProps<Partial<NotesSearchParameters>>>(null);

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setValues(searchOptions);
    }
  }, [searchOptions]);

  const initialValues = {
    ...searchOptions,
  };

  const handleApply = (e: React.MouseEvent<HTMLElement>) => {
    if (formikRef.current) {
      formikRef.current.handleSubmit(e as unknown as FormEvent<HTMLFormElement>);
    }
  };
  const handleReset = () => {
    dispatch({ type: RESET_SEARCH_OPTIONS });
  };

  return (
    <Dialog scroll="paper" {...props}>
      <DialogTitle>Candidates Filters</DialogTitle>
      <DialogContent dividers>
        <Formik
          innerRef={formikRef}
          validationSchema={vaildationschema}
          initialValues={initialValues}
          onSubmit={(values) => {
            dispatch({ type: SET_SEARCH_OPTIONS, searchOptions: values });
            props.onClose &&
              props.onClose(
                new Event('submit', { cancelable: true, bubbles: true }),
                'escapeKeyDown',
              );
          }}
          enableReinitialize
        >
          {() => (
            <Form>
              <Divider>Filters</Divider>
              <FormTextInput name="candidateId" label="CandidateId" />
              <Divider>Filters by Date</Divider>
              <FormDatePicker name="createdBefore" label="Created Before" />
              <FormDatePicker name="createdAfter" label="Created After" />
              <Divider>Pagination</Divider>
              <FormSelect
                name="limit"
                label="Items per page"
                options={['10', '25', '50'].map((option) => ({ label: option, value: option }))}
              />
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReset} color="primary">
          Reset
        </Button>
        <Button onClick={handleApply} variant="contained" color="primary">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}
