import { PersistGate } from 'redux-persist/es/integration/react';
import { Provider } from 'react-redux';
import store, { persistor } from './store';
import AppRoutes from './routes';
import ModalProvider from 'mui-modal-provider';
import { SnackbarProvider } from 'notistack';

export default function App(): JSX.Element {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <SnackbarProvider maxSnack={3}>
          <ModalProvider beta={true}>
            <AppRoutes />
          </ModalProvider>
        </SnackbarProvider>
      </PersistGate>
    </Provider>
  );
}
