/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CALL_API } from '../../../redux-store/middleware';
import { AssessmentDataType, AssessmentFormBody, AssessmentSearchParameters } from '../types';
import { ADD_ASSESSMENT, SET_ASSESSMENT, UPDATE_ASSESSMENT } from './constants';

export const defaultSearchParameter: Partial<AssessmentSearchParameters> = {
  status: '',
  type: '',
  candidateId: '',
  interviewer: { id: '', name: '', email: '' },
  orderBy: '',
  orderDirection: '',
};

export const getAssessments =
  (searchOptions = defaultSearchParameter) =>
  async (dispatch: any) => {
    try {
      const searchParams = new URLSearchParams();

      if (searchOptions.status) {
        searchParams.append('status', searchOptions.status);
      }
      if (searchOptions.type) {
        searchParams.append('type', searchOptions.type);
      }
      if (searchOptions.candidateId) {
        searchParams.append('candidateId', searchOptions.candidateId);
      }
      if (searchOptions.interviewer) {
        searchParams.append('interviewer', searchOptions.interviewer.toString());
      }
      if (searchOptions.limit) {
        searchParams.append('limit', searchOptions.limit.toString());
      }
      if (searchOptions.orderBy) {
        searchParams.append('orderBy', searchOptions.orderBy);
      }
      if (searchOptions.orderDirection) {
        searchParams.append('orderDirection', searchOptions.orderDirection);
      }
      const assessments = await dispatch({
        [CALL_API]: {
          url: `/ats/assessments?${searchParams.toString()}`,
          method: 'GET',
        },
      });
      dispatch({
        type: SET_ASSESSMENT,
        assessments: assessments,
      });
      return assessments as Array<AssessmentDataType>;
    } catch (error) {
      console.log(error);
    }
  };

export const addAssessment = (body: AssessmentFormBody) => async (dispatch: any) => {
  try {
    const assessmentValue = await dispatch({
      [CALL_API]: {
        url: `/ats/assessments`,
        method: 'POST',
        body,
      },
    });
    dispatch({
      type: ADD_ASSESSMENT,
      assessment: assessmentValue,
    });
    return assessmentValue as AssessmentDataType;
  } catch (error) {
    console.log(error);
  }
};

export const getAssessmentbyId = (id: string) => async (dispatch: any) => {
  try {
    const assessmentValues = await dispatch({
      [CALL_API]: {
        url: `/ats/assessments/${id}`,
        method: 'GET',
      },
    });
    return assessmentValues;
  } catch (error) {
    console.log(error);
  }
};

export const updateAssessment = (id: string, body: AssessmentFormBody) => async (dispatch: any) => {
  try {
    const updateValues = await dispatch({
      [CALL_API]: {
        url: `/ats/assessments/${id}`,
        method: 'POST',
        body,
      },
    });
    dispatch({
      type: UPDATE_ASSESSMENT,
      assessment: updateValues,
    });
    return updateValues;
  } catch (error) {
    console.log(error);
  }
};
