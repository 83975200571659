import { Box, Drawer } from '@mui/material';
import { Location } from 'history';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router';
import Create from './create';
import NotesDetail from './details';
import NotesList from './list';
import UpdateNote from './update';

export default function NotesRouter(): JSX.Element {
  const { path } = useRouteMatch();
  const history = useHistory();
  const location = useLocation<{ background: Location }>();
  const background = location.state && location.state.background;
  return (
    <>
      <Switch location={background || location}>
        <Route exact path={path} component={NotesList} />
        <Route exact path={`${path}/add`} component={Create} />
        <Route path={`${path}/:id`} component={NotesDetail} />
        <Route path={`${path}/update/:id`} component={UpdateNote} />
      </Switch>
      <Drawer
        open={!!background}
        anchor="right"
        onClose={() => {
          history.go(-1);
        }}
      >
        <Box sx={{ width: 420 }} role="presentation">
          <Switch>
            <Route exact path={`${path}/add`} component={Create} />
            <Route exact path={`${path}/:id`} component={NotesDetail} />
            <Route path={`${path}/update/:id`} component={UpdateNote} />
          </Switch>
        </Box>
      </Drawer>
    </>
  );
}
