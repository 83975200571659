import { Box, LinearProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import { TitleBar } from '../../assets/components';
import { ProjectBody } from '../types';
import { onCreateProject } from '../actions';
import ProjectForm from './Form';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { useState } from 'react';

const initialValues = {
  name: '',
  clientName: '',
  description: '',
  websiteUrl: '',
  email: '',
  phoneNumber: '',
  address: '',
};

export default function CreateProject(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [showProgress, setShowProgress] = useState(false);

  const onSubmit = async (values: ProjectBody) => {
    try {
      const { name, clientName, description } = values;
      const reqBody = {
        name: name,
        clientName: clientName,
        description: description,
        // websiteUrl: websiteUrl,
        // email: email,
        // phoneNumber: phoneNumber,
        // address: address,
      };
      setShowProgress(true);
      await dispatch(onCreateProject(reqBody));
      enqueueSnackbar('Client Added Successfully', { variant: 'success' });
      setTimeout(history.goBack, 1);
    } catch (error) {
      enqueueSnackbar((error as Error).message, { variant: 'error' });
    } finally {
      setShowProgress(false);
    }
  };
  return (
    <>
      <TitleBar title="Add Client" />
      {showProgress && <LinearProgress />}
      <Box sx={{ p: 2 }}>
        <ProjectForm initialValues={initialValues} onSubmit={onSubmit} />
      </Box>
    </>
  );
}
