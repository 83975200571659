export const SET_CANDIDATES = 'SET_CANDIDATES';
export const ADD_CANDIDATE = 'ADD_CANDIDATE';
export const UPDATE_CANDIDATE = 'UPDATE_CANDIDATE';
export const candidateStatuses = [
  {
    label: 'Applied',
    value: 'applied',
  },
  {
    label: 'Assessing',
    value: 'assessing',
  },
  {
    label: 'Rejected',
    value: 'rejected',
  },
  {
    label: 'Waiting for HR',
    value: 'waitingForHR',
  },
  {
    label: 'Offer Letter Send',
    value: 'offer-letter-send',
  },
  {
    label: 'Offer Letter Rejected',
    value: 'offer-letter-rejected',
  },
  {
    label: 'Offer Letter Accepted',
    value: 'offer-letter-accepted',
  },
  {
    label: 'Not Joining',
    value: 'not-joining',
  },
  {
    label: 'On Hold',
    value: 'onHold',
  },
  {
    label: 'Joined',
    value: 'joined',
  },
];

export function getStatusLabel(value: string): string {
  return candidateStatuses.find((status) => status.value === value)?.label || 'Unknown';
}
