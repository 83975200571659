/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { CALL_API } from '../../redux-store/middleware';
import { getPrevWeekStart, getWeekEnd, getWeekStart, GET_TIMESHEETS_SUCCESS } from './constants';
import {
  Allocation,
  Employee,
  SOWTask,
  TimelogBody,
  Timesheet,
  TimesheetSearchParameters,
} from './types';

const TIMESHEETS_BASE_URL = '/timesheets';
const PROJECTS_BASE_URL = '/projects';

export const getTimesheets =
  (
    options: Partial<TimesheetSearchParameters> = {
      weekOf: [null, null],
      startDate: [null, null],
      submittedAt: [null, null],
      approvedAt: [null, null],
      createdAt: [null, null],
      status: '',
      sortBy: 'date',
      pageSize: 30,
      sortDirection: 'desc',
      noOfHours: '',
    },
  ) =>
  async (dispatch: any) => {
    try {
      const searchParameters = new URLSearchParams();
      searchParameters.set('pageSize', '' + (options.pageSize || 30));
      searchParameters.set('page', '' + (options.page || 1));
      searchParameters.set('sortBy', '' + (options.sortBy || 'date'));
      searchParameters.set('sortDirection', '' + (options.sortDirection || 'desc'));
      searchParameters.set('noOfHours', '' + (options.noOfHours || ''));
      options.employee && searchParameters.set('employeeId', options.employee.id);
      options.status && searchParameters.set('status', '' + options.status);
      options.weekOf && searchParameters.set('weekOf', '' + options.weekOf);
      options.startDate && searchParameters.set('startDateAfter', '' + options.startDate);
      if (options.startDate) {
        const endDate = new Date(options.startDate);
        endDate.setDate(endDate.getDate() + 6);
        searchParameters.set('startDateBefore', '' + endDate);
      }
      options.approvedBy && searchParameters.set('approvedById', options.approvedBy.id);

      const timesheets = await dispatch({
        [CALL_API]: {
          url: `${TIMESHEETS_BASE_URL}/timesheets?${searchParameters.toString()}`,
          method: 'GET',
        },
      });
      dispatch({
        type: GET_TIMESHEETS_SUCCESS,
        timesheets,
      });
      return timesheets as Timesheet[];
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const getTimesheetById = (timeSheetId: string) => async (dispatch: any) => {
  try {
    const timesheet = await dispatch({
      [CALL_API]: {
        url: `${TIMESHEETS_BASE_URL}/timesheets/${timeSheetId}`,
        method: 'GET',
      },
    });
    return {
      ...timesheet,
      startDate: new Date(timesheet.startDate),
      endDate: new Date(timesheet.endDate),
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createTimesheet =
  ({ employeeId, startDate }: { employeeId: string; startDate: Date }) =>
  async (dispatch: any) => {
    try {
      const weekStart = getWeekStart(startDate);
      const weekEnd = getWeekEnd(startDate);

      const timesheet = await dispatch({
        [CALL_API]: {
          url: `${TIMESHEETS_BASE_URL}/timesheets`,
          method: 'POST',
          body: {
            employee: {
              id: employeeId,
            },
            startDate: weekStart.toISOString(),
            endDate: weekEnd.toISOString(),
          },
        },
      });
      return {
        ...timesheet,
        startDate: new Date(timesheet.startDate),
        endDate: new Date(timesheet.endDate),
      };
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const getTimeLogs = (employeeId: string, startDate: Date) => async (dispatch: any) => {
  try {
    const weekStart = getWeekStart(startDate);
    const weekEnd = getWeekEnd(startDate);
    const searchParameters = new URLSearchParams();
    searchParameters.set('startDate', weekStart.toISOString());
    searchParameters.set('endDate', weekEnd.toISOString());
    const timelogs = await dispatch({
      [CALL_API]: {
        url: `${TIMESHEETS_BASE_URL}/timelogs/employees/${employeeId}?${searchParameters.toString()}`,
        method: 'GET',
      },
    });
    return timelogs.map(({ date, ...rest }: { date: Date }) => ({
      ...rest,
      date: new Date(date),
    }));
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const approveTimeSheetByEmployeeId =
  ({ employeeId, startDate, endDate }: { employeeId: string; startDate: Date; endDate: Date }) =>
  async (dispatch: any) => {
    try {
      const timesheet = await dispatch({
        [CALL_API]: {
          url: `${TIMESHEETS_BASE_URL}/timesheets/${employeeId}/approve`,
          method: 'POST',
          body: {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          },
        },
      });
      return timesheet;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const unlockTimeSheetByEmployeeId =
  ({ employeeId, startDate, endDate }: { employeeId: string; startDate: Date; endDate: Date }) =>
  async (dispatch: any) => {
    try {
      const timesheet = await dispatch({
        [CALL_API]: {
          url: `${TIMESHEETS_BASE_URL}/timesheets/${employeeId}/unlock`,
          method: 'POST',
          body: {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          },
        },
      });
      return timesheet;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const submitTimeSheetByEmployeeId =
  ({ employeeId, startDate, endDate }: { employeeId: string; startDate: Date; endDate: Date }) =>
  async (dispatch: any) => {
    try {
      const timesheet = await dispatch({
        [CALL_API]: {
          url: `${TIMESHEETS_BASE_URL}/timesheets/${employeeId}/submit`,
          method: 'POST',
          body: {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          },
        },
      });
      return timesheet;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const getMyAllocations = (employeeId: string, date: Date) => async (dispatch: any) => {
  const weekStart = getWeekStart(date);
  const weekEnd = getWeekEnd(date);
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.set('startDate', weekStart.toISOString());
  urlSearchParams.set('endDate', weekEnd.toISOString());
  try {
    const sows = await dispatch({
      [CALL_API]: {
        url: `${PROJECTS_BASE_URL}/projects/resources/employees/${employeeId}?${urlSearchParams.toString()}`,
        method: 'GET',
      },
    });
    return sows;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getTeamMembers = (date: Date) => async (dispatch: any) => {
  const weekStart = getPrevWeekStart(date);
  const weekEnd = getWeekEnd(date);
  try {
    const teamMembers = await dispatch({
      [CALL_API]: {
        url: `${PROJECTS_BASE_URL}/projects/resources/team?startDate=${weekStart.toISOString()}&endDate=${weekEnd.toISOString()}`,
        method: 'GET',
      },
    });
    return teamMembers;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getMySowsWithTasks = (employeeId: string, date: Date) => async (dispatch: any) => {
  const weekStart = getWeekStart(date);
  const weekEnd = getWeekEnd(date);
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.set('startDate', weekStart.toISOString());
  urlSearchParams.set('endDate', weekEnd.toISOString());
  try {
    const allocations = await dispatch({
      [CALL_API]: {
        url: `${PROJECTS_BASE_URL}/projects/resources/employees/${employeeId}?${urlSearchParams.toString()}`,
        method: 'GET',
      },
    });
    const _sows = (allocations as unknown as Array<Allocation>).map(
      (allocation) => allocation.assignedTo,
    );
    const sows = Promise.all(
      _sows.map(async (sow) => {
        const _sowTasks = (await dispatch({
          [CALL_API]: {
            url: `${PROJECTS_BASE_URL}/projects/phases/${sow.id}/tasks`,
            method: 'GET',
          },
        })) as SOWTask[];
        const tasks = _sowTasks.map((sowTask) => ({
          ...sowTask.task,
          billable: sowTask.billable,
        }));
        return {
          ...sow,
          tasks: tasks,
        };
      }),
    );
    return sows;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addTimelog =
  (timelog: TimelogBody): any =>
  async (dispatch: any) => {
    const { employee, sow, task, ...rest } = timelog;
    try {
      const createdTimelog = await dispatch({
        [CALL_API]: {
          url: `${TIMESHEETS_BASE_URL}/timelogs`,
          method: 'POST',
          body: {
            ...rest,
            employee: { id: employee.id },
            sow: { id: sow.id },
            task: { id: task.id },
          },
        },
      });
      return { ...createdTimelog, date: new Date(createdTimelog.date) };
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const updateTimelog =
  (timelogId: string, timelog: TimelogBody & { employee: Employee }) => async (dispatch: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { sow, task, notes, noOfHours, date, employee } = timelog;
    try {
      const updatedTimelog = await dispatch({
        [CALL_API]: {
          url: `${TIMESHEETS_BASE_URL}/timelogs/${timelogId}`,
          method: 'POST',
          body: {
            notes,
            noOfHours,
            date,
            sow: { id: sow.id },
            task: { id: task.id },
            employee: { id: employee.id },
          },
        },
      });
      return { ...updatedTimelog, date: new Date(updatedTimelog.date) };
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const deleteTimelog = (timelogId: string) => async (dispatch: any) => {
  try {
    const deletedTimelog = await dispatch({
      [CALL_API]: {
        url: `${TIMESHEETS_BASE_URL}/timelogs/${timelogId}`,
        method: 'DELETE',
      },
    });
    return deletedTimelog;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getTeamReportForSOW =
  ({
    sow,
    startDate,
    endDate,
    employees,
    billableHours,
    designations,
  }: {
    sow?: string;
    startDate: Date;
    endDate: Date;
    employees?: string;
    billableHours?: string;
    designations?: string;
  }) =>
  async (dispatch: any) => {
    const searchParameters = new URLSearchParams({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    });
    if (sow) {
      searchParameters.set('sow', sow);
    }
    if (employees) {
      searchParameters.set('employees', employees);
    }
    if (designations) {
      searchParameters.set('designations', designations);
    }
    if (billableHours) {
      searchParameters.set('billableHours', billableHours);
    }

    try {
      const data = await dispatch({
        [CALL_API]: {
          url: `${TIMESHEETS_BASE_URL}/reports/sow?${searchParameters.toString()}`,
          method: 'GET',
        },
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const getTaskReportForSOW =
  ({ sow, startDate, endDate }: { sow?: string; startDate: Date; endDate: Date }) =>
  async (dispatch: any) => {
    const searchParameters = new URLSearchParams({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    });
    if (sow) {
      searchParameters.set('sow', sow);
    }
    try {
      const data = await dispatch({
        [CALL_API]: {
          url: `${TIMESHEETS_BASE_URL}/reports/tasks?${searchParameters.toString()}`,
          method: 'GET',
        },
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const getSOWWiseTeamReport =
  ({
    sow,
    startDate,
    endDate,
    billableHours,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    employees,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    designations,
  }: {
    sow?: string;
    startDate: Date;
    endDate: Date;
    billableHours?: string;
    employees?: string;
    designations?: string;
  }) =>
  async (dispatch: any) => {
    const searchParameters = new URLSearchParams({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    });
    if (sow) {
      searchParameters.set('sow', sow);
    }
    if (billableHours) {
      searchParameters.set('billableHours', billableHours);
    }
    try {
      const data = await dispatch({
        [CALL_API]: {
          url: `${TIMESHEETS_BASE_URL}/reports/summary?${searchParameters.toString()}`,
          method: 'GET',
        },
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
