import { Button, Stack, useTheme } from '@mui/material';
import { Formik } from 'formik';
import { LocationDescriptor } from 'history';
import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useHistory, generatePath } from 'react-router';
import { onGetUsers } from '../../../redux-store/actions/users';
import { RootStateType } from '../../../redux-store/reducers';
import { UsersState } from '../../../redux-store/reducers/users';
import ReuseableLayoutHeader from '../../common/layouts';
import { getPhases } from '../../delivery/actions';
import { Designations } from '../../delivery/constants';
import FilteredAutocompleteMultiple from '../../forms/Autocomplete/filtered-autocomplete-multiple';
import FormSelect from '../../forms/FormSelect';
import { SOWType } from '../../time-tracking/types';
import { useExtractParams, periods } from '../constants';

export default function ReportsTeamFilter(): JSX.Element {
  const { users, searchOptions } = useSelector<RootStateType, UsersState>((state) => state.users);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedEmployees, setSelectedEmployees] = useState<Array<any>>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedDesignations, setSelectedDesignations] = useState<Array<any>>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedBillableHours, setSelectedBillableHours] = useState<Array<any>>([]);

  const { sow, period, startDateString, employees, billableHours, designations } =
    useExtractParams();

  // const { projectSummaryReportUrl, teamReportsUrl, taskReportsUrl } = useTabUrls();
  const { path } = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    async function fetchUsers() {
      try {
        await dispatch(onGetUsers(searchOptions));
      } catch (error) {
        enqueueSnackbar((error as Error).message, { variant: 'error' });
      }
    }
    fetchUsers();
  }, []);

  function handleChange(
    newSOW: string | undefined,
    newPeriod: string,
    newSelectedEmployees: string | undefined,
    newSelectedDesignations: string | undefined,
    newSelectedBillableHours: string | undefined,
  ) {
    if (
      newSOW === sow &&
      newSelectedEmployees === employees &&
      newSelectedDesignations === designations &&
      newSelectedBillableHours === billableHours &&
      newPeriod === period
    ) {
      return;
    }
    const searchParams = new URLSearchParams({
      period: newPeriod,
      startDate: startDateString,
    });
    if (newSOW) {
      searchParams.set('sow', newSOW);
    }
    if (newSelectedEmployees) {
      searchParams.set('employees', newSelectedEmployees);
    }
    if (newSelectedDesignations) {
      searchParams.set('designations', newSelectedDesignations);
    }
    if (newSelectedBillableHours) {
      searchParams.set('billableHours', newSelectedBillableHours);
    }

    // navigate to new url
    const location: LocationDescriptor<unknown> = {
      pathname: generatePath(path),
      search: searchParams.toString(),
    };
    history.replace(location);
  }

  function handlePeriodChange(newPeriod: string) {
    handleChange(sow, newPeriod, employees, designations, billableHours);
  }
  function onEmployeesValueChange(values: Array<{ name: string; id: string }>) {
    const newSelectedEmployees = values.map((value) => value.id);
    console.log('newSelectedEmployees', newSelectedEmployees);
    handleChange(sow, period, newSelectedEmployees.join(','), designations, billableHours);
    setSelectedEmployees(values);
  }

  function onDesignationsValueChange(values: Array<{ name: string; id: string }>) {
    const newSelectedDesignations = values.map((value) => value.id);
    handleChange(sow, period, employees, newSelectedDesignations.join(','), billableHours);
    setSelectedDesignations(values);
  }

  function onBillableHoursValueChange(values: Array<{ name: string; id: string }>) {
    const newSelectedBillableHours = values.map((value) => value.id);
    handleChange(sow, period, employees, designations, newSelectedBillableHours.join(','));
    setSelectedBillableHours(values);
  }

  const periodValues: Array<{ label: string; value: string }> = [...periods] as Array<{
    label: string;
    value: string;
  }>;
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sows, setSows] = useState<Array<SOWType>>([]);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    async function getAllPhases() {
      try {
        const _sows = await dispatch(getPhases());
        setSows(_sows as unknown as Array<SOWType>);
      } catch (error) {
        enqueueSnackbar(`Could not fetch SOWS: ${(error as Error).message}`, { variant: 'error' });
      }
    }
    getAllPhases();
  }, []);
  const theme = useTheme();
  const designations_ = Designations.map((designation) => {
    return {
      name: designation.label,
      id: designation.value,
    };
  });

  const employees_ = users.map((employee) => ({
    name: employee.name,
    id: employee.id,
  }));

  const showButton =
    selectedEmployees.length > 0 ||
    selectedDesignations.length > 0 ||
    selectedBillableHours.length > 0 ||
    selectedDesignations.length > 0;

  const handleClear = () => {
    handleChange(undefined, period, undefined, undefined, undefined);
    setSelectedEmployees([]);
    setSelectedDesignations([]);
    setSelectedBillableHours([]);
  };

  // const showButton = selectedDesignations.length > 0 || selectedEmployees.length > 0;

  return (
    <>
      <ReuseableLayoutHeader>
        <FilteredAutocompleteMultiple
          label="Employees"
          searchPlaceholder="Search Employees"
          options={employees_ as Array<{ name: string; id: string }>}
          filterId="employee-id"
          value={selectedEmployees}
          onChange={onEmployeesValueChange}
        />
        <FilteredAutocompleteMultiple
          label="Designation"
          searchPlaceholder="Search designation"
          options={designations_}
          filterId="designation-id"
          value={selectedDesignations}
          onChange={onDesignationsValueChange}
        />
        <FilteredAutocompleteMultiple
          label="Billable Hours"
          options={[
            {
              name: 'All',
              id: 'all',
            },
            {
              name: '> 80%',
              id: '80-100',
            },
            {
              name: '> 60%',
              id: '60-80',
            },
            {
              name: '< 60%',
              id: '40-60',
            },
            {
              name: '< 40%',
              id: '20-40',
            },
            {
              name: '< 20%',
              id: '0-20',
            },
          ]}
          filterId="billableHours-id"
          disableSearch={true}
          value={selectedBillableHours}
          onChange={onBillableHoursValueChange}
        />
        <Formik
          initialValues={{ sow, period }}
          onSubmit={() => {
            console.log('Form Submitted');
          }}
        >
          {() => (
            <Stack
              direction="row"
              sx={{
                px: 2,
                borderRight: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
              }}
            >
              <FormSelect
                value={period}
                options={periodValues}
                name="period"
                sx={{ width: '150px', '& fieldset': { display: 'none' } }}
                onChange={async (event) => {
                  handlePeriodChange(event.target.value as string);
                }}
              />
            </Stack>
          )}
        </Formik>
        {showButton && (
          <Button variant="text" sx={{ ml: 1 }} onClick={handleClear}>
            Clear
          </Button>
        )}
      </ReuseableLayoutHeader>
    </>
  );
}
