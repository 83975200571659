import * as React from 'react';
import Button from '@mui/material/Button';
import { Box, Stack, TablePagination, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { useEffect, useState } from 'react';
import { useTimeStore } from '../store/context-store';
import { RESET_SEARCH_OPTIONS, SET_SEARCH_OPTIONS } from '../store/timesheet-list.reducer';
import { getWeeksList } from '../constants';
import FilteredAutocompleteMultiple from '../../forms/Autocomplete/filtered-autocomplete-multiple';
import FilterListIcon from '@mui/icons-material/FilterList';

const allChecked = {
  name: 'All',
  id: '',
};

export function LayoutHeader({
  sx = {},
  setShowFilters,
}: {
  sx?: SxProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setShowFilters?: any;
}): JSX.Element {
  const theme = useTheme();
  const weeks = [...getWeeksList()];
  const [rootState, dispatchToTimeStore] = useTimeStore();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedTeamMembers, setSelectedTeamMembers] = useState<Array<any>>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedHours, setSelectedHours] = useState<Array<any>>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedStatus, setSelectedStatus] = useState<Array<any>>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedApproval, setSelectedApproval] = useState<Array<any>>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedWeek, setSelectedWeek] = useState<Array<any>>([]);
  const {
    timesheets: { searchOptions },
    team: { users },
  } = rootState;

  const showClearButton =
    selectedTeamMembers.length > 0 ||
    selectedHours.length > 0 ||
    selectedStatus.length > 0 ||
    selectedApproval.length > 0 ||
    selectedWeek.length > 0;

  function onTeamValuesChanged(values: Array<string>) {
    setSelectedTeamMembers(values);
  }

  function onHoursValuesChanged(values: Array<string>) {
    setSelectedHours(values);
  }

  function onStatusValuesChanged(values: Array<string>) {
    setSelectedStatus(values);
  }

  function onWeekValuesChanged(values: Array<string>) {
    setSelectedWeek(values);
  }

  function onApprovalValuesChanged(values: Array<string>) {
    setSelectedApproval(values);
  }

  useEffect(() => {
    if (selectedTeamMembers.length > 0) {
      dispatchToTimeStore({
        type: SET_SEARCH_OPTIONS,
        searchOptions: {
          ...searchOptions,
          employee: {
            id: selectedTeamMembers.map((t) => t.id).join(','),
          },
        },
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { employee, ...rest } = searchOptions;
      dispatchToTimeStore({
        type: SET_SEARCH_OPTIONS,
        searchOptions: {
          ...rest,
        },
      });
    }
  }, [selectedTeamMembers]);

  useEffect(() => {
    if (selectedStatus.length > 0) {
      dispatchToTimeStore({
        type: SET_SEARCH_OPTIONS,
        searchOptions: {
          ...searchOptions,
          status: selectedStatus.map((status) => status.id).join(','),
        },
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { status, ...rest } = searchOptions;
      dispatchToTimeStore({
        type: SET_SEARCH_OPTIONS,
        searchOptions: {
          ...rest,
        },
      });
    }
  }, [selectedStatus]);

  useEffect(() => {
    if (selectedHours.length > 0) {
      dispatchToTimeStore({
        type: SET_SEARCH_OPTIONS,
        searchOptions: {
          ...searchOptions,
          noOfHours: selectedHours.map((hour) => hour.id).join(','),
        },
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { noOfHours, ...rest } = searchOptions;
      dispatchToTimeStore({
        type: SET_SEARCH_OPTIONS,
        searchOptions: {
          ...rest,
        },
      });
    }
  }, [selectedHours]);
  useEffect(() => {
    if (selectedWeek.length > 0) {
      dispatchToTimeStore({
        type: SET_SEARCH_OPTIONS,
        searchOptions: {
          ...searchOptions,
          weekOf: selectedWeek.map((week) => week.id).join(','),
        },
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { weekOf, ...rest } = searchOptions;
      dispatchToTimeStore({
        type: SET_SEARCH_OPTIONS,
        searchOptions: {
          ...rest,
        },
      });
    }
  }, [selectedWeek]);

  useEffect(() => {
    if (selectedApproval.length > 0) {
      dispatchToTimeStore({
        type: SET_SEARCH_OPTIONS,
        searchOptions: {
          ...searchOptions,
          approvedBy: {
            id: selectedApproval.map((approval) => approval.id).join(','),
          },
        },
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { approvedBy, ...rest } = searchOptions;
      dispatchToTimeStore({
        type: SET_SEARCH_OPTIONS,
        searchOptions: {
          ...rest,
        },
      });
    }
  }, [selectedApproval]);

  const teamlist = users;

  const teamlist_ = teamlist.length > 0 ? [allChecked, ...teamlist] : [];

  const handleClear = () => {
    setSelectedTeamMembers([]);
    setSelectedHours([]);
    setSelectedStatus([]);
    setSelectedWeek([]);
    setSelectedApproval([]);
    dispatchToTimeStore({
      type: RESET_SEARCH_OPTIONS,
    });
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const themeMixins = theme.mixins as any;

  return (
    <>
      <Box
        sx={{
          ...themeMixins.standardBorder,
          my: [0, 3],
          width: '100%',
          borderRadius: '2px',
          flex: ['1 1 auto', 'unset'],
          ...sx,
        }}
      >
        <Stack direction="row" sx={{ display: ['block', 'flex'] }}>
          <>
            <ReuseableStack>
              <FilterListIcon />
              {/* <Typography variant="h5" sx={{ color: 'secondary.main' }}>
                Filters
              </Typography> */}
            </ReuseableStack>

            <FilteredAutocompleteMultiple
              label="Week"
              options={weeks}
              filterId="approver-filter"
              disableSearch={true}
              value={selectedWeek}
              onChange={onWeekValuesChanged}
              sx={{ minWidth: 165 }}
            />
          </>
          {teamlist_.length > 0 && (
            <FilteredAutocompleteMultiple
              label="Team"
              searchPlaceholder="Search Team"
              options={teamlist_}
              onChange={onTeamValuesChanged}
              value={selectedTeamMembers}
              filterId="team-filter"
              sx={{ minWidth: 190 }}
            />
          )}

          <FilteredAutocompleteMultiple
            label="Hours"
            filterId="hours-filter"
            options={[
              {
                name: '0 - 40',
                id: 'regular',
              },
              {
                name: ' > 40',
                id: 'overlogged',
              },
            ]}
            onChange={onHoursValuesChanged}
            value={selectedHours}
            disableSearch={true}
            sx={{ minWidth: 160 }}
          />
          <FilteredAutocompleteMultiple
            label="Status"
            filterId="status-filter"
            options={[
              {
                name: 'All',
                id: '',
              },
              {
                name: 'Pending for Approval',
                id: 'SUBMITTED',
              },
              {
                name: 'Not Submitted',
                id: 'CREATED',
              },
              {
                name: 'Approved',
                id: 'APPROVED',
              },
            ]}
            onChange={onStatusValuesChanged}
            value={selectedStatus}
            disableSearch={true}
            sx={{ minWidth: 165 }}
          />
          <FilteredAutocompleteMultiple
            label="Approved By"
            searchPlaceholder="Search Approver"
            options={teamlist_}
            filterId="approver-filter"
            onChange={onApprovalValuesChanged}
            value={selectedApproval}
            sx={{ minWidth: 195 }}
          />
        </Stack>
      </Box>
      <Stack
        flexDirection="row"
        sx={{
          width: ['100%', 'unset'],
        }}
      >
        {showClearButton && (
          <Button
            variant="text"
            sx={{
              ml: [0, 1],
              width: ['100%', 'unset'],
              bgcolor: ['warning.main', 'primary.white'],
              color: ['primary.white', 'primary.main'],
            }}
            onClick={handleClear}
          >
            Clear
          </Button>
        )}
        <Button
          sx={{
            display: ['block', 'none'],
            width: '100%',
            bgcolor: 'primary.main',
            color: 'primary.white',
          }}
          variant="text"
          onClick={() => setShowFilters(false)}
        >
          Close
        </Button>
      </Stack>
    </>
  );
}

export function ReuseableStack({
  children,
  sx = {},
}: {
  children: React.ReactNode;
  sx?: SxProps;
}): JSX.Element {
  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="flex-start"
      sx={{
        borderRight: '0.6px solid rgba(190, 191, 198, 0.4)',
        py: 1.5,
        px: 2,
        minWidth: 50,
        ...sx,
      }}
    >
      {children}
    </Stack>
  );
}

export function PaginationLayout({
  sx = {},
  totalPageCount,
  onPageChange,
  onPageSizeChange,
}: {
  sx?: SxProps;
  totalPageCount: number;
  onPageChange?: (page: number) => void;
  onPageSizeChange?: (pageSize: number) => void;
}): JSX.Element {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    onPageChange && onPageChange(newPage as number);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onPageSizeChange && onPageSizeChange(parseInt(event.target.value));
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const theme = useTheme();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const themeMixins = theme.mixins as any;
  return (
    <Box
      sx={{
        flex: '0 1 auto',
        ...themeMixins.topBoxShadow,
        width: 'calc(100% + 32px)',
        ml: -2,
        mb: -2,
        '& .MuiTablePagination-selectLabel ,& .MuiTablePagination-select': {
          fontWeight: 'bold',
          color: 'primary.darkBlue',
        },
        '& .MuiInputBase-root': {
          ...themeMixins.standardBorder,
        },
      }}
    >
      <Stack direction="row" alignItems="center" sx={{ ...sx }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            sx={{
              color: 'primary.darkBlue',
              fontWeight: 700,
              px: 2,
              pr: [0, 2],
              fontSize: [12, 14],
            }}
          >
            Total{' '}
            <Typography variant="h5" component="span" sx={{ display: ['none', 'inline'] }}>
              Record Count
            </Typography>
            : #{totalPageCount}
          </Typography>
        </Stack>
        <Stack direction="row" sx={{ ml: 'auto' }}>
          <TablePagination
            count={totalPageCount}
            component="div"
            page={page}
            onPageChange={handleChangePage}
            labelRowsPerPage="Records per page:"
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to }) => `${from}-${to}`}
            sx={{
              fontSize: [12, '0.875rem'],
              '& .MuiToolbar-root': { pl: [1, 2] },
              '& .MuiTablePagination-selectLabel': {
                fontSize: [12, '0.875rem'],
              },
              '& .MuiInputBase-root': {
                mr: [1, 4],
              },
              '& .MuiTablePagination-actions': {
                ml: [0, '20px'],
                '& .MuiButtonBase-root': {
                  px: [0, 1],
                },
              },
            }}
          />
        </Stack>
      </Stack>
    </Box>
  );
}
