import { Box, Drawer } from '@mui/material';
import { Location } from 'history';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router';
import AssetDetail from './Detail';
import CreateAsset from './CreateAsset';
import UpdateAsset from './UpdateAsset';
import AssetsList from './List';
import { useEffect } from 'react';
import { SET_ASSETS } from '../../redux-store/types/asset';
import { useDispatch } from 'react-redux';

export default function Router(): JSX.Element {
  const { path } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation<{ background: Location }>();
  const background = location.state && location.state.background;
  useEffect(() => {
    return () => {
      //Setting assets to empty when moving out of /admin/assets routes so on coming back the list shows up empty.
      dispatch({ type: SET_ASSETS, assets: [] });
    };
  }, []);
  return (
    <>
      <Switch location={background || location}>
        <Route path={`${path}/add`} component={CreateAsset} />
        <Route path={`${path}/update/:id`} component={UpdateAsset} />
        <Route path={`${path}/:id`} component={AssetDetail} />
        <Route exact path={path} component={AssetsList} />
      </Switch>
      <Drawer
        open={!!background}
        anchor="right"
        onClose={() => {
          history.goBack();
        }}
      >
        <Box sx={{ width: 420 }} role="presentation">
          <Switch>
            <Route exact path={`${path}/add`} component={CreateAsset} />
            <Route path={`${path}/update/:id`} component={UpdateAsset} />
            <Route exact path={`${path}/:id`} component={AssetDetail} />
          </Switch>
        </Box>
      </Drawer>
    </>
  );
}
