import { AnyAction } from 'redux';
import { AuthState, LOGIN_SUCCESS, LOGOUT_SUCCESS } from '../types/login';

const initialState: AuthState = {
  token: null,
  role: null,
  name: null,
};

function authReducer(state = initialState, action: AnyAction): AuthState {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      const { body } = action;
      return {
        ...state,
        ...body,
      };
    }
    case LOGOUT_SUCCESS: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
}

export default authReducer;
