import {
  Box,
  Button,
  Divider,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TableBodyCell, TableCellText, TableHeadCell } from '../../common';
import { useReport } from '../constants';
import { EmployeeWithHours } from '../../time-tracking/types';
import { formatDesignation } from '../../users/constants';
import ReportsTeamFilter from './filter';
import theme from '../../common/default';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { PaginationLayout } from '../../time-tracking/approve/CustomLayouts';
import LinearProgressWithSegments from '../LinearProgress';
import { CSVLink } from 'react-csv';
import DownloadIcon from '@mui/icons-material/Download';

import TeamReportsHeader from '../sow-period-filter-header';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getResources } from '../../delivery/actions';
import { Resource } from '../../delivery/types';
import { getTeamReportForSOW } from '../../time-tracking/actions';

function formatHours(hours: number) {
  return hours.toFixed(1);
}

const currencyFormatter = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
});

function formatAmount(amount: number) {
  return currencyFormatter.format(amount);
}

export default function TeamReports(): JSX.Element {
  const { showLoading, results } = useReport<EmployeeWithHours>(getTeamReportForSOW);

  return (
    <>
      <Box sx={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
        <Box
          sx={{
            width: '100%',
            zIndex: '999',
            px: [0, 2],
            flex: '0 1 auto',
            position: 'relative',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ...(theme.mixins as any).standardBorderBottom,
          }}
        >
          <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
            <Stack alignItems="center">
              <Typography variant="h2">Reports</Typography>
            </Stack>
            <Stack alignItems="center" direction="row" spacing={2}>
              <Stack>
                <CSVLink
                  data={results || []}
                  filename="billable-hours.csv"
                  style={{ marginLeft: 0, textDecoration: 'none' }}
                >
                  <Button variant="contained">
                    Import
                    <DownloadIcon sx={{ ml: 2 }} />
                  </Button>
                </CSVLink>
              </Stack>

              <TeamReportsHeader />
            </Stack>
          </Stack>
          <Divider
            sx={{
              width: 'calc(100% + 64px)',
              ml: -4,
              mt: 2,
            }}
          />
          <Stack
            alignItems="center"
            justifyContent="space-between"
            sx={{
              width: '100%',
            }}
          >
            <ReportsTeamFilter />
          </Stack>
        </Box>
        <Divider
          sx={{
            width: 'calc(100% + 45px)',
            ml: -4,
          }}
        />
        <ReportsInfoLayout resultsdata={results as unknown as EmployeeWithHours[]} />
        <Divider
          sx={{
            width: 'calc(100% + 45px)',
            ml: -4,
          }}
        />
        {showLoading && <LinearProgress />}
        {results && results.length > 0 && <MUITable results={results} />}
      </Box>
    </>
  );
}

function MUITable({ results }: { results: Array<EmployeeWithHours> }): JSX.Element {
  const { path } = useRouteMatch();
  const location = useLocation();
  const [resources, setResources] = useState<Array<Resource>>();
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchResourceList() {
      try {
        const response = await dispatch(getResources());
        setResources(response as unknown as Array<Resource>);
      } catch (error) {
        console.log(error);
      }
    }
    fetchResourceList();
  }, []);
  return (
    <Box sx={{ display: 'flex', flexFlow: 'column', height: '65%' }}>
      <Box sx={{ flex: '1 1 auto', overflowY: 'auto' }}>
        <TableContainer
          sx={{
            p: 0,
          }}
        >
          <Table size="small" aria-label="team-reports-table">
            <TableHead>
              <TableRow>
                <TableHeadCell sx={{ borderLeft: 'none' }}>
                  <TableCellText text="Employee Name" sx={{ fontWeight: 700 }} />
                </TableHeadCell>
                <TableHeadCell>
                  <TableCellText text="Designation" />
                </TableHeadCell>
                <TableHeadCell>
                  <TableCellText text="Total Hours" />
                </TableHeadCell>
                <TableHeadCell>
                  <TableCellText text="Billable Hours" />
                </TableHeadCell>
                <TableHeadCell>
                  <TableCellText text="Billable Amount" />
                </TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* <TableRow>
              <TableHeadCell></TableHeadCell>
              <TableHeadCell>Total</TableHeadCell>
              <TableHeadCell></TableHeadCell>
              <TableHeadCell sx={{ textAlign: 'right' }}>
                {formatHours(results.map(({ noOfHours }) => noOfHours).reduce((a, b) => a + b, 0))}
              </TableHeadCell>
              <TableHeadCell sx={{ textAlign: 'right' }}>
                {formatHours(
                  results
                    .map(({ noOfBillableHours }) => noOfBillableHours)
                    .reduce((a, b) => a + b, 0),
                )}
              </TableHeadCell>
              <TableHeadCell sx={{ textAlign: 'right' }}>
                {formatAmount(
                  results.map(({ billableAmount }) => billableAmount).reduce((a, b) => a + b, 0),
                )}
              </TableHeadCell>
            </TableRow> */}
              {results.map((employee: EmployeeWithHours, i) => (
                <TableRow key={employee.id}>
                  <TableBodyCell sx={{ borderLeft: 'none', width: '30%' }}>
                    {i + 1} {'.'}{' '}
                    <Button
                      component={Link}
                      to={{
                        pathname: `${path}/${employee.id}`,
                        state: {
                          background: location,
                          resources: resources?.filter((r) => r.employee.id === employee.id),
                        },
                      }}
                    >
                      {employee.name}
                    </Button>
                  </TableBodyCell>
                  {/* <TableCell>{employee.name}</TableCell> */}
                  <TableBodyCell>{formatDesignation(employee.designation)}</TableBodyCell>
                  <TableBodyCell>{formatHours(employee.noOfHours)}</TableBodyCell>
                  <TableBodyCell>{formatHours(employee.noOfBillableHours)}</TableBodyCell>
                  <TableBodyCell>{formatAmount(employee.billableAmount)}</TableBodyCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <PaginationLayout
        totalPageCount={results.length}
        // onPageChange={handlePageChange as unknown as (page: number) => void}
        // onPageSizeChange={handlePageSizeChange as unknown as (pageSize: number) => void}
      />
    </Box>
  );
}

function ReportsInfoLayout({ resultsdata }: { resultsdata: EmployeeWithHours[] }): JSX.Element {
  const totalAllocatedHours =
    resultsdata &&
    formatHours(resultsdata.map(({ noOfHours }) => noOfHours).reduce((a, b) => a + b, 0));

  const totalBillableHours =
    resultsdata &&
    formatHours(
      resultsdata.map(({ noOfBillableHours }) => noOfBillableHours).reduce((a, b) => a + b, 0),
    );
  const totalBillableAmount =
    resultsdata &&
    formatAmount(
      resultsdata.map(({ billableAmount }) => billableAmount).reduce((a, b) => a + b, 0),
    );
  // const totalCost =
  //   resultsdata &&
  //   formatAmount(resultsdata.map(({ costPerHour }) => costPerHour).reduce((a, b) => a + b, 0));

  return (
    <>
      <Stack
        sx={{
          py: 2,
          px: 4,
          width: 'calc(100% + 32px)',
          ml: -2,
          bgcolor: 'primary.veryLightGrey',
          flexDirection: 'row',
          '& .MuiBox-root': {
            minWidth: 200,
            '& span.MuiTypography-root': {
              width: 10,
              height: 10,
              borderRadius: '1px',
              display: 'inline-block',
              mr: 2,
            },
          },
        }}
      >
        <Box>
          <Typography variant="h6" fontWeight={400} mb={1}>
            Allocated Hours
          </Typography>
          <Typography variant="h2">{totalAllocatedHours}</Typography>
        </Box>
        <Box>
          <Typography variant="h6" fontWeight={400} mb={1}>
            Logged Hours
          </Typography>
          <Typography variant="h2">{totalBillableHours} </Typography>
        </Box>
        <Stack sx={{ flexGrow: 1, flexDirection: 'row', alignItems: 'flex-start' }}>
          <Box>
            <Stack flexDirection="row" alignItems="center" justifyContent="space-between" mb={1}>
              <Typography variant="h6" fontWeight={400} width={150}>
                <Typography
                  component="span"
                  sx={{
                    bgcolor: 'primary.blueBg',
                  }}
                ></Typography>
                Billable
              </Typography>
              <Typography variant="h6">{totalBillableAmount}</Typography>
            </Stack>
            <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" fontWeight={400} width={150}>
                <Typography
                  component="span"
                  sx={{
                    bgcolor: 'primary.blueBgLight',
                  }}
                ></Typography>
                Non - Billable
              </Typography>
              <Typography variant="h6">{formatAmount(0)}</Typography>
            </Stack>
          </Box>
          <Box sx={{ flexGrow: 1, ml: 5 }}>
            <LinearProgressWithSegments value={65} />
          </Box>
        </Stack>
      </Stack>
    </>
  );
}
