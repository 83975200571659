import { useEffect, useRef, useState } from 'react';

export type GoogleSignInProps = {
  onSuccess: (response: { credential: string; clientId: string }) => void;
  clientId: string;
  config?: GsiButtonConfiguration;
};

export type GsiButtonConfiguration = {
  type: 'standard' | 'icon';
  theme?: 'outline' | 'filled_blue' | 'filled_black';
  size?: 'large' | 'small' | 'medium';
  text?: 'signin_with' | 'signup_with' | 'continue_with' | 'signup_with';
  shape?: 'rectangular' | 'pill' | 'circle' | 'square';
  logo_alignment?: 'left' | 'center';
  width?: number;
  locale?: string;
};

const DefaultButtonConfiguration: GsiButtonConfiguration = { type: 'standard' };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const globalObject: any = window;

export default function GoogleSignin({
  clientId,
  onSuccess,
  config = DefaultButtonConfiguration,
}: GoogleSignInProps): JSX.Element {
  const [gsiScriptLoaded, setGsiScriptLoaded] = useState(false);
  const elementRef = useRef(null);
  useEffect(() => {
    if (gsiScriptLoaded) return;

    const initializeGsi = () => {
      if (!globalObject.google || gsiScriptLoaded) return;

      setGsiScriptLoaded(true);
      globalObject.google.accounts.id.initialize({
        client_id: clientId,
        callback: onSuccess,
      });
      globalObject.google.accounts.id.renderButton(elementRef.current, config);
    };

    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.onload = initializeGsi;
    script.async = true;
    script.id = 'google-gsi-client-script';
    document.querySelector('body')?.appendChild(script);

    return () => {
      // Cleanup function that runs when component unmounts
      globalObject.google?.accounts.id.cancel();
      document.getElementById('google-gsi-client-script')?.remove();
    };
  }, [onSuccess]);

  return <div ref={elementRef} />;
}
