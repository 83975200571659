import * as Yup from 'yup';

import { Form, Formik } from 'formik';
import { Box } from '@mui/material';

import FormDatePicker from '../../forms/FormDatePicker';
import FormTextInput from '../../forms/FormTextInput';
import { SubmitButton } from '../../forms/Button';

import { NotesFormBody } from '../types';
import { FormCandidatesPicker } from '../components';

const Schema = Yup.object().shape({
  note: Yup.string().required('Required Note!'),
  date: Yup.date(),
  candidate: Yup.object()
    .nullable()
    .shape({
      id: Yup.string().required('Candidate is required'),
    })
    .required('Candidate is required'),
});
export type NoteFormProps = {
  onSubmit: (values: NotesFormBody) => void;
  initialValues: NotesFormBody;
};
export default function NoteForm({ onSubmit, initialValues }: NoteFormProps): JSX.Element {
  return (
    <Formik
      initialValues={initialValues}
      validateOnMount={false}
      validationSchema={Schema}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit(values);
        setSubmitting(false);
      }}
    >
      {() => (
        <Form>
          <FormCandidatesPicker label="Candidate" name="candidate" />
          <FormTextInput
            label="Note"
            name="note"
            type="text"
            rows={3}
            multiline
            placeholder="Name"
          />
          <FormDatePicker label="Date" name="date" />
          <Box sx={{ m: '10px', float: 'right' }}>
            <SubmitButton label="Submit" />
          </Box>
        </Form>
      )}
    </Formik>
  );
}
