import * as Yup from 'yup';

import { Form, Formik } from 'formik';
import { Box } from '@mui/material';

import FormTextInput from '../../forms/FormTextInput';
import { SubmitButton } from '../../forms/Button';
import FormSelect from '../../forms/FormSelect';

import { CandidateFormBody, RelatedType } from '../types';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { getCampaigns } from '../campaigns/actions';
import { candidateStatuses } from './constants';

const Schema = Yup.object().shape({
  campaign: Yup.object()
    .shape({
      id: Yup.string().required('Campaign is required'),
    })
    .required('Campaign is required'),
  name: Yup.string().required('Name is required'),
  email: Yup.string(),
  phone: Yup.string(),
  status: Yup.string().required('Required Status!'),
  expectedSalary: Yup.string(),
  currentSalary: Yup.string(),
  totalExpeience: Yup.number(),
  relevantExperience: Yup.number(),
  resume: Yup.string().required('Resume is required'),
});
export type CandidateFormProps = {
  onSubmit: (values: CandidateFormBody) => void;
  initialValues: CandidateFormBody;
};
export default function CandidateForm({
  onSubmit,
  initialValues,
}: CandidateFormProps): JSX.Element {
  const dispatch = useDispatch();
  const [campaigns, setCampaigns] = useState<RelatedType[]>([]);

  useEffect(() => {
    const fetchCampaigns = async function () {
      const _campaigns = await dispatch(getCampaigns());
      setCampaigns(_campaigns as unknown as Array<RelatedType>);
    };
    fetchCampaigns();
  }, []);
  const formattedCampaigns = campaigns.map((campaign) => ({
    value: campaign.id,
    label: campaign.name || campaign.id,
  }));
  return (
    <Formik
      initialValues={initialValues}
      //   validateOnMount={false}
      validationSchema={Schema}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit(values);
        setSubmitting(false);
      }}
    >
      {() => (
        <Form>
          <FormSelect label="Campaign" name="campaign.id" options={formattedCampaigns} />
          <FormTextInput label="Name" name="name" type="text" />
          <FormTextInput label="Resume" name="resume" type="text" />
          <FormTextInput label="Email" name="email" type="email" />
          <FormTextInput label="Phone" name="phone" type="text" />
          <FormSelect options={candidateStatuses} name="status" label="Status" />
          <FormTextInput label="Current Salary" name="currentSalary" type="text" />
          <FormTextInput label="Expected Salary" name="expectedSalary" type="text" />
          <FormTextInput label="Total Experience" name="totalExperience" type="number" />
          <FormTextInput label="Relevant Experience" name="relevantExperience" type="number" />
          <FormTextInput label="LinkedIn" name="linkedin" type="text" />
          <FormTextInput label="Notice Period" name="noticePeriod" type="number" />
          <FormTextInput
            label="Notice Period Negotiable"
            name="noticePeriodNegotiable"
            type="text"
          />
          <FormTextInput label="Holding Any Offer" name="holdingAnyOffer" type="text" />
          <FormTextInput label="Last Working Day" name="lastWorkingDay" type="date" />
          <Box sx={{ m: '10px', float: 'right' }}>
            <SubmitButton label="Submit" />
          </Box>
        </Form>
      )}
    </Formik>
  );
}
