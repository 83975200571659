import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { NotesFormBody } from '../types';
import { getNotesById, updateNote } from './actions';
import { Box, LinearProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { TitleBar } from '../../assets/components';
import UpdateNoteForm from './form';

export default function UpdateNotes(): JSX.Element {
  const [details, setDetails] = useState<NotesFormBody>();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [showProgress, setShowProgress] = useState<boolean>(false);

  useEffect(() => {
    async function getNotesDetails() {
      try {
        setShowProgress(true);
        const result = await dispatch(getNotesById(id));
        const { note, date, candidate } = result as unknown as NotesFormBody;
        setDetails({
          note,
          date,
          candidate,
        }) as unknown as NotesFormBody;
      } catch (error) {
        enqueueSnackbar((error as Error).message, {
          variant: 'error',
        });
      } finally {
        setShowProgress(false);
      }
    }
    getNotesDetails();
  }, [id]);

  return (
    <>
      <TitleBar title="Update Note" />
      {showProgress && <LinearProgress />}
      {details && (
        <Box sx={{ p: 2 }}>
          <UpdateNoteForm
            initialValues={details}
            onSubmit={async (values: NotesFormBody): Promise<void> => {
              try {
                setShowProgress(true);
                const { date, note, candidate } = values;
                const reqBody = {
                  date,
                  note,
                  candidate,
                };
                await dispatch(updateNote(id, reqBody));
                setTimeout(history.push.bind(null, '../../notes'), 1);
                enqueueSnackbar('Note updated successfully', { variant: 'success' });
              } catch (error) {
                enqueueSnackbar((error as Error).message, { variant: 'error' });
              } finally {
                setShowProgress(false);
              }
            }}
          />
        </Box>
      )}
    </>
  );
}
