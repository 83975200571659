import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import ProjectsRouter from './projects/Router';
import PhasesRouter from './phases/Router';
import ResourceRouter from './resources/Router';
import CostsRouter from './costs/Router';

export default function Router(): JSX.Element {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/projects`} component={ProjectsRouter} />
      <Route path={`${path}/phases`} component={PhasesRouter} />
      <Route path={`${path}/allocations`} component={ResourceRouter} />
      <Route path={`${path}/costs`} component={CostsRouter} />
      <Redirect exact path={`${path}/`} to={`${path}/phases`} />
    </Switch>
  );
}
