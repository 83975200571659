import {
  Box,
  Button,
  Divider,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useRouteMatch, useLocation } from 'react-router-dom';
import { formatShortDate } from '../../../utils/date';
import { TableBodyCell, TableCellText, TableHeadCell } from '../../common';
import { getTimesheets } from '../actions';
import { formatAsTime, TimesheetStatusChip } from '../common';
import { useTimeStore } from '../store/context-store';
import {
  CHANGE_PAGE_NUMBER,
  SET_SEARCH_OPTIONS,
  SET_TIMESHEETS,
} from '../store/timesheet-list.reducer';
import { Timesheet } from '../types';
import { LayoutHeader, PaginationLayout } from './CustomLayouts';

export default function TimesheetsList(): JSX.Element {
  const [showProgress, setShowProgress] = useState(false);
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const [noOfPages, setNoOfPages] = useState(0);
  const [rootState, dispatchToTimeStore] = useTimeStore();
  const timesheets = rootState.timesheets.timesheets;
  const members = rootState.team.users;
  const { searchOptions, page } = rootState.timesheets;
  const handlePageChange = (page: number) => {
    dispatchToTimeStore({ type: CHANGE_PAGE_NUMBER, page: page + 1 });
  };

  const handlePageSizeChange = (pageSize: number) => {
    dispatchToTimeStore({
      type: SET_SEARCH_OPTIONS,
      searchOptions: { ...searchOptions, pageSize },
    });
  };

  useEffect(() => {
    async function fetchTimesheets() {
      try {
        setShowProgress(true);
        const {
          timesheets: _timesheets,
          totalCount,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          pageSize,
        } = (await dispatch(getTimesheets({ ...searchOptions, page }))) as unknown as {
          timesheets: Array<Timesheet>;
          totalCount: number;
          pageSize: number;
        };
        dispatchToTimeStore({ type: SET_TIMESHEETS, timesheets: _timesheets });
        // setNoOfPages(Math.ceil(totalCount / pageSize));
        setNoOfPages(totalCount);
      } catch (error) {
        enqueueSnackbar((error as Error).message, { variant: 'error' });
      } finally {
        setShowProgress(false);
      }
    }
    fetchTimesheets();
  }, [searchOptions, page]);
  const [showFilters, setShowFilters] = useState(false);

  return (
    <Box sx={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
      <Box
        sx={{
          width: '100%',
          zIndex: '999',
          px: [0, 2],
          flex: '0 1 auto',
          position: 'relative',
        }}
      >
        <Stack
          flexDirection="row"
          alignItems="center"
          sx={{
            justifyContent: ['space-between', 'normal'],
            mb: [1, 0],
          }}
        >
          <Typography variant="h2" mr={4}>
            Timesheets
          </Typography>
          <Button
            variant="text"
            color="secondary"
            startIcon={<img src="/images/refresh.svg" alt="refresh icon" />}
            onClick={() => {
              dispatchToTimeStore({
                type: SET_SEARCH_OPTIONS,
                searchOptions: { ...searchOptions },
              });
            }}
          >
            Reload
          </Button>
          <Button
            variant="contained"
            onClick={() => setShowFilters(true)}
            sx={{
              display: ['inline-block', 'none'],
            }}
          >
            Filters
          </Button>
        </Stack>
        <Divider
          sx={{
            width: 'calc(100% + 64px)',
            ml: -4,
            pb: 2,
          }}
        />
        <Stack
          alignItems="center"
          justifyContent="space-between"
          sx={{
            flexDirection: ['column', 'row'],
            position: ['fixed', 'static'],
            left: '0',
            top: '0',
            width: '100%',
            height: ['100vh', 'auto'],
            bgcolor: 'primary.white',
            zIndex: '99999999',
            m: 0,
            pt: [7, 0],
            display: [showFilters ? 'flex' : 'none', 'flex'],
          }}
        >
          <LayoutHeader setShowFilters={setShowFilters} />
        </Stack>
        {showProgress && (
          <LinearProgress sx={{ position: 'absolute', left: 0, bottom: 0, width: '100%' }} />
        )}
      </Box>
      <Divider
        sx={{
          width: 'calc(100% + 32px)',
          ml: -2,
        }}
      />
      <Box
        sx={{
          px: [0, 2],
          flex: '1 1 auto',
          overflow: 'auto',
        }}
      >
        <TableContainer
          sx={{
            p: 0,
          }}
        >
          <Table size="small" aria-label="timesheets table">
            <TableHead>
              <TableRow
                sx={{
                  border: 'none',
                  '& .MuiTableCell-root': {
                    minWidth: [180, 'unset'],
                    px: [1, 2],
                    '&:first-of-type': { borderLeft: 0 },
                    '&:last-child': { borderRight: 0 },
                  },
                }}
              >
                <TableHeadCell sx={{ fontWeight: 700 }}>
                  <TableCellText text="Week" />
                </TableHeadCell>
                <TableHeadCell>
                  <TableCellText text="Team" />
                </TableHeadCell>
                <TableHeadCell>
                  <TableCellText text="Hours" />
                </TableHeadCell>
                <TableHeadCell>
                  <TableCellText text="Status" />
                </TableHeadCell>
                <TableHeadCell>
                  <TableCellText text="Submitted By" />
                </TableHeadCell>
                <TableHeadCell>
                  <TableCellText text="Approved By" />
                </TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                '& .MuiTableCell-root': {
                  '&:first-of-type': { borderLeft: 0 },
                  '&:last-child': { borderRight: 0 },
                },
              }}
            >
              {timesheets.map((timesheet) => {
                const canApprove =
                  members.find((member) => member.id === timesheet.employee.id) !== undefined;
                return (
                  <TableRow
                    key={timesheet.id}
                    sx={{
                      '& .MuiTableCell-root': {
                        px: [1, 2],
                        py: [2, 3],
                        '& .MuiButton-root': { p: [0, '7px 16px'] },
                      },
                    }}
                  >
                    <TableBodyCell sx={{ borderLeft: 'none' }}>
                      {timesheet.status == 'CREATED' && (
                        <Button
                          sx={{
                            textTransform: 'none',
                            '&:hover': {
                              bgcolor: 'primary.white',
                            },
                          }}
                          to={{
                            pathname: `${url}/${timesheet.id}`,
                            state: { background: location },
                          }}
                          component={RouterLink}
                        >
                          <Typography variant="h5" sx={{ color: 'primary.main' }}>
                            {`${formatShortDate(timesheet.startDate)} to ${formatShortDate(
                              timesheet.endDate,
                            )}`}
                          </Typography>
                        </Button>
                      )}
                      {(timesheet.status == 'APPROVED' ||
                        (!canApprove && timesheet.status === 'SUBMITTED')) && (
                        <Button
                          to={{
                            pathname: `${url}/${timesheet.id}`,
                            state: { background: location },
                          }}
                          component={RouterLink}
                          sx={{
                            textTransform: 'none',
                            '&:hover': {
                              bgcolor: 'primary.white',
                            },
                          }}
                        >
                          <Typography variant="h5" sx={{ color: 'primary.main' }}>
                            {`${formatShortDate(timesheet.startDate)} to ${formatShortDate(
                              timesheet.endDate,
                            )}`}
                          </Typography>
                        </Button>
                      )}
                      {canApprove && timesheet.status == 'SUBMITTED' && (
                        <Button
                          to={{
                            pathname: `${url}/${timesheet.id}`,
                            state: { background: location },
                          }}
                          component={RouterLink}
                          sx={{
                            textTransform: 'none',
                            '&:hover': {
                              bgcolor: 'primary.white',
                            },
                          }}
                        >
                          <Typography variant="h5" sx={{ color: 'primary.main' }}>
                            {`${formatShortDate(timesheet.startDate)} to ${formatShortDate(
                              timesheet.endDate,
                            )}`}
                          </Typography>
                        </Button>
                      )}
                    </TableBodyCell>
                    <TableBodyCell>
                      <Typography variant="body1">{timesheet.employee.name}</Typography>
                    </TableBodyCell>
                    <TableBodyCell>
                      <Typography variant="body1" sx={{ fontWeight: 600 }}>
                        {formatAsTime(timesheet.noOfHours)}
                        {'   '}
                        <Typography variant="body1" component="span">
                          (
                          {timesheet.noOfHours == 0
                            ? 0
                            : Math.round((timesheet.noOfBillableHours * 100) / timesheet.noOfHours)}
                          % Billable)
                        </Typography>
                      </Typography>
                    </TableBodyCell>
                    <TableBodyCell sx={{ textAlign: 'center' }}>
                      <TimesheetStatusChip status={timesheet.status} />
                    </TableBodyCell>
                    <TableBodyCell>
                      {timesheet.submittedAt && (
                        <Typography variant="body1" sx={{ fontWeight: 600 }}>
                          {timesheet.submittedBy?.name}
                          <Typography variant="body1" component="span">
                            {' '}
                            on{' '}
                          </Typography>

                          {formatShortDate(timesheet.submittedAt)}
                        </Typography>
                      )}
                    </TableBodyCell>
                    <TableBodyCell>
                      {timesheet.approvedAt && (
                        <Typography variant="body1" sx={{ fontWeight: 600 }}>
                          {timesheet.approvedBy?.name}
                          <Typography variant="body1" component="span">
                            {' '}
                            on{' '}
                          </Typography>
                          {formatShortDate(timesheet.approvedAt)}
                        </Typography>
                      )}
                    </TableBodyCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <PaginationLayout
        totalPageCount={noOfPages}
        onPageChange={handlePageChange as unknown as (page: number) => void}
        onPageSizeChange={handlePageSizeChange as unknown as (pageSize: number) => void}
      />
    </Box>
  );
}
