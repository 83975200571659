import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TypographyProps,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import { RootState } from '../../../redux-store/reducers';
import { getPhases } from '../actions';
import { PhasesState } from '../store/phases.reducer';
import { ENGAGEMENT_TYPES, PHASE_SEARCH_OPTIONS } from '../constants';
import { resolve } from 'path';
import { formatShortDate } from '../../../utils/date';
import { OnlyIfPermission, TableBodyCell, TableHeadCell } from '../../common';
import { PaginationLayout } from '../../time-tracking/approve/CustomLayouts';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Phase } from '../types';
import { SowFilters } from './Filters';
//   import ProjectSearchOptions from './SearchOptions';

function TableCellText({ text, sx }: { text: string; sx?: TypographyProps }): JSX.Element {
  return (
    <Typography variant="h5" sx={{ fontSize: 13, color: 'primary.darkBlue', opacity: 0.8, ...sx }}>
      {text}
    </Typography>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any

export default function PhaseList(): JSX.Element {
  const dispatch = useDispatch();
  const location = useLocation();
  const { url } = useRouteMatch();
  const [showProgress, setShowProgress] = useState(false);

  const { phases, searchOptions } = useSelector<RootState, PhasesState>(
    (state) => state.delivery.phases,
  );
  useEffect(() => {
    async function getPhasesList() {
      try {
        setShowProgress(true);
        await dispatch(getPhases(searchOptions));
      } catch (error) {
        console.log(error);
      } finally {
        setShowProgress(false);
      }
    }
    getPhasesList();
  }, [searchOptions]);

  const handlePageSizeChange = (pageSize: number) => {
    dispatch({
      type: PHASE_SEARCH_OPTIONS,
      searchOptions: { ...searchOptions, limit: pageSize },
    });
  };

  return (
    <Box sx={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
      <Box
        sx={{
          width: '100%',
          zIndex: '999',
          px: 2,
          flex: '0 1 auto',
          position: 'relative',
        }}
      >
        <Stack flexDirection="row" alignItems="center" justifyContent="space-between" pb={2}>
          <Typography variant="h2">Statements of Work</Typography>
          <Grid item>
            <OnlyIfPermission name="delivery:phases:create">
              <Button
                variant="contained"
                component={Link}
                to={{ pathname: `${url}/create` }}
                fullWidth={false}
              >
                Add SOW
              </Button>
            </OnlyIfPermission>
          </Grid>
        </Stack>
        <Divider
          sx={{
            width: 'calc(100% + 64px)',
            ml: -4,
          }}
        />
        <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
          <SowFilters />
        </Stack>
        <Divider
          sx={{
            width: 'calc(100% + 64px)',
            ml: -4,
          }}
        />
        {/* <Grid pb={2} pt={2} spacing={2} container justifyContent="space-between">
          <Grid item>
            <Stack spacing={2} direction="row">
              <SearchOptionsList
                onChange={handleSearchOptionsType}
                value={searchOptionType}
                autoWidth
              />
              <Button
                variant="outlined"
                fullWidth={false}
                onClick={() => {
                  setOpenOptions(true);
                }}
              >
                Filters
              </Button>
            </Stack>
          </Grid>
        </Grid> */}
      </Box>
      {showProgress && <LinearProgress />}
      <Box sx={{ px: 2, flex: '1 1 auto', overflow: 'auto' }}>
        <TableContainer
          sx={{
            p: 0,
          }}
        >
          <Table aria-label="phases table">
            <TableHead>
              <TableRow
                sx={{
                  '& .MuiTableCell-root': {
                    '&:first-of-type': { borderLeft: 0 },
                    '&:last-child': { borderRight: 0 },
                  },
                }}
              >
                <TableHeadCell>
                  <TableCellText text="Name" />
                </TableHeadCell>
                <TableHeadCell>
                  <TableCellText text="Type" />
                </TableHeadCell>
                <TableHeadCell>
                  <TableCellText text="Start Date" />
                </TableHeadCell>
                <TableHeadCell>
                  <TableCellText text="End Date" />
                </TableHeadCell>
                <TableHeadCell>
                  <TableCellText text="Project" />
                </TableHeadCell>
                <TableHeadCell>
                  <TableCellText text="Notes" />
                </TableHeadCell>
                {/* <OnlyIfPermission name="delivery:phases:update">
                  <TableHeadCell>
                    <TableCellText text="Actions" />
                  </TableHeadCell>
                </OnlyIfPermission> */}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                '& .MuiTableCell-root': {
                  '&:first-of-type': { borderLeft: 0 },
                  '&:last-child': { borderRight: 0 },
                },
              }}
            >
              {phases.map((phase, index) => (
                <TableRow key={phase.id}>
                  <TableBodyCell sx={{ borderLeft: 'none' }}>
                    <MenuItemComponent url={url} phase={phase} index={index + 1} />
                  </TableBodyCell>
                  <TableBodyCell>
                    <Typography variant="body1">{ENGAGEMENT_TYPES[phase.type]}</Typography>
                  </TableBodyCell>
                  <TableBodyCell>
                    <Typography variant="body1">{formatShortDate(phase.startDate)}</Typography>
                  </TableBodyCell>
                  <TableBodyCell>
                    <Typography variant="body1">{formatShortDate(phase.endDate)}</Typography>
                  </TableBodyCell>
                  <TableBodyCell>
                    <Button
                      component={Link}
                      to={{
                        pathname: resolve(url, '..', `projects/${phase.project?.id}`),
                        state: { background: location },
                      }}
                      sx={{ textTransform: 'none' }}
                    >
                      <Typography variant="h5" sx={{ color: 'primary.main' }}>
                        {phase.project?.name}
                      </Typography>
                    </Button>
                  </TableBodyCell>
                  <TableBodyCell>
                    <Typography variant="body1">{phase.notes}</Typography>
                  </TableBodyCell>

                  {/* <OnlyIfPermission name="delivery:phases:update">
                    <TableBodyCell>
                      <Button
                        component={Link}
                        to={{
                          pathname: `${url}/tasks/${phase.id}`,
                          state: { background: location },
                        }}
                      >
                        <Typography variant="h5" sx={{ color: 'primary.main' }}>
                          Manage Tasks
                        </Typography>
                      </Button>
                      <Button
                        component={Link}
                        to={{
                          pathname: `${url}/update/${phase.id}`,
                          state: { background: location },
                        }}
                      >
                        <Typography variant="h5" sx={{ color: 'primary.main' }}>
                          Update
                        </Typography>
                      </Button>
                    </TableBodyCell>
                  </OnlyIfPermission> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <PaginationLayout
        totalPageCount={phases.length}
        // onPageChange={handlePageChange as unknown as (page: number) => void}
        onPageSizeChange={handlePageSizeChange as unknown as (pageSize: number) => void}
      />
    </Box>
  );
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function MenuItemComponent({ url, phase, index }: { url: any; phase: Phase; index: number }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Stack flexDirection="row" alignItems="center">
        <IconButton
          sx={{ border: '1px solid #C4C4C4', p: 0, mr: 2 }}
          aria-label="more"
          id={`${phase.id}-button`}
          aria-controls={open ? phase.id : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Typography component="span">{index}</Typography>
        <Button
          component={Link}
          to={{
            pathname: `${url}/${phase.id}`,
          }}
          sx={{ textTransform: 'none' }}
        >
          <Typography variant="h5" sx={{ color: 'primary.main' }}>
            {phase.name}
          </Typography>
        </Button>
      </Stack>
      <Menu
        id={phase.id}
        MenuListProps={{
          'aria-labelledby': `${phase.id}-button`,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiMenu-paper': {
            boxShadow: 'none',
            background: 'rgba(79, 79, 86, 0.98)',
            borderRadius: '2px',
            '& .MuiMenuItem-root': {
              color: '#fff',
              padding: 0,
              '& .MuiButton-root': {
                width: '100%',
                justifyContent: 'flex-start',
              },
            },
          },
        }}
      >
        <MenuItem onClick={handleClose}>
          <Button
            component={Link}
            to={{
              pathname: `${url}/update/${phase.id}`,
              state: { sowName: phase.name },
            }}
          >
            <Typography variant="h5" sx={{ color: '#fff' }}>
              Edit
            </Typography>
          </Button>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Button
            component={Link}
            to={{
              pathname: `${url}/tasks/${phase.id}`,
            }}
          >
            <Typography variant="h5" sx={{ color: '#fff' }}>
              Manage Tasks
            </Typography>
          </Button>
        </MenuItem>
      </Menu>
    </>
  );
}
