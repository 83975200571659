import { useSelector } from 'react-redux';
import { generatePath, match, useRouteMatch } from 'react-router';
import { RootStateType } from '../../redux-store/reducers';
import { AuthState } from '../../redux-store/types/login';
import { formatDateRange } from '../../utils/date';
import { TaskWithSOW, Timelog, TrackRouteParams } from './types';

export const GET_TIMESHEETS_SUCCESS = 'GET_TIMESHEETS_SUCCESS';

export function mapStatus(status: string): string {
  return (
    { '': 'Any', APPROVED: 'Approved', SUBMITTED: 'Pending Approval', CREATED: 'Not Submitted' }[
      status
    ] || status
  );
}

export const STATUSES = ['', 'CREATED', 'SUBMITTED', 'APPROVED'];

export const STATUS_OPTIONS = STATUSES.map((status) => ({
  label: mapStatus(status),
  value: status,
}));

export function getWeekStart(date: Date): Date {
  const d = new Date(date);
  d.setUTCHours(12, 0, 0, 0);
  d.setDate(d.getDate() - d.getDay() + (d.getDay() == 0 ? -6 : 1)); // adjust when day is sunday);
  return d;
}

export function getPrevWeekStart(date: Date): Date {
  const d = getWeekStart(date);
  d.setDate(d.getDate() - 7);
  return d;
}

export function getMonthStart(date: Date): Date {
  const d = new Date(date);
  d.setUTCHours(12, 0, 0, 0);
  d.setDate(1);
  return d;
}

export function getSemiMonthStart(date: Date): Date {
  const d = new Date(date);
  d.setUTCHours(12, 0, 0, 0);
  if (d.getDate() < 15) {
    d.setDate(1);
  } else {
    d.setDate(16);
  }
  return d;
}

export function getQuarterStart(date: Date): Date {
  const d = new Date(date);
  d.setUTCHours(12, 0, 0, 0);
  d.setMonth(Math.floor((d.getMonth() + 1) / 3) * 3);
  d.setDate(1);
  return d;
}

export function getYearStart(date: Date): Date {
  const d = new Date(date);
  d.setUTCHours(12, 0, 0, 0);
  d.setMonth(0);
  d.setDate(1);
  return d;
}

export function getWeekEnd(date: Date): Date {
  const d = new Date(getWeekStart(date));
  d.setDate(d.getDate() + 6);
  return d;
}

export const getWeeksList = (): Array<{ name: string; id: Date | string }> => {
  const items = new Array(10).fill(0).map((_, i) => {
    const date = new Date();
    date.setUTCHours(12, 0, 0, 0);
    date.setDate(date.getDate() - 7 * i);
    return date;
  });
  const weeks = items.map((date) => {
    return {
      name: `Week of ${formatDateRange(getWeekStart(date), getWeekEnd(date))}`,
      id: getWeekStart(date).toISOString(),
    };
  });

  return weeks;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const defaultKeyer = (item: any) => item['id'] as string;

export function removeDuplicates<T>(
  array: Array<T>,
  keyer: (item: T) => string = defaultKeyer,
): Array<T> {
  const obj = array.reduce((prev, item) => {
    prev[keyer(item)] = item;
    return prev;
  }, {} as Record<string, T>);
  return Object.values(obj);
}
export function useExtractParams(): TrackRouteParams &
  Omit<match<TrackRouteParams>, 'isExact' | 'url' | 'params'> & {
    isEditingOwnTimesheet: boolean;
    isCurrentWeek: boolean;
    isCurrentDay: boolean;
    date: Date;
    weekStart: Date;
    weekEnd: Date;
    prevUrl: string;
    nextUrl: string;
    weekUrl: string;
    dayUrl: string;
    currentWeekUrl: string;
    currentDayUrl: string;
  } {
  const { token } = useSelector<RootStateType, AuthState>((state) => state.authReducer);
  const { params, path } = useRouteMatch<TrackRouteParams>();
  const { year, month, day, mode = 'day', employeeId: paramEmployeeId } = params;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const selfEmployeeId = extractEmployeeIdFromToken(token!);
  const employeeId = paramEmployeeId || selfEmployeeId;
  const parsedDate = new Date(+year, +month - 1, +day);
  parsedDate.setMinutes(parsedDate.getMinutes() - parsedDate.getTimezoneOffset());
  parsedDate.setUTCHours(12, 0, 0, 0);
  const date = parsedDate.toString() === 'Invalid Date' ? new Date() : parsedDate;
  date.setUTCHours(12, 0, 0, 0);
  const weekStart = getWeekStart(date);
  const weekEnd = getWeekEnd(date);
  const prevDate =
    mode === 'week'
      ? new Date(weekStart.getTime() - 7 * 24 * 60 * 60 * 1000)
      : new Date(date.getTime() - 24 * 60 * 60 * 1000);
  const prevUrl = generatePath(path, {
    mode,
    year: prevDate.getFullYear().toString(),
    month: (prevDate.getMonth() + 1).toString(),
    day: prevDate.getDate().toString(),
    employeeId,
  });
  const nextDate =
    mode === 'week'
      ? new Date(weekStart.getTime() + 7 * 24 * 60 * 60 * 1000)
      : new Date(date.getTime() + 24 * 60 * 60 * 1000);
  const nextUrl = generatePath(path, {
    mode,
    year: nextDate.getFullYear().toString(),
    month: (nextDate.getMonth() + 1).toString(),
    day: nextDate.getDate().toString(),
    employeeId,
  });
  const weekUrl = generatePath(path, {
    mode: 'week',
    year: weekStart.getFullYear().toString(),
    month: (weekStart.getMonth() + 1).toString(),
    day: weekStart.getDate().toString(),
    employeeId,
  });
  const dayUrl = generatePath(path, {
    mode: 'day',
    year: date.getFullYear().toString(),
    month: (date.getMonth() + 1).toString(),
    day: date.getDate().toString(),
    employeeId,
  });
  const today = new Date();
  today.setUTCHours(12, 0, 0, 0);
  const restParams = {
    year: today.getFullYear().toString(),
    month: (today.getMonth() + 1).toString(),
    day: today.getDate().toString(),
    employeeId,
  };
  const currentWeekUrl = generatePath(path, {
    mode: 'week',
    ...(selfEmployeeId === employeeId ? {} : restParams),
  });
  const currentDayUrl = generatePath(path, {
    mode: 'day',
    ...(selfEmployeeId === employeeId ? {} : restParams),
  });
  return {
    year,
    month,
    day,
    mode,
    date,
    employeeId,
    isEditingOwnTimesheet: selfEmployeeId === employeeId,
    isCurrentWeek: weekStart.getTime() === getWeekStart(new Date()).getTime(),
    isCurrentDay: date.getTime() === today.getTime(),
    weekStart,
    weekEnd,
    prevUrl,
    nextUrl,
    weekUrl,
    dayUrl,
    currentWeekUrl,
    currentDayUrl,
    path,
  };
}

const extractEmployeeIdFromToken = (token: string): string => {
  const payload = token.split('.')[1];
  const payloadPart = atob(payload);
  const decodedPayload = JSON.parse(payloadPart);
  return decodedPayload.id as string;
};

declare type IConsolidatedEntry = Pick<Timelog, 'sow' | 'task' | 'noOfHours' | 'date'>;

export function useConsolidatedLogs(
  timelogs: Array<Timelog>,
  startDate: Date,
): {
  timelogsCompressed: Array<IConsolidatedEntry>;
  days: Array<Date>;
  uniqueTaskEntries: Array<TaskWithSOW>;
} {
  const compareTaskEntries = (a: IConsolidatedEntry, b: IConsolidatedEntry): number =>
    a.sow.project.name.localeCompare(b.sow.project.name) ||
    a.sow.name.localeCompare(b.sow.name) ||
    a.task.name.localeCompare(b.task.name);
  const timelogsCompressed = timelogs
    .map(({ sow, task, noOfHours, date }) => ({ sow, task, noOfHours, date }))
    .reduce((entries, { sow, task, noOfHours, date }) => {
      const existingEntryWithSameTask = entries.find(
        (s) => s.task.id === task.id && s.sow.id === sow.id && s.date.getTime() === date.getTime(),
      );
      if (existingEntryWithSameTask) {
        existingEntryWithSameTask.noOfHours += noOfHours;
        return [...entries];
      }
      return [...entries, { sow, task, noOfHours, date }];
    }, [] as Array<IConsolidatedEntry>)
    .sort(compareTaskEntries);

  const days = new Array(7).fill(0).map((_, i) => {
    const day = new Date(startDate);
    day.setDate(day.getDate() + i);
    day.setUTCHours(12, 0, 0, 0);
    return day;
  });

  const uniqueTaskEntries = timelogsCompressed.reduce((arr, timelog) => {
    if (!arr.find((s) => s.id === timelog.task.id && s.sow.id === timelog.sow.id)) {
      return [...arr, { ...timelog.task, sow: timelog.sow }];
    }
    return arr;
  }, [] as Array<TaskWithSOW>);
  return { timelogsCompressed, days, uniqueTaskEntries };
}
