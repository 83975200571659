import { CALL_API } from '../../redux-store/middleware';
import {
  LeavePayload,
  UpdateLeavePayload,
  UpdateLeaveStatusPayload,
  GET_LEAVES_BY_USER,
  GET_LEAVES_BY_USER_SUCCESS,
  CREATE_LEAVE,
  CREATE_LEAVE_SUCCESS,
  UPDATE_LEAVE,
  UPDATE_LEAVE_SUCCESS,
  UPDATE_LEAVE_STATUS,
  UPDATE_LEAVE_STATUS_SUCCESS,
} from './types';

export const onGetLeaves = () => async (dispatch: any) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/leaves/leaves`,
        method: 'GET',
        types: [GET_LEAVES_BY_USER_SUCCESS, GET_LEAVES_BY_USER],
      },
    });
  } catch (error) {
    alert(`${error}`);
  }
};

export const onCreateLeave = (body: LeavePayload) => async (dispatch: any) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/leaves/leaves`,
        method: 'POST',
        types: [CREATE_LEAVE_SUCCESS, CREATE_LEAVE],
        body,
      },
    });
  } catch (error) {
    alert(`${error}`);
  }
};

export const onUpdateLeave = (body: UpdateLeavePayload) => async (dispatch: any) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/leaves/leaves/${body.id}/update`,
        method: 'POST',
        types: [UPDATE_LEAVE_SUCCESS, UPDATE_LEAVE],
        body,
      },
    });
  } catch (error) {
    alert(`${error}`);
  }
};

export const onUpdateLeaveStatus = (body: UpdateLeaveStatusPayload) => async (dispatch: any) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/leaves/leaves/${body.id}/statusUpdate`,
        method: 'POST',
        types: [UPDATE_LEAVE_STATUS_SUCCESS, UPDATE_LEAVE_STATUS],
        body,
      },
    });
  } catch (error) {
    alert(`${error}`);
  }
};
