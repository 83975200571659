/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CALL_API } from '../../../redux-store/middleware';
import { Group, GroupFormBody, Employee, GroupShort, Grant } from '../types';
import { ADD_GROUP, SET_GROUPS, UPDATE_GROUP } from './constants';

const BASE_URL = '/permissions/groups';
const PERMISSIONS_BASE = '/permissions/permissions';
export const getGroups =
  ({ name }: { name?: string }) =>
  async (dispatch: any) => {
    try {
      const searchParams = new URLSearchParams();
      if (name) {
        searchParams.append('name', name);
      }
      const groups = await dispatch({
        [CALL_API]: {
          url: `${BASE_URL}?${searchParams.toString()}`,
          method: 'GET',
        },
      });
      !searchParams.has('name') &&
        dispatch({
          type: SET_GROUPS,
          groups,
        });
      return groups as Group[];
    } catch (error) {
      console.error(error);
      return [];
    }
  };

export const createNewGroup = (groupFormBody: GroupFormBody) => async (dispatch: any) => {
  const { ...groupBody } = groupFormBody;
  try {
    const group = await dispatch({
      [CALL_API]: {
        url: BASE_URL,
        method: 'POST',
        body: groupBody,
      },
    });
    dispatch({
      type: ADD_GROUP,
      group,
    });
    return group;
  } catch (error) {
    throw new Error('Error creating new group. ' + error);
  }
};

export const updateGroup = (id: string, groupFormBody: GroupFormBody) => async (dispatch: any) => {
  try {
    const group = await dispatch({
      [CALL_API]: {
        url: `${BASE_URL}/${id}`,
        method: 'POST',
        body: groupFormBody,
      },
    });
    dispatch({
      type: UPDATE_GROUP,
      group,
    });
    return group;
  } catch (error) {
    throw new Error('Error updating group. ' + error);
  }
};

export const getGroupDetail = (groupId: string) => async (dispatch: any) => {
  try {
    const group = await dispatch({
      [CALL_API]: {
        url: `${BASE_URL}/${groupId}`,
        method: 'GET',
      },
    });
    return group as Group;
  } catch (error) {
    throw new Error('Unable to fetch group. ' + error);
  }
};

export const addMember = (groupId: string, employee: Employee) => async (dispatch: any) => {
  try {
    const group = await dispatch({
      [CALL_API]: {
        url: `${BASE_URL}/${groupId}/members`,
        method: 'POST',
        body: { employee: { id: employee.id } },
      },
    });
    return group;
  } catch (error) {
    throw new Error('Error adding member. ' + error);
  }
};

export const addSubgroup = (groupId: string, subgroup: GroupShort) => async (dispatch: any) => {
  try {
    const group = await dispatch({
      [CALL_API]: {
        url: `${BASE_URL}/${groupId}/subgroups`,
        method: 'POST',
        body: { subGroupId: subgroup.id },
      },
    });
    return group;
  } catch (error) {
    throw new Error('Error adding member. ' + error);
  }
};

export const getGroupPermissions = (groupId: string) => async (dispatch: any) => {
  try {
    const permissions = await dispatch({
      [CALL_API]: {
        url: `${PERMISSIONS_BASE}/groups/${groupId}`,
        method: 'GET',
      },
    });
    return permissions;
  } catch (error) {
    throw new Error('Error fetching permissions for group. ' + error);
  }
};

export const grantPermissionToGroup =
  (groupId: string, permission: string) => async (dispatch: any) => {
    try {
      const grant = await dispatch({
        [CALL_API]: {
          url: `${PERMISSIONS_BASE}/groups/add`,
          method: 'POST',
          body: { group: { id: groupId }, permission },
        },
      });
      return grant as unknown as Grant;
    } catch (error) {
      throw new Error('Error granting permission to group. ' + error);
    }
  };
export const revokePermissionFromGroup =
  (groupId: string, permission: string) => async (dispatch: any) => {
    try {
      await dispatch({
        [CALL_API]: {
          url: `${PERMISSIONS_BASE}/groups/revoke`,
          method: 'POST',
          body: { group: { id: groupId }, permission },
        },
      });
      const grant = dispatch({
        [CALL_API]: {
          url: `${PERMISSIONS_BASE}/groups/${groupId}/allowed?permission=${permission}`,
          method: 'GET',
        },
      });
      return grant as Grant;
    } catch (error) {
      throw new Error('Error granting permission to group. ' + error);
    }
  };

export const getLoggedinUserPermissions = () => async (dispatch: any) => {
  try {
    const permissions = await dispatch({
      [CALL_API]: {
        url: `${PERMISSIONS_BASE}/employees/me`,
        method: 'GET',
      },
    });
    return permissions;
  } catch (error) {
    throw new Error('Error fetching permissions for logged in user. ' + error);
  }
};
