import {
  DesktopDateRangePicker,
  DesktopDateRangePickerProps,
  LocalizationProvider,
} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { FormControl, Grid, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useField } from 'formik';

export type FormDateRangePickerProps = Omit<
  DesktopDateRangePickerProps,
  'value' | 'onChange' | 'renderInput'
> & {
  startLabel: string;
  endLabel: string;
  name: string;
  margin?: 'dense' | 'none';
};

export function FormDateRangePicker({
  startLabel,
  endLabel,
  name,
  margin = 'dense',
}: FormDateRangePickerProps): JSX.Element {
  //   const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const [field, meta, { setValue }] = useField(name);
  const value = field.value || [null, null];
  const errorText = meta.error && meta.touched ? meta.error : '';
  const hasError = !!errorText;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormControl fullWidth>
        <DesktopDateRangePicker
          startText={startLabel}
          endText={endLabel}
          {...field}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          value={value}
          renderInput={(startProps, endProps) => (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField {...startProps} margin={margin} error={hasError} fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField {...endProps} margin={margin} error={hasError} fullWidth />
              </Grid>
            </Grid>
          )}
        />
        {hasError ? <Box sx={{ color: 'red' }}>{meta.error}</Box> : null}
      </FormControl>
    </LocalizationProvider>
  );
}
