import {
  AppBar,
  Divider,
  Grid,
  LinearProgress,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell as MUITableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Toolbar,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { onGetUsersbyId } from '../../redux-store/actions/users';
import { UserDetails } from '../../redux-store/types/users';
import { formatShortDate } from '../../utils/date';
import { DataValues } from '../common';
import { formatDesignation } from './constants';

type SelectedTabType = 'details' | 'history';

function TableCell({ sx, ...rest }: TableCellProps): JSX.Element {
  return <MUITableCell sx={{ px: 1, ...sx }} {...rest} />;
}
export default function UserDetail(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const [selectedTab, setSelectedTab] = useState<SelectedTabType>('details');
  const dispatch = useDispatch();

  const [users_details, setDetails] = useState<UserDetails>();
  const [showProgress, setShowProgress] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function getUsersbyId() {
      try {
        setShowProgress(true);
        const user_details = await dispatch(onGetUsersbyId(id));
        setDetails(user_details as unknown as UserDetails);
      } catch (error) {
        enqueueSnackbar((error as Error).message, {
          variant: 'error',
        });
      } finally {
        setShowProgress(false);
      }
    }
    getUsersbyId();
  }, [id]);

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: 'white' }} elevation={1}>
        <Toolbar>
          <Tabs value={selectedTab} onChange={(event, newValue) => setSelectedTab(newValue)}>
            <Tab value="details" label="Details" />
            <Tab value="history" label="History" />
          </Tabs>
        </Toolbar>
      </AppBar>
      {showProgress && <LinearProgress />}
      {users_details && (
        <>
          {selectedTab == 'details' && (
            <>
              <Grid container sx={{ px: 2 }}>
                <DataValues label="Name" value={users_details.name} />
                <DataValues
                  label="Designation"
                  value={formatDesignation(users_details.designation)}
                />
                <DataValues label="Department" value={users_details.department} half />
                <DataValues label="Sub Department" value={users_details.subDepartment} half />
                <DataValues label="Email" value={users_details.email} />
                <DataValues
                  label="Joining Date"
                  value={formatShortDate(users_details.joining_date)}
                  half
                />
                <DataValues label="Type" value={users_details.additionalDetails?.type} half />
                <DataValues label="Manager" value={users_details.manager?.name || 'None'} half />
                <DataValues
                  label="Employee Id"
                  value={users_details.additionalDetails?.empId}
                  half
                />
              </Grid>
              <Divider sx={{ mt: 2 }}>Contact Details</Divider>
              <Grid container sx={{ px: 2 }}>
                <DataValues
                  label="Phone Number"
                  value={users_details.additionalDetails?.phoneNumber}
                  half
                />
                <DataValues
                  label="Date of Birth"
                  value={formatShortDate(users_details.additionalDetails?.dob)}
                  half
                />
                <DataValues label="Address" value={users_details.additionalDetails?.address} />
                <DataValues
                  label="Emergency Contact No"
                  value={users_details.additionalDetails?.emergencyContact}
                  half
                />
                <DataValues
                  label="Blood Group"
                  value={users_details.additionalDetails?.bloodGroup}
                  half
                />
                <DataValues
                  label="Father's Name"
                  value={users_details.additionalDetails?.fatherName}
                  half
                />
                <DataValues
                  label="Spouse's Name"
                  value={users_details.additionalDetails?.spouseName}
                  half
                />
                <DataValues label="PAN" value={users_details.additionalDetails?.pan} half />
                <DataValues
                  label="AADHAR No"
                  value={users_details.additionalDetails?.aadhar}
                  half
                />
              </Grid>
            </>
          )}

          {selectedTab === 'history' && (
            <TableContainer>
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>Changed By</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody component={Paper}>
                  {users_details.history?.map((history) => (
                    <TableRow key={history.id}>
                      <TableCell>
                        <strong>{history.changedBy.name}</strong> on{' '}
                        {formatShortDate(history.createdAt)}
                      </TableCell>
                      <TableCell>{history.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}
    </>
  );
}
