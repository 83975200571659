import { AnyAction } from 'redux';
import { LOGOUT_SUCCESS } from '../../../redux-store/types/login';
import {
  CREATE_PROJECT,
  PROJECT_SEARCH_OPTIONS,
  PROJECT_SEARCH_OPTION_TYPE,
  RESET_PROJECT_SEARCH_OPTIONS,
  SET_PROJECTS,
  UPDATE_PROJECT,
} from '../projects/constants';
import { Project, ProjectSearchParameters } from '../types';

export const allProjects: ProjectSearchParameters = {
  name: '',
  description: '',
  orderBy: '',
  orderDirection: 'desc',
  limit: 25,
  offset: '',
};
export interface ProjectState {
  projects: Array<Project>;
  searchOptions: ProjectSearchParameters;
  searchOptionType: string;
}

export const searchOptionsList: Record<string, ProjectSearchParameters> = {
  allProjects: allProjects,
};
const initialState = {
  projects: [],
  searchOptions: allProjects,
  searchOptionType: 'allProjects',
};
export default function projectsReducer(state = initialState, action: AnyAction): ProjectState {
  switch (action.type) {
    case SET_PROJECTS: {
      return {
        ...state,
        projects: action.projects,
      };
    }
    case CREATE_PROJECT: {
      return {
        ...state,
        projects: [action.project, ...state.projects],
      };
    }
    case UPDATE_PROJECT: {
      return {
        ...state,
        projects: state.projects.map((project: Project) =>
          project.id === action.project.id ? { ...project, ...action.project } : project,
        ),
      };
    }

    case RESET_PROJECT_SEARCH_OPTIONS: {
      return {
        ...state,
        searchOptions: allProjects,
      };
    }
    case PROJECT_SEARCH_OPTIONS: {
      return {
        ...state,
        searchOptions: { ...action.searchOptions },
      };
    }
    case PROJECT_SEARCH_OPTION_TYPE: {
      return {
        ...state,
        searchOptionType: action.searchOptionType,
        searchOptions: {
          ...(searchOptionsList[action.searchOptionType] || allProjects),
        },
      };
    }
    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
}
