import { Box, LinearProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { getTicketsbyId, updateComment } from '../../redux-store/actions/tickets';
import { TicketDetails } from '../../redux-store/types/tickets';
import Comment from './Comment';

export default function UpdateComment(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  //const [change, setOnChange] = useState('');
  const [ticketdetails, setticketdetails] = useState<TicketDetails>();
  const [showProgress, setShowProgress] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    async function getTicketsIDdata() {
      try {
        setShowProgress(true);
        const ticketIdDetails = await dispatch(getTicketsbyId(id));
        setticketdetails(ticketIdDetails as unknown as TicketDetails);
      } catch (error) {
        enqueueSnackbar((error as Error).message, {
          variant: 'error',
        });
      } finally {
        setShowProgress(false);
      }
    }
    getTicketsIDdata();
  }, [id]);
  return (
    <>
      {showProgress && <LinearProgress />}
      {ticketdetails && (
        <Box sx={{ p: 2 }}>
          <Comment
            initialValues={ticketdetails}
            onSubmit={async (values) => {
              const { title } = values;
              setShowProgress(true);
              await dispatch(updateComment(id, title));
              setShowProgress(false);
              setTimeout(history.push.bind(null, '/admin/tickets'), 1);
            }}
          />
        </Box>
      )}
    </>
  );
}
