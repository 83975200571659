/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../redux-store/reducers';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import UsersRouter from '../components/users/Router';
import Leaves from '../components/leaves';
import HolidaysRouter from '../components/holidays/HolidaysRouter';
import PendingLeaves from '../components/leaves/pendingLeaves';
import AdminLogin from '../components/auth';
import EmployeeLogin from '../components/auth/user';
import { history } from '../store';
import ProtectedRoute from './ProtectedRoute';
import RedirectRoute from './RedirectRoute';
import SimpleDialog from '../components/dialogs/SimpleDialog';
import { useModal } from 'mui-modal-provider';
import TicketRouter from '../components/tickets/Router';
import TimeTrackingRouter from '../components/time-tracking/router';
import AssetsRouter from '../components/assets/router';
import { AuthState } from '../redux-store/types/login';
import { Box, createTheme, Stack, ThemeProvider, Typography } from '@mui/material';
import { WAAppBar } from '../components/common';
import SideBar from '../components/forms/Sidebar';
import ATSRouter from '../components/ats/Router';
import PermissionsRouter from '../components/permissions/router';
import DeliveryRouter from '../components/delivery/router';
import { CLEAR_GLOBAL_ERROR } from '../redux-store/types/global';
import { useSnackbar } from 'notistack';
import { getLoggedinUserPermissions } from '../components/permissions/groups/actions';
import { PermissionsList } from '../components/permissions/types';
import { HeaderToggleContext } from '../HeaderToggleContext';
import ReportsRouter from '../components/reports_/router';

function WaitingForPermissions(): JSX.Element {
  return (
    <Stack
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
        height: '100%',
      }}
    >
      <Typography variant="h6">Verifying permissions...</Typography>
    </Stack>
  );
}

function LoggedinRoutes(): JSX.Element {
  const { role } = useSelector<RootStateType, AuthState>((state) => state.authReducer);
  const permissions = useSelector<RootStateType, PermissionsList>(
    (state) => state.permissions.permissions,
  );
  const [headerToggle, setHeaderToggle] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    async function fetchPermissions() {
      try {
        const perms = await dispatch(getLoggedinUserPermissions());
        dispatch({
          type: 'SET_PERMISSIONS',
          payload: (perms as unknown as Array<{ permission: { name: string }; allowed: boolean }>)
            .filter((p) => p.allowed)
            .map((p) => p.permission.name),
        });
      } catch (err) {
        enqueueSnackbar((err as Error).message, { variant: 'error' });
      }
    }
    fetchPermissions();
  }, []);
  const theme = createTheme({
    palette: {
      primary: {
        main: '#4878F5',
        grey: '#828282',
        lightGrey: '#C4C4C4',
        veryLightGrey: '#F4F4F4',
        darkBlue: '#3A2F5C',
        lightBlueBg: 'rgba(72, 120, 245, 0.05)',
        skyBlueBg: '#E0E9FF',
        blueBg: '#1376E3',
        blueBgLight: '#D0E4F9',
        lightBlack: '#656565',
        greyBlack: '#33364C',
        white: '#ffffff',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any,
      secondary: {
        main: '#5C5E70',
      },
      error: {
        main: '#EB5757',
      },
      warning: {
        main: '#E9994F',
        contrastText: '#ffffff',
      },
      success: {
        main: '#4AC699',
        contrastText: '#ffffff',
      },
      text: {
        primary: '#5C5E70',
      },
    },
    mixins: {
      topBoxShadow: {
        boxShadow: '0px -4px 3px #ccc',
      },
      standardBorder: {
        border: '1px solid #C4C4C4',
      },
      standardBorderLeft: {
        borderLeft: '1px solid #C4C4C4',
      },
      standardBorderRight: {
        borderRight: '1px solid #C4C4C4',
      },
      standardBorderTop: {
        borderTop: '1px solid #C4C4C4',
      },
      standardBorderBottom: {
        borderBottom: '1px solid #C4C4C4',
      },
      flexCenterSpaceBetween: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      flexCenterLeft: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
      },
      flexCenter: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any,
    typography: {
      fontSize: 14,
      fontFamily: "'Open Sans', sans-serif",
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '2px',
            padding: '7px 16px',
            textTransform: 'unset',
            fontSize: 14,
            fontWeight: 600,
            '&.Mui-disabled': {
              background: '#91AEF9',
              color: '#fff',
            },
          },
          outlinedPrimary: {
            border: '1px solid #4878F5',
          },
          textSecondary: {
            padding: '0 10px',
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontSize: 14,
            fontWeight: 400,
          },
          h1: {
            fontSize: 24,
            color: '#3A2F5C',
            fontWeight: 600,
          },
          h2: {
            fontSize: 22,
            color: '#3A2F5C',
            fontWeight: 600,
          },
          h3: {
            fontSize: 18,
            color: '#3A2F5C',
            fontWeight: 600,
          },
          h4: {
            fontSize: 16,
            color: '#3A2F5C',
            fontWeight: 600,
            textTransform: 'none',
          },
          h5: {
            fontSize: 14,
            color: '#3A2F5C',
            fontWeight: 600,
            textTransform: 'none',
          },
          h6: {
            fontSize: 12,
            color: '#3A2F5C',
            fontWeight: 600,
            textTransform: 'none',
          },
          body1: {
            fontSize: 14,
            color: '#3A2F5C',
            fontWeight: 400,
            textTransform: 'none',
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            border: '1px solid #BEBFC6',
            borderRadius: 20,
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            textTransform: 'unset',
            fontSize: 16,
            color: '#828282',
            fontWeight: 400,
            padding: '5px 10px',
            border: 0,
            borderRadius: 20,
            marginTop: '-2px',
            lineHeight: 'unset',
            minWidth: 70,
            '&.Mui-selected': {
              backgroundColor: '#4878F5',
              color: '#fff',
              fontWeight: 600,
              borderRadius: '20px !important',
              '&:hover': {
                backgroundColor: '#4878F5',
              },
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: 'unset',
            '&.normal-tabs': {
              '& .MuiButtonBase-root': {
                padding: '8px 4px',
                minWidth: 'unset',
                marginRight: '16px',
                minHeight: 'unset',
                textTransform: 'unset',
              },
            },
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            '& .MuiTableHead-root': {
              '& .MuiTableCell-root': {
                fontWeight: 600,
                textTransform: 'none',
                color: 'rgb(58, 47, 92)',
                fontSize: '13px',
                opacity: 0.8,
                padding: '24px 16px',
              },
            },
            '& .MuiTableBody-root': {
              '& .MuiTableCell-root': {
                fontWeight: 400,
                textTransform: 'none',
                color: 'rgb(58, 47, 92)',
                fontSize: '14px',
              },
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          root: {
            '& .MuiMenu-paper': {
              borderRadius: '2px',
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize: '16px',
            color: '#33364C',
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Stack>
        <HeaderToggleContext.Provider value={{ headerToggle, setHeaderToggle }}>
          <WAAppBar />
          <Stack direction="row">
            <SideBar permissions={permissions} />
            <Box
              sx={{
                width: '100%',
                height: 'calc(100vh - 64px)',
                px: 2,
                py: 2,
                overflowY: 'auto',
              }}
            >
              {permissions && (
                <Router history={history}>
                  <Switch>
                    <Route
                      exact
                      path="/"
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      render={(props: any) =>
                        role && role != 'super_admin' ? (
                          <Redirect {...props} to="/time/track" from={props.location.pathname} />
                        ) : (
                          <Redirect {...props} to="/admin/users" from={props.location.pathname} />
                        )
                      }
                    ></Route>
                    <ProtectedRoute path="/admin/users" component={UsersRouter} />
                    <ProtectedRoute path="/admin/tickets" component={TicketRouter} />
                    <ProtectedRoute path="/leaves" component={Leaves} />
                    <ProtectedRoute path="/pendingLeaves" component={PendingLeaves} />
                    <ProtectedRoute path="/holidays" component={HolidaysRouter} />
                    <ProtectedRoute path="/admin/assets" component={AssetsRouter} />
                    <ProtectedRoute path="/admin/ats" component={ATSRouter} />
                    <ProtectedRoute path="/admin/permissions" component={PermissionsRouter} />
                    <ProtectedRoute path="/admin/reports" component={ReportsRouter} />
                    <ProtectedRoute path="/time" component={TimeTrackingRouter} />
                    <ProtectedRoute path="/delivery" component={DeliveryRouter} />
                  </Switch>
                </Router>
              )}
              {!permissions && <WaitingForPermissions />}
            </Box>
          </Stack>
        </HeaderToggleContext.Provider>
      </Stack>
    </ThemeProvider>
  );
}

function AppRoutes(): JSX.Element {
  const errorState = useSelector((state: RootStateType) => state.error);
  const dispatch = useDispatch();
  const { showModal } = useModal();
  useEffect(() => {
    if (errorState) {
      const { destroy } = showModal(SimpleDialog, {
        content: errorState.error,
        actions: [
          {
            label: 'OK',
            onClick: () => {
              destroy();
              dispatch({ type: CLEAR_GLOBAL_ERROR });
            },
          },
        ],
      });
    }
  }, [errorState]);

  return (
    <Router history={history}>
      <Switch>
        <RedirectRoute path="/login" component={AdminLogin} />
        <RedirectRoute path="/employee/login" component={EmployeeLogin} />
        <ProtectedRoute path="*" component={LoggedinRoutes} />
      </Switch>
    </Router>
  );
}

export default AppRoutes;
